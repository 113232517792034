import React, { useMemo } from 'react';
import { isEmpty, map } from 'lodash';
import Scrollbars from 'react-custom-scrollbars';
import { DedicatedEventProvider, isHidden, useApplicationState } from '@apollo/core';
import { SPORT_SERVICE } from '@apollo/core/src/constants';
import { hasMarketTypes } from '@apollo/core/src/utils/status';
import { Link } from 'react-router-dom';
import TopEvent from '../../../Sports/TopEvents/TopEvent/TopEvent';
import EventCarousel from './EventCarousel';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import { TournamentLink, SPORTS_TABS } from '../../../../shared/components/Sport/SportLinks';

const SportEvents = ({
  title,
  sportUrlName,
  categoryUrlName,
  tournamentUrlName,
  events,
  linkHash,
  isSwipeableCarousel = false,
}) => {
  const { layout } = useApplicationState();
  const t = useTranslate();

  const filteredEvents = useMemo(
    () => events?.filter((e) => {
      if (e.sportService !== SPORT_SERVICE.PREMATCH) {
        return false;
      }
      if (isHidden(e) || !hasMarketTypes(e)) {
        return false;
      }
      return true;
    }) || [],
    [events],
  );

  if (isEmpty(filteredEvents)) {
    return null;
  }

  return (
    <div className='sport-events'>
      {!isEmpty(title) && (
      <div className='sport-events__header'>
        <h3 className='sport-events__title'>{title}</h3>
        {!sportUrlName && linkHash === SPORTS_TABS.UPCOMING_SPORTS && (
        <Link to='/sportsbook#upcoming-sports' className='sport-events__link-all'>
          {t('see_all')}
        </Link>
        )}
        {sportUrlName && (
        <TournamentLink
          sportUrlName={sportUrlName}
          categoryUrlName={categoryUrlName}
          tournamentUrlName={tournamentUrlName}
          className='sport-events__link-all'
        >
          {t('see_all')}
        </TournamentLink>
        )}
      </div>
      )}
      <div className='sport-events__list'>
        {layout.mobileDevice === true && isSwipeableCarousel ? (
          <EventCarousel events={filteredEvents} countSlideShow={3} />
        ) : (
          <Scrollbars className='scroll__container' autoHeight>
            {map(filteredEvents, (event) => (
              <DedicatedEventProvider event={event} key={event.id}>
                <TopEvent className='sport-events__list-item' />
              </DedicatedEventProvider>
            ))}
          </Scrollbars>
        )}
      </div>
    </div>
  );
};
export default SportEvents;
