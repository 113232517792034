import React, { useEffect, useMemo } from 'react';
import { useApplicationState } from '@apollo/core';
import _ from 'lodash';
import { onSignInSubmit, useActiveForm } from '@apollo/react-forms';

import { Redirect, useHistory, useLocation } from 'react-router-dom';
import useTranslate from '../../components/I18n/Interpreter';
import CmdLink from '../../components/CmdLink';
import Label from '../../components/I18n/Label';
import FormCom from '../../components/Form/FormCom';
import Navigation from './Navigation';
import Loader from '../../components/Loader';
import AuthBlock from '../AuthBlock/AuthBlock';
import SignUpLink from '../../components/SignUp/SignUpLink';

const SignIn = () => {
  const applicationState = useApplicationState();
  const authenticated = _.get(applicationState, 'authenticated');
  const resolved = _.get(applicationState, 'resolved');

  const t = useTranslate();
  const { replace: historyReplace, goBack } = useHistory();
  const { pathname, search } = useLocation();
  const { types, activeType, activeStep, setActiveType, submit } = useActiveForm(
    'CLIENT_LOGIN',
    onSignInSubmit
  );
  const initialCaptchaKey = _.get(activeType, 'optionalParams.captchaKey');
  const initialCaptchaRequired = _.get(activeType, 'optionalParams.captchaRequired');

  useEffect(() => {
    const params = new URLSearchParams(search);
    const path = params.get('path');

    const newActiveType = _.find(types, ({ title }) => path === _.camelCase(title));

    if (newActiveType === activeType) {
      return;
    }

    if (newActiveType) {
      setActiveType(newActiveType);
    } else if (activeType) {
      params.set('path', _.camelCase(activeType.title));

      historyReplace(`${pathname}?${params.toString()}`);
    }
  }, [historyReplace, pathname, activeType, types, search, setActiveType]);

  const forgotPasswordRedirect = useMemo(
    () => (
      <CmdLink cmd='forgotPassword'>
        <Label message='forgot_password' />
      </CmdLink>
    ),
    []
  );

  const submitHandler = React.useCallback(() => {
    const params = new URLSearchParams(search);
    const redirect = params.get('redirect');

    if (redirect !== '') {
      goBack();
    }

    // ToastManager.success(t('welcome_back'));
  }, [search]);

  if (resolved && authenticated) {
    return <Redirect from='/' to={pathname} />;
  }

  const renderForm = activeStep ? (
    <FormCom
      id={activeType.id}
      action={submit}
      form={activeStep}
      className='form--signin'
      onSubmit={submitHandler}
      buttonText='sign_in'
      footerContent={forgotPasswordRedirect}
      initialCaptchaKey={initialCaptchaKey}
      initialCaptchaRequired={initialCaptchaRequired}
    />
  ) : (
    <Loader />
  );

  return (
    <AuthBlock>
      <div className='auth-block-modal__form signin__form'>
        <header className='auth-block-modal__container__header'>
          <h1 className='auth-block-modal__container__header__title'>
            <Label message='sign-in' />
          </h1>
        </header>
        <div className='auth-block-modal__form__top'>
          <div className='auth-block-modal__form__top__inputs stretch'>
            <div className='block'>
              <Navigation types={types} onChange={setActiveType} activeType={activeType} />
              {renderForm}
            </div>
          </div>
        </div>
      </div>
      <div className='auth-block-modal__container__footer'>
        <h3>{t('dont_have_acc')}</h3>
        <SignUpLink className='signup-link' />
      </div>
    </AuthBlock>
  );
};

export default SignIn;
