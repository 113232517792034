import { DeferredPromise } from '@apollo/core';

export default class FileSystemAdapter {
  constructor() {
    const { cordova, device } = window;

    if (!cordova) {
      throw Error('[FileSystemAdapter] This class can only be used when in Cordova mode.');
    }

    if (!device) {
      throw Error('[FileSystemAdapter] Unable to identify the current device.');
    }

    console.info('[FileSystemAdapter] Current device', device.platform?.toUpperCase(), cordova.file);
    switch (device.platform?.toUpperCase()) {
      case 'IOS':
        this.storageLocation = cordova.file.documentsDirectory;
        break;

      case 'ANDROID':
        this.storageLocation = `${cordova.file.externalRootDirectory}Documents`;
        break;

      default:
        this.storageLocation = cordova.file.dataDirectory;
    }
  }

  saveFile(fileName, fileData, type = 'text/plain') {
    const deferred = new DeferredPromise();
    console.info('[FileSystemAdapter] Save file', fileName, this.storageLocation);
    window.resolveLocalFileSystemURL(this.storageLocation, (dir) => {
      console.info('[FileSystemAdapter] Directory resolved', fileName, this.storageLocation);
      dir.getFile(fileName, {
        create: true,
        exclusive: false,
      }, (file) => {
        console.info('[FileSystemAdapter] File created', fileName, file);
        file.createWriter((fileWriter) => {
          // eslint-disable-next-line no-param-reassign
          fileWriter.onwriteend = () => {
            const url = file.toURL();
            console.info('[FileSystemAdapter] File write complete', url);
            deferred.resolve(() => url);
          };

          // eslint-disable-next-line no-param-reassign
          fileWriter.onerror = (error) => {
            console.error('[FileSystemAdapter] File writer encountered an error', error);
            deferred.reject(null);
          };

          const blob = new Blob([fileData], {
            type,
          });
          fileWriter.write(blob);
        }, (error) => {
          console.error('[FileSystemAdapter] Failed to write to file system', error);
          deferred.reject(null);
        });
      });
    }, (error) => {
      console.error('[FileSystemAdapter] Failed to load file system', error);
      deferred.reject(null);
    });

    return deferred;
  }
}
