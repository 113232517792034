import React, { useMemo } from 'react';
import { getRaceInfoFromOutcomeInfo } from '../../../core/utils';
import { sportService } from '../../../core/constants';
import RaceLink from '../Racing/RaceLink';
import { EventLink, TournamentLink } from '../Sport/SportLinks';

const BetItemEventLink = ({ outcomeInfo, children, className }) => {
  const isRacing = useMemo(
    () => outcomeInfo?.sportService?.toUpperCase() === sportService.RACING,
    [outcomeInfo]
  );

  const raceInfo = useMemo(
    () => (isRacing ? getRaceInfoFromOutcomeInfo(outcomeInfo) : {}),
    [outcomeInfo, isRacing]
  );

  if (!outcomeInfo) {
    return <span className={className}>{children}</span>;
  }

  if (isRacing && !_.isEmpty(raceInfo)) {
    return (
      <RaceLink {...raceInfo} className={className}>
        {children}
      </RaceLink>
    );
  }

  if (outcomeInfo?.eventOutright) {
    return (
      <TournamentLink
        sportUrlName={outcomeInfo.sportUrlName || outcomeInfo.sportName}
        categoryUrlName={outcomeInfo.categoryUrlName || outcomeInfo.categoryName}
        tournamentUrlName={outcomeInfo.tournamentUrlName || outcomeInfo.tournamentName}
        className={className}
      >
        {children}
      </TournamentLink>
    );
  }

  return (
    <EventLink className={className} event={outcomeInfo}>
      {children}
    </EventLink>
  );
};
export default BetItemEventLink;
