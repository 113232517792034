import { useEffect, useState } from 'react';

const useIsTablet = () => {
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isTabletDevice = /ipad|android(?!.*mobile)/i.test(userAgent);

    setIsTablet(isTabletDevice);
  }, []);

  return isTablet;
};

export default useIsTablet;
