import React from 'react';
import { useApplicationState } from '@apollo/core';
import Label from '../../../shared/components/I18n/Label';
import Odds from '../../../shared/components/Odds';

const RunnerOddsHistories = ({ runner, activeRace }) => {
  const oddsHistories = activeRace?.fwinOutcomesWithOddsHistories
    && activeRace?.fwinOutcomesWithOddsHistories[runner.number.toString()]
      ?.oddsHistories;

  const { layout } = useApplicationState();

  if (!oddsHistories || _.isEmpty(oddsHistories)) {
    return null;
  }

  return (
    <div className='event__odds-histories'>
      {oddsHistories.map((odds, i) => {
        if (layout.mobileDevice && i !== 0 && i < oddsHistories.length - 4) {
          // show open odds and last 4 odds.
          return null;
        }

        let classNamePostfix = '';
        if (i === 0) {
          classNamePostfix = 'open';
        } else if (odds.odds > oddsHistories[i - 1]?.odds) {
          classNamePostfix = 'up';
        } else {
          classNamePostfix = 'down';
        }

        return (
          <div
            key={odds.index + odds.time}
            className={`event__odds-histories-item event__odds-histories-item--${classNamePostfix}`}
          >
            {i === 0 ? (
              <span>
                <Label message='open odds' />
                : &nbsp;
              </span>
            ) : null}
            <Odds value={odds.odds} minDecimal={odds.odds < 10 ? 2 : 0} />
          </div>
        );
      })}
    </div>
  );
};

export default RunnerOddsHistories;
