import React, { useEffect, useMemo, useRef } from 'react';
import cx from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import { map } from 'lodash';
import moment from 'moment';
import { useRacingState } from '@apollo/core/src/state/racing/racing';
import RaceLink from '../../../shared/components/Racing/RaceLink';
import { RaceMainTabs } from '../../../core/constants';
import BetReturnBonusIcon from '../../../shared/components/Bonus/BetReturnBonusIcon';
import { defaultMainTab, isMultiLegsByMarketTypeId } from './utils';

const RaceNumberList = ({ availableMultilegs = [], activeMultiLegs = [], marketTab }) => {
  const [racingState] = useRacingState();
  const activeItemRef = useRef();

  const { activeMarketTypeId, activeRace, activeMeet, multilegsSelections = [] } = racingState;

  const isMultiLegsMarket = useMemo(
    () => isMultiLegsByMarketTypeId(activeMarketTypeId),
    [activeMarketTypeId]
  );

  useEffect(() => {
    const { current } = activeItemRef;
    if (current !== null) {
      current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, [activeRace.raceId]);

  return (
    <div className='race__number__list'>
      <Scrollbars className='scroll__container'>
        {map(activeMeet.races, (r, index) => (
          <div
            ref={activeRace.raceId === r.raceId ? activeItemRef : null}
            key={`${r.raceId}_${activeRace.raceId}`}
            className={cx('race__number__item', `race__number__item--${r.status}`)}
          >
            <RaceLink
              className={cx(
                'race__number__item_option',
                {
                  active: activeRace && r.number === activeRace.number,
                },
                {
                  item__multilegs:
                    activeRace && isMultiLegsMarket && activeMultiLegs?.includes(`${r.number}`),
                },
                {
                  'item__multilegs--selected':
                    activeRace &&
                    isMultiLegsMarket &&
                    activeMultiLegs?.includes(`${r.number}`) &&
                    multilegsSelections[activeMultiLegs.indexOf(`${r.number}`)]?.length,
                }
              )}
              raceDate={activeMeet.date}
              raceType={activeMeet.raceType.typeName}
              raceCountry={activeMeet.venue.country}
              raceVenue={activeMeet.venue.venueName}
              raceNumber={r.number}
              marketTab={
                marketTab === RaceMainTabs.MULTILEGS_TAB &&
                !availableMultilegs.includes(r.number?.toString())
                  ? defaultMainTab
                  : marketTab
              }
              marketType={
                activeRace && isMultiLegsMarket && activeMultiLegs?.includes(`${r.number}`)
                  ? activeMarketTypeId
                  : null
              }
            >
              <div className='race-number'>
                <span className='race-number--r'>R</span>
                {r.number}
              </div>
              <div className='startTime'>{moment(r.startTime).format('HH:mm')}</div>
              <div className='race-number--tick' />
              <BetReturnBonusIcon race={r} />
            </RaceLink>
          </div>
        ))}
      </Scrollbars>
    </div>
  );
};

export default RaceNumberList;
