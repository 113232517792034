import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import Input from '../../Input/Input';
import { getIsFieldRequired } from '../../../../../core/utils';

const TextField = ({
  field, onChange, onFocus, onBlur,
}) => {
  const {
    touched,
    value,
    autofocus,
    errors,
    active,
    label,
    placeholder,
    disabled,
    name,
    rules,
  } = field;
  const inputValue = _.isNull(value) ? '' : value;
  const changeHandler = useCallback((event) => onChange(event.target.value), [
    onChange,
  ]);
  const success = touched && !errors.length;

  return (
    <Input
      type='password'
      name={name}
      errors={value ? errors : rules}
      rules={rules}
      value={inputValue}
      placeholder={placeholder}
      success={success}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={changeHandler}
      autoFocus={autofocus}
      touched={touched}
      active={active}
      disabled={disabled}
      label={label}
      isRequiredSymbol={getIsFieldRequired(rules)}
      withTranslate
    />
  );
};

export default TextField;
