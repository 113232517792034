import React from 'react';
import Label from '../../../shared/components/I18n/Label';

const RaceFlucsHeader = () => (
  <div className='event__flucs event__flucs--header'>
    <div className='event__flucs-item event__flucs-item--open'>
      <Label className='flucs-label' message='flucs open header' />
    </div>
    <div className='event__flucs-item event__flucs-item--fluc1'>
      <Label className='flucs-label' message='flucs 1 header' />
    </div>
    <div className='event__flucs-item event__flucs-item--fluc2'>
      <Label className='flucs-label' message='flucs 2 header' />
    </div>
  </div>
);

export default RaceFlucsHeader;
