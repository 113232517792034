import React, { useMemo, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { RaceTypesMap } from '@apollo/core/src/constants';
import { useApplicationState } from '@apollo/core';
import { useRacingState } from '@apollo/core/src/state/racing/racing';
import RaceLink from '../../../shared/components/Racing/RaceLink';
import RaceCountdown from '../../../shared/components/Racing/RaceCountdown/RaceCountdown';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import { RacingStatus } from '../../../shared/components/Racing/RacingStatus';
import { RacingIcon } from '../../../shared/components/Racing/RacingIcon';
import Dropdown from '../../../shared/components/Dropdown/Dropdown';
import RaceFlucsToggle from '../raceFlucs/RaceFlucsToggle';
import Label from '../../../shared/components/I18n/Label';
import ToggleCheckbox from '../../../shared/components/ToggleCheckbox/ToggleCheckbox';
import { getRaceCompleteText } from '../../../core/utils';
import { AnimatedDropdown } from '../../Animations/AnimatedDropdown';
import RaceNumberList from './RaceNumberList';
import RaceMarketTabs from './RaceMarketTabs';
import RaceMarketSubTabs from './RaceMarketSubTabs';
import { defaultMainTab } from './utils';

const RacePageHead = ({
  displaySettings,
  setDisplaySettings,
  marketTabs,
  marketSubTabs,
  availableMultilegs = [],
  activeMultiLegs = [],
}) => {
  const t = useTranslate();
  const [racingState] = useRacingState();

  const { layout } = useApplicationState();

  const {
    activeMarketTypeId,
    meetings,
    activeRace,
    activeMeet,
    marketTab = defaultMainTab,
  } = racingState;

  const [isShowRaceList, setIsShowRaceList] = useState(false);

  const meetingsList = useMemo(() => {
    if (activeMeet == null) {
      return [];
    }
    return (meetings || []).filter(
      ({ type, country, date }) => type === activeMeet.type
        && date === activeMeet.date
        && (activeMeet.country === 'AUS' ? country === 'AUS' : country !== 'AUS'),
    );
  }, [meetings, activeMeet]);

  return (
    <>
      <div className='race-container'>
        <div className='race-nav__container'>
          <div className='race-page__header'>
            <div className='race__info'>
              <i
                className={`flag__sport flag--${activeMeet.venue.country}`}
                title={activeMeet.venue.venueName}
              />
              {activeRace ? (
                <RacingIcon id={activeRace.type} name={RaceTypesMap?.[activeRace.type]?.typeName} />
              ) : null}
              <Dropdown onExpanded={setIsShowRaceList} className='race-list-dropdown'>
                <div className='race-name' title={activeMeet.venue.venueName}>
                  <h1 className='size-24'>
                    {activeMeet.venue.venueName}
                    {activeRace?.number && !layout.mobileDevice && (
                      <span className='race__metadata-number'>{`R${activeRace.number}`}</span>
                    )}
                  </h1>
                </div>
                <AnimatedDropdown
                  isExpanded={isShowRaceList}
                  className='race-list race-list__dropdown'
                  endOverflow='auto'
                >
                  {meetingsList.map((meeting) => (
                    <div
                      key={meeting.meetingId}
                      className={cx([
                        'race-list-item',
                        meeting.meetingId === activeMeet.meetingId && ' active',
                      ])}
                    >
                      <RaceLink
                        raceDate={meeting.date}
                        raceType={meeting.raceType.typeName}
                        raceCountry={meeting.country}
                        raceVenue={meeting.venueName}
                        raceNumber={meeting.races[0]?.number}
                      >
                        {meeting.venueName}
                      </RaceLink>
                    </div>
                  ))}
                </AnimatedDropdown>
              </Dropdown>

              {activeRace && (
                <div className='race-track'>
                  {activeRace?.going && (
                    <span>
                      <span>{activeRace.going}</span>
                    </span>
                  )}
                </div>
              )}

              {activeRace && (
                <div className='race-countdown-wrapper' title={`id:${activeRace.raceId}`}>
                  <RaceCountdown
                    key={`${activeRace.raceId}-${activeRace.status}-${activeRace.result}`}
                    date={new Date(activeRace.startTime)}
                    completeText={getRaceCompleteText(activeRace)}
                  />
                  <RacingStatus status={activeRace.status} />
                </div>
              )}
            </div>
          </div>
          <div className='race__meta-numbers'>
            <div className='race__metadata'>
              {activeRace?.distance && (
                <span className='race__metadata-distance'>
                  <span>
                    {activeRace.distance}
                    m
                  </span>
                </span>
              )}

              {activeRace?.title ? <span className='race-title'>{activeRace?.title}</span> : null}
              {activeRace?.startTime && (
                <span className='metadata metadata--race-date'>
                  {moment(activeRace.startTime).format('DD/MM/YYYY hh:mma')}
                </span>
              )}

              {activeRace?.weather && (
                <span className='race__metadata-weather'>
                  <em>
                    {t('Weather')}
                    :
                    {' '}
                  </em>
                  <span>{activeRace.weather}</span>
                </span>
              )}

              {activeRace?.raceClass && (
                <span className='race__metadata-raceClass'>
                  <em>
                    {t('Race Class')}
                    :
                    {' '}
                  </em>
                  <span>{activeRace.raceClass}</span>
                </span>
              )}
            </div>
            <div className='race-numbers race-numbers--mobile'>
              <div className='race__number__container'>
                <RaceNumberList
                  marketTab={marketTab}
                  activeMultiLegs={activeMultiLegs}
                  availableMultilegs={availableMultilegs}
                />
              </div>
            </div>
          </div>
          <RaceMarketTabs
            activeTab={marketTab}
            marketTabs={marketTabs}
            marketSubTabs={marketSubTabs}
          />
        </div>
        <div className='display-settings tournament__header'>
          <div className='display-settings__item'>
            <div className='settings--toggle'>
              <Label message='race speed map' />
              <ToggleCheckbox
                checked={displaySettings.isSpeedMap}
                onChangedHandler={() => setDisplaySettings({
                  ...displaySettings,
                  isSpeedMap: !displaySettings.isSpeedMap,
                })}
              />
            </div>
          </div>
          <div className='display-settings__item'>
            <div className='settings--toggle'>
              <Label message='race form' />
              <ToggleCheckbox
                checked={displaySettings.isForm}
                onChangedHandler={() => setDisplaySettings({
                  ...displaySettings,
                  isForm: !displaySettings.isForm,
                })}
              />
            </div>
          </div>
          <div className='display-settings__item'>
            <RaceFlucsToggle
              className='settings--toggle'
              isFlucs={displaySettings.isFlucs}
              toggleIsFlucs={() => setDisplaySettings({
                ...displaySettings,
                isFlucs: !displaySettings.isFlucs,
              })}
            />
          </div>
        </div>
      </div>

      {marketSubTabs[marketTab]?.length > 1 ? (
        <RaceMarketSubTabs
          activeTab={marketTab}
          activeMarketTypeId={activeMarketTypeId}
          marketSubTabs={marketSubTabs}
        />
      ) : null}

      <div className='race-numbers '>
        <div className='race__number__container'>
          <RaceNumberList
            marketTab={marketTab}
            activeMultiLegs={activeMultiLegs}
            availableMultilegs={availableMultilegs}
          />
        </div>
      </div>
    </>
  );
};

export default RacePageHead;
