import React, { useCallback } from 'react';
import { getIsFieldRequired } from '../../../../../core/utils';
import MoneyInput from '../../MoneyInput/MoneyInput';

const MoneyField = ({ field, onChange, onFocus, onBlur }) => {
  const {
    type,
    touched,
    value,
    autofocus,
    disabled,
    active,
    label,
    placeholder,
    name,
    rules,
    errors,
    className,
    uiTitleClassName,
    amountIcon,
  } = field;

  // const inputValue = isFinite(value) ? value / 100 : value;

  const changeHandler = useCallback(
    (floatValue) => {
      // const newValue = isFinite(floatValue) ? round(floatValue * 100) : null;
      onChange(floatValue);
    },
    [onChange]
  );

  const success = touched && !errors[0];

  return (
    <MoneyInput
      value={value}
      type={type || 'tel'}
      disabled={disabled}
      touched={touched}
      errors={errors}
      active={active}
      label={label}
      withTranslate
      placeholder={placeholder}
      name={name}
      amountIcon={amountIcon}
      isRequiredSymbol={getIsFieldRequired(rules)}
      uiTitleClassName={uiTitleClassName}
      onChange={changeHandler}
      onFocus={onFocus}
      onBlur={onBlur}
      autofocus={autofocus}
      success={success}
      className={className}
    />
  );
};

export default MoneyField;
