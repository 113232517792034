import { FIELD_COMPONENTS } from '@apollo/react-forms';
import PhoneField from '../Fields/PhoneField';
import EmailWithVerification from '../Fields/EmailWithVerificationField';
import TextField from '../Fields/TextField';
import PasswordField from '../Fields/PasswordField';
import SelectField from '../Fields/SelectField';
import MoneyField from '../Fields/MoneyField';
import DaypickerField from '../Fields/DaypickerField/DaypickerField';
import DaypickerFieldNew from '../Fields/DaypickerField/DaypickerFieldNew';
import CheckboxField from '../Fields/CheckboxField';
import RadioSelectField from '../Fields/RadioSelectField';
import ComplexPasswordField from '../Fields/ComplexPasswordField';
import FileField from '../Fields/FileField';
import NumberField from '../Fields/NumberField/NumberField';
import HiddenField from '../Fields/HiddenField/HiddenField';
import AddressField from '../Fields/AddressField';
import ActionButtons from '../ActionButtons/ActionButtons';

const {
  TEXT,
  PASSWORD,
  SELECT,
  STRING,
  LIST,
  CHECKBOX,
  TIMESTAMP,
  CURRENCYPICKER,
  COMPLEX_PASSWORD,
  PHONE,
  EMAIL_WITH_VERIFICATION,
  PHONE_WITH_VERIFICATION,
  FILE,
  NUMBER,
  SMS_CODE,
  ADDRESS_AUTOCOMPLETE,
  HOW_DID_YOU_HEAR_ABOUT_US,
} = FIELD_COMPONENTS;

const fieldComponents = {
  text: TextField,
  hidden: HiddenField,
  password: PasswordField,
  select: SelectField,
  // daypicker: DaypickerField,
  daypicker: DaypickerFieldNew,
  checkbox: CheckboxField,
  radioselect: RadioSelectField,
  countrypicker: SelectField,
  money: MoneyField,
  file: FileField,

  actionButtons: ActionButtons,

  // Backend Form fields
  [TEXT]: TextField,
  [PASSWORD]: PasswordField,
  [SELECT]: SelectField,
  [STRING]: TextField,
  [LIST]: SelectField,
  [CHECKBOX]: CheckboxField,
  // [TIMESTAMP]: DaypickerField,
  [TIMESTAMP]: DaypickerFieldNew,
  [CURRENCYPICKER]: SelectField,
  [COMPLEX_PASSWORD]: ComplexPasswordField,
  [PHONE]: PhoneField,
  [EMAIL_WITH_VERIFICATION]: EmailWithVerification,
  [PHONE_WITH_VERIFICATION]: PhoneField,
  [FILE]: FileField,
  [NUMBER]: NumberField,
  [SMS_CODE]: NumberField,
  [ADDRESS_AUTOCOMPLETE]: AddressField,
  [HOW_DID_YOU_HEAR_ABOUT_US]: TextField, // TODO: add a special component for selecting "how did you hear about us"
};

export { fieldComponents };
