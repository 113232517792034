import React, { useCallback } from 'react';
import { NumericFormat } from 'react-number-format';
import Input from '../../Input';
import { getIsFieldRequired } from '../../../../../core/utils';

const NumberField = ({
  field,
  onChange,
  onFocus,
  onBlur,
}) => {
  const {
    type,
    touched,
    value,
    autofocus,
    disabled,
    active,
    label,
    placeholder,
    name,
    rules,
    errors,
    className,
    uiTitleClassName,
    amountIcon,
  } = field;

  const changeHandler = useCallback((event) => {
    const { value } = event.target;
    const formattedValue = value === '' ? null : Number(value);
    onChange(formattedValue);
  }, [onChange]);

  const error = touched && errors[0] ? errors[0] : '';
  const success = touched && !errors[0];

  return (
    <NumericFormat
      type={type || 'tel'}
      customInput={Input}
      name={name}
      error={error}
      touched={touched}
      active={active}
      value={value}
      placeholder={placeholder}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={changeHandler}
      autoFocus={autofocus}
      disabled={disabled}
      label={label}
      isRequiredSymbol={getIsFieldRequired(rules)}
      success={success}
      className={className}
      uiTitleClassName={uiTitleClassName}
      amountIcon={amountIcon}
      withTranslate
    />
  );
};

export default NumberField;
