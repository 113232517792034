import React from 'react';
import {
  map,
  isEmpty,
} from 'lodash';
import {
  useCategoryState,
  useSportsbookState,
} from '@apollo/core';

import {
  isUrlNamesMatch,
} from '@apollo/routing';

import TournamentListItem from './TournamentListItem';
import Loader from '../../../shared/components/Loader';
import Label from '../../../shared/components/I18n/Label';

const TournamentList = () => {
  const [sportsState] = useSportsbookState();
  const {
    selectedTournamentUrlName,
  } = sportsState;

  const {
    name, regionId, tournaments, loading,
  } = useCategoryState();

  if (loading) {
    return (
      <Loader />
    );
  }

  if (isEmpty(tournaments)) {
    return (
      <div className='message'>
        <Label message='no_tournaments_found' />
      </div>
    );
  }

  const tournamentComponents = map(tournaments, (tournament) => (
    <TournamentListItem
      key={tournament.id}
      active={isUrlNamesMatch(selectedTournamentUrlName, tournament.urlName)}
      tournament={tournament}
    />
  ));

  return (
    <div className='resultGame'>
      <div className='bottom__block thee active'>
        <div title={name}>
          <span className={`icon-flag result flag--${regionId}`} />
          <span className='name'>{name}</span>
        </div>
      </div>

      <div className='resultGame__content'>
        {tournamentComponents}
      </div>
    </div>
  );
};

export default TournamentList;
