import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { useApplicationState, usePreferences } from '@apollo/core';
import Label from '../../../../shared/components/I18n/Label';
import IconFavorite from '../../../../shared/components/IconFavorite/IconFavorite';
import { sportProviders, sportService as sportServices } from '../../../../core/constants';
import OutcomeDropDownCell from '../../../../shared/components/DetailOdds/OutcomeSelectCell';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import { formatOdds } from '../../../../core/utils';

const MarketTypeDropDownView = ({
  event,
  marketType,
  isFavorite,
  data,
  marketTypesShowState,
  setMarketTypesShowState,
  isBadgeHeaderHidden,
  isSgm,
  activeGroup,
}) => {
  const t = useTranslate();

  const preferences = usePreferences();
  const isArchived = event.sportService === sportServices.ARCHIVED;

  const { selectFavoriteMarketType } = isArchived ? {} : preferences;

  const [isExpanded, setIsExpanded] = useState(() => {
    const savedState = _.get(
      marketTypesShowState,
      `${event.sportId}.${activeGroup?.text}.${marketType.id}`,
    );

    if (savedState !== undefined) {
      return savedState;
    }

    return activeGroup?.group ? marketType?.show : marketType?.groups?.includes('MAIN');
  });

  const applicationState = useApplicationState();
  const authenticated = _.get(applicationState, 'authenticated');

  const showSgmIcon = React.useMemo(
    () => Boolean(marketType.providerName === sportProviders.Sportcast || isSgm),
    [isSgm, marketType.providerName],
  );

  useEffect(() => {
    setIsExpanded(
      _.get(
        marketTypesShowState,
        `${event.sportId}.${activeGroup?.text}.${marketType.id}`,
        activeGroup?.group ? marketType?.show : marketType?.groups?.includes('MAIN'),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeGroup, event.sportId, marketType]);

  const selectFavoriteMarketTypeHandler = useCallback(
    (event) => {
      event.stopPropagation();
      selectFavoriteMarketType({ marketTypeId: marketType.id });
    },
    [marketType.id, selectFavoriteMarketType],
  );

  const toggleExpand = useCallback(() => {
    if (!isArchived) {
      setMarketTypesShowState((prevState) => ({
        ...prevState,
        [event?.sportId]: {
          ...prevState[event?.sportId],
          [activeGroup?.text]: {
            ...prevState[event?.sportId]?.[activeGroup?.text],
            [marketType.id]: !isExpanded,
          },
        },
      }));
    }
    setIsExpanded((isExpanded) => !isExpanded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsExpanded, isExpanded, event?.sportId, activeGroup?.group, marketType.id, isArchived]);

  const marketTitleClassName = cx('marketGroup__title', { isExpanded });

  const selectOptions = useMemo(
    () => data.rows.reduce((acc, row) => {
      row.forEach((outcome, outcomeIndex) => {
        if (!acc[outcomeIndex]) {
          acc[outcomeIndex] = [];
        }

        if (outcome.specifiers) {
          acc[outcomeIndex].push({
            text: `${outcome.specifiers} ${formatOdds(outcome.odds, 3, 2)}`,
            value: acc[outcomeIndex].length,
          });
        }
      });
      return acc;
    }, []),
    [data],
  );

  const getOddsFromOption = ({ text }) => Math.abs(Number(text.split(' ')[1]));

  const findDefaultOptionByOdds = (options, defaultOdds = 2) => {
    const sorted = options
      .map(({ text, value }) => {
        const odds = getOddsFromOption({ text });
        return { diff: Math.abs(odds - defaultOdds), value };
      })
      .sort(({ diff: a }, { diff: b }) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
    const optionPosition = sorted[0].value;
    return options[optionPosition];
  };

  const getOptionBySpecifierAbsValue = (options, specifier) => options.find(({ text }) => {
    const [optionSpecifier] = text.split(' ');
    return Math.abs(Number(specifier)) === Math.abs(Number(optionSpecifier));
  });

  const getHandlerForGetOutcome = useCallback(
    (columnIndex) => (rowIndex) => {
      try {
        return data.rows[rowIndex][columnIndex];
      } catch (error) {
        return data.rows[0];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.rows, selectOptions, isSgm],
  );

  const getDefaultOptionBySportId = (options, sportId) => {
    if (String(sportId) === '1') {
      getOptionBySpecifierAbsValue(options, '1.5');
    }
    return findDefaultOptionByOdds(options);
  };

  const getName = (outcome, outcomeIndex) => {
    if (marketType?.groups?.includes('OVER_UNDER')) {
      return outcome.name?.split(' ')[0];
    }
    return outcomeIndex ? event.participants?.away?.fullName : event.participants?.home?.fullName;
  };

  return (
    <div
      className={cx(
        'eventView__market',
        marketType.displayLayout
          ? `eventView__market--${marketType.displayLayout.toLowerCase()}`
          : '',
      )}
    >
      <div className='marketGroup'>
        <div
          className={marketTitleClassName}
          onClick={toggleExpand}
          title={`id:${marketType.id}:${marketType.providerName?.slice(0, 2)}`}
        >
          <span className='name'>{marketType.name}</span>
          {!isBadgeHeaderHidden && (
            <Label
              message='bet group title'
              render={(translation) => (
                <span className='relatedMarket' title={translation}>
                  <span className='relatedMarket__icon AkIcon-link-icon' />
                  <If condition={marketType.betGroups}>
                    <span className='relatedMarket__content'>
                      {
                        // temporary decision before core lib will be fixed
                        _.isArray(marketType.betGroups)
                          ? marketType.betGroups.join(', ')
                          : Object.values(marketType.betGroups).join(', ')
                      }
                    </span>
                  </If>
                </span>
              )}
            />
          )}
          {authenticated && !isBadgeHeaderHidden && (
            <IconFavorite isActive={isFavorite} onClick={selectFavoriteMarketTypeHandler} />
          )}
          {showSgmIcon && <span className='icons icon--sgm-green' title={t('sgm')} />}
          <span className='icon AIcon-angle-right' />
        </div>
        <If condition={isExpanded}>
          <div className='MarketTypePickLineView'>
            <div className='MarketTypePickLineView__row'>
              {data.rows[0].map((outcome, outcomeIndex) => (
                <OutcomeDropDownCell
                  isSgm={isSgm}
                  key={outcome.id}
                  name={getName(outcome, outcomeIndex)}
                  options={selectOptions[outcomeIndex]}
                  defaultOption={getDefaultOptionBySportId(
                    selectOptions[outcomeIndex],
                    event.sportId,
                  )}
                  marketType={marketType}
                  event={event}
                  isArchived={isArchived}
                  getOutcome={getHandlerForGetOutcome(outcomeIndex)}
                />
              ))}
            </div>
          </div>
        </If>
      </div>
    </div>
  );
};

export default MarketTypeDropDownView;
