import { map, orderBy } from 'lodash';
import React, { useEffect, useRef } from 'react';
import RaceCountdown from '../../../shared/components/Racing/RaceCountdown/RaceCountdown';
import RaceLink from '../../../shared/components/Racing/RaceLink';
import Label from '../../../shared/components/I18n/Label';
import { getRaceCompleteText } from '../../../core/utils';
import { RACE_COUNTRIES, RACE_STATUS } from '../../../core/constants';
import { RacingIcon } from '../../../shared/components/Racing/RacingIcon';
import BetReturnBonusIcon from '../../../shared/components/Bonus/BetReturnBonusIcon';
import PromoOddsBonusIcon from '../../../shared/components/Bonus/PromoOddsBonusIcon';

const VenuePageDesktop = ({ raceType: rt, raceDate, activeMarketTypeId, selectedCountries }) => {
  const scrollRef = useRef();
  const headerRef = useRef();

  const hasSelectedMultilegsType = (race, multilegsMarkets) => multilegsMarkets?.some((multilegsMarket) => {
    const legs = multilegsMarket.legs?.split('-') || [];
    return legs.includes(`${race?.number}`) && multilegsMarket.type === activeMarketTypeId;
  });

  const getMultilegsMarketType = (race) => {
    const legs = race?.meeting?.multilegsMarkets?.find(
      (multilegsMarket) => multilegsMarket.type === activeMarketTypeId,
    )?.legs;

    return legs ? `${activeMarketTypeId}:${legs}` : activeMarketTypeId;
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollLeft + 200,
        behavior: 'smooth',
      });
    }
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollLeft - 200,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current && headerRef.current) {
        headerRef.current.scrollLeft = scrollRef.current.scrollLeft;
      }
    };

    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  if (!rt?.venues?.local?.length && !rt?.venues?.international?.length) {
    return null;
  }

  return (
    <div className='venue_race_container'>
      <div key={rt.typeName} className='race_type_header__container'>
        <div className='race_type_header'>
          <RacingIcon id={rt.id} name={rt.typeName} />
          <div className='tournament-name' title={rt.typeName}>
            {rt.typeName}
          </div>
        </div>
      </div>
      <div className='venue_race_table_header_container'>
        <div className='venue_race_table_header' ref={headerRef}>
          <div className='venue_race_table_header_item first' />
          {Array(rt?.maxNumberOfRaces)
            .fill()
            .map((_v, i) => (
              <div key={i} className='venue_race_table_header_item'>
                {`R${i + 1}`}
              </div>
            ))}
        </div>
        {headerRef.current?.clientWidth < headerRef.current?.scrollWidth ? (
          <div className='venue_race_table_header-nav'>
            <button
              type='button'
              className='venue_race_table_header-nav__button'
              onClick={scrollRight}
            >
              <span className='icon AIcon-angle-right' />
            </button>
            <button
              type='button'
              className='venue_race_table_header-nav__button'
              onClick={scrollLeft}
            >
              <span className='icon AIcon-angle-left' />
            </button>
          </div>
        ) : null}
      </div>
      <div className='venue_race_table venue_race__desktop' ref={scrollRef}>
        <table className='venue_race_table_data'>
          {rt?.venues.local.length && selectedCountries[RACE_COUNTRIES.local] ? (
            <tbody>
              {map(orderBy(rt?.venues.local, ['countryName'], ['desc']), (venue) => (
                <tr className='venue_race_table_row' key={venue.venueId}>
                  <td className='venue_race_table_row_item__data first'>
                    <div className=''>
                      <RaceLink
                        raceDate={raceDate}
                        raceType={venue.raceType.typeName}
                        raceCountry={venue.country}
                        raceVenue={venue.venueName}
                        raceNumber={
                          venue.races?.find((r) => r.status === RACE_STATUS.OPEN)?.number
                          || venue.races[0]?.number
                        }
                        className=''
                      >
                        <div className='venue_race_table_row_item__data__link'>
                          <span className='name'>{venue.venueName}</span>
                          <div className='country'>
                            {venue.country === 'AUS' ? venue.state : venue.countryName}
                          </div>
                        </div>
                      </RaceLink>
                    </div>
                  </td>
                  {map(
                    Array(rt?.maxNumberOfRaces)
                      .fill()
                      .map((v, i) => i),
                    (i) => {
                      const race = venue?.races?.[i];

                      return (
                        <td
                          className={`venue_race_table_row_item__data ${race?.status}`}
                          key={`${race?.raceId}-${i}`}
                        >
                          {race ? (
                            <RaceLink
                              key={race.raceId}
                              raceDate={raceDate}
                              raceType={venue.raceType.typeName}
                              raceCountry={venue.country}
                              raceVenue={venue.venueName}
                              raceNumber={race.number}
                              marketType={
                                hasSelectedMultilegsType(race, venue.multilegsMarkets)
                                  ? getMultilegsMarketType(race)
                                  : null
                              }
                              className={`race-summary ${
                                hasSelectedMultilegsType(race, venue.multilegsMarkets)
                                  ? 'race-summary--highlighted'
                                  : ''
                              }`}
                            >
                              <div className='race-summary__timer'>
                                <RaceCountdown
                                  key={`${race.raceId}-${race.status}-${race.result}`}
                                  date={new Date(race.startTime)}
                                  completeText={getRaceCompleteText(race)}
                                />
                              </div>
                              <div className='race-summary__icons'>
                                {race?.hasFixedOdds ? (
                                  <div className='race-summary__fixed-odds'>FO</div>
                                ) : null}
                                <BetReturnBonusIcon race={race} />
                                <PromoOddsBonusIcon race={race} />
                              </div>
                            </RaceLink>
                          ) : null}
                        </td>
                      );
                    },
                  )}
                </tr>
              ))}
            </tbody>
          ) : null}
          {rt.venues.international.length && selectedCountries[RACE_COUNTRIES.international] ? (
            <tbody>
              {selectedCountries[RACE_COUNTRIES.local] ? (
                <tr className='venue_race_table_row_international'>
                  <td>
                    <Label message='International' />
                  </td>
                </tr>
              ) : null}
              {map(rt.venues.international, (venue) => (
                <tr className='venue_race_table_row' key={venue.venueId}>
                  <td className='venue_race_table_row_item__data first'>
                    <div className=''>
                      <RaceLink
                        raceDate={raceDate}
                        raceType={venue.raceType.typeName}
                        raceCountry={venue.country}
                        raceVenue={venue.venueName}
                        raceNumber={
                          venue.races?.find((r) => r.status === RACE_STATUS.OPEN)?.number
                          || venue.races[0]?.number
                        }
                        className=''
                      >
                        <div className='venue_race_table_row_item__data__link'>
                          <span className='name'>{venue.venueName}</span>
                          <div className='country'>{venue.countryName}</div>
                        </div>
                      </RaceLink>
                    </div>
                  </td>
                  {map(
                    Array(rt?.maxNumberOfRaces)
                      .fill()
                      .map((v, i) => i),
                    (i) => {
                      const race = venue?.races?.[i];
                      return (
                        <td
                          className={`venue_race_table_row_item__data ${race?.status}`}
                          key={`${race?.raceId}-${i}`}
                        >
                          {race ? (
                            <RaceLink
                              key={race.raceId}
                              raceDate={raceDate}
                              raceType={venue.raceType.typeName}
                              raceCountry={venue.country}
                              raceVenue={venue.venueName}
                              raceNumber={race.number}
                              className={`race-summary ${
                                hasSelectedMultilegsType(race, venue.multilegsMarkets)
                                  ? 'race-summary--highlighted'
                                  : ''
                              }`}
                              marketType={
                                hasSelectedMultilegsType(race, venue.multilegsMarkets)
                                  ? getMultilegsMarketType(race)
                                  : null
                              }
                            >
                              <div className='race-summary__timer'>
                                <RaceCountdown
                                  key={`${race.raceId}-${race.status}-${race.result}`}
                                  date={new Date(race.startTime)}
                                  completeText={getRaceCompleteText(race)}
                                />
                              </div>
                              <div className='race-summary__icons'>
                                {race?.hasFixedOdds ? (
                                  <div className='race-summary__fixed-odds'>FO</div>
                                ) : null}
                                <BetReturnBonusIcon race={race} />
                                <PromoOddsBonusIcon race={race} />
                              </div>
                            </RaceLink>
                          ) : null}
                        </td>
                      );
                    },
                  )}
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>
      </div>
    </div>
  );
};

export default VenuePageDesktop;
