import React from 'react';
import RaceLink from '../../../shared/components/Racing/RaceLink';

const RaceMarketTab = ({
  containerClassName,
  btnClassName,
  marketName,
  activeMeet,
  raceNumber,
  marketTab,
  marketType,
  refProps,
}) =>
  marketName && activeMeet && raceNumber ? (
    <RaceLink
      className={containerClassName}
      raceDate={activeMeet.date}
      raceType={activeMeet.raceType.typeName}
      raceCountry={activeMeet.venue.country}
      raceVenue={activeMeet.venue.venueName}
      raceNumber={raceNumber}
      marketTab={marketTab}
      marketType={marketType}
      refProps={refProps}
    >
      <div className={btnClassName}>
        <span className='market_name'>{marketName}</span>
      </div>
    </RaceLink>
  ) : null;

export default RaceMarketTab;
