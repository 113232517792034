import React from 'react';
import { SiteConfigManager, useSettings } from '@apollo/core';

import { COMPONENT_TYPES, SelectedEventQueryRoute, SelectedSportProvider } from '@apollo/routing';
import SportsbookLoader from '../SportsbookLoader';
import TopEvents from '../TopEvents/TopEvents';
import EventLoader from '../EventLoader';
import LiveSportsbook from './LiveSportsbook';
import LiveCategories from './LiveCategories';
import SportFilter from '../SportFilter';
import Details from '../DetailsPage/DetailsPage';

import StatscoreProvider from '../Statscore/StatscoreProvider';
import { BroadcastProvider } from '../../../state/Broadcast/BroadcastContext';
import MainBody from '../../../shared/components/MainBody/MainBody';
import SportsPathHandler from '../SportsPathHandler';

const { SPORT_LIVE } = COMPONENT_TYPES;

const Live = () => {
  const { topEvents = true } = SiteConfigManager.getComponentOptions(SPORT_LIVE);

  const { lang } = useSettings();

  return (
    <StatscoreProvider>
      <BroadcastProvider>
        <LiveSportsbook key={lang}>
          <SportsPathHandler>
            <SportsbookLoader>
              <MainBody className='main__body--sport-live'>
                {topEvents && <TopEvents />}

                <SelectedSportProvider>
                  <SportFilter />
                  <LiveCategories />
                </SelectedSportProvider>

                <SelectedEventQueryRoute>
                  <EventLoader>
                    <Details />
                  </EventLoader>
                </SelectedEventQueryRoute>
              </MainBody>
            </SportsbookLoader>
          </SportsPathHandler>
        </LiveSportsbook>
      </BroadcastProvider>
    </StatscoreProvider>
  );
};

export default Live;
