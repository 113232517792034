import {
  BetManager,
  Bus,
  isSuspended,
  useApplicationState,
  useHighlightedOutcome,
} from '@apollo/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BET_EVENT_TYPE } from '@apollo/core/src/constants';
import Select from '../Form/Select';
import useSgm from '../../../state/Sgm/SgmState';
import Odds from '../Odds';

const OutcomeSelectCell = ({
  isSgm,
  name,
  options,
  defaultOption,
  marketType,
  event,
  isArchived,
  getOutcome,
}) => {
  const { selectOutcome: selectOutcomeSgm, selectedOutcomes: selectedOutcomesSgm } = useSgm();
  const { layout } = useApplicationState();
  const [selectedOption, setSelectedOption] = useState();
  const selectedOutcome = useMemo(
    () => selectedOption && getOutcome(selectedOption.value),
    [getOutcome, selectedOption],
  );

  const { selected } = useHighlightedOutcome(selectedOutcome && selectedOutcome.id);

  useEffect(() => {
    setSelectedOption(defaultOption);
  }, [isSgm]);

  const onClickHandler = useCallback(() => {
    const outcome = getOutcome(selectedOption.value);
    // Preventing from adding suspended bets
    if (isSuspended(outcome) || isArchived) {
      return;
    }

    if (isSgm) {
      selectOutcomeSgm({
        outcomeId: outcome.id,
        marketTypeId: marketType.id,
        marketId: outcome.marketId,
      });
    } else {
      BetManager.selectBet({
        betEventType: BET_EVENT_TYPE.SPORT_PREMATCH,
        event,
        eventId: event.id,
        marketTypeId: marketType.id,
        marketId: outcome.marketId,
        outcomeId: outcome.id,
        betGroups: marketType.betGroups,
        isNew: true,
      });

      // Show Betslip
      if (layout.desktopDevice === true) {
        Bus.send({
          event: Bus.events.layout.setSideBarRight,
          data: true,
        });
      }
    }
  }, [
    getOutcome,
    selectedOption,
    isArchived,
    event,
    marketType.id,
    marketType.betGroups,
    layout.mobileDevice,
  ]);

  const isOutcomeSelected = useMemo(() => {
    if (isSgm && selectedOutcome) {
      return Boolean(selectedOutcomesSgm?.find((sel) => sel.outcomeId === selectedOutcome?.id));
    }

    return selected;
  }, [selected, selectedOutcomesSgm, selectedOutcome]);

  const optionRenderer = useCallback(({ text }) => {
    const [specifiers, odds] = text.split(' ');
    return (
      <div className='select-option__wrapper'>
        <span className='specifiers-value'>{specifiers}</span>
        <span className='odds-value'>{odds}</span>
      </div>
    );
  }, []);

  /*   const renderDirectionArrow = useMemo(
    () => (directionClassName ? (
      <i key='arrow' className={`arrow AkIcon-long-arrow-${directionClassName}-icon`} />
    ) : null),
    [directionClassName],
  ); */

  return (
    <div
      className={`market bet MarketTypeDefaultView__cell marketGroup__content odd-wrapper market--odd-box ${isOutcomeSelected ? 'selected' : ''} market--${marketType.displayLayout.toLowerCase()}`}
    >
      <div className='odd-wrapper__content'>
        <div className='name'>{name}</div>
        <span className={`odd ${isArchived ? 'odds--archived' : ''}`} onClick={onClickHandler}>
          {/* {renderDirectionArrow} */}
          <Odds value={selectedOutcome?.odds} decimal={3} minDecimal={2} />
        </span>
        <div className='pick-line-dropdown'>
          <Select
            value={selectedOption}
            onChange={setSelectedOption}
            options={options}
            optionRenderer={optionRenderer}
          />
        </div>
      </div>
    </div>
  );
};

export default OutcomeSelectCell;
