import React, { useMemo } from 'react';
import {
  BETSLIP_VIOLATION,
  DateService,
  useBetslipState,
  useObservableOddsContext,
} from '@apollo/core';
import _, { isEmpty } from 'lodash';
import { RacingIcon } from '../../../shared/components/Racing/RacingIcon';
import { sportService } from '../../../core/constants';
import BetItemEventLink from '../../../shared/components/BetItemEventLink/BetItemEventLink';

const BetItemMultiHeader = ({ betCount, title, multiBet, total, isSameMulti }) => {
  const { errors } = useBetslipState();

  const { hasUnacceptedOdds, odds: observableOdds } = useObservableOddsContext();

  const betError = React.useMemo(
    () => (multiBet && !isEmpty(errors)
      ? errors.find((err) => err.requestId === multiBet.requestId)
      : null),
    [errors, multiBet],
  );

  const isOddsChanged = React.useMemo(
    () => betError
      && betError.violations
      && betError.violations[0]?.code === BETSLIP_VIOLATION.OUTCOME_ODDS_CHANGED,
    [betError],
  );

  const initialOdds = useMemo(() => {
    let odds = 1;
    if (!_.isEmpty(observableOdds) && multiBet) {
      multiBet?.outcomes?.forEach((outcome) => {
        if (_.isNumber(observableOdds[outcome.outcomeId])) {
          odds *= observableOdds[outcome.outcomeId];
        }
      });
    }
    return odds;
  }, [observableOdds, multiBet]);

  const outcomeInfo = useMemo(() => multiBet.outcomes?.[0].outcomeInfo, [multiBet.outcomes]);

  return (
    <div className='event event--multi__wrapper'>
      <div className='event event--multi'>
        <div className='event__outcome-name'>
          {isSameMulti ? (
            outcomeInfo.sportService === sportService.RACING ? (
              <RacingIcon id={outcomeInfo.sportId} name={outcomeInfo.sportName} />
            ) : (
              <span
                className={`icon sportIcon-${outcomeInfo.sportId}`}
                title={outcomeInfo.sportName}
              />
            )
          ) : (
            <span className='icon-racing icon-leg-multi' title={title} />
          )}

          <span className='name'>
            {betCount}
            {' '}
            {title}
          </span>
        </div>
      </div>
      {isSameMulti ? (
        <BetItemEventLink outcomeInfo={outcomeInfo}>
          <div className='event__event-name'>{outcomeInfo.localizedEventName}</div>
          <div className='event-time event-time--bet'>
            {DateService.getFormattedDateWithTime(outcomeInfo.eventStartTime)}
          </div>
        </BetItemEventLink>
      ) : null}
    </div>
  );
};

export default BetItemMultiHeader;
