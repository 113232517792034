import React from 'react';
import { SiteConfigManager } from '@apollo/core';

import {
  availableAccountRoutes,
  CLIENT_PROFILE_COMPONENT_TYPES,
  clientProfileRoutes,
  COMPONENT_TYPES,
} from '@apollo/routing';
import Label from '../../../shared/components/I18n/Label';
import ClientProfileLinks from '../ClientProfileLinks';
import DetailRouter from './DetailRouter';

const { CLIENT_PROFILE } = COMPONENT_TYPES;
const { PROFILE } = CLIENT_PROFILE_COMPONENT_TYPES;
const { profile } = clientProfileRoutes;

const Details = () => {
  const { tabs = [] } = SiteConfigManager.getSubComponentOptions(CLIENT_PROFILE, PROFILE);

  return (
    <div className='main__page__account__block'>
      <div className='main__page__account__block__title'>
        <div className='name'>
          <Label message='my_profile' />
        </div>

        <ClientProfileLinks
          tabs={tabs}
          availableRoutes={availableAccountRoutes}
          parentRoute={profile}
        />
      </div>
      <div className='main__page__account__block__content'>
        <DetailRouter tabs={tabs} />
      </div>
    </div>
  );
};

export default Details;
