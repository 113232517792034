import React, { useCallback } from 'react';
import { NotificationManager } from '@apollo/core';
import Label from '../../../../../shared/components/I18n/Label';

const TransactionCancelButton = ({ onCancel }) => {
  const cancelHandler = useCallback(() => {
    NotificationManager.display({
      type: 'confirm',
      title: 'withdrawal_cancellation',
      message: 'confirm_withdrawal_cancellation',
      data: {
        onConfirm: onCancel,
      },
    });
  }, []);

  return (
    <div
      onClick={cancelHandler}
      className='status-button red'
    >
      <Label message='cancel' />
    </div>
  );
};

export default TransactionCancelButton;
