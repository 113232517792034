import React, { useCallback, useMemo } from 'react';
import { onProfileUpdateSubmit, useActiveForm } from '@apollo/react-forms';
import _ from 'lodash';
import ToastManager from '../../../core/ToastManager';
import Label from '../../../shared/components/I18n/Label';
import FormCom from '../../../shared/components/Form/FormCom';

const PROFILE_UPDATE_SIMPLE = 'PROFILE_UPDATE_SIMPLE';

const PersonalDataForm = () => {
  const { types, resetActiveForm } = useActiveForm('PROFILE_UPDATE', onProfileUpdateSubmit);

  const submitHandler = useCallback(() => {
    resetActiveForm();
    ToastManager.success(<Label message='New data saved' />);
  }, [resetActiveForm]);

  const profileUpdateSimpleType = useMemo(
    () => _.find(types, ({ templateName }) => templateName === PROFILE_UPDATE_SIMPLE),
    [types]
  );

  if (!profileUpdateSimpleType) {
    return null;
  }

  const {
    steps: [form],
    id,
  } = profileUpdateSimpleType;

  return (
    <FormCom
      id={id}
      additionalValues={{ id: `${id}` }}
      form={form}
      action={onProfileUpdateSubmit}
      buttonText='save'
      onSubmit={submitHandler}
    />
  );
};

export default PersonalDataForm;
