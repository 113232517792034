import React, { useMemo } from 'react';
import {
  actions,
  getBonusAssignedToBet,
  getSingleBetItems,
  useBetslipDispatch,
  useBetslipState,
} from '@apollo/core';
import { BONUS_TYPE } from '@apollo/core/src/constants';
import MoneyInput from '../../../shared/components/Form/MoneyInput/MoneyInput';
import Label from '../../../shared/components/I18n/Label';

let betAmountPrev = 0; // to prevent BETSLIP_UPDATE_BETS event

const StakeAllBlock = () => {
  const { confirming, bonuses, bonusAssignments } = useBetslipState();

  const singleBetItems = getSingleBetItems();

  const betItems = useMemo(() => singleBetItems?.filter((bet) => {
    // skip betItems with FREEBET bonus
    const assignedBonus = getBonusAssignedToBet({ requestId: bet.requestId, bonusAssignments, bonuses });
    return !assignedBonus || assignedBonus.bonusType !== BONUS_TYPE.FREEBET;
  }) || [], [singleBetItems, bonuses, bonusAssignments]);

  const [amount, setAmount] = React.useState();

  const isDisabled = React.useMemo(
    () => singleBetItems.length && singleBetItems.some((betItem) => !betItem.active),
    [singleBetItems],
  );

  const dispatch = useBetslipDispatch();

  const handleBetAmountChange = React.useCallback(
    (amount) => {
      setAmount(amount);
      if (amount !== betAmountPrev) {
        dispatch({
          type: actions.BETSLIP_UPDATE_BETS,
          payload: {
            requestIds: betItems.map((bet) => bet.requestId),
            amount,
          },
        });
      }

      betAmountPrev = amount;
    },
    [dispatch, betItems],
  );

  React.useEffect(() => {
    if (betItems.length > 1) {
      if (betItems.every((betItem) => betItem.amount === betItems[0].amount)) {
        setAmount(betItems[0].amount);
      } else {
        setAmount(null);
        betAmountPrev = null;
      }
    } else {
      betAmountPrev = null;
    }
  }, [betItems]);

  return singleBetItems.length > 1 && betItems.length && !confirming
    ? (
      <div className='stake-all'>
        <div className='b-row'>
          <div className='b-col stake-all__label'>
            <Label message='stake all singles' />
          </div>
          <div className='b-col'>
            <MoneyInput
              value={amount}
              withTranslate
              disabled={isDisabled}
              onChange={handleBetAmountChange}
              placeholder='betslip_error_bet_amount_required'
            />
          </div>
        </div>

      </div>
    ) : null;
};

export default StakeAllBlock;
