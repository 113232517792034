import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { betHistoryConstants } from '@apollo/core';
import HistoryFilterListItem from '../HitoryFilterListItem';
import DateRangePicker from '../../../../shared/components/DateRangePicker/DateRangePicker';

const { dateRangeOptions, rangeName } = betHistoryConstants;

const CasinoHistoryFilterList = ({
  activeTimeFilter, setActiveTimeFilter, range, setRange,
}) => (
  <>
    <div className='history-filter'>
      <div />
      <div className='history-filter__date'>
        {
            _.map(dateRangeOptions, (rangeOption) => {
              const isChecked = rangeOption === activeTimeFilter;

              return (
                <HistoryFilterListItem
                  key={rangeOption}
                  message={rangeOption}
                  name={rangeOption}
                  isChecked={isChecked}
                  onClick={() => setActiveTimeFilter(rangeOption)}
                  radio
                />
              );
            })
          }
        <div className='date-range-picker'>
          <DateRangePicker
            disabled={activeTimeFilter !== rangeName}
            stateDateWrapper={(from, to) => {
              setRange({
                from: moment(from).startOf('day').valueOf(),
                to: moment(to).endOf('day').valueOf(),
              });
            }}
            initialStartDate={moment(_.get(range, 'from'))}
            initialEndDate={moment(_.get(range, 'to'))}
          />
        </div>
      </div>
    </div>
  </>
);

export default CasinoHistoryFilterList;
