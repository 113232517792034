import React from 'react';
import { Slide, ToastContainer as ToastContainerComponent } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastContainer = () => (
  <ToastContainerComponent
    position='top-center'
    hideProgressBar
    transition={Slide}
    closeButton={false}
    draggable={false}
    newestOnTop
    autoClose={5000}
  />
);

export default ToastContainer;
