import React, { useMemo } from 'react';
import _ from 'lodash';
import { centsToDecimal, DateService, useApplicationState } from '@apollo/core';
import { getCurrencySymbol } from '../../../core/utils';

const HistoryTransactionListItem = ({ number, time, cashOutReason, payout, t, amount, betId }) => {
  const applicationState = useApplicationState();
  const currency = _.get(applicationState, 'wallet.currency');

  const decimalAmount = useMemo(() => centsToDecimal(amount), [amount]);
  const decimalPayout = useMemo(() => centsToDecimal(payout), [payout]);
  const currencySymbol = getCurrencySymbol(currency);
  const formattedTime = DateService.getFormattedDateWithTime(time);

  return (
    <>
      <div>{betId}</div>
      <div>{number}</div>
      <div>{formattedTime}</div>
      <div>{t(cashOutReason)}</div>
      <div>
        {currencySymbol}
        {decimalAmount}
      </div>
      <div>
        {currencySymbol}
        {decimalPayout}
      </div>
    </>
  );
};

export default HistoryTransactionListItem;
