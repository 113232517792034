import React, { createContext } from 'react';

const SET_STATE = 'SET_STATE';
const SELECT_OUTCOME = 'SELECT_OUTCOME';
const CLEAR_OUTCOMES = 'CLEAR_OUTCOMES';
const REMOVE_OUTCOME = 'REMOVE_OUTCOME';

const defaultState = {
  selectedOutcomes: [],
  odds: null,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_STATE: {
      return {
        ...state,
        ...payload,
      };
    }
    case SELECT_OUTCOME: {
      const outcomes = state.selectedOutcomes;

      const index = outcomes.findIndex((o) => o.outcomeId === payload.outcomeId);
      if (index < 0) {
        return {
          ...state,
          selectedOutcomes: [...outcomes, payload],
        };
      } // unselect
      return {
        ...state,
        selectedOutcomes: outcomes.filter((o) => o.outcomeId !== payload.outcomeId),
      };
    }
    case REMOVE_OUTCOME: {
      const outcomes = state.selectedOutcomes;

      return {
        ...state,
        selectedOutcomes: outcomes.filter((o) => o.outcomeId !== payload.outcomeId),
      };
    }
    case CLEAR_OUTCOMES: {
      return {
        ...state,
        selectedOutcomes: [],
      };
    }
    default: {
      return state;
    }
  }
};

const useSgmState = () => {
  const [state, dispatch] = React.useReducer(reducer, defaultState);

  const selectOutcome = React.useCallback(
    ({ outcomeId, marketTypeId, marketId }) => {
      dispatch({ type: SELECT_OUTCOME, payload: { outcomeId, marketTypeId, marketId } });
    },
    [],
  );
  const removeOutcome = React.useCallback(
    ({ outcomeId }) => {
      dispatch({ type: REMOVE_OUTCOME, payload: { outcomeId } });
    },
    [],
  );
  const clearOutcomes = React.useCallback(
    () => {
      dispatch({ type: CLEAR_OUTCOMES });
    },
    [],
  );

  return {
    ...state,
    selectOutcome,
    removeOutcome,
    clearOutcomes,
  };
};

export const SgmContext = createContext();

export const SgmProvider = ({ children }) => {
  const sgmState = useSgmState();

  return (
    <SgmContext.Provider value={sgmState}>
      {children}
    </SgmContext.Provider>
  );
};

const useSgm = () => React.useContext(SgmContext);

export default useSgm;
