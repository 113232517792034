import React, { useMemo } from 'react';
import _ from 'lodash';
import { centsToDecimal, useApplicationState } from '@apollo/core';
import Label from '../../I18n/Label';
import StatusTooltip from '../../StatusTooltip/StatusTooltip';
import { getCurrencySymbol } from '../../../../core/utils';

const HeaderInfoAuthorizedBalance = () => {
  const applicationState = useApplicationState();
  const [currency, balance, BONUS] = _.at(applicationState, [
    'wallet.currency',
    'wallet.balance',
    'wallet.detailedBalance.BONUS',
  ]);

  const currencySymbol = getCurrencySymbol(currency);
  const decimalBalance = useMemo(() => centsToDecimal(balance), [balance]);
  const decimalBonus = useMemo(() => centsToDecimal(BONUS), [BONUS]);

  if (_.isNull(balance)) {
    return (
      <div className='verify-status'>
        <div className='label-with-icon status-label'>
          <Label message='balance_is_unavailable' />
        </div>
        <StatusTooltip message={<Label message='balance_is_unavailable_description' />}>
          <span className='status-label'>?</span>
        </StatusTooltip>
      </div>
    );
  }

  return (
    <div className='header__balance'>
      <div className='balance__main'>
        <span className='amount'>
          {currencySymbol}
          {decimalBalance}
        </span>
      </div>

      {BONUS > 0 && (
        <div className='balance__bonus'>
          <Label message='bonus_balance' className='text' />
          <span className='amount'>
            {currencySymbol}
            {decimalBonus}
          </span>
        </div>
      )}
    </div>
  );
};

export default HeaderInfoAuthorizedBalance;
