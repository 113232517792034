import React from 'react';
import _ from 'lodash';
import { useBetHistory } from '@apollo/core';
import { betTypes } from '../../../../../core/constants';
import BetHistoryMultipleType from './BetHistoryMultipleType';
import BetHistorySingleRow from './BetHistorySingleRow';
import Loader from '../../../../../shared/components/Loader';

const { MULTIPLE, SYSTEM } = betTypes;

const BetHistoryTableList = () => {
  const { bets, replaceBet, setOrderCashoutInfoToBet, isLoading } = useBetHistory();

  if (isLoading) {
    return <Loader />;
  }

  return _.map(bets, (bet) => {
    const { betType, betId } = bet;

    if (betType === MULTIPLE || betType === SYSTEM) {
      return (
        <BetHistoryMultipleType
          key={betId}
          bet={bet}
          replaceBet={replaceBet}
          setOrderCashoutInfoToBet={setOrderCashoutInfoToBet}
        />
      );
    }

    return (
      <BetHistorySingleRow
        key={betId}
        bet={bet}
        replaceBet={replaceBet}
        setOrderCashoutInfoToBet={setOrderCashoutInfoToBet}
      />
    );
  });
};
export default BetHistoryTableList;
