import React from 'react';
import {
  NotificationManager,
} from '@apollo/core';
import BasePopup from './BasePopup';


export default function InformationPopup(notification) {
  const {
    id,
    message,
    title,
  } = notification;

  const handleContinueButtonClick = React.useCallback(
    () => {
      NotificationManager.removeNotification(id);
    },
    [id],
  );

  return (
    <BasePopup
      onClose={handleContinueButtonClick}
      title={title}
      message={message}
      icon='info'
      withOkButton
    />
  );
}
