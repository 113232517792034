import React from 'react';
import _ from 'lodash';
import { isVisible } from '@apollo/core';

const useHasTournamentOnlyOutrightEvents = (events) =>
  React.useMemo(() => {
    if (!events || !events.length) {
      return false;
    }
    return !_.some(events, (event) => !event.outright && isVisible(event));
  }, [events]);

export default useHasTournamentOnlyOutrightEvents;
