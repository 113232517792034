import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import useSportsbookFilterState from './useSportsbookFilterState';
import FilterMoreButton from './FilterMoreButton';
import FilterDropdown from './FilterDropdown';

const SportsbookFilter = ({
  options = [],
  value,
  onChange,
  ItemComponent,
  MoreButtonComponent = FilterMoreButton,
  valueProperty,
  className,
}) => {
  const sportsbookFilterClassName = cx('sports-filter', className);
  const selectedItemRef = useRef(null);
  const optionsAmount = options.length;

  const { listRef, overflowItemIndex, isRendering, isDropdownVisible, handleDropdownToggle } = useSportsbookFilterState(options);

  useEffect(() => {
    if (selectedItemRef.current && listRef.current) {
      const tabOffsetLeft = selectedItemRef.current.offsetLeft;

      listRef.current.scrollTo({
        left: tabOffsetLeft - 50,
      });
    }
  }, [value]);

  const renderItems = React.useMemo(
    () => _.chain(options)
      .slice(0, isRendering || overflowItemIndex === null ? options.length : overflowItemIndex)
      .map((option, index) => {
        const Component = option.ItemComponent || ItemComponent;
        const isSelected = option[valueProperty] === value;
        return (
          <div
            key={option[valueProperty] || index}
            className='sports-filter__list-item'
            ref={isSelected ? selectedItemRef : null}
          >
            <Component option={option} onSelect={onChange} value={value} />
          </div>
        );
      })
      .value(),
    [options, valueProperty, ItemComponent, onChange, value, overflowItemIndex, isRendering],
  );

  const renderMoreButton = React.useMemo(() => {
    if (!isRendering && overflowItemIndex === null) {
      return null;
    }

    return (
      <MoreButtonComponent
        hiddenOptionsAmount={optionsAmount - overflowItemIndex}
        isDropdownExpanded={isDropdownVisible}
        onToggle={handleDropdownToggle}
        isActive={_.findIndex(options, { [valueProperty]: value }) >= overflowItemIndex}
      />
    );
  }, [
    options,
    isRendering,
    overflowItemIndex,
    isDropdownVisible,
    handleDropdownToggle,
    optionsAmount,
    value,
  ]);

  const renderDropdown = React.useMemo(() => {
    if (!isDropdownVisible) {
      return null;
    }

    return (
      <FilterDropdown onClose={handleDropdownToggle}>
        {_.chain(options)
          .slice(overflowItemIndex)
          .map((option) => {
            const Component = option.ItemComponent || ItemComponent;

            return (
              <div
                key={option[valueProperty] || 'null'}
                className='sportsbook-filter-dropdown-item-container'
              >
                <Component option={option} onSelect={onChange} value={value} />
              </div>
            );
          })
          .value()}
      </FilterDropdown>
    );
  }, [
    handleDropdownToggle,
    isDropdownVisible,
    overflowItemIndex,
    options,
    valueProperty,
    onChange,
    value,
  ]);

  return (
    <div className={sportsbookFilterClassName}>
      <div className='sports-filter__container'>
        <div className='sports-filter__list' ref={listRef}>
          {renderItems}
        </div>
        {renderMoreButton}
        {renderDropdown}
      </div>
    </div>
  );
};

export default SportsbookFilter;
