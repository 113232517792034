import { logger, NotificationManager, SiteConfigManager } from '@apollo/core';
import { appEvents, unixTime } from '../constants';
import ToastManager, { formatToastMessage } from '../ToastManager';

/*
  Bus Events Mapping:
    - Binding actions with bus events
    - Resource loading handler
    - Logging events

    - Binding history with bus too
*/
export default (Bus, history) => {
  /*
    Check if bus already got listeners
  */
  if (Bus.listenerCount('newListener') > 0) {
    return;
  }

  // Bus.on(Bus.events.socket.closed, (options) => {
  //   ToastManager.display({
  //     type: 'disconnect',
  //     status: 'error',
  //     title: 'Connection lost',
  //     message: 'ws_disconnected',
  //     data: options,
  //   });
  // });

  // On success place bet
  // Bus.on(Bus.events.bet.placedResult, () => {
  // });

  // Bus.on(Bus.events.auth.unauthorized, (options) => {
  //   ToastManager.display({
  //     type: 'disconnect',
  //     status: 'error',
  //     title: 'user_disconnected',
  //     message: 'please_login',
  //     data: options,
  //   });
  // });

  // Bus.on(Bus.events.internal.error, ({ error, message }) => {
  //   ToastManager.notify({
  //     type: 'notification',
  //     status: 'error',
  //     title: error,
  //     message,
  //   });
  // });

  // --------------------------
  // Auth
  // --------------------------
  // Bus.on('auth:login', (profile) => {
  //   // Bus.invoke(
  //   //   authorize(profile)
  //   // )
  //
  //   ToastManager.notify({
  //     type: 'notification',
  //     status: 'success',
  //     title: 'Authorization',
  //     message: 'You have successfully logged in',
  //   });
  //
  //   // Go to main
  //   history.push(history.location.pathname);
  // });

  // Bus.on('auth:registation', () => {
  //   ToastManager.notify({
  //     type: 'notification',
  //     status: 'success',
  //     title: 'Authorization',
  //     message: 'You have successfully signed up',
  //   });
  //
  //   // Go to main
  //   history.push(`${history.location.pathname}?cmd=account&path=/deposit`);
  // });

  // Bus.on('auth:formRegistationSuccess', async (credentials) => {
  //   const popupList = [{ type: 'registrationBonus', data: {} }];
  //
  //   if (credentials.showPopup) {
  //     popupList.unshift({
  //       type: 'oneClickSignUp',
  //       data: credentials,
  //     });
  //   }
  //
  //   // Add ability for each popup to open next one on close current
  //   forEach(popupList, (popup, index) => {
  //     const nextPopup = popupList[index + 1];
  //     popup.data.onClose = nextPopup ? () => NotificationManager.display(nextPopup) : noop;
  //   });
  //
  //   if (popupList[0]) {
  //     NotificationManager.display(popupList[0]);
  //   }
  // });

  Bus.on(Bus.events.auth.login, (profile) => {
    // Reload data so that any UI restrictions are applied
    SiteConfigManager.resolveCmsConfig();
    Bus.emit('profile:bonus_reload');
    Bus.emit(Bus.events.sport.sportReInit);
    Bus.emit(Bus.events.racing.racingReInit);
  });

  Bus.on(Bus.events.auth.logout, () => {
    history.push('/');
    // This is done in Core Lib
    // AccountManager.logout();
  });

  // Bus.on(Bus.events.auth.logout, () => {
  //   ToastManager.display({
  //     type: 'authLogout',
  //     status: 'warning',
  //     title: 'authorization',
  //     message: 'popup_auth_logged_out'
  //   })
  // })

  // Bus.on(Bus.events.auth.resolved, () => {
  //   ToastManager.notify({
  //     type: 'notification',
  //     title: 'Authorization',
  //     message: 'Session restored',
  //   });
  // });

  // Bus.on(Bus.events.auth.unresolved, (error) => {
  //   // If no error provided
  //   // Then token not exist at all
  //   if (!error) {
  //     return;
  //   }

  //   ToastManager.notify({
  //     type: 'notification',
  //     title: 'Authorization',
  //     message: 'your_session_expired',
  //   });
  // });

  // ------------------------------------
  // Profile
  // ------------------------------------

  // Bus.on(Bus.events.profile.balanceUpdate, () => {
  //   ToastManager.notify({
  //     type: 'notification',
  //     status: 'warning',
  //     title: 'Balance Update',
  //     message: 'Your balance is updated',
  //   });
  // });

  Bus.on(Bus.events.profile.realityCheck, () => {
    NotificationManager.display({
      id: 'realityCheck',
      type: 'realityCheck',
      status: 'popup',
      title: 'reality_check_popup_title',
      message: 'reality_check_popup_message',
    });
  });

  Bus.on(appEvents.deviceResume, () => {
    SiteConfigManager.resolveCmsConfig();
  });

  Bus.on(appEvents.deviceNetworkOnline, () => {
    SiteConfigManager.resolveCmsConfig();
    Bus.emit('profile:bonus_reload');
    Bus.emit(Bus.events.sport.sportReInit);
    Bus.emit(Bus.events.racing.racingReInit);
  });

  Bus.on(appEvents.devicePause, () => {
    localStorage.setItem('devicePauseTime', JSON.stringify(Date.now()));
  });

  Bus.on(appEvents.deviceResume, () => {
    const now = Date.now();
    const devicePauseTime = localStorage.getItem('devicePauseTime');
    const sleepTime = now - devicePauseTime;
    const reInit = sleepTime >= unixTime.mins15;
    if (!devicePauseTime || reInit) {
      Bus.emit('profile:bonus_reload');
      Bus.emit(Bus.events.global.reloadTranslations);
      Bus.emit(Bus.events.sport.sportReInit);
      Bus.emit(Bus.events.racing.racingReInit);
    }
    logger.debug(`DEVICE RESUME: ${sleepTime} >= ${unixTime.mins15}`, reInit);
    localStorage.removeItem('devicePauseTime');
  });

  // ------------------------------------
  // Bet
  // ------------------------------------

  // Bus.on(Bus.events.bet.placed, ({ placeBetRequestId }) => {
  //   ToastManager.notify({
  //     type: 'notification',
  //     title: 'Bettor',
  //     message: `Bet #${placeBetRequestId} accepted`,
  //   });
  // });

  // Bus.on(Bus.events.bet.placeBetSuccessResult, ({ data }) => {
  //   ToastManager.notify({
  //     type: 'notification',
  //     status: 'success',
  //     title: 'Bettor',
  //     message: `Bet #${data.placeBetRequestId} success`,
  //   });
  // });

  // Bus.on(Bus.events.bet.placeBetFailResult, ({ data }) => {
  //   ToastManager.notify({
  //     type: 'notification',
  //     status: 'error',
  //     title: 'Bettor',
  //     message: `Bet #${data.placeBetRequestId} failed`,
  //   });
  // });

  // Bus.on(Bus.events.bet.editError, () => {
  //   ToastManager.notify({
  //     type: 'notification',
  //     status: 'error',
  //     title: 'Edit bet',
  //     message: 'Unable to edit bet',
  //   });
  // });

  // Bus.on(Bus.events.bet.editSuccess, (bet) => {
  //   ToastManager.notify({
  //     type: 'notification',
  //     status: 'success',
  //     title: `Bet ${bet.betId}`,
  //     message: 'Bet successfully edited',
  //   });
  // });

  // Bus.on('bet:orderPlaced', () => {
  //   ToastManager.notify({
  //     type: 'notification',
  //     status: 'success',
  //     title: 'Order bet',
  //     message: 'Your order bet placed',
  //   });
  // });

  // Bus.on('bet:cashoutSubmitted', () => {
  //   ToastManager.notify({
  //     type: 'notification',
  //     status: 'success',
  //     title: 'Cashout',
  //     message: 'Cashout successful',
  //   });
  // });

  // Bus.on('bet:partialCashoutSubmitted', () => {
  //   ToastManager.notify({
  //     type: 'notification',
  //     status: 'success',
  //     title: 'Partial cashout',
  //     message: 'Partial cashout successful',
  //   });
  // });

  // Bus.on('bet:orderCashoutSubmitted', () => {
  //   ToastManager.notify({
  //     type: 'notification',
  //     status: 'success',
  //     title: 'Order cashout',
  //     message: 'Order cashout successful',
  //   });
  // });

  // Bus.on('socket:session', (payload) => {
  //   if (payload.data) {
  //     ToastManager.notify({
  //       type: 'notification',
  //       status: 'success',
  //       title: 'Session',
  //       message: payload.data,
  //     });
  //   }
  // });

  // Bus.on('socket:closed', () => {
  //   ToastManager.notify({
  //     type: 'notification',
  //     status: 'error',
  //     title: 'Disconnected',
  //     message: 'Connection closed',
  //   });
  // });

  // Bus.on('socket:error', (payload) => {
  //   ToastManager.notify({
  //     type: 'notification',
  //     status: 'error',
  //     title: 'Warning',
  //     message: payload.message,
  //   });
  // });

  // --------------------------

  // Bus.on('sport:error', (error) => {
  //   ToastManager.notify({
  //     type: 'notification',
  //     status: 'error',
  //     title: error.name,
  //     message: error.message,
  //   });
  // });

  /*
 CASHOUT
  */

  Bus.on('cashout:success', (data) => {
    NotificationManager.display({
      type: 'notification',
      status: 'success',
      title: 'cashout_success_title',
      message: 'cashout_success_message',
      data,
    });
  });

  Bus.on('partial_cashout:success', (data) => {
    NotificationManager.display({
      type: 'notification',
      status: 'success',
      title: 'partial_cashout_success_title',
      message: 'partial_cashout_success_message',
      data,
    });
  });

  Bus.on('order_cashout:success', (data) => {
    NotificationManager.display({
      type: 'notification',
      status: 'success',
      title: 'order_cashout_success_title',
      message: 'order_cashout_success_message',
      data,
    });
  });

  Bus.on('cashout:error', (data) => {
    NotificationManager.display({
      type: 'notification',
      status: 'error',
      title: 'cashout_error_title',
      message: 'cashout_error_message',
      data,
    });
  });

  Bus.on('partial_cashout:wrong_amount', (data) => {
    NotificationManager.display({
      type: 'notification',
      status: 'error',
      title: 'partial_cashout_wrong_amount_title',
      message: 'partial_cashout_wrong_amount_message',
      data,
    });
  });

  Bus.on('partial_cashout:error', (data) => {
    NotificationManager.display({
      type: 'notification',
      status: 'error',
      title: 'partial_cashout_error_title',
      message: 'partial_cashout_error_message',
      data,
    });
  });

  Bus.on('order_cashout:error', (data) => {
    NotificationManager.display({
      type: 'notification',
      status: 'error',
      title: 'order_cashout_error_title',
      message: 'order_cashout_error_message',
      data,
    });
  });

  Bus.on('order_partial_cashout:success', (data) => {
    NotificationManager.display({
      type: 'notification',
      status: 'success',
      title: 'order_partial_cashout_success_title',
      message: 'order_partial_cashout_success_message',
      data,
    });
  });

  Bus.on('order_partial_cashout:error', (data) => {
    NotificationManager.display({
      type: 'notification',
      status: 'error',
      title: 'order_partial_cashout_error_title',
      message: 'order_partial_cashout_error_message',
      data,
    });
  });

  Bus.on('cashout_not_allowed', (data) => {
    NotificationManager.display({
      type: 'notification',
      status: 'error',
      title: 'cashout_not_allowed_title',
      message: 'cashout_not_allowed_message',
      data,
    });
  });

  Bus.on(Bus.events.notification.showFlashMessage, (data) => {
    const props = JSON.parse(data.data);
    const messageContent = formatToastMessage(props.body);
    ToastManager.showNotification(props.status, messageContent);
  });

  Bus.on(Bus.events.notification.showModalWindow, (data) => {
    const props = JSON.parse(data.data);
    NotificationManager.display({
      id: 'templatePopup',
      type: 'templatePopup',
      data: { htmlContent: props.body },
      status: props.status,
    });
  });
};
