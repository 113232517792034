import React, { useCallback } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { getIsFieldRequired } from '../../../../../core/utils';
import Input from '../../Input/Input';

const DaypickerField = ({ field, onChange, onFocus, onBlur }) => {
  const { touched, value, autofocus, errors, warnings, active, label, name, rules, disabled } = field;
  const changeHandler = useCallback(
    (event) => {
      const newValue = moment.utc(event.target.value, 'YYYY-MM-DD');

      onChange(newValue.isValid() ? newValue.valueOf() : null);
    },
    [onChange],
  );
  const error = touched && errors[0];
  const warning = touched ? get(warnings, '[0].message') : null;
  const momentValue = moment.utc(value);
  const inputValue = momentValue.isValid() ? momentValue.format('YYYY-MM-DD') : '';
  // do not change "momentValue.isValid() ? DateService.getFormattedDate(value) : ''" - DateService.getFormattedDate(value) broke the input format;
  const success = touched && !error;

  return (
    <Input
      type='date'
      name={name}
      error={error}
      warning={warning}
      value={inputValue}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={changeHandler}
      autoFocus={autofocus}
      success={success}
      isRequiredSymbol={getIsFieldRequired(rules)}
      touched={touched}
      active={active}
      label={label}
      disabled={disabled}
      withTranslate
    />
  );
};

export default DaypickerField;
