import React from 'react';
import cx from 'classnames';
import { STATUS } from '@apollo/core/src/constants';
import moment from 'moment';
import { RACE_STATUS } from '../../../../core/constants';
import RaceCountdown from './RaceCountdown';

export default class RaceCountdownMobile extends RaceCountdown {
  render() {
    const {
      race,
      date,
      completeText,
    } = this.props;

    const {
      countdownText,
    } = this.state;

    let text = '';

    const className = cx(
      'race-countdown',
      {
        'race-countdown--success': this.secondsDifference <= 0 && completeText !== RACE_STATUS.OPEN,
        'race-countdown--danger': (completeText === RACE_STATUS.OPEN && this.secondsDifference < 0) || (this.secondsDifference > 0 && this.secondsDifference < 60),
        'race-countdown--warning': this.secondsDifference > 60 && this.secondsDifference < 300,
        'race-countdown--mobile': this.secondsDifference > 3600,
        'race-countdown--light--mobile': completeText === RACE_STATUS.OPEN && this.secondsDifference < 1800 && this.secondsDifference > 0,
      },
    );

    if ((completeText !== STATUS.ABANDONED && completeText === RACE_STATUS.OPEN && this.secondsDifference > 3600)) {
      text = moment(date).format('HH:mm');
    } else
    if ((completeText !== STATUS.ABANDONED && completeText === RACE_STATUS.OPEN)) {
      text = countdownText;
    }

    return (
      <div className={className}>
        {race ? <span className='race-number'>{`R${race?.number} `}</span> : null}
        {text}
        {
          completeText === STATUS.ABANDONED
            ? STATUS.ABANDONED.slice(0, 5)
            : this.secondsDifference <= 0 && completeText !== RACE_STATUS.OPEN && completeText
        }
      </div>
    );
  }
}
