import React, { useCallback } from 'react';
import { isNull } from 'lodash';
import Input from '../../Input/Input';
import { getIsFieldRequired } from '../../../../../core/utils';
import VerifyBlock from './VerifyBlock';
import Label from '../../../I18n/Label';

const EmailWithVerification = ({ field, onChange, onFocus, onBlur }) => {
  const {
    touched,
    value,
    autofocus,
    disabled,
    errors,
    active,
    label,
    placeholder,
    name,
    rules,
    className,
    uiTitleClassName,
    amountIcon,
  } = field;
  const inputValue = isNull(value) ? '' : value;
  const changeHandler = useCallback((event) => onChange(event.target.value), [onChange]);
  const error = touched && errors[0];
  const success = touched && !error;

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const cmd = params.get('cmd');

  return (
    <>
      <Input
        name={name}
        error={error}
        value={inputValue}
        placeholder={placeholder}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={changeHandler}
        autoFocus={autofocus}
        touched={touched}
        active={active}
        disabled={disabled}
        label={label}
        isRequiredSymbol={getIsFieldRequired(rules)}
        success={success}
        className={className}
        uiTitleClassName={uiTitleClassName}
        amountIcon={amountIcon}
        withTranslate
        leftContent={<VerifyBlock />}
      />
      {cmd === 'signup' && name === 'EMAIL' && (
        <p className='signup-email-explanation'>
          <Label message='signup_email_explanation' />
        </p>
      )}
    </>
  );
};

export default EmailWithVerification;
