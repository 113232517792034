import React, { useCallback, useMemo } from 'react';
import TranslateWithLinks from '../../../I18n/TranslateWithLinks';
import Label from '../../../I18n/Label';
import Checkbox from '../../../Checkbox/Checkbox';

const CheckboxField = ({
  field, onChange, onFocus, onBlur,
}) => {
  const {
    touched,
    value,
    autofocus,
    errors,
    active,
    label,
    placeholder,
    name,
    disabled,
  } = field;
  const checked = Boolean(value);
  const changeHandler = useCallback((event) => {
    onChange(event);
  }, [onChange]);
  const error = touched && errors[0];
  const renderLabel = useMemo(() => {
    const renderCheckboxLabel = (label) => (
      <label htmlFor={name}>
        <TranslateWithLinks translate={label} />
      </label>
    );

    return <Label message={label} render={renderCheckboxLabel} />;
  }, [name, label]);

  return (
    <Checkbox
      name={name}
      error={error}
      placeholder={placeholder}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={changeHandler}
      autoFocus={autofocus}
      touched={touched}
      active={active}
      checked={checked}
      disabled={disabled}
      withTranslate
    >
      {renderLabel}
    </Checkbox>
  );
};

export default CheckboxField;
