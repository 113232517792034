import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { ResponsibleApi, DateService, Bus } from '@apollo/core';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import FormCom from '../../../../shared/components/Form/FormCom';
import { selfExclusionForm } from '../../../../shared/components/Form/formConfig/responsibleGaming';
import { cmsStaticBlock } from '../../../../core/constants';
import StaticBlock from '../../../../shared/components/StaticBlock/StaticBlock';
import Label from '../../../../shared/components/I18n/Label';
import ModalPortal from '../../../../shared/components/ModalPortal/ModalPortal';
import Button from '../../../../shared/components/Button/Button';

const SelfExclusion = () => {
  const t = useTranslate();
  const [selfExcludedDueDate, setSelfExcludedDueDate] = useState();
  const [selectedSelfExcluded, setSelectedSelfExcluded] = useState();

  useEffect(() => {
    (async () => {
      const { selfExcludedDueDate: date } = await ResponsibleApi.getSelfExclusionSettings();

      if (date) {
        setSelfExcludedDueDate(date);
      }
    })();
  }, []);

  const handleSubmit = useCallback(
    async (selfExclusionSettings) => {
      setSelectedSelfExcluded(selfExclusionSettings);
    },
    [setSelfExcludedDueDate]
  );
  const handleConfirm = async () => {
    try {
      const { selfExcludedDueDate: date } =
        await ResponsibleApi.setSelfExclusionSettings(selectedSelfExcluded);

      setSelfExcludedDueDate(date);
      setSelectedSelfExcluded();
      Bus.emit(Bus.events.auth.logout);
    } catch (e) {
      console.error(e);
    }
  };

  const handleCancel = useCallback(() => {
    setSelectedSelfExcluded();
  }, []);

  const warningMessage = useMemo(() => {
    if (selfExcludedDueDate) {
      const date = DateService.getFormattedDateWithTime(selfExcludedDueDate);
      return (
        <div className='warning-message'>
          <span>{t('you_excluded_yourself_till')}:</span>
          <span>{date}</span>
        </div>
      );
    }

    return null;
  }, [selfExcludedDueDate, t]);

  const renderInfoMessage = useMemo(
    () => (
      <div className='block'>
        <StaticBlock options={{ source: cmsStaticBlock.SelfExclusionMessage }} />
        {/* t('self_exclusion_message') */}
      </div>
    ),
    [t]
  );

  // todo: probably should be a standard Confirmation pop-up or moved to "Modals" directory
  const renderConfirmationPopUp = useMemo(
    () => (
      <div className='popup'>
        <div className='popup__auth'>
          <div className='block'>
            <div className='block__title'>
              <div>
                <Label message='selfExclusion' />
              </div>
            </div>

            <div className='block__content selfExclusion'>
              <div className='messages'>
                <StaticBlock options={{ source: cmsStaticBlock.SelfExclusionConfirmation }} />
              </div>
              <div className='block group-button has-cancel has-ok'>
                <Button type='button' className='bCancel' onClick={handleCancel} label='Cancel' />
                <Button type='button' className='bBase' onClick={handleConfirm} label='Confirm' />
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    [selectedSelfExcluded]
  );

  return (
    <div className='main__page__account__block__content'>
      <div className='wrapper wrapper--self-exc'>
        <div className='details-profile details-profile--self-exc'>
          <FormCom
            formHints={renderInfoMessage}
            buttonText='start_break'
            buttonClasses='editLink editLink--danger'
            action={handleSubmit}
            form={selfExclusionForm}
            className={!selfExcludedDueDate && 'active'}
          />
          {warningMessage}

          {selectedSelfExcluded ? (
            <ModalPortal forceInteraction>{renderConfirmationPopUp}</ModalPortal>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SelfExclusion;
