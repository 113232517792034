import React from 'react';

const getPercentage = (current, min, max) => ((current - min) / (max - min)) * 100;

const getValue = (percentage, min, max) => ((max - min) / 100) * percentage + min;

const getWidth = (percentage) => `${percentage}%`;

const stopPropagation = (e) => e.stopPropagation();

const Range = ({
  initial,
  min,
  max,
  formatFn = (number) => number.toFixed(0),
  onChange,
}) => {
  const initialValue = initial < min ? min : initial > max ? max : initial;
  const initialPercentage = getPercentage(initialValue, min, max);

  const rangeRef = React.useRef();
  const rangeProgressRef = React.useRef();
  const thumbRef = React.useRef();

  const diff = React.useRef();

  const handleUpdate = React.useCallback(
    (value, percentage) => {
      rangeProgressRef.current.style.width = getWidth(percentage);
    },
    [formatFn],
  );

  const handleMouseMove = (event) => {
    let newX = event.clientX
      - diff.current
      - rangeRef.current.getBoundingClientRect().left;

    const end = rangeRef.current.offsetWidth - thumbRef.current.offsetWidth;

    const start = 0;

    if (newX < start) {
      newX = 0;
    }

    if (newX > end) {
      newX = end;
    }

    const newPercentage = getPercentage(newX, start, end);
    const newValue = getValue(newPercentage, min, max);

    handleUpdate(newValue, newPercentage);

    onChange(newValue);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('mousemove', handleMouseMove);
  };

  const handleMouseDown = (event) => {
    diff.current = event.clientX - thumbRef.current.getBoundingClientRect().left;

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  React.useLayoutEffect(() => {
    handleUpdate(initialValue, initialPercentage);
  }, [initialValue, initialPercentage, handleUpdate]);

  const onClickRange = React.useCallback((e) => {
    const withOfRange = rangeRef.current.offsetWidth;
    const clickOffset = e.nativeEvent.offsetX;

    const newValue = Math.round(max * (clickOffset / withOfRange));
    const newPercentage = getPercentage(clickOffset, 0, withOfRange);

    handleUpdate(newValue, newPercentage);

    onChange(newValue);
  }, [onChange, max]);

  return (
    <div
      className='range'
      ref={rangeRef}
      onClick={onClickRange}
    >
      <div className='range_thumb' ref={rangeProgressRef}>
        <div
          ref={thumbRef}
          onMouseDown={handleMouseDown}
          onClick={stopPropagation}
        />
      </div>

    </div>
  );
};

export default Range;
