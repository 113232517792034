import React, { useEffect } from 'react';
import { NotificationManager } from '@apollo/core';
import BasePopup from './BasePopup';
import { useParticleAnimation } from '../../features/Animations/ParticleAnimation';

const TemplatePopup = ({ id, status, title, data }) => {
  const { animationPrepared, runParticleAnimation, stopParticleAnimation } = useParticleAnimation();

  const handleContinueButtonClick = React.useCallback(() => {
    NotificationManager.removeNotification(id);
    stopParticleAnimation();
  }, [id, stopParticleAnimation]);

  useEffect(() => {
    if (animationPrepared) {
      runParticleAnimation();
    }
  }, [animationPrepared]);

  return (
    <BasePopup status={status} onClose={handleContinueButtonClick} title={title} icon={status}>
      <div dangerouslySetInnerHTML={{ __html: data.htmlContent }} />
    </BasePopup>
  );
};

export default TemplatePopup;
