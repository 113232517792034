import React from 'react';
import { useSportsbookState, useTournamentState } from '@apollo/core';

import { useHistory } from 'react-router-dom';
import { isEmpty, map } from 'lodash';
import qs from 'query-string';

import EventListItem from './EventListItem';
import Loader from '../../../shared/components/Loader';

const EventList = () => {
  const [sportsState] = useSportsbookState();
  const { selectedEventId } = sportsState;

  const tournamentState = useTournamentState();
  const {
    events, name, loading,
  } = tournamentState;

  const { replace: historyReplace } = useHistory();

  /*
    Autoselecting first event
  */
  React.useEffect(() => {
    const params = qs.parse(window.location.search);

    if (params.e || isEmpty(events)) {
      return;
    }

    const [firstEvent] = Object.values(events);

    params.e = firstEvent.id;
    historyReplace(`${window.location.pathname}?${qs.stringify(params)}`);
  }, [events]);

  const listTitle = (
    <div className='resultGame__title'>
      <span>{name}</span>
    </div>
  );

  if (loading) {
    return (
      <>
        {listTitle}
        <div className='resultGame__content'>
          <Loader />
        </div>
      </>
    );
  }

  return (
    <>
      {listTitle}
      <div className='resultGame__content'>
        {map(events, (event) => (
          <EventListItem
            key={event.id}
            event={event}
            active={selectedEventId === event.id}
          />
        ))}
      </div>
    </>
  );
};

export default EventList;
