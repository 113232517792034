import React, { useEffect, useMemo } from 'react';
import { isFunction } from 'lodash';
import Label from '../I18n/Label';
import { AnimatedDropdown } from '../../../features/Animations/AnimatedDropdown';

const Accordion = ({
  title,
  titleClose,
  titleOpen,
  subTitle,
  children,
  collapsed,
  className,
  onToggle,
  isOneActive = false, // always open not more than one
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(collapsed);

  useEffect(() => {
    if (isOneActive) {
      setIsCollapsed(collapsed);
    }
  }, [collapsed, isOneActive]);

  const headerTitle = useMemo(() => {
    if (title) {
      return title;
    }
    if (isCollapsed) {
      return titleClose || <Label message='more' />;
    }
    return titleOpen || <Label message='less' />;
  }, [title, isCollapsed, titleClose, titleOpen]);

  const toggle = () => {
    setIsCollapsed(!isCollapsed);

    if (isFunction(onToggle)) {
      onToggle(!isCollapsed);
    }
  };

  return (
    <div className={`accordion ${isCollapsed ? 'collapsed' : ''} ${className ?? ''}`}>
      <div className='accordion-header' onClick={toggle}>
        <span className={`arrow AIcon-angle-${isCollapsed ? 'down' : 'up'}`} />
        <span className='title'>{headerTitle}</span>
        {subTitle && <span className='sub-title'>{subTitle}</span>}
      </div>
      <AnimatedDropdown isExpanded={!isCollapsed} className='accordion-body show'>
        {children}
      </AnimatedDropdown>
    </div>
  );
};

export default Accordion;
