import React from 'react';
import { NavLink } from 'react-router-dom';
import { COMPONENT_TYPES, sportsbookRoutes } from '@apollo/routing';
import { SiteConfigManager } from '@apollo/core';
import IconFavorite from '../../../shared/components/IconFavorite/IconFavorite';
import Label from '../../../shared/components/I18n/Label';

const { favorites } = sportsbookRoutes;
const { SPORT_PREMATCH } = COMPONENT_TYPES;

const SportFilterFavoriteOption = () => {
  const prematchPath = SiteConfigManager.getComponentPath(SPORT_PREMATCH);
  const path = `${prematchPath}/${favorites}`;

  return (
    <NavLink className='sport-filter-option sport-filter-option--favourites' to={path}>
      <div className='sport-filter-option__container sport-filter-option__container--icon'>
        <IconFavorite className='sport-filter-option__icon' />
      </div>
      <div className='sport-filter-option__container sport-filter-option__container--name'>
        <Label className='sport-filter-option__name' message='favorites' />
      </div>
    </NavLink>
  );
};

export default SportFilterFavoriteOption;
