import React, { useCallback, useEffect, useRef, useState } from 'react';

const Dropdown = ({
  className,
  onExpanded,
  children,
  hover,
  disabled,
  // if you provide toggle ref then only when user click on current element the toggle
  // effect will occur, otherwise the whole children will work like toggle
  toggleRef,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const wrapperRef = useRef(null);

  const toggleExpand = useCallback(
    (e) => {
      if (disabled || (toggleRef && !toggleRef.current.contains(e.target))) {
        return;
      }
      setIsExpanded(!isExpanded);
    },
    [isExpanded, setIsExpanded, toggleRef, disabled]
  );

  const handleClickOutside = useCallback(
    (e) => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        if (isExpanded === true) {
          setIsExpanded(false);
        }
      }
    },
    [isExpanded, setIsExpanded]
  );

  useEffect(() => {
    onExpanded(isExpanded);
    if (isExpanded) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, isExpanded, onExpanded]);

  if (hover) {
    return (
      <div className={className} ref={wrapperRef}>
        {children}
      </div>
    );
  }
  return (
    <div className={className} ref={wrapperRef} onClick={toggleExpand}>
      {children}
    </div>
  );
};

export default Dropdown;
