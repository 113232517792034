import React from 'react';
import { useApplicationState, useSportsbookState } from '@apollo/core';
import SportFilterFavoriteOption from './SportFilterFavoriteOption';
import SportsbookFilter from '../SportsbookFilter/SportsbookFilter';
import SportFilterOption from './SportFilterOption';
import SportFilterMoreButton from './SportFilterMoreButton';

const favoriteOption = {
  id: 'favorite',
  ItemComponent: SportFilterFavoriteOption,
};

const SportFilter = () => {
  const { authenticated } = useApplicationState();

  const [sportsState] = useSportsbookState();
  const { initialized, sports, loading, selectedSportUrlName } = sportsState;

  /* const options = React.useMemo(() => {
    if (!authenticated) {
      return sports;
    }

    return [favoriteOption, ...sports];
  }, [sports, authenticated]); */

  if (!initialized && loading) {
    return null;
  }

  return (
    <SportsbookFilter
      options={sports}
      value={selectedSportUrlName}
      valueProperty='urlName'
      ItemComponent={SportFilterOption}
      MoreButtonComponent={SportFilterMoreButton}
    />
  );
};

export default SportFilter;
