import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import { useRacingState } from '@apollo/core/src/state/racing/racing';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import RaceMarketTab from './RaceMarketTab';
import { getMultiLegDefaultRaceNumber, getMultiLegTabText } from './utils';
import { RacingMarketTypeName } from '../../../shared/components/Racing/RacingMarketTypeName';

const RaceMarketSubTabs = ({ activeTab, activeMarketTypeId, marketSubTabs = [] }) => {
  const t = useTranslate();
  const [racingState] = useRacingState();
  const activeItemRef = useRef();

  useEffect(() => {
    const { current } = activeItemRef;
    if (current) {
      current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, [activeMarketTypeId]);

  const { activeRace, activeMeet } = racingState;

  return (
    <div className='race-nav__container race-nav__container--sub'>
      <div className='race-page__markets__tab_wrapper race-page__markets__tab_wrapper-multilegs'>
        <div className='race-page__markets__tab_wrapper__flex'>
          <div className='page__markets_tab_container'>
            <Scrollbars className='scroll__container scroll__container--market-subtabs'>
              {marketSubTabs[activeTab].map((tabKey) => (
                <RaceMarketTab
                  key={tabKey}
                  refProps={activeMarketTypeId === tabKey ? activeItemRef : null}
                  containerClassName={cx('tab', tabKey, {
                    active: activeMarketTypeId === tabKey,
                  })}
                  btnClassName={cx('tab_button', {
                    active: activeMarketTypeId === tabKey,
                  })}
                  activeMeet={activeMeet}
                  raceNumber={getMultiLegDefaultRaceNumber(tabKey, activeRace.number)}
                  marketType={tabKey}
                  marketTab={activeTab}
                  marketName={<RacingMarketTypeName marketType={getMultiLegTabText(tabKey)} />}
                />
              ))}
            </Scrollbars>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaceMarketSubTabs;
