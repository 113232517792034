import React, { useMemo } from 'react';
import { SportSelector } from '@apollo/core/src/state/sport/sport';
import { SiteConfigManager } from '@apollo/core';
import { COMPONENT_TYPES } from '@apollo/routing';
import SportTypeButton from './SportTypeButton';
import { isPromotionsActive } from '../../../../core/utils';
import GenericTypeButton from '../GenericTypeButton';

const SportTypeButtonList = () => {
  const topSports = SportSelector.selectSports();

  const filteredTopSports = useMemo(
    () => topSports.filter((s) => s.totalCount > 0).slice(0, 4),
    [topSports],
  );

  const sportsRoute = SiteConfigManager.getComponentPath(COMPONENT_TYPES.SPORT_PREMATCH);
  const promotionsRoute = SiteConfigManager.getComponentPath(COMPONENT_TYPES.PROMOTIONS);
  const promotionsActive = useMemo(() => isPromotionsActive(), [promotionsRoute]);

  return (
    <div className='types types--sport'>
      {promotionsActive && (
        <GenericTypeButton
          path={promotionsRoute}
          label='promotions'
          icon={<i className='icons icon--bet-bonus-all' />}
        />
      )}
      {filteredTopSports.map((sport) => (
        <SportTypeButton key={sport.id} sport={sport} />
      ))}
      <GenericTypeButton
        path={sportsRoute}
        label='All sports'
        icon={<i className='icons AkIcon-more-icon' />}
      />
    </div>
  );
};
export default SportTypeButtonList;
