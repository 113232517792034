import React from 'react';
import cx from 'classnames';
import {
  centsToDecimal,
  useApplicationState,
  useBetslipState,
  useObservableOddsContext,
} from '@apollo/core';
import Label from '../../shared/components/I18n/Label';
import { getCurrencySymbol } from '../../core/utils';

const BetButton = (props) => {
  const { betAmount, disabled, pendingBets, onSubmit, handleClearAllExpiredBets } = props;

  const { hasUnacceptedOdds, acceptAllOdds } = useObservableOddsContext();

  const { loading, confirming } = useBetslipState();

  const {
    wallet: { currency, detailedBalance },
  } = useApplicationState();

  const currencySymbol = getCurrencySymbol(currency);
  const betAmountDecimal = React.useMemo(() => centsToDecimal(betAmount), [betAmount]);

  const handleSubmit = React.useCallback(() => {
    // Accepting odds changes
    acceptAllOdds();
    // Placing bets
    onSubmit();
  }, [onSubmit, acceptAllOdds]);

  const betButtonError = React.useMemo(() => {
    if (loading) {
      return {
        message: 'loading',
      };
    }

    if (pendingBets) {
      return {
        message: 'bet_processing',
      };
    }

    if (!betAmount) {
      return {
        message: 'place_amount_to_bet',
      };
    }

    return null;
  }, [betAmount, loading, detailedBalance, pendingBets]);

  /*
  FIXME: Should be removed
  */
  const betButtonText = React.useMemo(() => {
    if (disabled) {
      return <Label message='remove_inactive_outcomes_to_bet' />;
    }

    if (betButtonError) {
      return <Label message={betButtonError.message} />;
    }

    const formattedBetAmount = `${currencySymbol}${betAmountDecimal}`;
    const params = {
      value: formattedBetAmount,
    };

    if (hasUnacceptedOdds) {
      return <Label message='betslip_accept_odds_changes' params={params} />;
    }

    if (confirming) {
      return <Label message='confirm_bets' params={params} />;
    }

    return <Label message='bet_amount_now' params={params} />;
  }, [betButtonError, betAmountDecimal, currencySymbol, hasUnacceptedOdds, confirming, disabled]);

  const betButtonClassName = cx('btn buttonBet', {
    buttonBet__acceptChanges: hasUnacceptedOdds,
    buttonBet__clearExpired: disabled,
    disabled: !disabled && !!betButtonError,
  });

  return (
    <button
      type='button'
      className={betButtonClassName}
      onClick={disabled ? handleClearAllExpiredBets : handleSubmit}
      disabled={!disabled && (!!betButtonError || loading) /* || errors.length */}
    >
      {betButtonText}
    </button>
  );
};

export default BetButton;
