import React, { useMemo } from 'react';
import { SportSelector } from '@apollo/core/src/state/sport/sport';
import CategoryListItem from '../Prematch/CategoryListItem';

const CategoryList = ({ sportId, activeCategory, toggleActiveCategory }) => {
  const categories = SportSelector.selectCategoriesBySportId(sportId).filter(
    (c) => c.totalCount > 0,
  );

  const tournaments = SportSelector.selectTournamentsBySportId(sportId);

  const filteredTournaments = useMemo(
    () => tournaments.filter((t) => t.totalCount > 0),
    [tournaments],
  );

  return categories.map((category) => (
    <CategoryListItem
      key={category.id}
      category={category}
      tournaments={filteredTournaments.filter((t) => t.categoryId === category.id)}
      active={category.id === activeCategory}
      onToggleActiveCategory={() => toggleActiveCategory(category.id)}
    />
  ));
};

export default CategoryList;
