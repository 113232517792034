import React from 'react';
import Label from '../../../shared/components/I18n/Label';
import BetOdds from './BetOdds';
import Svg from '../../../shared/components/svg/Svg';

/*
 * Same Game Multi Betslip view
 */
const BetItemViewSgm = (props) => {
  const { event, outcome, disabled, suspended, loading, oddsToDisplay, assignedBonus } = props;

  const warningWillBeRemoved = disabled && !suspended && !loading;

  return (
    <>
      <div className='event event--sport'>
        <div className='event__outcome-name'>
          <Label message='Same Game Multi' />
          <span>
            <small className='event-icon'>
              {Object.values(outcome.sgmLegs).length}
              &nbsp;
              <Label message='Legs' />
            </small>
          </span>
        </div>

        {event.onlySingle && (
          <div className='warning'>
            <Svg name='warning' />
            <Label message='bet_only_single' />
          </div>
        )}
        {warningWillBeRemoved && (
          <div className='warning'>
            <Svg name='warning' />
            <Label message='bet_is_inactive' />
          </div>
        )}
      </div>

      {/* <div className='info'>
        {prevOutcomeName && (
          <div className='outcomeName_changed'>{prevOutcomeName}</div>
        )}
        <div>
          {betslipNameParser(outcome, marketType, event)}
        </div>

      </div> */}

      <BetOdds
        outcomeId={outcome.id}
        odds={outcome.odds}
        oddsToDisplay={oddsToDisplay}
        assignedBonus={assignedBonus}
      />
    </>
  );
};

export default BetItemViewSgm;
