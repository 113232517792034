import React from 'react';
import QueryRoute from '../../../core/queryRoute';
import ModalPortal from '../ModalPortal';
import SignIn from '../../modals/SignIn/SignIn';
import SignUp from '../../modals/SignUp/SignUp';
import ForgotPassword from '../../modals/ForgotPassword';
import ResetPassword from '../../modals/ResetPassword';
import EmailVerification from '../../modals/EmailVerification';
import Blocked from '../../modals/Blocked/Blocked';
import Version from '../../modals/Version/Version';

const QueryRoutes = () => (
  <>
    <QueryRoute command='version'>
      <ModalPortal forceInteraction>
        <Version />
      </ModalPortal>
    </QueryRoute>

    <QueryRoute command='blocked'>
      <ModalPortal forceInteraction>
        <Blocked />
      </ModalPortal>
    </QueryRoute>

    <QueryRoute command='signin'>
      <ModalPortal scrollable forceInteraction>
        <SignIn />
      </ModalPortal>
    </QueryRoute>

    <QueryRoute command='signup'>
      <ModalPortal scrollable forceInteraction className='modal--signup'>
        <SignUp />
      </ModalPortal>
    </QueryRoute>

    <QueryRoute command='forgotPassword'>
      <ModalPortal forceInteraction>
        <ForgotPassword />
      </ModalPortal>
    </QueryRoute>

    <QueryRoute command='resetPassword'>
      <ModalPortal forceInteraction>
        <ResetPassword />
      </ModalPortal>
    </QueryRoute>

    <QueryRoute command='emailVerification'>
      <ModalPortal>
        <EmailVerification />
      </ModalPortal>
    </QueryRoute>

    <QueryRoute command='resetPasswordPhone'>
      <ModalPortal>
        <ResetPassword formType='PASSWORD_RESET_PHONE' />
      </ModalPortal>
    </QueryRoute>
  </>
);

export default QueryRoutes;
