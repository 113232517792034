import React, { useCallback } from 'react';
import { onProfileUpdateSubmit, useActiveForm } from '@apollo/react-forms';
import ToastManager from '../../../core/ToastManager';
import Label from '../../../shared/components/I18n/Label';
import FormCom from '../../../shared/components/Form/FormCom';

const CommunicationForm = () => {
  const {
    activeType,
    activeStep,
    submit,
    resetActiveForm,
  } = useActiveForm(
    'CLIENT_COMMUNICATION',
    onProfileUpdateSubmit,
  );

  const submitHandler = useCallback(() => {
    resetActiveForm();
    ToastManager.success(<Label message='New data saved' />);
  }, [resetActiveForm]);

  // console.log({ form });

  return (
    <FormCom
      id={activeType?.id}
      action={submit}
      form={activeStep}
      additionalValues={{ id: `${activeType?.id}` }}
      buttonText='save'
      onSubmit={submitHandler}
    />
  );
};

export default CommunicationForm;
