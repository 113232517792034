import React from 'react';
import { constants } from '@apollo/core';
import MoneyInput from '../../../shared/components/Form/MoneyInput/MoneyInput';

const MoneyInputBlock = ({
  amount,
  handleBetAmountChange,
  autofocus,
  isAmountFocused,
  disabled,
  assignedBonus,
}) => (
  <div className='b-col'>
    <MoneyInput
      autofocus={autofocus}
      focused={isAmountFocused}
      value={amount}
      disabled={disabled}
      readOnly={assignedBonus?.bonusType === constants.BONUS_TYPE.FREEBET}
      onChange={handleBetAmountChange}
      placeholder='betslip_error_bet_amount_required'
    />
  </div>
);
export default MoneyInputBlock;
