import React from 'react';
import { isEmpty, map } from 'lodash';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import Label from '../../../../shared/components/I18n/Label';
import { ordinalSuffixOf } from '../../../../core/utils';
import Odds from '../../../../shared/components/Odds/Odds';

const RaceExoticDividendTable = ({ activeRace }) => {
  const t = useTranslate();
  const exoticsDividends = activeRace?.uiResults?.exoticsDividends ?? [];

  return (
    <>
      <div className='tournament__header'>
        <div className='event event--classic'>
          <div className='event__main-period'>
            <div className='event__info'>
              <div className='event-participants'>
                <div className='event-participants__participant'>
                  <div className='race-exotic-dividend-type'>
                    <Label message='Bet Type' />
                  </div>
                  <div className='race-exotic-dividend-item'>
                    <Label message='runners' />
                  </div>
                </div>
              </div>
            </div>
            <div className=' event__market-row race-exotic-dividend'>
              <div className='event__market-row-market event__market-row--race-dividend'>
                {t('Dividend')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='event-group event-group--races'>
        {isEmpty(exoticsDividends) === false
          && map(exoticsDividends, (dividend, ind) => (
            <div
              key={`${dividend.type}-${ind}`}
              className='event event--classic'
            >
              <div className='event__main-period'>
                <div className='event__info'>
                  <div className='event-participants'>
                    <div className='race-exotic-dividend'>
                      <div className='race-exotic-dividend-type'>
                        {t(dividend.type)}
                      </div>
                      <div className='race-exotic-dividend'>
                        {dividend?.numbers?.map((num, index) => (
                          <div key={num} className='race-exotic-dividend-item'>
                            <div className='race-exotic-dividend-item-num bold'>
                              {ordinalSuffixOf(index + 1)}
                            </div>
                            <div className='race-exotic-dividend-item-val bold'>
                              {num}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='event__market-row race-exotic-dividend'>
                  <div className='event__market-row-market event__market-row--race-dividend bold'>
                    <Odds
                      value={dividend.dividend}
                      decimal={3}
                      minDecimal={1}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};
export default RaceExoticDividendTable;
