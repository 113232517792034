import React from 'react';

import SoccerScoreTemplate from './SoccerScoreTemplate/SoccerScoreTemplate';

const components = {
  1: SoccerScoreTemplate,
};

const DetailPageMarketsAdapter = (props) => {
  const { sportId } = props;

  const Component = components[sportId] ? components[sportId] : SoccerScoreTemplate;

  return <Component {...props} />;
};

export default DetailPageMarketsAdapter;
