import React from 'react';
import { useEventState } from '@apollo/core';
import SportLoader from './SportLoader';

const EventLoader = (props) => {
  const { children } = props;

  const { initialized, loading } = useEventState();

  if (!initialized && loading) {
    return <SportLoader />;
  }

  return children;
};
export default EventLoader;
