import React, { useCallback, useLayoutEffect, useRef, useReducer } from 'react';
import _ from 'lodash';

const SET_STATE = 'SET_STATE';
const TOGGLE_DROPDOWN = 'TOGGLE_DROPDOWN';

const initialState = {
  overflowItemIndex: null,
  isDropdownVisible: false,
  isRendering: true,
  isLoaded: false,
};
const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_STATE: {
      return { ...state, ...payload };
    }
    case TOGGLE_DROPDOWN: {
      const { isDropdownVisible } = state;

      return {
        ...state,
        isDropdownVisible: !isDropdownVisible,
      };
    }
    default: {
      return state;
    }
  }
};

const useSportsbookFilterState = (options) => {
  const listRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isRendering } = state;

  useLayoutEffect(() => {
    dispatch({
      type: SET_STATE,
      payload: { isRendering: true },
    });
  }, [options]);

  useLayoutEffect(() => {
    const resizeHandler = _.debounce(() => {
      dispatch({
        type: SET_STATE,
        payload: { isRendering: true },
      });
    }, 250);

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useLayoutEffect(() => {
    if (!isRendering) {
      return;
    }

    const list = listRef.current;
    const items = list.querySelectorAll('.sportsbook-filter-list-item-container');
    const listRect = list.getBoundingClientRect();
    let overflowItemIndex = null;

    for (let i = 0; i < items.length; i += 1) {
      const item = items[i];
      const itemRect = item.getBoundingClientRect();

      if (listRect.right < itemRect.right) {
        overflowItemIndex = i;
        break;
      }
    }

    dispatch({
      type: SET_STATE,
      payload: {
        isRendering: false,
        overflowItemIndex,
      },
    });
  }, [isRendering]);

  const handleDropdownToggle = useCallback(() => dispatch({ type: TOGGLE_DROPDOWN }), []);

  return {
    listRef,
    handleDropdownToggle,
    ...state,
  };
};

export default useSportsbookFilterState;
