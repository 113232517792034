import React, { useMemo } from 'react';
import { getSportMainMarketTypesByPeriod } from '@apollo/core';
import { SportSelector, useSportState } from '@apollo/core/src/state/sport/sport';
import useGroupEventsByDate from './useGroupEventsByDate';
import Tournament from '../Tournament/Tournament';

const PrematchTournament = () => {
  const [sportsState, sportsDispatcher] = useSportState();
  const { selectedSportId } = sportsState;

  const selectedSport = SportSelector.selectSportById(selectedSportId);

  const mainMarketTypesByPeriod = useMemo(
    () => getSportMainMarketTypesByPeriod(selectedSport.mainMarketTypes || []),
    [selectedSport],
  );

  const groupEventsByDate = useGroupEventsByDate();

  return (
    <Tournament
      groupEventsByDate={groupEventsByDate}
      mainMarketTypesByPeriod={mainMarketTypesByPeriod}
    />
  );
};

export default PrematchTournament;
