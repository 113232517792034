import React, { useEffect, useMemo, useState } from 'react';
import { Bus, isHidden, SiteConfigManager } from '@apollo/core';
import { isEmpty } from 'lodash';
import { COMPONENT_TYPES } from '@apollo/routing';
import { Route, Switch, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { SportSelector, useSportState } from '@apollo/core/src/state/sport/sport';
import { hasMarketTypes } from '@apollo/core/src/utils/status';
import SportLoader from '../SportLoader';
import TournamentFilter from './TournamentFilter/TournamentFilter';
import SportsbookLoader from '../SportsbookLoader';
import SportsbookFavorites from './SportsbookFavorites';
import DetailsPage from '../DetailsPage/DetailsPage';
import StatscoreProvider from '../Statscore/StatscoreProvider';
import { BroadcastProvider } from '../../../state/Broadcast/BroadcastContext';
import SportPage from '../SportPage/SportPage';
import SportBreadcrumbs from '../SportBreadcrumbs';
import PrematchTournament from './PrematchTournament';
import PrematchFeatured from './PrematchFeatured';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import PrematchAll from './PrematchAll';
import { SportLink, SPORTS_TABS } from '../../../shared/components/Sport/SportLinks';
import Upcoming from '../SportPage/Upcoming';
import MainBody from '../../../shared/components/MainBody/MainBody';
import SportsPathHandler from '../SportsPathHandler';
import SportNothingFound from '../SportNothingFound';

export const tabs = {
  ALL_SPORTS: 'ALL_SPORTS',
  UPCOMING: 'UPCOMING',
  FEATURED: 'FEATURED',
};

const getTabsHashPath = (tab) => {
  switch (tab) {
    case tabs.ALL_SPORTS:
      return SPORTS_TABS.ALL_SPORTS;
    case tabs.FEATURED:
      return SPORTS_TABS.FEATURED_SPORTS;
    case tabs.UPCOMING:
      return SPORTS_TABS.UPCOMING_SPORTS;
    default:
      return null;
  }
};

const PrematchSport = () => {
  const t = useTranslate();
  const [sportsState] = useSportState();
  const {
    loading,
    initialized,
    selectedSportId,
    selectedTournamentId,
    selectedTournamentUrlName,
    tournamentsBySportId,
  } = sportsState;

  const [activeTab, setActiveTab] = useState(tabs.FEATURED);

  const { hash } = useLocation();

  const selectedSport = SportSelector.selectSportById(selectedSportId);
  const selectedTournament = SportSelector.selectTournamentById(selectedTournamentId);

  useEffect(() => {
    if (!selectedSport) {
      return;
    }
    Bus.emit(Bus.events.sport.sportLoadCategories, {
      filter: {
        sportId: selectedSport.id,
        fromDate: Date.now(),
        toDate: null,
      },
      paging: {
        itemsPerPage: 50,
        currentPage: 0,
      },
    });
    Bus.emit(Bus.events.sport.sportLoadTournaments, {
      filter: {
        sportId: selectedSport.id,
        fromDate: Date.now(),
        toDate: null,
      },
      paging: {
        itemsPerPage: 50,
        currentPage: 0,
      },
    });
  }, [selectedSport]);

  const tournaments = SportSelector.selectTournamentsBySportId(selectedSportId);
  const topTournaments = useMemo(
    () => tournaments.filter((t) => t.prematchCount > 0 && t.isTop === true),
    [tournaments],
  );

  const events = SportSelector.selectEvents({ sportId: selectedSportId });
  const upcomingEvents = useMemo(
    () => events.filter((e) => {
      if (isHidden(e) || !hasMarketTypes(e)) {
        return false;
      }
      if (e.outright) {
        return false;
      }
      return e.startTime > Date.now();
    }),
    [events],
  );

  const topMenuItems = useMemo(() => {
    const topMenu = [];
    if (!isEmpty(topTournaments)) {
      topMenu.push({
        name: t('featured'),
        id: tabs.FEATURED,
      });
    }

    if (!isEmpty(upcomingEvents)) {
      topMenu.push({
        name: t('upcoming'),
        id: tabs.UPCOMING,
      });
    }

    topMenu.push({
      name: t('all'),
      id: tabs.ALL_SPORTS,
    });
    return topMenu;
  }, [t, topTournaments, upcomingEvents]);

  useEffect(() => {
    if (loading) {
      return;
    }
    switch (hash?.replace('#', '')) {
      case SPORTS_TABS.ALL_SPORTS:
        setActiveTab(tabs.ALL_SPORTS);
        break;

      case SPORTS_TABS.FEATURED_SPORTS:
        if (!isEmpty(tournamentsBySportId)) {
          if (!isEmpty(topTournaments)) {
            setActiveTab(tabs.FEATURED);
          } else {
            setActiveTab(tabs.UPCOMING);
          }
        }
        break;

      case SPORTS_TABS.UPCOMING_SPORTS:
        setActiveTab(tabs.UPCOMING);
        break;

      default:
        if (!isEmpty(tournamentsBySportId)) {
          if (!isEmpty(topTournaments)) {
            setActiveTab(tabs.FEATURED);
          } else if (!isEmpty(upcomingEvents)) {
            setActiveTab(tabs.UPCOMING);
          } else {
            setActiveTab(tabs.ALL_SPORTS);
          }
        }
        break;
    }
  }, [hash, topTournaments, upcomingEvents, tournamentsBySportId, loading]);

  if (!selectedSport) {
    if (!initialized && loading) {
      return <SportLoader />;
    }
    return <SportNothingFound className='nothing-found--sport-prematch' />;
  }

  if (selectedTournamentUrlName) {
    if (!selectedTournament) {
      if (!initialized && loading) {
        return <SportLoader />;
      }
      return (
        <SportNothingFound
          className='nothing-found--tournament-prematch'
          selectedSport={selectedSport}
        />
      );
    }

    return (
      <>
        <div className='sport-page__header'>
          <h1 className='sport-name'>
            {selectedTournament.name}
            {selectedTournament.categoryName && (
              <span className='sport-name__category'>{` - ${selectedTournament.categoryName}`}</span>
            )}
          </h1>
        </div>
        <TournamentFilter />
        <PrematchTournament />
      </>
    );
  }

  return (
    <>
      <div className='sport-page__header'>
        <h1 className='sport-name'>{selectedSport?.name}</h1>
        <div className='tournament__header'>
          <div className='tournament__header__market-row'>
            {topMenuItems.map(({ name, id }) => (
              <SportLink
                key={id}
                sportUrlName={selectedSport?.urlName}
                hash={getTabsHashPath(id)}
                className={cx(['sport-filter-option', activeTab === id && 'active'])}
              >
                {name}
                {id === tabs.ALL_SPORTS ? ` ${selectedSport?.name}` : ''}
              </SportLink>
            ))}
          </div>
        </div>
      </div>

      {activeTab === tabs.FEATURED && !isEmpty(topTournaments) && (
        <PrematchFeatured sportId={selectedSport?.id} />
      )}

      {activeTab === tabs.UPCOMING && (
        <Upcoming showTimeFilter sportId={selectedSport?.id} />
      )}

      {activeTab === tabs.ALL_SPORTS && <PrematchAll sportId={selectedSport?.id} />}
    </>
  );
};

const PrematchSportsbookRouter = () => {
  const prematchPath = SiteConfigManager.getExtraConfig(
    `pathList.${COMPONENT_TYPES.SPORT_PREMATCH}`,
  );
  return (
    <SportsPathHandler>
      <StatscoreProvider>
        <BroadcastProvider>
          <SportsbookLoader>
            <MainBody className='main__body--sport-prematch'>
              <SportBreadcrumbs />
              <Switch>
                <Route
                  exact
                  strict
                  path={[
                    `${prematchPath}/:sportName/:categoryName/:tournamentName/:eventName`,
                    `${prematchPath}/:sportName/:categoryName/:tournamentName`,
                  ]}
                  render={(props) => {
                    if (props.location.search) {
                      return <DetailsPage />;
                    }
                    return <PrematchSport />;
                  }}
                />
                <Route exact strict path={`${prematchPath}`} component={SportPage} />
                <Route
                  exact
                  strict
                  path={`${prematchPath}/favorites`}
                  component={SportsbookFavorites}
                />
                <Route component={PrematchSport} />
              </Switch>
            </MainBody>
          </SportsbookLoader>
        </BroadcastProvider>
      </StatscoreProvider>
    </SportsPathHandler>
  );
};
export default PrematchSportsbookRouter;
