import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { filter, isEmpty, map, reverse, sortBy } from 'lodash';
import { logger, orderBetApi } from '@apollo/core';
import MyOrderItem from './MyOrderItem';
import Label from '../../../shared/components/I18n/Label';
import Loader from '../../../shared/components/Loader';

const MyOrders = ({ active }) => {
  const [bets, setBets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!active) {
      setBets([]);
      return;
    }

    const ac = new AbortController();

    setIsLoading(true);
    orderBetApi
      .findActiveOrderBets({
        signal: ac.signal,
        body: {
          filter: { status: 'CREATED' },
          sorting: [
            {
              direction: 'DESC',
              fieldName: 'createTime',
              order: 0,
            },
          ],
          paging: {
            currentPage: 0,
            itemsPerPage: 10,
          },
        },
      })
      .then((res) => {
        const { data } = res;
        setBets(reverse(sortBy(data, 'createTime')));
      })
      .catch((error) => {
        logger.error('can not fetch ordered bets', error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => ac.abort();
  }, [active]);

  const className = cx('bets__myBets', 'tab__content', {
    active,
  });

  const content = React.useMemo(() => {
    const removeOrderItem = (removeOrderId) => {
      setIsLoading(true);

      const ac = new AbortController();
      orderBetApi
        .cancelOrderBet({ body: { id: removeOrderId } })
        .then(() => {
          const newBets = filter(bets, ({ id }) => id !== removeOrderId);
          setBets(newBets);
        })
        .catch(() => {
          const newBets = map(bets, (bet) => {
            const { id } = bet;
            if (id !== removeOrderId) return bet;
            return {
              ...bet,
              hasError: true,
            };
          });

          setBets(newBets);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (isLoading && isEmpty(bets)) {
      return (
        <div className='betslip__block'>
          <div className='history'>
            <div className='history__subHeader opacityBlock'>
              <div className='bets__matches__block__content'>
                <Loader />
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (!bets || isEmpty(bets)) {
      return (
        <div className='betslip__block'>
          <div className='history'>
            <div className='history__subHeader opacityBlock'>
              <div className='EMPTY_STATE_IMAGE' />
              <Label message='you_have_no_order_bets' />
            </div>
          </div>
        </div>
      );
    }

    return map(bets, (bet) => (
      <MyOrderItem
        key={bet.id}
        bet={bet}
        removeOrderItem={removeOrderItem}
        disableRemove={isLoading}
      />
    ));
  }, [bets, isLoading]);

  return <div className={className}>{content}</div>;
};

export default MyOrders;
