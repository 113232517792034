import React from 'react';
import _ from 'lodash';
import { isFeedOffline, isInactive, isSuspended, isVisible, useBetslipState } from '@apollo/core';
import OutrightOutcomes from './OutrightOutcomes';

const OutrightMarkets = ({ activeMarket, event, suspended, disabled }) => {
  const {
    settings: { feedStates },
  } = useBetslipState();

  if (!activeMarket) {
    return null;
  }

  const outcomes = _.chain(activeMarket).get('outcomes').filter(isVisible).orderBy('odds')
    .value();

  if (_.isEmpty(outcomes)) {
    return null;
  }

  return (
    <div className='outright-market'>
      <OutrightOutcomes
        event={event}
        marketTypeId={activeMarket.marketTypeId}
        market={activeMarket}
        outcomes={outcomes}
        suspended={
          suspended
          || isSuspended(activeMarket)
          || isFeedOffline(feedStates[activeMarket?.providerName])
        }
        disabled={disabled || isInactive(activeMarket)}
      />
    </div>
  );
};

export default OutrightMarkets;
