import React from 'react';
import { CategoryLink } from '../../../shared/components/Sport/SportLinks';

const CategoryListItem = ({ category, active }) => {
  const {
    regionId,
    name,
  } = category;

  return (
    <CategoryLink
      active={active}
      sportUrlName={category.sportUrlName}
      categoryUrlName={category.urlName}
      className='bottom__block three'
    >
      <div>
        <span className={`icon-flag result flag--${regionId}`} />
        <span className='name'>{name}</span>
      </div>
    </CategoryLink>
  );
};

export default CategoryListItem;
