import React, { useState } from 'react';
import _, { isEmpty, map } from 'lodash';
import cx from 'classnames';
import { SettingsManager, SiteConfigManager, useApplicationState, useSettings } from '@apollo/core';
import { updateUserLanguage } from '@apollo/core/src/api/auth';
import LangItem from './LangItem';
import Dropdown from '../../../Dropdown/Dropdown';
import { AnimatedDropdown } from '../../../../../features/Animations/AnimatedDropdown';

const ChangeLang = () => {
  const { lang } = useSettings();
  const [isExpanded, setExpanded] = useState(false);
  const { authenticated, account } = useApplicationState();
  const languages = SiteConfigManager.getConfig('languages');

  const setLanguage = React.useCallback(
    (newLang) => {
      SettingsManager.updateSettings({
        lang: newLang,
      });

      const currentLang = _.get(account, 'language');

      if (authenticated && newLang !== currentLang) {
        updateUserLanguage({ language: newLang });
      }
    },
    [authenticated, account]
  );

  const languageOptions = React.useMemo(
    () =>
      map(languages, ({ name, code }) => (
        <LangItem key={code} code={code} label={name} onClick={setLanguage} />
      )),
    [setLanguage]
  );

  if (isEmpty(languages) === true || languages.length <= 1) {
    return null;
  }

  const selectorClassName = cx('lang__block', { active: isExpanded });

  return (
    <Dropdown className='header__lang' onExpanded={setExpanded}>
      <div className={selectorClassName}>
        <span className={`lang-flag flag--${lang}`} />
        <span>{lang}</span>
      </div>
      <AnimatedDropdown isExpanded={isExpanded} className='lang__dropdown'>
        {languageOptions}
      </AnimatedDropdown>
    </Dropdown>
  );
};

export default ChangeLang;
