import React from 'react';
import { useApplicationState } from '@apollo/core';
import { TournamentLink } from '../../../../shared/components/Sport/SportLinks';
import PromoOddsBonusIcon from '../../../../shared/components/Bonus/PromoOddsBonusIcon';
import EventParticipants from './EventParticipants';

const EventParticipantsBlock = ({ withSportIcon, event }) => {
  const { layout } = useApplicationState();

  return withSportIcon ? (
    <div className='event__info-block'>
      <div className='event-participants-wrapper'>
        <EventParticipants event={event} withSportIcon />
      </div>
      <div className='event__info-tournament'>
        <span className='event__info-tournament--category'>
          {event.categoryName}
          {' '}
          -
          {' '}
        </span>

        <TournamentLink
          className='event__info-tournament--tournament'
          sportUrlName={event.sportUrlName}
          categoryUrlName={event.categoryUrlName}
          tournamentUrlName={event.tournamentUrlName}
        >
          {event.tournamentName}
        </TournamentLink>
      </div>
      {!layout.mobileDevice && <PromoOddsBonusIcon event={event} />}
    </div>
  ) : (
    <EventParticipants event={event} />
  );
};

export default EventParticipantsBlock;
