import React from 'react';
import cx from 'classnames';
import Label from '../../../shared/components/I18n/Label';

const SportFilterMoreButton = ({ isActive, isDropdownExpanded, onToggle, hiddenOptionsAmount }) => {
  const className = cx('sport-filter-option', 'sport-filter-option__more-button', {
    active: isActive,
    expanded: isDropdownExpanded,
  });

  return (
    <div className={className} onClick={onToggle}>
      <div className='sport-filter-option__container sport-filter-option__container--icon'>
        <i className='sport-filter-option__icon AkrIcon-arrow-down' />
        <div className='sport-filter-option__counter'>{hiddenOptionsAmount}</div>
      </div>
      <div className='sport-filter-option__container sport-filter-option__container--name'>
        <Label className='sport-filter-option__name' message='more_sports' />
      </div>
    </div>
  );
};

export default SportFilterMoreButton;
