import React from 'react';
import BasePopup from './BasePopup';

const handleContinueButtonClick = () => (
  window.location.reload()
);

export default function DisconnectionPopup(notification) {
  const {
    message,
    title,
  } = notification;

  return (
    <BasePopup
      onClose={handleContinueButtonClick}
      title={title}
      message={message}
      icon='error'
      withOkButton
    />
  )
}
