import React, { useEffect, useMemo } from 'react';
import {
  availableClientProfileRoutes,
  CLIENT_PROFILE_COMPONENT_TYPES,
  clientProfileRoutes,
  COMPONENT_TYPES,
} from '@apollo/routing';
import { Bus, getAvailablePages, SiteConfigManager } from '@apollo/core';
import ClientProfileSubRouter from './ClientProfileSubRouter';
import History from './HistoryTab/History';
import Profile from './DetailsTab/Details';
import Payment from './PaymentTab/Payment';
import ResponsibleGaming from './ResponsibleGamingTab/ResponsibleGaming';
import Bonuses from './Bonuses/Bonuses';
import BetslipSettings from './BetslipSettings';
import Communication from './Communication/communication';

const { CLIENT_PROFILE } = COMPONENT_TYPES;

const { profile, payment, history, responsibleGaming, bonuses, settings, communication } = clientProfileRoutes;

const { PROFILE, PAYMENT, HISTORY, RESPONSIBLE_GAMING, BONUSES, SETTINGS, COMMUNICATION } = CLIENT_PROFILE_COMPONENT_TYPES;

const getRoutes = (clientProfileRoute) => ({
  [PROFILE]: {
    defaultPath: `${clientProfileRoute}/${profile}`,
    component: Profile,
  },
  [PAYMENT]: {
    defaultPath: `${clientProfileRoute}/${payment}`,
    component: Payment,
  },
  [HISTORY]: {
    defaultPath: `${clientProfileRoute}/${history}`,
    component: History,
  },
  [RESPONSIBLE_GAMING]: {
    defaultPath: `${clientProfileRoute}/${responsibleGaming}`,
    component: ResponsibleGaming,
  },
  [BONUSES]: {
    defaultPath: `${clientProfileRoute}/${bonuses}`,
    optionalPath: '/:bonusType?',
    component: Bonuses,
  },
  [COMMUNICATION]: {
    defaultPath: `${clientProfileRoute}/${communication}`,
    component: Communication,
  },
  [SETTINGS]: {
    defaultPath: `${clientProfileRoute}/${settings}`,
    component: BetslipSettings,
  },
});

const ClientProfileRouter = () => {
  const { tabs: clientProfileTabs = [] } = SiteConfigManager.getComponentOptions(CLIENT_PROFILE);

  const tabs = useMemo(
    () => getAvailablePages(availableClientProfileRoutes, clientProfileTabs),
    [clientProfileTabs],
  );

  // useEffect(() => {
  //   Bus.send({
  //     event: Bus.events.layout.setSideBarRight,
  //     data: false,
  //   });
  // }, [tabs]);

  return (
    <ClientProfileSubRouter tabs={tabs} getAvailableRoutes={getRoutes} parentRoute={profile} />
  );
};

export default ClientProfileRouter;
