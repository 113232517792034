import { constants } from '@apollo/core';

export const betslipIndex = 0;
export const myBetsIndex = 1;
export const myOrdersIndex = 2;
export const myTransactionsIndex = 3;

export const handicapREGEX = /[-+]?\d+(\.\d+)?$/;

// Used when restoring default settings
export const defaultSettings = {
  acceptOddsChanges: constants.ACCEPT_ODDS_CHANGES_TYPES.UP,
  oddsFormat: constants.ODDS_FORMAT.DECIMAL,
  theme: constants.THEME_CONTRAST.LIGHT,
  quickBet: false,
};
