import React from 'react';
import cx from 'classnames';
import { useOrderBetslip } from '@apollo/core';
import { BET_TYPE } from '@apollo/core/src/constants';
import Label from '../../../shared/components/I18n/Label';
import MoneyInput from '../../../shared/components/Form/MoneyInput/MoneyInput';
import PossibleWinSection from '../BetslipDefaultView/PossibleWinSection';
import { getCurrencySymbol } from '../../../core/utils';

const OrderBetslipControls = ({ betItems, selectedBet, disabled, currency }) => {
  const {
    orderedOdds,
    orderedAmount,
    acceptSportServiceChange,
    acceptBetAmountChange,
    onOrderBet,
    switchAcceptSportServiceChange,
    switchAcceptBetAmountChange,
    setOrderedOdds,
    setOrderAmount,
  } = useOrderBetslip(betItems, selectedBet);
  const { betType, odds } = selectedBet;
  const isSystem = betType === BET_TYPE.SYSTEM;

  const orderBetButtonClassName = cx('buttonBet', { disabled });

  const isOddsValid = orderedOdds > odds;
  const isValid = orderedAmount && selectedBet && orderedOdds && isOddsValid;
  const currencySymbol = getCurrencySymbol(currency);

  return (
    <div className='bet-slip__order-bet-controls controls order '>
      <div className='controls__block'>
        <div className='switchBlockInfo'>
          <Label message='turn_live_order_bet_on' />
        </div>
        <div className='toggle'>
          <label className='switch'>
            <input
              type='checkbox'
              checked={acceptSportServiceChange}
              onChange={switchAcceptSportServiceChange}
            />
            <span className='slider' />
          </label>
        </div>
      </div>
      <div className='controls__block'>
        <div className='switchBlockInfo'>
          <Label message='accept_bet_amount_change' />
        </div>
        <div className='toggle'>
          <label className='switch'>
            <input
              type='checkbox'
              checked={acceptBetAmountChange}
              onChange={switchAcceptBetAmountChange}
            />
            <span className='slider' />
          </label>
        </div>
      </div>
      <div className='controls__block controls__block--equal'>
        <div className='leftSide'>
          <Label message='desired_odds' />
        </div>
        <div className={cx(['inputBlock', !isOddsValid && 'error'])}>
          <div className='block'>
            <div className='block__wrap'>
              <label className='row'>
                <input
                  inputMode='numeric'
                  className='ui__field'
                  type='text'
                  value={orderedOdds}
                  onChange={setOrderedOdds}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className='controls__block controls__block_column'>
        <div className='inputBlock'>
          <MoneyInput
            value={orderedAmount}
            disabled={disabled}
            onChange={setOrderAmount}
            placeholder='amount'
          />
        </div>
        {isValid && (
          <PossibleWinSection
            possibleWin={(orderedAmount * orderedOdds).toFixed(2)}
            currencySymbol={currencySymbol}
          />
        )}
      </div>
      {!isOddsValid && (
        <Label
          message='order_bet_wrong_odds_error'
          render={(t) => (
            <div className='controls__block'>
              <div className='inputError'>{t}</div>
            </div>
          )}
        />
      )}

      <button
        type='button'
        className={orderBetButtonClassName}
        onClick={onOrderBet}
        disabled={disabled || !isValid || isSystem}
      >
        {!orderedAmount && <Label message='place_amount_to_order_bet' />}
        {orderedAmount && <Label message='place_order_bet' />}
      </button>
    </div>
  );
};

export default OrderBetslipControls;
