import React from 'react';
import { SRM_MARKETS_LIST } from '@apollo/core/src/constants';
import { useApplicationState } from '@apollo/core';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import { getOutcomeSelections } from '../../../core/utils';
import Accordion from '../../../shared/components/Accordion/Accordion';
import { RaceRunnerImage } from '../../Racing/RacePage/RaceRunnerImage/RaceRunnerImage';
import MultiStatusIcon from '../MyBets/MultiStatusIcon';

const BetSubItemViewSrm = ({ runners, outcome, collapsedMulti, raceType }) => {
  const t = useTranslate();
  const { layout } = useApplicationState();

  const selections = React.useMemo(() => getOutcomeSelections(outcome), [outcome]);

  const runnerByNum = React.useCallback(
    (num) => runners?.find((runner) => `${runner.number}` === num),
    [runners]
  );

  const runnersInfo = selections?.map((sel, index) => {
    const runnerNum = sel.runner;
    const runner = runnerByNum(runnerNum);
    return (
      <div key={`${runner?.id}-${runnerNum}`} className='sub-item'>
        <div className='event__outcome-container'>
          {runner?.silkImageUrl ? (
            <RaceRunnerImage
              runner={runner}
              masked={raceType !== 'DOG'}
              className='event__outcome-logo'
            />
          ) : (
            <span className='event__outcome-logo event__outcome-logo--empty' />
          )}

          <div className='event__outcome-details'>
            <div className='sub-item__index'>
              {runnerNum}
              .&nbsp;
            </div>
            <div className='sub-item__name'>{runner?.name}</div>
            <div className='sub-item__market'>
              {t(SRM_MARKETS_LIST[sel.place - 1])}
              <MultiStatusIcon outcomeInfo={outcome?.outcomeInfo} outcomePosition={index} />
            </div>
          </div>
        </div>
      </div>
    );
  });

  return layout.mobileDevice && collapsedMulti ? (
    <Accordion className='event-legs' collapsed titleOpen={t('hide_legs_more')}>
      {runnersInfo}
    </Accordion>
  ) : (
    runnersInfo
  );
};

export default BetSubItemViewSrm;
