import React, { useMemo } from 'react';
import { getUploadDocumentForm, onUploadDocumentSubmit } from '@apollo/react-forms';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import useVerifyDocumentsDocumentTypePlaceholder from './useVerifyDocumentsDocumentTypePlaceholder';
import FormCom from '../../../../shared/components/Form/FormCom';

const UploadForm = ({ documentTypes }) => {
  const t = useTranslate();
  const footerContent = useMemo(
    () => (
      <div className='upload-form-footer'>
        <span>{t('accepted_file_formats_jpg_png_pdf_tiff_jpeg_only')}</span>
        <span>
          <span className='icon AkIcon-lock-icon' />
          <span>{t('Secure')}</span>
        </span>
      </div>
    ),
    [t]
  );

  const uploadDocumentForm = useMemo(() => getUploadDocumentForm(documentTypes), [documentTypes]);

  const ref = useVerifyDocumentsDocumentTypePlaceholder();

  return (
    <div ref={ref} className='verify-form-container'>
      <FormCom
        form={uploadDocumentForm}
        action={onUploadDocumentSubmit}
        buttonText='upload'
        formHints={footerContent}
      />
    </div>
  );
};
export default UploadForm;
