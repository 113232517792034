import React, { useEffect, useState } from 'react';
import moment from 'moment';
import useTranslate from '../I18n/Interpreter';

const calculateTimeLeft = (time) => {
  const difference = moment.utc(time).diff(moment());

  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      difference: +difference,
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

const TimerItem = ({ value }) => {
  if (value === undefined || value === null) return;
  const splitValue = value.toString().split('');
  return (
    value.toString().length === 2
      ? (
        <>
          <span>{splitValue[0]}</span>
          <span>{splitValue[1]}</span>
        </>
      )
      : (
        <>
          <span>0</span>
          <span>{splitValue[0]}</span>
        </>
      )
  );
};

const CountdownTimer = ({ time, simple }) => {
  const t = useTranslate();

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(time));

  useEffect(() => {
    const timeoutId = setInterval(() => {
      setTimeLeft(calculateTimeLeft(time));
    }, 1000);

    return () => clearInterval(timeoutId);
  }, [time]);

  return (
    timeLeft.difference > 0
      ? (
        simple
          ? (
            <>
              <TimerItem value={timeLeft.days} />
              :
              <TimerItem value={timeLeft.hours} />
              :
              <TimerItem value={timeLeft.minutes} />
              :
              <TimerItem value={timeLeft.seconds} />
            </>
          )
          : (
            <>
              <div className='timer'>
                <div>
                  <TimerItem value={timeLeft.days} />
                </div>
                <b>
                  {' '}
                  :
                  {' '}
                </b>
                <div>
                  <TimerItem value={timeLeft.hours} />
                </div>
                <b>
                  {' '}
                  :
                  {' '}
                </b>
                <div>
                  <TimerItem value={timeLeft.minutes} />
                </div>
                <b>
                  {' '}
                  :
                  {' '}
                </b>
                <div>
                  <TimerItem value={timeLeft.seconds} />
                </div>
              </div>
              <div className='timerText'>
                <span>{t('days')}</span>
                <span>{t('hours')}</span>
                <span>{t('minutes')}</span>
                <span>{t('seconds')}</span>
              </div>
            </>
          )
      )
      : <span>{t('time_over')}</span>
  );
};

export default CountdownTimer;
