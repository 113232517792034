import React, { forwardRef, useCallback, useMemo } from 'react';
import { prepareRequest } from '@apollo/core';
import { areFieldsLoadedAndValid, Form } from '@apollo/react-forms';
import cx from 'classnames';
import _ from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import GlobalErrors from './GlobalErrors';
import Label from '../I18n/Label';
import useFormCaptcha from './useFormCaptcha';
import { fieldComponents } from './formConfig';

const SubmitButton = ({ fields, globalErrors, isLoading, children, onClick, classes = null }) => {
  const isDisabled = Boolean(isLoading || globalErrors.length || !areFieldsLoadedAndValid(fields));
  const className = cx(
    {
      bBasePrimary: !classes,
      disabled: isDisabled,
    },
    classes,
  );

  return (
    <button onClick={onClick} type='button' className={className}>
      {children}
    </button>
  );
};

const ContainerComponent = ({
  children,
  className,
  id,
  autocomplete,
  action,
  method,
  onSubmit,
  fields,
  globalErrors,
  isLoading,
  buttonText = 'submit',
  buttonClasses = null,
  formHints = null,
  footerContent = null,
  buttonRightContent = null,
  buttonLeftContent = null,
  onCaptchaChange,
  onSetGlobalErrors,
  isCaptchaRequired,
  captchaKey,
}) => {
  const recaptchaRef = React.useRef();

  const handleCaptchaChange = React.useCallback(
    (value) => {
      onSetGlobalErrors([]);
      onCaptchaChange(value);
    },
    [onCaptchaChange, onSetGlobalErrors],
  );

  const handleSubmitWithCaptcha = useCallback(
    (event) => {
      onSubmit(event);
      if (recaptchaRef && recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    },
    [recaptchaRef, onSubmit],
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter' && event.shiftKey === false) {
        event.preventDefault();
        handleSubmitWithCaptcha(event);
      }
    },
    [handleSubmitWithCaptcha],
  );
  const formClassName = useMemo(() => cx('form', className), [className]);

  return (
    <form
      id={id}
      autoComplete={autocomplete === false ? 'off' : undefined}
      action={action || undefined}
      method={method || 'POST'}
      onSubmit={onSubmit}
      onKeyDown={handleKeyDown}
      className={formClassName}
    >
      <div className='form-wrapper'>
        {children}

        {isCaptchaRequired && (
          <ReCAPTCHA sitekey={captchaKey} onChange={handleCaptchaChange} ref={recaptchaRef} />
        )}

        <GlobalErrors globalErrors={globalErrors} />

        {formHints && <div className='form-wrapper__hints'>{formHints}</div>}

        <div className='block block__bGroup'>
          {buttonRightContent}
          <SubmitButton
            onClick={handleSubmitWithCaptcha}
            fields={fields}
            globalErrors={globalErrors}
            isLoading={isLoading}
            classes={buttonClasses}
          >
            <Label message={buttonText} />
          </SubmitButton>
          {buttonLeftContent}
        </div>

        {footerContent && <div className='form-wrapper__footer'>{footerContent}</div>}
      </div>
    </form>
  );
};

const FormCom = (
  {
    id,
    action,
    form,
    buttonText = null,
    buttonClasses = null,
    formHints,
    footerContent,
    buttonRightContent,
    buttonLeftContent,
    onSubmit,
    additionalValues,
    onError,
    initialValues,
    className,
    initialCaptchaKey,
    initialCaptchaRequired,
  },
  ref,
) => {
  const {
    input: captchaInput,
    isRequired: isCaptchaRequired,
    key: captchaKey,
    setInput: setCaptchaInput,
    handleError: handleErrorWithCaptcha,
  } = useFormCaptcha(initialCaptchaKey, initialCaptchaRequired, onError);

  const validateFieldAsyncHandler = useCallback(
    async (field) => {
      const { name: fieldName, value } = field;

      if (_.isNull(value)) {
        return [];
      }

      const request = prepareRequest({
        url: 'client/form:validate-field',
        method: 'POST',
      });

      let errors = [];

      try {
        await request({
          fieldName,
          formId: id,
          value,
        });
      } catch (error) {
        const newErrors = _.get(error, 'errors[0].violations');

        if (newErrors && newErrors.length) {
          errors = newErrors;
        }
      }

      return errors;
    },
    [id],
  );

  const ContainerComponentMemo = useMemo(
    () => (props) => (
      <ContainerComponent
        id={form.name}
        autocomplete={form.autocomplete}
        action={form.action}
        buttonClasses={buttonClasses}
        buttonText={buttonText}
        formHints={formHints}
        footerContent={footerContent}
        buttonRightContent={buttonRightContent}
        buttonLeftContent={buttonLeftContent}
        className={className}
        isCaptchaRequired={isCaptchaRequired}
        onCaptchaChange={setCaptchaInput}
        captchaKey={captchaKey}
        {...props}
      />
    ),
    [footerContent, buttonLeftContent, className, buttonText, isCaptchaRequired, form],
  );

  const mergedAdditionalValues = _.merge(additionalValues, {
    ...(isCaptchaRequired ? { CAPTCHA_INPUT: captchaInput } : {}),
  });

  return (
    <Form
      ref={ref}
      form={form}
      onError={handleErrorWithCaptcha}
      onSubmit={onSubmit}
      action={action}
      fieldComponents={fieldComponents}
      ContainerComponent={ContainerComponentMemo}
      onValidateFieldAsync={validateFieldAsyncHandler}
      additionalValues={mergedAdditionalValues}
      initialValues={initialValues}
    />
  );
};

export default forwardRef(FormCom);
