import React from 'react';
import { useApplicationState } from '@apollo/core';
import useTransactionHistory from './useTransactionHistory';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import TransactionHistoryFilterList from './TransactionHistoryFilter';
import Svg from '../../../../shared/components/svg/Svg';
import Pagination from '../../../../shared/components/Pagination';
import TransactionHistoryDesktop from './TransactionHistoryDesktop';
import TransactionHistoryMobile from './TransactionHistoryMobile';
import Loader from '../../../../shared/components/Loader';

const TransactionHistory = () => {
  const {
    transactions,
    activeTimeFilter,
    activeTypeFilters,
    currentPage,
    totalPages,
    setActiveTimeFilter,
    setActiveTypeFilters,
    setCurrentPage,
    cancelWithdrawalHandler,
    range,
    setRange,
    isLoading,
  } = useTransactionHistory();
  const { layout } = useApplicationState();

  const t = useTranslate();
  return (
    <>
      <TransactionHistoryFilterList
        activeTimeFilter={activeTimeFilter}
        setActiveTimeFilter={setActiveTimeFilter}
        range={range}
        activeTypeFilters={activeTypeFilters}
        setActiveTypeFilters={setActiveTypeFilters}
        setRange={setRange}
      />
      {totalPages === 0 && transactions.length === 0 ? (
        <div className='empty-state-history'>
          <div className='svg-container'>
            <Svg name='emptyState' />
          </div>
          <span>{t('There is no items yet')}</span>
        </div>
      ) : (
        <>
          <div className='history-table'>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {layout.mobileDevice ? (
                  <TransactionHistoryMobile
                    {...{
                      transactions,
                      cancelWithdrawalHandler,
                      totalPages,
                    }}
                  />
                ) : (
                  <TransactionHistoryDesktop
                    {...{
                      transactions,
                      cancelWithdrawalHandler,
                      totalPages,
                    }}
                  />
                )}
              </>
            )}
          </div>
          {transactions.length > 0 && totalPages > 1 && (
            <Pagination
              pages={totalPages}
              currentPage={currentPage}
              changeCurrentPage={setCurrentPage}
            />
          )}
        </>
      )}
    </>
  );
};

export default TransactionHistory;
