import React, { useMemo, Fragment } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import {
  useEventState,
} from '@apollo/core';
import {
  SPORT_IDS_WITH_POINTS,
  SPORT_IDS_WITH_SETS,
  SPORT_IDS_WITHOUT_PERIOD_VIEW,
} from '../../../core/constants';
import useTranslate from '../I18n/Interpreter';
import useCurrentParticipantTurn from './useCurrentParticipantTurn';
import Label from '../I18n/Label';

const getScoreCellClassName = (
  isHeading = false,
  isGeneral = false,
  icon = '',
) => cx([
  'score',
  isHeading && 'hr',
  isGeneral && (isHeading ? 'general' : 'ct'),
  icon && `gameStatIcon-${_.kebabCase(icon)}`,
]);

const getDisplayScore = (score) => {
  const numScore = parseInt(score);

  return _.isNaN(numScore) ? score : numScore;
};

const chooseTitle = (sportId) => {
  if (SPORT_IDS_WITH_SETS.includes(sportId)) return 'Sets';
  if (sportId === 7) return 'Games';
  return 'Score';
};

const GameStat = () => {
  const event = useEventState();

  const [
    startTime,
    sportId,
    homeShortName,
    awayShortName,
    homeScore,
    awayScore,
    matchClock,
    periodInfo,
    away,
    home,
  ] = _.at(event, [
    'startTime',
    'sportId',
    'participants.home.shortName',
    'participants.away.shortName',
    'info.score.homeScore',
    'info.score.awayScore',
    'info.score.matchClock',
    'info.stats.periodInfo',
    'info.stats.away',
    'info.stats.home',
  ]);

  const t = useTranslate();
  const currentParticipantTurn = useCurrentParticipantTurn(event);
  const homeClassName = useMemo(
    () => cx(['ct', 'name', currentParticipantTurn === 'home' && 'active']),
    [currentParticipantTurn],
  );
  const awayClassName = useMemo(
    () => cx(['ct', 'name', currentParticipantTurn === 'away' && 'active']),
    [currentParticipantTurn],
  );

  const time = useMemo(() => {
    if (_.toNumber(matchClock) === -1) {
      return '';
    }

    if (matchClock) {
      return `${(_.toNumber(matchClock) / 60).toFixed(0)}`;
    }

    return '';
  }, [matchClock, startTime]);

  const renderPeriodView = () => {
    if (SPORT_IDS_WITHOUT_PERIOD_VIEW.includes(sportId)) {
      return null;
    }

    return _.map(periodInfo, ({ periodDescription, homeScore, awayScore }) => {
      const title = periodDescription === 'Game' && SPORT_IDS_WITH_POINTS.includes(sportId)
        ? 'Points'
        : periodDescription;

      return (
        <Fragment key={title}>
          <span
            className={getScoreCellClassName(true, true, title)}
          >
            {title}
          </span>
          <span
            className={getScoreCellClassName(false, true)}
          >
            {getDisplayScore(homeScore)}
          </span>
          <span
            className={getScoreCellClassName(false, true)}
          >
            {getDisplayScore(awayScore)}
          </span>
        </Fragment>
      );
    });
  };

  const renderGeneralScore = () => {
    const title = chooseTitle(sportId);

    return (
      <>
        <Label className={getScoreCellClassName(true, true, title)} message={title} />
        <span className={getScoreCellClassName(false, true)}>{getDisplayScore(homeScore)}</span>
        <span className={getScoreCellClassName(false, true)}>{getDisplayScore(awayScore)}</span>
      </>
    );
  };

  const renderAdditionalInfo = () => {
    if (_.isEmpty(home) || _.isEmpty(away)) {
      return null;
    }

    return _.chain(_.union(Object.keys(home), Object.keys(away)))
      .filter((i) => i !== 'points')
      .map((v) => (
        <Fragment key={v}>
          <span className={getScoreCellClassName(true, false, v)}>{t(v)}</span>
          <span className={getScoreCellClassName()}>{home[v] !== undefined ? home[v] : '-'}</span>
          <span className={getScoreCellClassName()}>{away[v] !== undefined ? away[v] : '-'}</span>
        </Fragment>
      ))
      .value();
  };

  return (
    <div className='gameStat'>
      <span className='hr name'>{time ? `${time}'` : 'Participants'}</span>
      <span className={homeClassName}>
        <span>{homeShortName}</span>
      </span>
      <span className={awayClassName}>
        <span>{awayShortName}</span>
      </span>
      {renderPeriodView()}
      {renderGeneralScore()}
      {renderAdditionalInfo()}
    </div>
  );
};

export default GameStat;
