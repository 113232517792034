import React from 'react';
import _ from 'lodash';
import Outcome from '../../../../shared/components/DetailOdds/Outcome';

const OutrightOutcomes = ({ event, market, outcomes, suspended, disabled }) =>
  _.map(outcomes, (outcome) => {
    const [id, specifiers] = _.at(outcome, ['id', 'specifiers']);

    return (
      <div key={id} className='outright-market__outcome'>
        {/* <Logo participantId={0} sportId={event.sportId} size='lg' alt={0} isNewDesign /> */}
        <Outcome
          marketType={market}
          event={event}
          market={market}
          outcome={outcome}
          disabled={disabled}
          suspended={suspended}
        />
      </div>
    );
  });

export default OutrightOutcomes;
