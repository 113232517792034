import React from 'react';
import Label from '../../../shared/components/I18n/Label';
import BetOdds from './BetOdds';
import { getLegsCountFromSelection } from '../../../core/utils';

const BetItemViewSrm = (props) => {
  const { outcome, market, oddsToDisplay, assignedBonus } = props;

  const legs = React.useMemo(() => getLegsCountFromSelection(outcome?.selection), [outcome]);

  return (
    <>
      <div className='event event--racing'>
        <div className='event__outcome-name'>
          <Label message='Leg Same Race Multi' />
          <span>
            <small className='event-icon'>
              {legs}
              &nbsp;
              <Label message='Legs' />
            </small>
          </span>
        </div>
      </div>

      <BetOdds
        market={market}
        outcomeId={outcome?.id}
        odds={outcome?.odds}
        oddsToDisplay={oddsToDisplay}
        assignedBonus={assignedBonus}
      />
    </>
  );
};

export default BetItemViewSrm;
