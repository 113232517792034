import React, { useEffect, useMemo, useState } from 'react';
import DOMPurify from 'dompurify';
import { CmsApi, useApplicationState, useSettings } from '@apollo/core';
import { Helmet } from 'react-helmet-async';
import { buildCdnUrl, LocalConfig } from '../../core/utils';
import MainBody from '../../shared/components/MainBody/MainBody';
import useTranslate from '../../shared/components/I18n/Interpreter';

const PromotionPage = ({
  match: {
    params: { id },
  },
}) => {
  const t = useTranslate();
  const { lang } = useSettings();
  const { authenticated } = useApplicationState();
  const [promotion, setPromotion] = useState({});

  useEffect(() => {
    const ac = new AbortController();
    CmsApi.getPromotion({ id, signal: ac.signal }).then((payload) => {
      setPromotion(payload);
    });
    return () => ac.abort();
  }, [id, lang, authenticated]);

  const headMeta = useMemo(
    () => ({
      title: `${promotion.title} - ${LocalConfig.get('public.title')} ${t('page_title')}`,
      description: `${promotion.title}. ${LocalConfig.get('public.description')}`,
      keywords: `${promotion.title}, ${LocalConfig.get('public.keywords')}`,
    }),
    [promotion?.title],
  );

  return (
    <>
      <Helmet>
        <title>{headMeta.title}</title>
        <meta name='description' content={headMeta.description} />
        <meta name='keywords' content={headMeta.keywords} />
      </Helmet>
      <div className='main__page main__page--promotions'>
        <MainBody className='main__body--promotions'>
          <div className='promotion-detail'>
            {promotion?.cover_image_url && (
              <div className='promotion-detail__image'>
                <img
                  src={buildCdnUrl(promotion.cover_image_url, 'LOGOS_CDN_URL')}
                  alt=''
                  loading='lazy'
                />
              </div>
            )}

            {promotion?.mobile_cover_image_url && (
              <div className='promotion-detail__image promotion-detail__image--mobile'>
                <img
                  src={buildCdnUrl(promotion.mobile_cover_image_url, 'LOGOS_CDN_URL')}
                  alt=''
                  loading='lazy'
                />
              </div>
            )}
            <h1 className='promotion-detail__title'>{promotion?.title}</h1>

            <div
              className='promotion-detail__info'
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(promotion?.full_content, {
                  KEEP_CONTENT: true,
                }),
              }}
            />
          </div>
        </MainBody>
      </div>
    </>
  );
};

export default PromotionPage;
