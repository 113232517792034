import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SportSelector } from '@apollo/core/src/state/sport/sport';
import { isEmpty } from 'lodash';
import { Bus } from '@apollo/core';
import Label from '../../../shared/components/I18n/Label';
import TournamentsListItem from '../SportsSidebar/Tournaments/TournamentsListItem';
import CategoryList from '../SportPage/CategoryList';
import { SPORTS_BY_CATEGORY } from '../../../core/constants';
import SportLoader from '../SportLoader';
import SportNothingFound from '../SportNothingFound';

const PrematchAll = ({ sportId }) => {
  const [activeCategory, setActiveCategory] = useState();

  const loading = SportSelector.selectIsLoading();
  const selectedSport = SportSelector.selectSportById(sportId);
  const tournaments = SportSelector.selectTournamentsBySportId(sportId);

  const filteredTournaments = useMemo(
    () => tournaments.filter((t) => t.totalCount > 0),
    [tournaments],
  );

  const tournamentIds = useMemo(() => filteredTournaments.map((t) => t.id), [filteredTournaments]);

  useEffect(() => {
    Bus.emit(Bus.events.sport.sportLoadEvents, {
      filter: {
        isOnlyMainMarketTypes: true,
        fromDate: Date.now(),
        toDate: null,
        sportId,
        tournamentIds,
      },
      paging: {
        itemsPerPage: 100,
        currentPage: 0,
      },
    });
  }, [sportId, tournamentIds]);

  const topTournaments = useMemo(
    () => filteredTournaments.filter((t) => t.isTop === true),
    [tournaments],
  );

  const toggleActiveCategory = useCallback(
    (id) => {
      if (id === activeCategory) {
        setActiveCategory(null);
      } else {
        setActiveCategory(id);
      }
    },
    [activeCategory],
  );

  if (isEmpty(filteredTournaments) && isEmpty(topTournaments)) {
    if (loading) {
      return <SportLoader />;
    }
    return <SportNothingFound className='nothing-found--prematch-all' />;
  }

  return (
    <div className='main__body main__body--sport-page'>
      {!isEmpty(topTournaments) && (
        <>
          <h3>
            <Label message='top' />
            {' '}
            {selectedSport?.name}
          </h3>
          <div className='sidebar-tree__section'>
            {topTournaments.map((tournament) => (
              <TournamentsListItem key={tournament.id} tournament={tournament} />
            ))}
          </div>
        </>
      )}
      <h3>
        <Label message='az' />
        {' '}
        {selectedSport?.name}
      </h3>
      <div className='sidebar-tree__section'>
        {SPORTS_BY_CATEGORY.includes(sportId) ? (
          <div className='sport-page__content--list'>
            <CategoryList
              sportId={sportId}
              activeCategory={activeCategory}
              toggleActiveCategory={toggleActiveCategory}
            />
          </div>
        ) : (
          filteredTournaments.map((tournament) => (
            <TournamentsListItem key={tournament.id} tournament={tournament} />
          ))
        )}
      </div>
    </div>
  );
};

export default PrematchAll;
