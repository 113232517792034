import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import {
  ApiManager,
  DateService,
} from '@apollo/core';

import { COMPETITION_STATUSES } from './constants';
import Label from '../../shared/components/I18n/Label';
import { buildCdnUrl, LocalConfig } from '../../core/utils';

const CompetitionCard = ({ competition, activeCompetition, setActiveCompetition }) => {
  const [
    status,
    name,
    imageLink,
    startTime,
    endTime,
  ] = _.at(competition, [
    'status',
    'description.name',
    'description.imageLink',
    'timeRange.startTime',
    'timeRange.endTime',
  ]);

  const onSelect = useCallback(() => {
    setActiveCompetition(competition);
  }, [competition, setActiveCompetition]);

  const CompetitionCardClass = cx(
    'promotion-card',
    competition.id === activeCompetition?.id && 'active',
  );

  const imgSrc = buildCdnUrl(imageLink);
  const dateText = `${DateService.getFormattedDate(startTime)} - ${DateService.getFormattedDate(endTime)}`;
  const now = Date.now();

  const PromotionInfo = useMemo(() => {
    if ([COMPETITION_STATUSES.IN_PROGRESS, COMPETITION_STATUSES.LAUNCHED].includes(status)) {
      if (startTime < now) {
        const restDays = Math.round((endTime - now) / (24 * 60 * 60 * 1000));

        return (
          <div className='promotion-card-days'>
            <Label className='promotion-card-days__text' message='time_left' />
            <div className='promotion-card-days__amount'>{restDays}</div>
            <Label className='promotion-card-days__text' message='days' />
          </div>
        );
      }
      const daysToStart = Math.round((startTime - now) / (24 * 60 * 60 * 1000));

      return (
        <div className='promotion-card-days'>
          <Label className='promotion-card-days__text' message='started_in' />
          <div className='promotion-card-days__amount'>{daysToStart}</div>
          <Label className='promotion-card-days__text' message='days' />
        </div>
      );
    }
    return (
      <div className='promotion-card-days'>
        <div className='promotion-card-days__text'>
          <Label message={status} />
        </div>
      </div>
    );
  }, [status, startTime, endTime]);

  return (
    <button type='button' className={CompetitionCardClass} onClick={onSelect}>
      <div className='promotion-card__top'>
        <img className='promotion-card__img' src={imgSrc} />
        <div className='promotion-card__date'>{dateText}</div>
      </div>
      <div className='promotion-card__wrapper'>
        <div className='promotion-card__text'>{name}</div>
        {PromotionInfo}
      </div>
    </button>
  );
};

export default CompetitionCard;
