import React, { useEffect } from 'react';
import { DocumentsManager, useDocumentsState } from '@apollo/core';
import UploadDocumentList from './UploadDocumentList';
import UploadForm from './UploadForm';
import { cmsStaticBlock } from '../../../../core/constants';
import StaticBlock from '../../../../shared/components/StaticBlock/StaticBlock';
import Label from '../../../../shared/components/I18n/Label';
import Loader from '../../../../shared/components/Loader';

const VerifyDocuments = () => {
  const documentsState = useDocumentsState();
  const { documents, documentTypes, loading } = documentsState;

  useEffect(() => {
    DocumentsManager.fetchDocuments();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (!documentTypes.length) {
    return null;
  }

  return (
    <div className='wrapper verify__documents'>
      <div className='wrapper__container'>
        <div className='verify-container'>
          <div className='document_upload'>
            <Label message='Personal_Verification' className='title' />
            <div className='document_upload__body'>
              <StaticBlock
                options={{ source: cmsStaticBlock.VerifyDocuments }}
              />
              <UploadForm documentTypes={documentTypes} />
            </div>
          </div>
          <div className='document_upload__list'>
            <Label message='Uploaded_Documents' className='title' />
            <div className='document_upload__body'>
              <UploadDocumentList documents={documents} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyDocuments;
