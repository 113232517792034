import { RacingManager } from '@apollo/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { RacingActions, useRacingState } from '@apollo/core/src/state/racing/racing';
import { STATUS } from '@apollo/core/src/constants';
import Loader from '../../../../shared/components/Loader/Loader';
import Odds from '../../../../shared/components/Odds/Odds';
import ToastManager from '../../../../core/ToastManager';
import { RACE_STATUS } from '../../../../core/constants';

const ANIMATION_TIMEOUT_MS = 3000;

const DutchOdds = ({
  className,
  race,
  runner,
  odds,
  selected,
  setOutcome,
  setIsFetchingOutcome,
}) => {
  const prevOddsRef = useRef(_.toNumber(odds));
  const resetDirectionClassTimerRef = useRef(null);
  const [directionClassName, setDirectionClassName] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const [racingState, racingDispatcher] = useRacingState();
  const { marketsByType, combinedSelections } = racingState;

  // console.log({ marketsByType });

  useEffect(() => {
    const newOdds = _.toNumber(odds);
    const prevOdds = prevOddsRef.current;
    const dOdds = newOdds - prevOdds;
    prevOddsRef.current = newOdds;

    if (!dOdds) {
      return;
    }

    // window.clearTimeout(resetDirectionClassTimerRef.current);
    setDirectionClassName(dOdds > 0 ? 'up' : 'down');
    resetDirectionClassTimerRef.current = window.setTimeout(() => {
      setDirectionClassName(null);
    }, ANIMATION_TIMEOUT_MS);

    return () => {
      window.clearTimeout(resetDirectionClassTimerRef.current);
    };
  }, [odds]);

  const isDisabled = () => ![STATUS.ACTIVE, RACE_STATUS.OPEN].includes(race.status) || runner.scratched;

  const clickHandler = useCallback(
    (e) => {
      const selections = combinedSelections;
      const prevSelections = [...combinedSelections];

      if (selections.includes(runner.number)) {
        selections.splice(selections.indexOf(runner.number), 1);
      } else {
        selections.push(runner.number);
      }
      if (selections.length > 1) {
        racingDispatcher({
          type: RacingActions.RACING_STATE_UPDATE,
          payload: {
            combinedSelections: [...selections],
          },
        });
        setIsFetchingOutcome(true);
        setIsFetching(true);
        RacingManager.getCombinedOutcome({
          raceId: race.raceId,
          marketId: marketsByType?.COMBINED?.marketId,
          numbers: selections,
        })
          .then(({ outcome }) => {
            setOutcome(outcome);
            setIsFetchingOutcome(false);
            setIsFetching(false);
          })
          .catch((err) => {
            setIsFetchingOutcome(false);
            setIsFetching(false);
            ToastManager.error(() => err.message);
            racingDispatcher({
              type: RacingActions.RACING_STATE_UPDATE,
              payload: {
                combinedSelections: prevSelections,
              },
            });
          });
      } else {
        setOutcome(null);
        racingDispatcher({
          type: RacingActions.RACING_STATE_UPDATE,
          payload: {
            combinedSelections: selections,
          },
        });
      }
      e.stopPropagation();
    },
    [race, runner, combinedSelections],
  );

  const outcomeClassName = React.useMemo(
    () => cx(
      className,
      'event__outcome',
      directionClassName ? `event__outcome--${directionClassName}` : '',
      {
        disabled: isDisabled(),
        selected,
        // editing,
        // placed,
        // failed,
        // loading,
        // succeeded,
        'event__outcome--with-name': Boolean(runner.name),
      },
    ),
    [
      directionClassName,
      className,
      selected,
      // editing,
      // placed,
      // failed,
      // loading,
      // succeeded,
      isDisabled(),
    ],
  );
  return (
    <div className={outcomeClassName} {...(!isDisabled() && { onClick: clickHandler })}>
      <span className='odds'>
        <Odds value={odds} decimal={2} minDecimal={2} />
      </span>
      {isFetching ? <Loader className='fetchingOutcome__absolute' /> : null}
      {directionClassName ? (
        <span className={`${directionClassName} AIcon-angle-${directionClassName}`} />
      ) : null}
    </div>
  );
};

export default DutchOdds;
