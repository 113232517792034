import React from 'react';
import cx from 'classnames';
import {
  BetManager,
  useBetslipState,
  useGlobalBetslipState,
} from '@apollo/core';
import { isEmpty } from 'lodash';
import BetslipDefaultView from './BetslipDefaultView';
import BetReports from './BetReports';
import BetslipEmptyState from './BetslipEmptyState';

const Betslip = ({ active }) => {
  const { betItems } = useBetslipState();
  const { reports } = useGlobalBetslipState();

  const isBetslipEmpty = isEmpty(betItems) && isEmpty(reports);

  React.useEffect(() => {
    BetManager.focus('main');
  }, []);

  const className = cx('bets__betslip', 'tab__content', {
    active,
  });

  return (
    <div className={className}>
      <BetReports />

      {isBetslipEmpty && <BetslipEmptyState />}

      <BetslipDefaultView active={active} />
    </div>
  );
};

export default Betslip;
