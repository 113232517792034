import React from 'react';
import { DateService } from '@apollo/core';
import classNames from 'classnames';
import useTranslate from '../../../../../shared/components/I18n/Interpreter';
import { transactionTypes, WITHDRAWAL_CREATE } from '../../../../../core/constants';
import { getCurrencySymbol } from '../../../../../core/utils';
import Label from '../../../../../shared/components/I18n/Label';
import TransactionCancelButton from './TransactionCancelButton';
import TransactionAmount from './TransactionAmount';
import TransactionEventDetails from './TransactionEventDetails';
import BetSourceTooltip from '../../BetHistory/TableList/BetSourceTooltip';
import BetCouponOdds from '../../../../Betslip/MyBets/BetCouponOdds';

const TransactionHistoryItemMobile = ({
  transaction,
  outcomes,
  isBetTransaction,
  flexiPercentage,
  disableCancel,
  decimalCashBalance,
  cancelWithdrawalHandler,
  oddsAmount,
  originalBetOdds,
  bonusInfoList,
  bonusType,
  betType,
  betUpdatedOdds,
}) => {
  const { id, type, creationDate, currency, properties } = transaction;

  const t = useTranslate();

  const outcomeInfo = outcomes && outcomes[0];

  const date = DateService.getFormattedDate(creationDate);
  const time = DateService.getFormattedTime(creationDate);
  const currencySymbol = getCurrencySymbol(currency);

  return (
    <div key={transaction.creationDate} className='history-item'>
      <div className='history-item__header'>
        <div className='history-item__header-id'>
          <Label message='id' />
          &nbsp;#
          {id}
        </div>
        <div className='history-item__header-date'>
          <span>{date}</span>
          <span className='sub-title'>{time}</span>
        </div>
      </div>
      <div
        className={`event-container ${
          outcomeInfo?.sameGameMultiLegs?.length ? 'event-container--baseline' : ''
        }`}
      >
        {isBetTransaction ? (
          <TransactionEventDetails
            outcomes={outcomes}
            transaction={transaction}
            flexiPercentage={flexiPercentage}
          />
        ) : (
          <span>{properties?.PM_NAME ?? '-'}</span>
        )}
        <div className='event-amount'>
          <div
            className={classNames('type', {
              isPositive: type === transactionTypes.BET_WIN,
            })}
          >
            <span>{t(type)}</span>
            <BetSourceTooltip bonusInfoList={bonusInfoList} />
          </div>
          <div className=''>
            <div className='bet-stake'>
              <TransactionAmount
                transaction={transaction}
                bonusInfoList={bonusInfoList}
                bonusType={bonusType}
              />
            </div>
            {oddsAmount ? (
              <span className='bet-odds'>
                {' @ '}
                {/* <TransactionOdds
                  betOdds={oddsAmount}
                  outcomes={outcomes}
                  originalBetOdds={originalBetOdds}
                /> */}
                <BetCouponOdds
                  updatedOdds={betUpdatedOdds}
                  bonusInfoList={bonusInfoList}
                  betType={betType}
                  outcome={{ odds: originalBetOdds || oddsAmount, outcomeInfo }}
                />
              </span>
            ) : null}
          </div>
          <div className=''>
            <Label message='avail bal' className='text-nowrap' />
            <span className='balance'>{`${currencySymbol}${decimalCashBalance}`}</span>
          </div>
        </div>
      </div>
      {!disableCancel && type === WITHDRAWAL_CREATE && (
        <div className='verify-status'>
          <TransactionCancelButton onCancel={cancelWithdrawalHandler} />
        </div>
      )}
    </div>
  );
};

export default TransactionHistoryItemMobile;
