import React, { useState } from 'react';
import { useApplicationState } from '@apollo/core';
import Label from '../../../../shared/components/I18n/Label';

const RecentRunsMobileRow = ({ run }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <tr onClick={() => setIsExpanded(!isExpanded)}>
        <td className={`finish_${run.finishPosition}`}>
          {run.finishPosition > 90 ? (
            <Label message='na' />
          ) : (
            run.finishPosition
          )}
          {' / '}
          {run.numStarters}
        </td>
        <td>{run.raceDate}</td>
        <td>{run.venueAbbrev || run.venueName || '-'}</td>
        <td>{run.distance}</td>
        <td>
          {run.trial ? (
            <Label message='trial' className='highlight' />
          ) : (
            run.spOdds || '-'
          )}
        </td>
        <td className='text-right'>
          <span
            className={`arrow AIcon-angle-right ${isExpanded ? 'active' : ''}`}
          />
        </td>
      </tr>
      {isExpanded ? (
        <tr className='opacityBlock'>
          <td colSpan={6}>
            <div className='runner-runs__data'>
              <div className='runner-runs__data--item'>
                <Label message='Time' className='item-title' />
                {run.totalTime || '-'}
              </div>
              <div className='runner-runs__data--item'>
                <Label message='Margin' className='item-title' />
                {run.margin || '-'}
              </div>
              <div className='runner-runs__data--item'>
                <Label message='Barrier' className='item-title' />
                {run.barrier}
              </div>
              <div className='runner-runs__data--item'>
                <Label message='Track' className='item-title' />
                {run.going || '-'}
              </div>
              <div className='runner-runs__data--item'>
                <Label message='Winner/2nd' className='item-title' />
                {run.winnerOrSecond || '-'}
              </div>
              <div className='runner-runs__data--item'>
                <Label message='Class' className='item-title' />
                {run.raceClass}
              </div>
              {run?.jockey ? (
                <div className='runner-runs__data--item'>
                  <Label message='Jockey/WGT' className='item-title' />
                  {run.jockey}
                  {' / '}
                  {run.weight || '-'}
                </div>
              ) : null}
            </div>
          </td>
        </tr>
      ) : null}
    </>
  );
};

const RecentRunsMobile = ({ runs }) => (
  <table>
    <thead>
      <tr>
        <th>
          <Label message='place' />
        </th>
        <th>
          <Label message='date' />
        </th>
        <th>
          <Label message='Venue' />
        </th>
        <th>
          <Label message='Distance' />
        </th>
        <th>
          <Label message='odds' />
        </th>
        <th />
      </tr>
    </thead>
    <tbody>
      {runs.map((run) => (<RecentRunsMobileRow key={`${run.raceDate}_${run.barrier}`} run={run} />))}
    </tbody>
  </table>
);
const RecentRunsDesktop = ({ runs }) => (
  <table>
    <thead>
      <tr>
        <th>
          <Label message='place' />
        </th>
        <th>
          <Label message='date' />
        </th>
        <th>
          <Label message='Venue' />
        </th>
        <th>
          <Label message='Distance' />
        </th>
        <th>
          <Label message='Time' />
        </th>
        <th>
          <Label message='Margin' />
        </th>
        <th>
          <Label message='Barrier' />
        </th>
        <th>
          <Label message='odds' />
        </th>
        <th>
          <Label message='Track' />
        </th>
        <th>
          <Label message='Winner/2nd' />
        </th>
        <th>
          <Label message='Class' />
        </th>
        {runs[0]?.jockey ? (
          <th>
            <Label message='Jockey/WGT' />
          </th>
        ) : null}
      </tr>
    </thead>
    <tbody>
      {runs.map((run) => (
        <tr key={`${run.raceDate}_${run.barrier}`}>
          <td className={`finish_${run.finishPosition}`}>
            {run.finishPosition > 90 ? (
              <Label message='na' />
            ) : (
              run.finishPosition
            )}
            {' / '}
            {run.numStarters}
          </td>
          <td>
            {run.raceDate}
          </td>
          <td>{run.venueAbbrev || run.venueName || '-'}</td>
          <td>{run.distance}</td>
          <td>{run.totalTime || '-'}</td>
          <td>{run.margin || '-'}</td>
          <td>
            {run.barrier}
          </td>
          <td>
            {run.trial ? (
              <Label message='trial' className='highlight' />
            ) : (
              run.spOdds || '-'
            )}
          </td>
          <td>{run.going || '-'}</td>
          <td>{run.winnerOrSecond || '-'}</td>
          <td>{run.raceClass}</td>
          {run.jockey ? (
            <td>
              {run.jockey || '-'}
              {' / '}
              {run.weight || '-'}
            </td>
          ) : null}
        </tr>
      ))}
    </tbody>
  </table>
);

const RecentRuns = ({ runs }) => {
  const { layout } = useApplicationState();

  if (!runs?.length) {

  }

  return (
    <div className='runner-runs'>
      {runs?.length
        ? (layout.mobileDevice
          ? <RecentRunsMobile runs={runs} />
          : <RecentRunsDesktop runs={runs} />)
        : <div><Label message='no runs for runner' /></div>}
    </div>
  );
};

export default RecentRuns;
