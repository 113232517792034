import React, { useMemo } from 'react';
import { isEmpty, map } from 'lodash';
import StatusTooltip from '../../../../../shared/components/StatusTooltip/StatusTooltip';
import Label from '../../../../../shared/components/I18n/Label';

const renderBetHistoryBonusName = (t) => <span>{`${t}: `}</span>;
const renderBetHistoryBonusType = (t) => <span>{`${t}: `}</span>;

const BetSourceTooltip = ({ bonusInfoList }) => {
  const bonusInfo = useMemo(() => map(bonusInfoList || [], ({ bonusType, name }) => (
    <div className='bonus-info' key={name}>
      <div>
        {/* <Label message='bet_history_bonus_name' render={renderBetHistoryBonusName} /> */}
        <span>{name}</span>
      </div>
      <div>
        <Label message='bet_history_bonus_type' render={renderBetHistoryBonusType} />
        <Label message={bonusType} />
      </div>
    </div>
  )), [bonusInfoList]);

  if (isEmpty(bonusInfoList)) {
    return (
      <span className='bet-source-tooltip icon AIcon-transaction-h' />
    );
  }

  return (
    <StatusTooltip
      className='bet-source-tooltip'
      message={bonusInfo}
    >
      <span className={`icon AIcon-bonus AIcon-bonus--${bonusInfoList[0].bonusType}`} />
    </StatusTooltip>
  );
};

export default BetSourceTooltip;
