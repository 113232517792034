import _ from 'lodash';
import { useEffect, useReducer, useCallback } from 'react';
import { betHistoryConstants, CasinoApi } from '@apollo/core';

const {
  rangeName, last24, last48, getThreeMonthRange, getDayRange, getTwoDayRange,
} = betHistoryConstants;

const SET_STATE = 'SET_STATE';

const ITEMS_PER_PAGE = 10;
const INITIAL_STATE = {
  shouldOperationsLoad: true,
  operations: [],
  activeTimeFilter: rangeName,
  range: getThreeMonthRange(),
  currentPage: 0,
  totalPages: 1,
  isLoading: true,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_STATE: {
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      return state;
    }
  }
};

const useCasinoHistory = () => {
  const [
    state,
    dispatch,
  ] = useReducer(reducer, INITIAL_STATE);
  const {
    range,
    activeTimeFilter,
    activeTypeFilters,
    currentPage,
    shouldOperationsLoad,
  } = state;

  useEffect(() => {
    dispatch({ type: SET_STATE, payload: { shouldOperationsLoad: true } });
  }, [activeTimeFilter, activeTypeFilters, currentPage, range]);

  useEffect(() => {
    if (!shouldOperationsLoad) {
      return;
    }

    dispatch({ type: SET_STATE, payload: { isLoading: true } });

    let createTime = range;

    if (activeTimeFilter === last24) {
      createTime = getDayRange();
    } else if (activeTimeFilter === last48) {
      createTime = getTwoDayRange();
    }

    CasinoApi.getCasinoHistory({
      filter: {
        createTime,
      },
      paging: {
        itemsPerPage: ITEMS_PER_PAGE,
        currentPage,
      },
    })
      .then((res) => {
        const newOperations = _.get(res, 'history');
        const newTotalPages = _.get(res, 'paging.totalPages');

        if (newOperations && _.isFinite(newTotalPages)) {
          dispatch({
            type: SET_STATE,
            payload: {
              operations: newOperations,
              totalPages: newTotalPages,
              isLoading: false,
              shouldOperationsLoad: false,
            },
          });
        } else {
          dispatch({
            type: SET_STATE,
            payload: {
              operations: newOperations,
              totalPages: newTotalPages,
              isLoading: false,
              shouldOperationsLoad: false,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: SET_STATE,
          payload: { isLoading: false, shouldOperationsLoad: false },
        });
      });
  }, [shouldOperationsLoad]);

  const setActiveTimeFilter = useCallback((activeTimeFilter) => {
    dispatch({ type: SET_STATE, payload: { activeTimeFilter, currentPage: 0 } });
  }, []);

  const setActiveTypeFilters = useCallback((activeTypeFilters) => {
    dispatch({ type: SET_STATE, payload: { activeTypeFilters, currentPage: 0 } });
  }, []);

  const setCurrentPage = useCallback((currentPage) => {
    dispatch({ type: SET_STATE, payload: { currentPage } });
  }, []);

  const setRange = useCallback((range) => {
    dispatch({ type: SET_STATE, payload: { range, currentPage: 0 } });
  }, [dispatch]);

  return {
    ...state,
    setActiveTimeFilter,
    setActiveTypeFilters,
    setCurrentPage,
    setRange,
  };
};

export default useCasinoHistory;
