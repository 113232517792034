import React from 'react';
import { Bus, centsToDecimal } from '@apollo/core';

export const callCashoutSuccessPopup = ({ amount, currencySymbol }) => {
  Bus.send({
    event: 'cashout:success',
    data: {
      children: (
        <div className='cashout_amount'>
          {currencySymbol}
          {centsToDecimal(amount)}
        </div>
      ),
      icon: 'success',
    },
  });
};

export const callCashoutErrorPopup = ({ amount, currencySymbol }) => {
  Bus.send({
    event: 'cashout:error',
    data: {
      children: (
        <div className='cashout_amount'>
          {currencySymbol}
          {centsToDecimal(amount)}
        </div>
      ),
      icon: 'error',
    },
  });
};

export const callOrderCashoutSuccessPopup = ({ amount, currencySymbol }) => {
  Bus.send({
    event: 'order_cashout:success',
    data: {
      children: (
        <div className='cashout_amount'>
          {centsToDecimal(amount)}
          {currencySymbol}
        </div>
      ),
      icon: 'success',
    },
  });
};

export const callOrderCashoutErrorPopup = ({ amount, currencySymbol }) => {
  Bus.send({
    event: 'order_cashout:error',
    data: {
      children: (
        <div className='cashout_amount'>
          {currencySymbol}
          {centsToDecimal(amount)}
        </div>
      ),
      icon: 'error',
    },
  });
};

export const callOrderPartialCashoutSuccessPopup = ({ amount, currencySymbol }) => {
  Bus.send({
    event: 'order_partial_cashout:success',
    data: {
      children: (
        <div className='cashout_amount'>
          {currencySymbol}
          {centsToDecimal(amount)}
        </div>
      ),
      icon: 'success',
    },
  });
};

export const callOrderPartialCashoutErrorPopup = ({ amount, currencySymbol }) => {
  Bus.send({
    event: 'order_partial_cashout:error',
    data: {
      children: (
        <div className='cashout_amount'>
          {currencySymbol}
          {centsToDecimal(amount)}
        </div>
      ),
      icon: 'error',
    },
  });
};

export const callPartialCashoutSuccessPopup = ({ amount, currencySymbol }) => {
  Bus.send({
    event: 'partial_cashout:success',
    data: {
      children: (
        <div className='cashout_amount'>
          {currencySymbol}
          {centsToDecimal(amount)}
        </div>
      ),
      icon: 'success',
    },
  });
};

export const callPartialCashoutErrorPopup = ({ amount, currencySymbol }) => {
  Bus.send({
    event: 'partial_cashout:error',
    data: {
      children: (
        <div className='cashout_amount'>
          {currencySymbol}
          {centsToDecimal(amount)}
        </div>
      ),
      icon: 'error',
    },
  });
};

export const callCashoutNotAllowedErrorCallbackPopup = () => {
  Bus.send({
    event: 'cashout_not_allowed',
    data: { icon: 'error' },
  });
};
