import React from 'react';

import { useLabel } from '@apollo/core';
import TranslateWithLinks from './TranslateWithLinks';

const Label = ({
  message,
  params,
  render,

  // Props passthrough
  className,
  onClick,
}) => {
  const labelMessage = useLabel(message, params);

  return render ? (
    render(labelMessage)
  ) : (
    <span className={className} onClick={onClick}>
      <TranslateWithLinks
        className={className}
        onClick={onClick}
        translate={labelMessage}
      />
    </span>
  );
};

export default Label;
