import { concat, find, map } from 'lodash';
import React from 'react';
import RaceCountdownMobile from '../../../shared/components/Racing/RaceCountdown/RaceCountdownMobile';
import { RACE_COUNTRIES, RACE_STATUS } from '../../../core/constants';
import Label from '../../../shared/components/I18n/Label';
import RaceLink from '../../../shared/components/Racing/RaceLink';
import { getRaceCompleteText } from '../../../core/utils';
import { RacingIcon } from '../../../shared/components/Racing/RacingIcon';
import BetReturnBonusIcon from '../../../shared/components/Bonus/BetReturnBonusIcon';
import PromoOddsBonusIcon from '../../../shared/components/Bonus/PromoOddsBonusIcon';

const VenuePageMobile = ({ raceType: rt, raceDate, selectedCountries }) => {
  const renderNextOpenRace = (race) => {
    if (!race) {
      return <Label className='race-summary__timer__wrapper' message='Resulted' />;
    }

    return (
      <div className='race-summary__timer__wrapper'>
        <div className='race-summary__timer'>
          <RaceCountdownMobile
            race={race}
            key={`${race.raceId}-${race.status}-${race.result}`}
            date={new Date(race.startTime)}
            completeText={getRaceCompleteText(race)}
          />
        </div>
        {race?.hasFixedOdds ? <div className='race-summary__fixed-odds'>FO</div> : null}
        <BetReturnBonusIcon race={race} />
        <PromoOddsBonusIcon race={race} />
      </div>
    );
  };

  const combinedVenues = () => {
    let allVenues = [];

    rt.venues.local.sort((localVenue) => (localVenue.country === 'NZL' ? -1 : 1));

    if (selectedCountries[RACE_COUNTRIES.local]) {
      allVenues = concat(allVenues, rt?.venues.local);
    }
    if (selectedCountries[RACE_COUNTRIES.international]) {
      allVenues = concat(allVenues, rt?.venues.international);
    }

    allVenues.sort((a, b) => {
      // check if we have OPEN race
      const hasOpenA = a.races.some((race) => race.status === 'OPEN');
      const hasOpenB = b.races.some((race) => race.status === 'OPEN');

      // OPEN is first
      if (hasOpenA && !hasOpenB) return -1;
      if (!hasOpenA && hasOpenB) return 1;

      return a?.country === 'NZL' || a?.country === 'AUS' || a?.country;
    });

    return allVenues;

    /* return sortBy(allVenues, (venue) => {
      const race = find(venue?.races, (race) => race.status === RACE_STATUS.OPEN);

      if (!race) return new Date(8640000000000000); // resulted last
      return new Date(race.startTime);
    }); */
  };

  if (!rt?.venues?.local?.length && !rt?.venues?.international?.length) {
    return null;
  }

  return (
    <div className='venue_race_table venue_race__mobile'>
      <div key={rt.typeName} className='race_type_header__container'>
        <div className='race_type_header'>
          <RacingIcon id={rt.id} name={rt.typeName} />
          <div className='tournament-name' title={rt.typeName}>
            {rt.typeName}
          </div>
        </div>
      </div>
      <div className='venue_race_table_data '>
        <div>
          {combinedVenues().length
            ? map(combinedVenues(), (venue) => {
              const race = find(venue?.races, (race) => race.status === RACE_STATUS.OPEN);

              return (
                <RaceLink
                  className='venue_race_table_row'
                  raceDate={raceDate}
                  raceType={venue.raceType.typeName}
                  raceCountry={venue.country}
                  raceVenue={venue.venueName}
                  raceNumber={race?.number}
                  key={venue.venueId}
                >
                  <div className='venue_race_table_row_item__data first'>
                    <div className='venue_race_table_row_item__data__link'>
                      <span className='name'>{venue.venueName}</span>
                      <div className='country'>
                        {venue.countryName}
                        {venue.state && `, ${venue.state}`}
                      </div>
                    </div>
                  </div>
                  <div className={`venue_race_table_row_item__data ${race?.status}`}>
                    {renderNextOpenRace(race)}
                  </div>
                </RaceLink>
              );
            })
            : null}
        </div>
      </div>
    </div>
  );
};

export default VenuePageMobile;
