import React from 'react';
import { useCategoryState } from '@apollo/core';
import LiveTournaments from './LiveTournaments';
import LiveCategoryLoader from './LiveCategoryLoader';

const LiveCategory = ({ mainMarketTypesByPeriod }) => {
  const categoryState = useCategoryState();
  const { tournaments, loading, initialized } = categoryState;

  if (!initialized && loading) {
    return <LiveCategoryLoader />;
  }

  return (
    <LiveTournaments mainMarketTypesByPeriod={mainMarketTypesByPeriod} tournaments={tournaments} />
  );
};

export default React.memo(LiveCategory);
