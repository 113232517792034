import React from 'react';
import _ from 'lodash';
import Theme from 'themeSource/custom';
import Label from '../../I18n/Label';

const DEFAULT_PARTNERS = [
  '/static/assets/images/partners/afl.png',
  '/static/assets/images/partners/nrl.png',
];

const FooterPartners = () => {
  const partners = Theme.BETTING_PARNERS ?? DEFAULT_PARTNERS;

  return (
    partners.length > 0 && (
      <div className='footer-block footer-block--icons'>
        <Label message='footer_partners' />

        <div className='icons-block'>
          {_.map(partners, (partner, key) => (
            <div
              key={`betting-partner-${key}`}
              className='payments__logo'
              style={{ backgroundImage: `url(${partner})` }}
            />
          ))}
        </div>
      </div>
    )
  );
};

export default FooterPartners;
