import React from 'react';
import { isHidden, isSuspended } from '@apollo/core';
import Outcome from 'src/app/shared/components/DetailOdds/Outcome';
import _ from 'lodash';
import cx from 'classnames';

const MainMarket = ({ market, event, suspended, className }) => {
  if (!market || isHidden(market)) {
    return null;
  }

  const isMarketSuspended = suspended || isSuspended(market);

  const classname = cx(className, 'main-market', {
    suspended: isMarketSuspended,
  });

  return (
    <div className={classname}>
      {_.chain(market)
        .get('outcomes')
        .sortBy(event?.outright ? 'odds' : 'orderPosition')
        .map((outcome) => {
          const { id } = outcome;

          return (
            <Outcome
              key={id}
              event={event}
              market={market}
              suspended={isMarketSuspended}
              outcome={outcome}
              marketType={market}
              className='main-market__outcome btn btn-sm btn-bet'
            />
          );
        })
        .value()}
    </div>
  );
};

export default MainMarket;
