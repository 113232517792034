import React, { useMemo, useState } from 'react';
import { BET_EVENT_TYPE } from '@apollo/core/src/constants';
import cx from 'classnames';
import Label from '../../../../shared/components/I18n/Label';
import { isDateFuture } from '../BonusesTable/bonusesUtils';
import { BonusSelector } from '../../../../state/Bonus/Bonus';
import BonusBannerItem from './BonusBannerItem/BonusBannerItem';
import EmptyBonusBanners from './EmptyBonusBanners';

const BONUS_SERVICES = ['ANY', BET_EVENT_TYPE.RACING, BET_EVENT_TYPE.SPORT_PREMATCH];

const BonusBanners = ({ bonusType }) => {
  const bonuses = BonusSelector.selectAllBonuses();

  const [selectedService, setSelectedService] = useState(BONUS_SERVICES[0]);

  const availableBonuses = useMemo(
    () => (bonuses || [])
      .filter(
        (bonus) => (!bonusType || bonus.bonusType === bonusType) && isDateFuture(bonus.props?.expiry),
      )
      .sort((b1, b2) => (b1.bonusType > b2.bonusType ? 1 : -1)),
    [bonuses, bonusType, selectedService],
  );

  const filteredBonuses = useMemo(
    () => availableBonuses.filter(
      (bonus) => selectedService === 'ANY' || bonus.service === 'ANY' || bonus.service === selectedService,
    ),
    [availableBonuses, selectedService],
  );

  if (availableBonuses?.length <= 0) {
    return (
      <div className='wrapper'>
        <div className='bonus-banners'>
          <div className='bonus-banners-container'>
            <EmptyBonusBanners />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='wrapper'>
      <div className='bonus-banners'>
        <div className='filter__wrapper'>
          {BONUS_SERVICES.map((service) => (
            <div
              key={service}
              className={cx('filter-item', { active: selectedService === service })}
              onClick={() => setSelectedService(service)}
            >
              <Label message={service === 'ANY' ? 'all' : service} />
            </div>
          ))}
        </div>

        <div className='bonus-banners-container'>
          {filteredBonuses?.length > 0 ? (
            filteredBonuses.map((bonus) => <BonusBannerItem key={bonus.id} bonus={bonus} />)
          ) : (
            <EmptyBonusBanners />
          )}
        </div>
      </div>
    </div>
  );
};

export default BonusBanners;
