import React, { createContext, useContext, useState } from 'react';

const EventFilterContext = createContext();

export const EventFilterProvider = ({ children }) => {
  const [filter, setFilter] = useState([]);

  return (
    <EventFilterContext.Provider value={{ filter, setFilter }}>
      {children}
    </EventFilterContext.Provider>
  );
};

export const useEventFilter = () => {
  const context = useContext(EventFilterContext);

  if (!context) {
    throw new Error('useEventFilter must be used within an EventFilterProvider');
  }

  return context;
};
