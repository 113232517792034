import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CmsApi, useSettings } from '@apollo/core';
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet-async';
import useTranslate from '../../shared/components/I18n/Interpreter';
import Loader from '../../shared/components/Loader/Loader';
import MainBody from '../../shared/components/MainBody/MainBody';
import { LocalConfig } from '../../core/utils';

const defaultState = {
  content: null,
  title: '',
  metaTitle: '',
  metaKeywords: '',
  error: null,
  resolved: false,
  loading: true,
};

const StaticPage = ({
  match: {
    params: { slug },
  },
}) => {
  const t = useTranslate();
  const [state, setState] = useState(defaultState);
  const { lang } = useSettings();

  const handleStaticDataLoaded = useCallback(
    (res) => {
      setState((state) => ({
        ...state,
        content: res.data,
        title: res.title || '',
        metaTitle: res.meta_title || '',
        metaKeywords: res.meta_keywords || '',
        loading: false,
        resolved: true,
      }));
    },
    [setState],
  );

  const handleStaticDataError = useCallback(
    (staticError) => {
      const { error } = staticError;
      setState((state) => ({
        ...state,
        error,
        loading: false,
        resolved: true,
      }));
    },
    [setState],
  );

  useEffect(() => {
    setState(defaultState);
    const ac = new AbortController();
    CmsApi.getPage({
      source: slug,
      lang,
      signal: ac.signal,
    })
      .then(handleStaticDataLoaded)
      .catch(handleStaticDataError);
    return () => ac.abort();
  }, [lang, slug, handleStaticDataError, handleStaticDataLoaded]);

  const renderView = () => {
    const { content, error, loading, resolved } = state;

    if (loading) {
      return (
        <div>
          <Loader />
          <div className='loading'>{t('loading...')}</div>
        </div>
      );
    }

    if (resolved) {
      if (error) {
        return <div>{error.message}</div>;
      }

      if (content) {
        const contentModel = {
          __html: DOMPurify.sanitize(content, { KEEP_CONTENT: true }),
        };

        return (
          <div
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={contentModel}
          />
        );
      }
    }

    return null;
  };

  const headMeta = useMemo(
    () => ({
      title: `${state.metaTitle || state.title} - ${LocalConfig.get('public.title')} ${t('page_title')}`,
      description: `${state.metaTitle || state.title}. ${LocalConfig.get('public.description')}`,
      keywords: `${state.metaKeywords}, ${LocalConfig.get('public.keywords')}`,
    }),
    [state?.metaKeywords, state?.metaTitle, state?.title],
  );

  return (
    <>
      <Helmet>
        <title>{headMeta.title}</title>
        <meta name='description' content={headMeta.description} />
        <meta name='keywords' content={headMeta.keywords} />
      </Helmet>
      <MainBody className='main__body--static-page'>
        <div className='static_page'>
          <div className={slug}>
            {state?.title && <h1 className='static_page__title'>{state.title}</h1>}
            {renderView()}
          </div>
        </div>
      </MainBody>
    </>
  );
};

export default StaticPage;
