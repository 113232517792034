import React, { useMemo } from 'react';
import _ from 'lodash';
import { sportService } from '../../../../../core/constants';
import CountdownTimer from '../../../../../shared/components/CountdownTimer/CountdownTimer';
import Logo from '../../../../../shared/components/Logo/Logo';
import GameStat from '../../../../../shared/components/GameStat/GameStat';
import SoccerScoreTemplateIcons from './SoccerScoreTemplateIcons';
import EventTop from '../../../../../shared/components/EventTop/EventTop';
import EventStartTime from '../../../Tournament/Event/EventStartTime';
import Label from '../../../../../shared/components/I18n/Label';

const { PREMATCH, LIVE, UNSPECIFIED, ARCHIVED } = sportService;

const SoccerScoreTemplate = ({ event }) => {
  const [
    sportId,
    categoryName,
    tournamentName,
    startTime,
    homeFullName,
    homeTeamId,
    homeTeamLogoUri,
    awayFullName,
    awayTeamId,
    awayTeamLogoUri,
    sportService,
    matchClock,
  ] = _.at(event, [
    'sportId',
    'categoryName',
    'tournamentName',
    'startTime',
    'participants.home.fullName',
    'participants.home.id',
    'participants.home.logoUri',
    'participants.away.fullName',
    'participants.away.id',
    'participants.away.logoUri',
    'sportService',
    'info.score.homeScore',
    'info.score.awayScore',
    'info.score.matchClock',
  ]);

  const time = useMemo(() => {
    if (sportService === PREMATCH) {
      return (
        <>
          <EventStartTime event={event} />
          <div className='countdown'>
            <CountdownTimer time={startTime} />
          </div>
        </>
      );
    }

    if ([LIVE, UNSPECIFIED, ARCHIVED].includes(sportService)) {
      return <EventStartTime withCountDown event={event} withDate />;
    }

    if (_.toNumber(matchClock) === -1) {
      return '';
    }

    if (matchClock) {
      return `${(_.toNumber(matchClock) / 60).toFixed(0)}`;
    }

    return '';
  }, [matchClock, startTime]);

  return (
    <div className='event-details eventView'>
      <EventTop sportId={sportId}>
        <div className='event-details__inner'>
          {[PREMATCH, UNSPECIFIED, ARCHIVED].includes(sportService) && (
            <>
              <div className='event-details__tournament'>
                {`${categoryName} - ${tournamentName}`}
              </div>

              <div className='event-details__teams'>
                <div className='top-event-info__body'>
                  <div className='top-event-info__left'>
                    <div className='top-event-info__participant'>{homeFullName}</div>
                    <Logo
                      participantId={homeTeamId}
                      logoUri={homeTeamLogoUri}
                      size='xl'
                      alt={homeFullName}
                      isNewDesign
                    />
                  </div>
                  <div className='top-event-info__center'>
                    <Label message='at' className='top-event-at' />
                  </div>
                  <div className='top-event-info__right'>
                    <Logo
                      participantId={awayTeamId}
                      logoUri={awayTeamLogoUri}
                      size='xl'
                      alt={awayFullName}
                      isNewDesign
                    />
                    <div className='top-event-info__participant'>{awayFullName}</div>
                  </div>
                </div>
              </div>
              <div className='top-event-info__center'>
                {time && <div className='time'>{time}</div>}
              </div>
            </>
          )}
          {sportService === LIVE && <GameStat />}
          <SoccerScoreTemplateIcons event={event} />
        </div>
      </EventTop>
    </div>
  );
};

export default SoccerScoreTemplate;
