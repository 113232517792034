import React, { useMemo } from 'react';

import { generatePath, Link, NavLink, useRouteMatch } from 'react-router-dom';

import qs from 'query-string';
import cx from 'classnames';
import { Bus, SiteConfigManager, useApplicationState } from '@apollo/core';
import { COMPONENT_TYPES } from '@apollo/routing';
import { SportSelector } from '@apollo/core/src/state/sport/sport';
import { isEmpty } from 'lodash';
import { sportService } from '../../../core/constants';

export const SPORTS_TABS = {
  ALL_SPORTS: 'all-sports',
  FEATURED_SPORTS: 'featured-sports',
  UPCOMING_SPORTS: 'upcoming-sports',
};

const getPath = (sportServiceName) => {
  let key = '';

  switch (sportServiceName) {
    case sportService.PREMATCH:
      key = COMPONENT_TYPES.SPORT_PREMATCH;
      break;
    case sportService.ARCHIVED:
      key = COMPONENT_TYPES.RESULTS;
      break;
    case sportService.LIVE:
      key = COMPONENT_TYPES.SPORT_LIVE;
      break;
    default:
      key = COMPONENT_TYPES.SPORT_PREMATCH;
  }

  return SiteConfigManager.getExtraConfig(`pathList.${key}`);
};

export const getEventUrl = ({ event, sport, category, tournament }) => {
  if (!event) {
    return '';
  }

  const sportPath = getPath(event.sportService);

  const url = generatePath(
    `${sportPath}/:sportUrlName?/:categoryUrlName?/:tournamentUrlName?/:eventUrlName??e=${event.id || event.eventId}`,
    {
      sportUrlName:
        decodeURIComponent(sport?.urlName || event.sportUrlName || '') || event.sportName,
      categoryUrlName:
        decodeURIComponent(category?.urlName || event.categoryUrlName || '') || event.categoryName,
      tournamentUrlName:
        decodeURIComponent(tournament?.urlName || event.tournamentUrlName || '')
        || event.tournamentName,
      eventUrlName: decodeURIComponent(event.urlName || '') || null,
    },
  );

  return url;
};

export const SportLink = (props) => {
  const { sportUrlName, children, className, active, hash } = props;
  const { layout } = useApplicationState();

  const path = useMemo(
    () => SiteConfigManager.getExtraConfig(`pathList.${COMPONENT_TYPES.SPORT_PREMATCH}`),
    [],
  );

  const toUrl = React.useMemo(
    () => generatePath(`${path}/:sportUrlName?`, {
      sportUrlName: decodeURIComponent(sportUrlName || '') || null,
    }),
    [path, sportUrlName],
  );

  const linkClassName = cx(className, {
    active,
  });

  const onClickHandler = React.useCallback(() => {
    if (layout.desktopDevice === false) {
      Bus.send({
        event: Bus.events.layout.setSideBarRight,
        data: false,
      });
      Bus.send({
        event: Bus.events.layout.setSideBarLeft,
        data: false,
      });
    }
    // historyPush(toUrl);
    return true;
  }, [layout.desktopDevice]);

  return (
    <Link
      to={`${toUrl}${hash ? `#${hash}` : ''}`}
      className={linkClassName}
      onClick={onClickHandler}
    >
      {children}
    </Link>
  );
};

export const SportAllLink = ({ children, ...props }) => (
  <SportLink {...props} hash={SPORTS_TABS.ALL_SPORTS}>
    {children}
  </SportLink>
);

export const CategoryLink = (props) => {
  const { children, className, sportUrlName, categoryUrlName, active, hash } = props;
  const { layout } = useApplicationState();

  const { path: currentPath } = useRouteMatch();

  const path = useMemo(() => {
    const resultsPath = SiteConfigManager.getExtraConfig(`pathList.${COMPONENT_TYPES.RESULTS}`);
    if (currentPath.startsWith(resultsPath)) {
      return resultsPath;
    }
    return SiteConfigManager.getExtraConfig(`pathList.${COMPONENT_TYPES.SPORT_PREMATCH}`);
  }, [currentPath]);

  const toUrl = React.useMemo(
    () => generatePath(`${path}/:sportUrlName?/:categoryUrlName?`, {
      sportUrlName: decodeURIComponent(sportUrlName || '') || null,
      categoryUrlName: decodeURIComponent(categoryUrlName || '') || null,
    }),
    [path, sportUrlName, categoryUrlName],
  );

  const linkClassName = cx(className, {
    active,
  });

  const onClickHandler = React.useCallback(() => {
    if (layout.desktopDevice === false) {
      Bus.send({
        event: Bus.events.layout.setSideBarRight,
        data: false,
      });
      Bus.send({
        event: Bus.events.layout.setSideBarLeft,
        data: false,
      });
    }
    // historyPush(toUrl);
    return true;
  }, [layout.desktopDevice]);

  return (
    <Link
      to={`${toUrl}${hash ? `#${hash}` : ''}`}
      className={linkClassName}
      onClick={onClickHandler}
    >
      {children}
    </Link>
  );
};

export const TournamentLink = (props) => {
  const { layout } = useApplicationState();
  const { children, className, sportUrlName, categoryUrlName, tournamentUrlName, active } = props;

  const path = useMemo(
    () => SiteConfigManager.getExtraConfig(`pathList.${COMPONENT_TYPES.SPORT_PREMATCH}`),
    [COMPONENT_TYPES.SPORT_PREMATCH],
  );

  const toUrl = React.useMemo(
    () => generatePath(`${path}/:sportUrlName?/:categoryUrlName?/:tournamentUrlName`, {
      sportUrlName: decodeURIComponent(sportUrlName || '') || null,
      categoryUrlName: decodeURIComponent(categoryUrlName || '') || null,
      tournamentUrlName: decodeURIComponent(tournamentUrlName || '') || null,
    }),
    [path, sportUrlName, categoryUrlName, tournamentUrlName],
  );

  const onClickHandler = React.useCallback(() => {
    if (layout.desktopDevice === false) {
      Bus.send({
        event: Bus.events.layout.setSideBarRight,
        data: false,
      });
      Bus.send({
        event: Bus.events.layout.setSideBarLeft,
        data: false,
      });
    }
    // historyPush(toUrl);
    return true;
  }, [layout.desktopDevice]);

  const linkClassName = cx(className, {
    active,
  });

  return (
    <Link to={toUrl} className={linkClassName} onClick={onClickHandler}>
      {children}
    </Link>
  );
};

export const EventLink = (props) => {
  const { children, className, event, state, urlParams } = props;
  const { layout } = useApplicationState();

  const paramsStr = useMemo(() => {
    let str = '';
    if (!isEmpty(urlParams)) {
      Object.keys(urlParams).forEach((key) => {
        str += `&${key}=${urlParams[key]}`;
      });
    }
    return str;
  }, [urlParams]);

  const sport = SportSelector.selectSportById(event.sportId);
  const category = SportSelector.selectCategoryById(event.categoryId);
  const tournament = SportSelector.selectTournamentById(event.tournamentId);

  const eventUrl = useMemo(
    () => getEventUrl({
      sport,
      category,
      tournament,
      event,
    }) + paramsStr,
    [event, paramsStr],
  );

  const checkEventQueryActive = React.useCallback(
    (match, location) => {
      const params = qs.parse(location.search);

      return params.e === `${event.id}`;
    },
    [event],
  );

  const onClickHandler = React.useCallback(() => {
    if (layout.desktopDevice === false) {
      // close betslip
      Bus.send({
        event: Bus.events.layout.setSideBarRight,
        data: false,
      });
    }
  }, [layout]);

  return (
    <NavLink
      to={eventUrl}
      className={className}
      isActive={checkEventQueryActive}
      state={state}
      onClick={onClickHandler}
    >
      {children}
    </NavLink>
  );
};
