import React from 'react';
import { isEmpty, map } from 'lodash';
import RaceRunner from '../RaceRunner';
import { ordinalSuffixOf } from '../../../../core/utils';
import Odds from '../../../../shared/components/Odds/Odds';
import useTranslate from '../../../../shared/components/I18n/Interpreter';

const RaceResultsTable = ({ activeRace }) => {
  const t = useTranslate();
  const runnersByNumber = React.useMemo(
    () => activeRace?.runners.reduce((acc, runner) => ({ ...acc, [runner.number]: runner }), {}),
    [activeRace]
  );

  const topRunners = activeRace?.uiResults?.topRunners ?? [];

  const runners = topRunners?.map((runner) => ({
    ...runnersByNumber?.[runner.runnerNumber],
    ...runner,
  }));

  return (
    <div className='event-group event-group--races'>
      {isEmpty(runners) === false &&
        map(runners, (r) => (
          <div
            key={`${r.runnerId}-${r.finishPosition}`}
            className={`event event--classic ${r.scratched ? 'event--scratched' : ''}`}
          >
            <div className='event__main-period'>
              <span className='runnerFinishPosition'>{ordinalSuffixOf(r.finishPosition)}</span>
              <RaceRunner runner={r} activeRace={activeRace} showRunnerImage />
              <div className='event__market-row'>
                <div className='event__market-row-market event__market-row--race-dividend'>
                  {r.fwinOdds ? (
                    <div className='result-with-label'>
                      <div className='label'>{t('win')}</div>
                      <div className='results-odds'>
                        <Odds value={r.fwinOdds} />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className='event__market-row-market event__market-row--race-dividend'>
                  {r.fplcOdds ? (
                    <div className='result-with-label'>
                      <div className='label'>{t('place')}</div>
                      <div className='results-odds'>
                        <Odds value={r.fplcOdds} />
                      </div>
                    </div>
                  ) : r.finishPosition > 1 && r.finishPosition < 4 ? (
                    <div>
                      No&nbsp;
                      {ordinalSuffixOf(r.finishPosition)} dividend
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
export default RaceResultsTable;
