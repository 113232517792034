import React, { useMemo } from 'react';
import { includes, isNull, isObject, map } from 'lodash';
import Label from '../../I18n/Label';

const ErrorBlock = ({ errors, rules, error, withTranslate }) => {
  const renderErrors = useMemo(() => {
    if (rules && rules.length) {
      return map(rules, (rule) => {
        const className = includes(errors, rule)
          ? 'errorBlock error errorBlock--rules'
          : 'errorBlock errorBlock--rules-no-errors';
        const { message, ruleOption } = rule;

        return (
          <li className={className}>
            {withTranslate ? <Label message={message} params={{ ruleOption }} /> : message}
          </li>
        );
      });
    }
    if (errors && errors.length) {
      return map(errors, (error) => (
        <li className='errorBlock errorBlock--errors'>
          {withTranslate ? <Label message={error} /> : error}
        </li>
      ));
    }

    if (error) {
      const isObjectError = isObject(error);
      const message = isObjectError ? error.message : error;
      const params = { ruleOption: isObjectError ? error.ruleOption : '' };

      return (
        <li className='errorBlock errorBlock--error'>
          {withTranslate ? <Label message={message} params={params} /> : message}
        </li>
      );
    }

    return null;
  }, [errors, rules, error, withTranslate]);

  if (isNull(renderErrors)) {
    return null;
  }

  return <ul className='error__list'>{renderErrors}</ul>;
};

export default ErrorBlock;
