import React, { useCallback, useMemo, useRef } from 'react';
import cx from 'classnames';
import { cashoutConstants, centsToDecimal, useCashout } from '@apollo/core';
import _ from 'lodash';
import {
  orderCashoutButtonTextSetMinAmount,
  orderCashoutButtonTextSetMaxAmount,
} from '../constants';
import Dropdown from '../../Dropdown/Dropdown';
import Label from '../../I18n/Label';
import MoneyInput from '../../Form/MoneyInput/MoneyInput';

const {
  orderCashoutTypes: { ORDER_CASHOUT_TYPE, ORDER_PARTIAL_CASHOUT_TYPE },
  orderCashoutWarningSmallAmount,
  orderCashoutWarningBigAmount,
} = cashoutConstants;

const OrderCashoutPopup = () => {
  const {
    onOrderCashout,
    isOrderCashOutAllowed,
    setIsOrderCashoutExpanded,
    isOrderCashoutExpanded,
    setOrderCashout,
    isOrderCashoutLoading,
    orderCashout,
    orderCashoutWarning,
    currencySymbol,
    orderCashoutType,
    setOrderCashoutType,
    orderPartialCashout,
    setOrderPartialCashout,
  } = useCashout();

  const toggleRef = useRef(null);

  const cashoutButtonClassName = useMemo(
    () =>
      cx('betslipDropdown__footer', {
        accept__changes: !_.isEmpty(orderCashoutWarning),
        isLoading: isOrderCashoutLoading,
      }),
    [isOrderCashoutLoading]
  );

  const orderCashoutButtonText = useMemo(() => {
    if (_.isEmpty(orderCashoutWarning)) {
      return 'create_rule';
    }
    if (orderCashoutWarning.type === orderCashoutWarningSmallAmount) {
      return orderCashoutButtonTextSetMinAmount;
    }

    if (orderCashoutWarning.type === orderCashoutWarningBigAmount) {
      return orderCashoutButtonTextSetMaxAmount;
    }

    return 'create_rule_warning';
  }, [orderCashoutWarning]);

  const setTypeToOrderCashout = useCallback(() => {
    setOrderCashoutType(ORDER_CASHOUT_TYPE);
  }, [setOrderCashoutType]);

  const setTypeToOrderPartialCashout = useCallback(() => {
    setOrderCashoutType(ORDER_PARTIAL_CASHOUT_TYPE);
  }, [setOrderCashoutType]);

  if (!isOrderCashOutAllowed) return null;

  return (
    <Dropdown onExpanded={setIsOrderCashoutExpanded} toggleRef={toggleRef}>
      <button type='button' className='bSettings' ref={toggleRef}>
        <span className='AkIcon-play-icon' />
      </button>
      {isOrderCashoutExpanded && (
        <div className='betslipDropdown'>
          <div className='betslipDropdown__title'>
            <Label message='auto_cash_out' />
          </div>
          <div className='betslipDropdown__content'>
            <div className='betslipDropdown__content__input'>
              <Label message='if_value_reaches' />
              <MoneyInput className='bottom' value={orderCashout} onChange={setOrderCashout} />
            </div>
          </div>
          <div className='betslipDropdown__content__radio'>
            <label className='container'>
              <input
                type='radio'
                checked={orderCashoutType === ORDER_CASHOUT_TYPE}
                onClick={setTypeToOrderCashout}
                name='order-cashout-radio'
              />
              <span className='checkmark' />
              <Label message='Order full Cashout' />
            </label>
            <label className='container'>
              <input
                type='radio'
                checked={orderCashoutType === ORDER_PARTIAL_CASHOUT_TYPE}
                onClick={setTypeToOrderPartialCashout}
                name='order-cashout-radio'
              />
              <span className='checkmark' />
              <Label message='Order Partial Cashout' />
            </label>
          </div>
          <div className='betslipDropdown__content'>
            <div className='betslipDropdown__content__input'>
              <Label message='if_value_reaches' />
              <MoneyInput
                className='bottom'
                value={orderPartialCashout}
                disabled={orderCashoutType !== ORDER_PARTIAL_CASHOUT_TYPE}
                onChange={setOrderPartialCashout}
              />
            </div>
          </div>
          {!_.isEmpty(orderCashoutWarning) && (
            <Label
              message={orderCashoutWarning.type}
              render={(translation) => (
                <span className='error-message'>
                  {`${translation} ${currencySymbol}${centsToDecimal(orderCashoutWarning.amount)}`}
                </span>
              )}
            />
          )}
          <button type='button' className={cashoutButtonClassName} onClick={onOrderCashout}>
            <Label message={orderCashoutButtonText} />
            {!_.isEmpty(orderCashoutWarning) && (
              <span>{`${currencySymbol}${centsToDecimal(orderCashoutWarning.amount)}`}</span>
            )}
          </button>
        </div>
      )}
    </Dropdown>
  );
};

export default OrderCashoutPopup;
