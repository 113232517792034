import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import {
  createCashoutContext,
  CashoutContextProvider,
  centsToDecimal,
  NotificationManager,
} from '@apollo/core';
import OrderCashoutPopup from './OrderCashoutPopup';
import PartialCashoutPopup from './PartialCashoutPopup';
import {
  callCashoutSuccessPopup,
  callCashoutErrorPopup,
  callOrderCashoutSuccessPopup,
  callOrderCashoutErrorPopup,
  callPartialCashoutSuccessPopup,
  callPartialCashoutErrorPopup,
  callOrderPartialCashoutSuccessPopup,
  callOrderPartialCashoutErrorPopup,
  callCashoutNotAllowedErrorCallbackPopup,
} from './callPopup';
import Label from '../I18n/Label';
import StaticBlock from '../StaticBlock/StaticBlock';
import { cmsStaticBlock } from '../../../core/constants';

const Cashout = ({
  betId,
  amount,
  odds,
  currencySymbol,
  replaceBet,
  cashOutCoefficient,
  minPartialCashOut,
  setOrderCashoutInfoToBet,
  estimatedCashOut: initialEstimatedCashOut,
  estimatedPartialCashOut: initialEstimatedPartialCashOut,
}) => {
  const cashoutContext = createCashoutContext({
    betId,
    amount,
    odds,
    replaceBet,
    currencySymbol,
    setOrderCashoutInfoToBet,
    minPartialCashOut,
    initialEstimatedCashOut,
    initialEstimatedPartialCashOut,
    cashOutCoefficient,
    cashoutSuccessCallback: callCashoutSuccessPopup,
    cashoutErrorCallback: callCashoutErrorPopup,
    orderCashoutSuccessCallback: callOrderCashoutSuccessPopup,
    orderCashoutErrorCallback: callOrderCashoutErrorPopup,
    orderPartialCashoutSuccessCallback: callOrderPartialCashoutSuccessPopup,
    orderPartialCashoutErrorCallback: callOrderPartialCashoutErrorPopup,
    partialCashoutSuccessCallback: callPartialCashoutSuccessPopup,
    partialCashoutErrorCallback: callPartialCashoutErrorPopup,
    cashoutNotAllowedErrorCallback: callCashoutNotAllowedErrorCallbackPopup,
  });
  const {
    estimatedCashOut,
    isLoading,
    onCashout,
    isCashOutAllowed,
    setIsPartialCashoutExpanded,
    estimatedPartialCashOut,
    isPartialCashOutAllowed,
    isOrderCashOutAllowed,
    setIsOrderCashoutExpanded,
    isPartialCashoutExpanded,
    isOrderCashoutExpanded,
  } = cashoutContext;

  const togglePartialCashout = useCallback(() => {
    setIsPartialCashoutExpanded(!isPartialCashoutExpanded);
    setIsOrderCashoutExpanded(false);
  }, [setIsPartialCashoutExpanded, setIsOrderCashoutExpanded, isPartialCashoutExpanded]);

  const toggleOrderCashout = useCallback(() => {
    setIsOrderCashoutExpanded(!isOrderCashoutExpanded);
    setIsPartialCashoutExpanded(false);
  }, [setIsOrderCashoutExpanded, setIsPartialCashoutExpanded, isOrderCashoutExpanded]);

  const amountEstimatedCashOut = useMemo(
    () => centsToDecimal(estimatedCashOut),
    [estimatedCashOut],
  );

  const partialCashoutClassName = cx([
    'controlsButton__popup AkIcon-play-icon',
    isPartialCashoutExpanded && 'active',
  ]);

  const orderCashoutClassName = cx([
    'controlsButton__popup AkIcon-settings-gear-icon',
    isOrderCashoutExpanded && 'active',
  ]);

  const onCashOutHandler = useCallback(async () => {
    NotificationManager.display({
      type: 'confirm',
      title: 'confirm_cashout',
      // $[amount!$]
      message: (
        <Label
          className='client-info__label'
          message='confirm_cashout_amount'
          params={{ amount: estimatedCashOut }}
        />
      ),
      data: {
        children: <StaticBlock options={{ source: cmsStaticBlock.ConfirmCashOut }} />,
        onConfirm: () => {
          onCashout();
        },
        onClose: () => {},
      },
    });
  }, [onCashout, estimatedCashOut]);
  if (!estimatedCashOut || !isCashOutAllowed) return null;

  return (
    <CashoutContextProvider value={cashoutContext}>
      <OrderCashoutPopup />
      <PartialCashoutPopup />
      <div className='controlsButton'>
        {isOrderCashOutAllowed && (
          <div className={orderCashoutClassName} onClick={toggleOrderCashout} />
        )}
        <div className='controlsButton__cashout' onClick={onCashOutHandler}>
          <Label message='cashout' />
          <span>{` ${currencySymbol}${amountEstimatedCashOut}`}</span>
        </div>
        {(estimatedPartialCashOut || isPartialCashOutAllowed) && (
          <div className={partialCashoutClassName} onClick={togglePartialCashout} />
        )}
      </div>
    </CashoutContextProvider>
  );
};

export default Cashout;
