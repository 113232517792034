import React from 'react';
import _ from 'lodash';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import HistoryTransactionListItem from './HistoryTransactionListItem';

const HistoryTransactionList = ({ originalBets }) => {
  const t = useTranslate();

  if (_.isEmpty(originalBets)) {
    return null;
  }

  return (
    <div className='history-transaction-list'>
      <div className='coupon__element__subFooter'>
        <div>{t('bet_id')}</div>
        <div>{t('Transaction_id')}</div>
        <div>{t('Time')}</div>
        <div>{t('action_type')}</div>
        <div>{t('bet')}</div>
        <div>{t('Return')}</div>
        {
          _.map(originalBets, ({
            billingTransactionId, createTime, cashOutReason, betMoney: { payout }, amount, betId,
          }) => (
            <HistoryTransactionListItem
              kye={betId}
              number={billingTransactionId}
              time={createTime}
              cashOutReason={cashOutReason}
              payout={payout}
              amount={amount}
              betId={betId}
              t={t}
            />
          ))
        }
      </div>
    </div>
  );
};

export default HistoryTransactionList;
