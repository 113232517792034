import { betHistoryConstants } from '@apollo/core';

export const {
  rangeName, last24, last48,
  getWeekRange, getMonthRange, getThreeMonthRange, getDayRange, getTwoDayRange,
} = betHistoryConstants;

export const dateRangeOptions = [
  {
    value: last24,
    range: getDayRange(),
    getRange: getDayRange,
  },
  {
    value: last48,
    range: getTwoDayRange(),
    getRange: getTwoDayRange,
  },
  {
    value: rangeName,
    range: {
      from: null,
      to: null,
    },
  },
];
