import { useCallback, useEffect, useReducer } from 'react';
import { logger, orderBetApi } from '@apollo/core';
import { reverse, sortBy } from 'lodash';

const initialState = {
  bets: [],
  isLoading: false,
  totalPages: 0,
  currentPage: 0,
  itemsPerPage: 10,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET_STATE': {
      return { ...state, ...payload };
    }
    default: {
      return state;
    }
  }
};

const useOrders = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentPage, itemsPerPage } = state;

  const setState = useCallback((payload) => {
    dispatch({ type: 'SET_STATE', payload });
  }, []);

  const setCurrentPage = useCallback((currentPage) => {
    dispatch({ type: 'SET_STATE', payload: { currentPage } });
  }, []);

  useEffect(() => {
    (async () => {
      setState({ isLoading: true });
      try {
        const { data, paging: newPaging } = await orderBetApi.findActiveOrderBets({
          body: {
            filter: { status: 'CREATED' },
            paging: {
              currentPage,
              itemsPerPage,
            },
          },
        });

        setState({
          bets: reverse(sortBy(data, 'createTime')),
          isLoading: false,
          currentPage: newPaging.currentPage,
          totalPages: newPaging.totalPages,
        });
      } catch (e) {
        logger.error('can not fetch ordered bets', e);
        setState({ isLoading: false });
      }
    })();
  }, [currentPage]);

  return {
    ...state,
    setCurrentPage,
  };
};

export default useOrders;
