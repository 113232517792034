import React from 'react';
import { SiteConfigManager } from '@apollo/core';
import { Route, Switch } from 'react-router-dom';
import Theme from 'themeSource/custom';
import { COMPONENT_TYPES } from '@apollo/routing';
import RacePage from './RacePage/RacePage';
import MeetingRacesMobile from './RacePage/MeetingRacesMobile';
import RaceReroute from './RaceReroute';
import RacingPathHandler, { nextToJumpPath } from './RacingPathHandler';
import VenuesPage from './VenuesPage/VenuesPage';

const Racing = () => {
  const racingPath = SiteConfigManager.getExtraConfig(`pathList.${COMPONENT_TYPES.RACING}`);

  const ThemeVenuesPage = Theme.VenuesPage || VenuesPage;

  return (
    <Switch>
      <Route exact path={`${racingPath}/id/:id?`} component={RaceReroute} />

      <Route exact path={`${racingPath}${nextToJumpPath}`}>
        <RacingPathHandler>
          <ThemeVenuesPage />
        </RacingPathHandler>
      </Route>

      <Route
        exact
        path={`${racingPath}/:raceDate/:raceType/:raceCountry/:raceVenue/:raceNumber?/:marketTab?/:marketType?`}
      >
        <RacingPathHandler>
          <RacePage />
        </RacingPathHandler>
      </Route>

      <Route
        exact
        path={`${racingPath}-venue/:raceDate/:raceType/:raceCountry/:raceVenue/:venueId`}
      >
        <RacingPathHandler>
          <MeetingRacesMobile />
        </RacingPathHandler>
      </Route>

      <Route exact path={`${racingPath}/:raceDate/:raceType?/:raceCountry?/:raceVenue?`}>
        <RacingPathHandler>
          <ThemeVenuesPage />
        </RacingPathHandler>
      </Route>

      <Route>
        <RacingPathHandler>
          <ThemeVenuesPage />
        </RacingPathHandler>
      </Route>
    </Switch>
  );
};

export default Racing;
