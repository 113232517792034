import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { isFunction, find } from 'lodash';
import RadioSelect from '../../../RadioSelect';

const RadioSelectField = ({ field, onChange }) => {
  const {
    value: formValue,
    label,
    name,
    options: initialOptions,
    disabled,
  } = field;
  const areFieldsAsync = useMemo(() => isFunction(initialOptions), [
    initialOptions,
  ]);
  const [options, setOptions] = useState(areFieldsAsync ? [] : initialOptions);
  useEffect(() => {
    if (areFieldsAsync) {
      (async () => {
        const loadedOptions = await initialOptions();

        setOptions(loadedOptions);
      })();
    }
  }, [areFieldsAsync, initialOptions]);
  const value = useMemo(
    () => find(options, (option) => option.value === formValue),
    [formValue],
  );
  const changeHandler = useCallback(
    (option) => {
      onChange(option.value);
    },
    [onChange],
  );

  return (
    <RadioSelect
      value={value}
      label={label}
      name={name}
      options={options}
      onChange={changeHandler}
      disabled={disabled}
      withTranslate
    />
  );
};

export default RadioSelectField;
