import React from 'react';
import { NavLink } from 'react-router-dom';

const PaymentMethodItem = ({ id, name, route, depositType }) => {
  const paymentRoute = `${route}/${id}`;

  return (
    <NavLink to={paymentRoute} className='payment-method-item' activeClassName='active'>
      <div className='wrapper'>
        <div className='wrapper__payments'>
          <div className='payment-radio' />
          <div className={`logo logo--${depositType?.toLowerCase()}`} />
          <div className='content'>
            <div className='name'>{name}</div>
          </div>
        </div>
      </div>
    </NavLink>
  );
};
export default PaymentMethodItem;
