import React from 'react';
import _ from 'lodash';
import { isInactive, isSuspended } from '@apollo/core';
import SportOutcome from '../../../SportOutcome/SportOutcome';

const MarketExpandedRow = ({ event, market, marketTypeId, suspended, disabled }) => {
  const isMarketSuspended = suspended || isSuspended(market);
  const isMarketDisabled = disabled || isInactive(market);

  return (
    <div className='market-expanded-row'>
      {_.chain(market)
        .get('outcomes')
        .orderBy('orderPosition')
        .slice(0, 2) // Fix for broken markets
        .map((outcome) => (
          <SportOutcome
            key={outcome.id}
            name={outcome.specifiers ? `(${outcome.specifiers})` : null}
            event={event}
            market={market}
            outcome={outcome}
            marketTypeId={marketTypeId}
            suspended={isMarketSuspended}
            disabled={isMarketDisabled}
          />
        ))
        .value()}
    </div>
  );
};

export default MarketExpandedRow;
