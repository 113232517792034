import React, { useMemo } from 'react';
import qs from 'query-string';
import { debounce } from 'lodash';
import { ApiManager } from '@apollo/core';
import AsyncSelect from 'react-select/async';
import Label from '../../../I18n/Label';
import { getIsFieldRequired } from '../../../../../core/utils';
import ErrorBlock from '../../Input/ErrorBlock';

// COUNTRY is enum value defined in com.apollo.um.form.model.dto.Field.FieldName#COUNTRY (user_management)
const getSelectedCountry = (formFields) => ((formFields || {}).COUNTRY || {}).value;

const loadOptions = (inputValue, callback, selectedCountry) => {
  if (inputValue?.length < 5) {
    callback([]);
  } else {
    const apiServerUrl = ApiManager.getHostname('core');
    const addressApiUrl = `${apiServerUrl + (apiServerUrl.endsWith('/') ? '' : '/')}client/address:autocomplete`;
    const requestParams = { country: selectedCountry, query: inputValue };

    fetch(`${addressApiUrl}?${qs.stringify(requestParams)}`)
      .then((response) => response.json())
      .then((data) => {
        // console.log('Autocomplete response ', data.addresses);
        callback(data.addresses?.map((address) => ({ value: address, label: address })) || []);
      });
  }
};

const debouncedLoadOptions = debounce(loadOptions, 500);

const AddressField = (props) => {
  const { field, fields, onChange, onFocus, onBlur } = props;

  const { value, label, rules, errors, touched, withTranslate = true } = field;

  const error = touched && errors[0] ? errors[0] : '';

  const onChangeHandler = (value) => {
    onChange(value.value);
  };

  const onFocusHandler = (value) => {
    onFocus(value);
  };

  const onBlurHandler = (value) => {
    // bug: on mobile, change and blur are simultaneous
    window.setTimeout(() => {
      onBlur(value);
    }, 50);
  };

  const renderLabel = useMemo(
    () =>
      label ? (
        <div className='ui__title'>
          {withTranslate ? <Label message={label} /> : label}
          {getIsFieldRequired(rules) ? <span className='required'> *</span> : null}
        </div>
      ) : null,
    [label, rules, withTranslate]
  );

  const success = touched && !errors[0];

  return (
    <div className='block block--input-select'>
      {renderLabel}
      <div className='block__wrap'>
        <AsyncSelect
          cacheOptions
          className={`react-select ${success ? 'success' : ''}`}
          classNamePrefix='react-select'
          name='address'
          defaultValue={{ value, label: value }}
          onChange={onChangeHandler}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          loadOptions={(inputValue, callback) =>
            debouncedLoadOptions(inputValue, callback, getSelectedCountry(fields))
          }
        />
        <ErrorBlock withTranslate={withTranslate} error={error} errors={[]} rules={[]} />
      </div>
    </div>
  );
};

export default AddressField;
