import React from 'react';

import {
  BetManager,
  useBetslipState,
  isSuspended,
  isInactive,
} from '@apollo/core';

import { isEmpty } from 'lodash';
import EditBetMarketSwitcherOdds from './EditBetMarketSwitcherOdds';
import Label from '../../../shared/components/I18n/Label';

export default function EditBetMarketSwitcher(props) {
  const {
    onSubmit,
    marketType,
    event,
    outcome,
  } = props;

  const selectedOutcomeId = outcome.id;
  const { id } = useBetslipState();

  React.useEffect(
    () => {
      BetManager.focus(id);

      return () => {
        BetManager.focus('main');
      };
    },
    [],
  );

  const displayedMarketType = React.useMemo(
    () => {
      const isEventSuspended = isSuspended(event);
      const isEventDisabled = isInactive(event);
      const markets = Object.values(marketType.markets);

      const marketOptions = markets.map((market) => {
        const outcomes = Object.values(market.outcomes);

        if (isEmpty(outcomes)) {
          return null;
        }

        const isMarketSuspended = isEventSuspended || isSuspended(market);
        const isMarketDisabled = isEventDisabled || isInactive(market);

        return (
          <div className='marketGroup__content-edit' key={market.id}>
            {
              outcomes.map((outcome) => (
                <EditBetMarketSwitcherOdds
                  key={outcome.id}
                  className='market bet'
                  event={event}
                  marketType={marketType}
                  market={market}
                  outcome={outcome}
                  suspended={isMarketSuspended}
                  disabled={isMarketDisabled}
                />
              ))
            }
          </div>
        );
      });

      return (
        <div className='eventView__market'>
          <div className='marketGroup'>
            <div className='marketGroup__title'>
              <span className='name'>
                {marketType.name}
              </span>
            </div>

            {marketOptions}
          </div>
        </div>
      );
    },
    [marketType.markets, selectedOutcomeId, event, marketType.id, marketType.name],
  );

  return (
    <div className='betslipDropdown odds'>
      <div className='betslipDropdown__title'>
        <Label message='outcome_replace' />
      </div>
      <div className='betslipDropdown__content'>
        <div className='eventView'>
          {displayedMarketType}
        </div>
      </div>
      <button type='button' className='betslipDropdown__footer' onClick={onSubmit}>
        <Label message='done' />
      </button>
    </div>
  );
}
