import React from 'react';

import {
  useSettings,
} from '@apollo/core';

export default function useThemeBootstrap() {
  const {
    theme,
  } = useSettings();

  React.useEffect(
    () => {
      document.documentElement.setAttribute('theme', theme);
    },
    [theme],
  );
}
