import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BetManager, Bus, RacingManager, useApplicationState } from '@apollo/core';
import { BET_EVENT_TYPE } from '@apollo/core/src/constants';
import { RacingActions, useRacingState } from '@apollo/core/src/state/racing/racing';
import Theme from 'themeSource/custom';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import Label from '../../../../shared/components/I18n/Label';
import { MARKET_PLACES } from '../../../../core/constants';
import { getOutcomeSelections, isBoxedSelections, oddsAnimation } from '../../../../core/utils';
import Loader from '../../../../shared/components/Loader';
import PercentageInput from '../../../../shared/components/Form/PercentageInput/PercentageInput';
import MoneyInput from '../../../../shared/components/Form/MoneyInput/MoneyInput';
import ToastManager from '../../../../core/ToastManager';
import SelectedOddsButtons from '../../../../shared/components/SelectedOdds/SelectedOddsButtons';
import KeyPad from '../../../Betslip/PlaceBetControls/KeyPad';

const getSelections = (selections, marketType) => {
  const res = [];
  MARKET_PLACES[marketType].forEach((p) => {
    const runners = selections.filter((s) => s.place === p).map((s) => s.runner);
    if (runners.length) {
      res[p - 1] = runners;
    }
  });

  return res;
};

const isValidCombo = (selections, marketType) => {
  const places = MARKET_PLACES[marketType];
  if (selections.length > 1) {
    // all places have at least one runner.
    const all = places.every((p) => selections.find((s) => s.place === p));
    // only a few first places (boxed mode)
    const onlyFirst = selections.length >= places.length
      && selections.find((s) => s.place === 1)
      && !selections.find((s) => s.place !== 1);

    return all || onlyFirst;
  }

  return false;
};

const Selections = ({ marketType }) => {
  const t = useTranslate();
  const { layout } = useApplicationState();
  const [isExpanded, setIsExpanded] = useState(!!Theme.EXPANDED_SELECTION);
  const [isFetchingOutcome, setIsFetchingOutcome] = useState(false);
  const [outcome, setOutcome] = useState(null);
  const [amount, setAmount] = useState();
  const [market, setMarket] = useState();

  const [racingState, racingDispatcher] = useRacingState();

  // variable for storing latest API request Promise object,
  // which can be used to remedy racing between simultaneous requests
  const latestRequest = useRef();

  const { activeRace, marketsByType } = racingState;

  useEffect(() => {
    const newMarket = marketsByType[marketType];
    if (JSON.stringify(market) !== JSON.stringify(newMarket)) {
      setMarket(newMarket);
    } else {
      // nothing to do
      // to prevent getExoticMarketOutcome call
    }
  }, [marketsByType, marketType, market]);

  const outcomeSelections = useMemo(
    () => racingState[`${marketType?.toLowerCase()}Selections`] || [],
    [marketType, racingState],
  );

  const selections = React.useMemo(() => getOutcomeSelections(outcome), [outcome]);

  useEffect(() => {
    if (!outcomeSelections.length) {
      setOutcome(null);
    }
  }, [outcomeSelections]);

  useEffect(() => {
    setOutcome(null);
    if (isValidCombo(outcomeSelections, marketType)) {
      setIsFetchingOutcome(true);

      // both local variable and "ref" are set to the same value.
      // when promise is settled (with data or error) local variable should be compared to "global" "ref"
      // and don't change state if they don't match (this happens only if later request reassigned the ref,
      // then state will be changed only in response to the latest (latest started) request regardless if response took more
      // or less time than the previous request)
      const currentRequest = (latestRequest.current = RacingManager.getExoticMarketOutcome({
        marketId: market?.marketId || marketsByType[marketType]?.marketId,
        selections: getSelections(outcomeSelections, marketType),
      })
        .then((data) => {
          // console.log(data);
          if (currentRequest === latestRequest.current) {
            if (data.status === 'FAILURE') {
              /* setOutcomeErr(data);
              setOutcome(null);

              errTimerRef.current = window.setTimeout(() => setOutcomeErr(null), 3000); */
            } else {
              setOutcome(data.outcome);
            }
            setIsFetchingOutcome(false);
          }
        })
        .catch((err) => {
          if (currentRequest === latestRequest.current) {
            setIsFetchingOutcome(false);

            if (err.status === 500) {
              ToastManager.error(() => t('500_error'));
            } else if (err.status !== 412) {
              ToastManager.error(() => err.message);
            }
          }
        }));
    }
  }, [outcomeSelections, marketType, market]);

  const clearSelection = useCallback(() => {
    const payload = {};
    payload[`${marketType?.toLowerCase()}Selections`] = [];

    racingDispatcher({
      type: RacingActions.RACING_STATE_UPDATE,
      payload,
    });
    setOutcome(null);
    setAmount(null);
  }, [racingDispatcher, marketType]);

  const addToBet = useCallback(
    (e) => {
      if (layout.mobileDevice) {
        oddsAnimation(
          e.target.classList.contains('btn') ? e.target : e.target.parentNode,
          true,
          outcome?.combos ? outcome.combos : '',
        );
      }
      BetManager.selectBet({
        betEventType: BET_EVENT_TYPE.RACING,
        event: activeRace,
        eventId: activeRace.raceId,
        marketTypeId: marketType,
        marketId: market?.marketId || marketsByType[marketType]?.marketId,
        outcomeId: outcome.outcomeId,
        amount,
        betGroups: [0],
        isNew: true,
      });

      ToastManager.success(() => t('added to betslip'));
      // clearSelection();
    },
    [activeRace, outcome, market, marketType, amount, t, layout.mobileDevice],
  );

  const betNow = useCallback(() => {
    BetManager.selectBet({
      betEventType: BET_EVENT_TYPE.RACING,
      event: activeRace,
      eventId: activeRace.raceId,
      marketTypeId: marketType,
      marketId: market?.marketId || marketsByType[marketType]?.marketId,
      outcomeId: outcome.outcomeId,
      amount,
      betGroups: [0],
      isNew: true,
      betNow: true,
    });

    Bus.send({
      event: Bus.events.layout.setSideBarRight,
      data: true,
    });
  }, [activeRace, outcome, market, marketType, amount, marketsByType]);

  const handleBetAmountChange = useCallback((amount) => {
    setAmount(amount);
  }, []);

  const handleBetPercentageChange = useCallback(
    (amount) => {
      setAmount(amount * (outcome?.combos || 1));
    },
    [outcome],
  );

  const isDisabled = !outcome?.combos || isFetchingOutcome;

  return outcomeSelections?.length ? (
    <div className='selectedOdds'>
      <div className='collapse__wrapper'>
        <div className='header' onClick={() => setIsExpanded(!isExpanded)}>
          <div className='icon'>
            <span className={`AIcon-angle-${isExpanded ? 'down' : 'up'}`} />
          </div>
          <div className='title'>
            {isExpanded ? <Label message='Hide Selections' /> : <Label message='Show Selections' />}
          </div>
          <div className='legs combos'>
            <Label message='combos' />
            {isFetchingOutcome ? (
              <Loader className='fetchingOutcome' />
            ) : (
              <span className='legs__combos'>{outcome?.combos || 0}</span>
            )}
          </div>
        </div>
        {isExpanded ? (
          <div className='content'>
            {isBoxedSelections(outcome) ? (
              <div className='row'>
                <div className='index'>
                  <Label message='boxed' />
                </div>
                <div className='name'>
                  {selections.map((sel) => sel.runner.replace('Boxed-', '')).join(', ')}
                </div>
              </div>
            ) : (
              MARKET_PLACES[marketType]?.map((p) => (
                <div className='row' key={p}>
                  <div className='index'>
                    <Label message={`place${p}`} />
                  </div>
                  <div className='name'>
                    {outcomeSelections
                      .filter((sel) => sel.place === p)
                      .map((sel) => sel.runner)
                      .join(', ')}
                  </div>
                </div>
              ))
            )}
          </div>
        ) : null}
        <div className='action_section'>
          <div className='legs combos'>
            <Label message='combos' />
            {isFetchingOutcome ? (
              <Loader className='fetchingOutcome' />
            ) : (
              <span className='legs__combos'>{outcome?.combos || 0}</span>
            )}
          </div>
          <div className='add_to_bet add_to_bet--with-amount'>
            <div className='add_to_bet__amount'>
              {!outcome && !isFetchingOutcome ? (
                <span className='multi-add-selection'>{t('add_selection')}</span>
              ) : (
                <>
                  <PercentageInput
                    value={amount}
                    combos={outcome?.combos || 1}
                    onChange={handleBetPercentageChange}
                    placeholder='betslip_percentage'
                    disabled={isDisabled}
                  />
                  <MoneyInput
                    value={amount}
                    onChange={handleBetAmountChange}
                    placeholder='stake'
                    disabled={isDisabled}
                  />
                </>
              )}
            </div>
            <SelectedOddsButtons
              addToBet={(e) => addToBet(e)}
              betNow={betNow}
              addDisabled={!(outcome?.combos || isFetchingOutcome)}
              clearSelection={clearSelection}
              clearDisabled={!outcomeSelections.length}
            />
          </div>
        </div>
      </div>
      {layout.keyPadMoneyInput && layout.mobileDevice ? (
        <KeyPad key={layout.keyPadMoneyInput} inputParentSelector='.main__body--race-page' />
      ) : null}
    </div>
  ) : null;
};

export default Selections;
