import React from 'react';
import { isEmpty, map } from 'lodash';
import { useSportsbookState, useSportState } from '@apollo/core';

import { isUrlNamesMatch } from '@apollo/routing';

import CategoryListItem from './CategoryListItem';
import Label from '../../../shared/components/I18n/Label';

const CategoryList = () => {
  const [sportsState] = useSportsbookState();
  const { selectedCategoryUrlName } = sportsState;

  const { categories, loading } = useSportState();

  if (loading) {
    return (
      <div className='sport-tree-item-message'>
        <span className='loading-message'>
          <Label message='loading' />
        </span>
      </div>
    );
  }

  if (isEmpty(categories)) {
    return (
      <div className='sport-tree-item-message'>
        <span className='empty'>
          <Label message='no_categories_found' />
        </span>
      </div>
    );
  }

  return map(categories, (category) => (
    <CategoryListItem
      key={category.id}
      category={category}
      active={isUrlNamesMatch(category.urlName, selectedCategoryUrlName)}
    />
  ));
};

export default CategoryList;
