import SidebarLeftRouting from './SidebarLeftRouting';
import DetailPageHeader from './DetailPageHeader';

export default {
  BETTING_PARNERS: [
    '/static/assets/images/partners/afl.png',
    '/static/assets/images/partners/nrl.png',
    '/static/assets/images/partners/cricket.png',
    '/static/assets/images/partners/tennis.png',
  ],
  USE_SIGN_UP_MODAL: true,
  USE_HORIZONTAL_PROFILE_MENU: true,
  HIDE_BONUS_NOTIFICATION_CANCEL_BUTTON: true,
  SidebarLeftRouting,
  DetailPageHeader,
};
