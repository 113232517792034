import React, { useMemo } from 'react';
import {
  SiteConfigManager,
  mergeRoutes,
} from '@apollo/core';

import {
  COMPONENT_TYPES,
} from '@apollo/routing';

import { forEach, get } from 'lodash';
import Router from '../../core/Router/Router';

const { CLIENT_PROFILE } = COMPONENT_TYPES;

const ClientProfileSubRouter = ({ tabs, getAvailableRoutes, parentRoute }) => {
  const clientProfilePath = SiteConfigManager.getComponentPath(CLIENT_PROFILE);

  const availableRoutes = useMemo(() => (
    getAvailableRoutes(clientProfilePath)
  ), [clientProfilePath]);

  // console.log({ availableRoutes });

  const routes = useMemo(() => {
    const routes = [];

    forEach(tabs, ({ type, isActive }) => {
      const route = get(availableRoutes, type);
      if (route && isActive) {
        routes.push(route);
      }
    });

    return routes;
  }, [tabs]);

  const mergedRoutes = useMemo(() => mergeRoutes(routes), [routes]);

  const urlToRedirect = useMemo(() => {
    const path = get(routes, '0.path');
    const defaultPath = get(routes, '0.defaultPath');
    return path || defaultPath || '/';
  }, [routes]);

  return (
    <Router
      routes={mergedRoutes}
      redirectTo={urlToRedirect}
      redirectFrom={`${clientProfilePath}/${parentRoute}`}
    />
  );
};

export default ClientProfileSubRouter;
