import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import { isEmpty } from 'lodash';
import { useRacingState } from '@apollo/core/src/state/racing/racing';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import RaceMarketTab from './RaceMarketTab';
import { defaultMainTab, getMarketLegsFromMarketType } from './utils';
import { RaceMainTabs } from '../../../core/constants';

const RaceMarketTabs = ({ activeTab = defaultMainTab, marketTabs = [], marketSubTabs = [] }) => {
  const t = useTranslate();
  const [racingState] = useRacingState();
  const activeItemRef = useRef();

  useEffect(() => {
    const { current } = activeItemRef;
    if (current) {
      current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, [activeTab]);

  const { activeRace, activeMeet, marketTypes } = racingState;

  const getRaceMarketTabName = (tabKey) => {
    if (tabKey === RaceMainTabs.COMBINED_TAB) {
      return marketTypes.find((market) => market.uiPosition === tabKey)?.name || t(tabKey);
    }
    return t(tabKey);
  };

  return (
    <div className='race-page__markets__tab_wrapper'>
      <div className='race-page__markets__tab_wrapper__flex'>
        <div className='page__markets_tab_container'>
          <Scrollbars className='scroll__container scroll__container--market-tabs'>
            {marketTabs?.map((tabKey) => {
              const type = !isEmpty(marketSubTabs?.[tabKey]) ? marketSubTabs[tabKey][0] : null;
              const number = getMarketLegsFromMarketType(type)?.[0] || activeRace.number;
              return (
                <RaceMarketTab
                  key={tabKey}
                  refProps={activeTab === tabKey ? activeItemRef : null}
                  containerClassName={cx('tab', {
                    active: activeTab === tabKey,
                  })}
                  btnClassName={cx('tab_button', {
                    active: activeTab === tabKey,
                  })}
                  marketName={getRaceMarketTabName(tabKey)}
                  activeMeet={activeMeet}
                  raceNumber={number}
                  marketTab={tabKey}
                  marketType={type}
                />
              );
            })}
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default RaceMarketTabs;
