import React from 'react';
import Label from '../../../shared/components/I18n/Label';
import ToggleCheckbox from '../../../shared/components/ToggleCheckbox/ToggleCheckbox';

const RaceFlucsToggle = ({ isFlucs, toggleIsFlucs, className }) => (
  <div className={`${className} event__flucs event__flucs--toggle`}>
    <Label message='flucs' />
    <ToggleCheckbox
      checked={isFlucs}
      onChangedHandler={() => {
        toggleIsFlucs();
      }}
    />
  </div>
);

export default RaceFlucsToggle;
