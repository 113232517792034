import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useApplicationState } from '@apollo/core';
import EventDateGroup from './EventDateGroup';
import { EVENT_LIST_TEMPLATE } from '../../../../core/utils';
import Dropdown from '../../../../shared/components/Dropdown/Dropdown';
import { useEventFilter } from '../../../../state/EventFilter/EventFilter';
import { AnimatedDropdown } from '../../../Animations/AnimatedDropdown';

export const MultiMarketsFilter = ({ mainMarketTypesByPeriod }) => {
  const betTypes = _.chain(mainMarketTypesByPeriod)
    .get('fullPeriod.marketTypes')
    .orderBy('mainOrderPosition')
    .value();
  const { layout } = useApplicationState();
  const [isExpanded, setExpanded] = useState(false);
  const { filter, setFilter } = useEventFilter();
  const toggleRef = useRef(null);

  const handleDropdownItemClick = (id) => {
    setFilter([id]);
    setExpanded(false);
  };

  useEffect(() => {
    if (layout.mobileDevice && betTypes.length > 0) {
      setFilter([betTypes[0].id]);
    } else {
      setFilter([]);
    }
  }, [layout.mobileDevice]);

  const selectedBetTypeName = useMemo(() => {
    const selectedBetType = betTypes.find((betType) => betType.id === filter[0]);
    return selectedBetType?.name ?? 'Bet type';
  }, [filter]);

  return layout.mobileDevice && (
    <div className='tournament__header tournament__header--bet-types'>

      <Dropdown onExpanded={setExpanded} toggleRef={toggleRef} className='bet-types__selector'>
        <div ref={toggleRef} className='bet-types__selector__toggler'>
          {selectedBetTypeName}
        </div>
        <AnimatedDropdown isExpanded={isExpanded} className='bet-types__selector__dropdown'>
          {betTypes.map(({ name: betTypeName, id }) => (
            <div key={betTypeName} onClick={() => handleDropdownItemClick(id)}>
              {betTypeName && betTypeName.includes('(') ? (
                <>
                  {betTypeName.substring(0, betTypeName.indexOf('('))}
                  <div className='text-small'>
                    {betTypeName.substring(betTypeName.indexOf('('), betTypeName.length)}
                  </div>
                </>
              ) : (
                betTypeName
              )}
            </div>
          ))}
        </AnimatedDropdown>
      </Dropdown>
    </div>
  );
};

const MultiMarketsHeader = ({ title, mainMarketTypesByPeriod }) => {
  const betTypes = _.chain(mainMarketTypesByPeriod)
    .get('fullPeriod.marketTypes')
    .orderBy('mainOrderPosition')
    .value();
  const { layout } = useApplicationState();

  return (
    <div className='event-group__header event-group__header--multi-markets'>
      <div className='event-group__title'>{title}</div>
      <div className='event-group__bet-types'>
        {!layout.mobileDevice && betTypes.map(({ name: betTypeName }) => (
          <div key={betTypeName} className='event-group__bet-types__item'>
            {betTypeName && betTypeName.includes('(') ? (
              <>
                {betTypeName.substring(0, betTypeName.indexOf('('))}
                <div className='text-small'>
                  {betTypeName.substring(betTypeName.indexOf('('), betTypeName.length)}
                </div>
              </>
            ) : (
              betTypeName
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const EventListGroupHeader = ({ template, startTime, children, mainMarketTypesByPeriod }) => {
  const headerRenderer = React.useCallback(
    (title) => <MultiMarketsHeader title={title} mainMarketTypesByPeriod={mainMarketTypesByPeriod} />,
    [mainMarketTypesByPeriod],
  );

  return (
    <>
      {template === EVENT_LIST_TEMPLATE.MULTI_MARKETS && (
        <EventDateGroup startTime={startTime} headerRenderer={headerRenderer}>
            {children}
        </EventDateGroup>
      )}
      {(template === EVENT_LIST_TEMPLATE.DATE || template === EVENT_LIST_TEMPLATE.CLASSIC) && (
        <EventDateGroup startTime={startTime}>{children}</EventDateGroup>
      )}
      {template === EVENT_LIST_TEMPLATE.OUTRIGHT && children}
    </>
  );
};

export default EventListGroupHeader;
