import React, { useCallback } from 'react';

import { signInRoutes } from '@apollo/routing';
import { Bus, useApplicationState } from '@apollo/core';
import CmdLink from '../../CmdLink';
import Label from '../../I18n/Label';
import SignUpLink from '../../SignUp/SignUpLink';

const { phone } = signInRoutes;

const HeaderInfoUnauthorized = () => {
  const { layout } = useApplicationState();

  const onClickHandler = useCallback(() => {
    if (layout.mobileDevice) {
      Bus.send({
        event: Bus.events.layout.setMainMenu,
        data: false,
      });
    }
  }, [layout]);

  return (
    <div className='btn-group btn-group--auth'>
      <CmdLink className='btn btn-login' cmd='signin' onClick={onClickHandler}>
        <Label message='login' />
      </CmdLink>
      <SignUpLink onClickHandler={onClickHandler} />
    </div>
  );
};

export default HeaderInfoUnauthorized;
