import React from 'react';
import { isNull } from 'lodash';

const HiddenField = ({
  field,
}) => {
  const {
    value,
    disabled,
    name,
  } = field;

  const inputValue = isNull(value) ? '' : value;

  return (
    <input
      value={inputValue}
      type='hidden'
      id={name}
      name={name}
      disabled={disabled}
    />
  );
};

export default HiddenField;
