import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Bus, isHidden } from '@apollo/core';
import { SportSelector } from '@apollo/core/src/state/sport/sport';
import { chain, isEmpty, map } from 'lodash';
import { hasMarketTypes } from '@apollo/core/src/utils/status';
import { unixTime } from '../../../core/constants';
import {
  EVENT_LIST_TEMPLATE,
  extractSportMarketTypes,
  groupEventsByDate,
} from '../../../core/utils';
import EventListItem from '../Tournament/EventDateGroup/EventListItem';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import EventDateGroup from '../Tournament/EventDateGroup/EventDateGroup';
import SportLoader from '../SportLoader';
import FilterBySport from './FilterBySport';
import SportNothingFound from '../SportNothingFound';
import FilterByTime from './FilterByTime';

const ITEMS_PER_PAGE = 100;

const getDateRange = (timeFilter) => {
  const fromDate = Date.now();
  const toDate = timeFilter ? fromDate + timeFilter * unixTime.hours1 : null;
  return {
    fromDate,
    toDate,
  };
};

const Upcoming = ({ showTimeFilter, sportId, tournamentIds }) => {
  const t = useTranslate();
  const [timeFilter, setTimeFilter] = useState(null);

  const [dateRange, setDateRange] = useState({
    fromDate: 0,
    toDate: 0,
  });

  const [selectedSportId, setSelectedSportId] = useState(sportId);

  const loading = SportSelector.selectIsLoading();
  const selectedSport = SportSelector.selectSportById(selectedSportId);
  const sports = SportSelector.selectSports();
  const events = SportSelector.selectEvents({ sportId: selectedSportId });

  const countDownCompleteHandler = useCallback(() => {
    setDateRange(getDateRange(timeFilter));
  }, [timeFilter]);

  const filteredEvents = useMemo(
    () => events
      .filter((e) => {
        if (isHidden(e) || !hasMarketTypes(e)) {
          return false;
        }
        if (e.outright) {
          return false;
        }
        if (tournamentIds) {
          return tournamentIds.includes(e.tournamentId);
        }
        return (
          e.startTime > dateRange.fromDate
            && (!dateRange.toDate || e.startTime < dateRange.toDate)
        );
      })
      .slice(0, ITEMS_PER_PAGE), // Limit the number of web socket connections
    [tournamentIds, events, dateRange],
  );

  useEffect(() => {
    setDateRange(getDateRange(timeFilter));
  }, [timeFilter]);

  useEffect(() => {
    if (!dateRange.fromDate) {
      return;
    }
    Bus.emit(Bus.events.sport.sportLoadEvents, {
      filter: {
        isOnlyMainMarketTypes: true,
        fromDate: dateRange.fromDate,
        toDate: tournamentIds ? null : dateRange.toDate,
        sportId: selectedSportId,
        tournamentIds,
      },
      paging: {
        itemsPerPage: ITEMS_PER_PAGE,
        currentPage: 0,
      },
    });
  }, [dateRange, selectedSportId, tournamentIds]);

  const eventDateGroupsArray = useMemo(() => {
    const eventsByDate = groupEventsByDate(filteredEvents);

    return eventsByDate
      .map((group) => (
        <EventDateGroup key={group[0]?.id} startTime={Number(group[0]?.startTime)}>
          {map(group, (event) => {
            const mainMarketTypesByPeriod = extractSportMarketTypes(sports, event.sportId);
            const filterItems = [
              // Add Market Type Tabs
              ...chain(mainMarketTypesByPeriod)
                .get('fullPeriod.marketTypes')
                .filter(
                  // ({ show, providerName }) => show && (debugMode || providerName === defaultSportProvider),
                  ({ show }) => show,
                )
                .orderBy('mainOrderPosition')
                .map(({ id, name }) => ({ id, name }))
                .value(),
            ];

            const firstAvailableMarket = filterItems.find((item) => event.marketTypes[item.id]);

            return (
              firstAvailableMarket && (
                <EventListItem
                  key={event.id}
                  event={event}
                  template={EVENT_LIST_TEMPLATE.CLASSIC}
                  activeMarketTypeId={firstAvailableMarket.id}
                  mainMarketTypesByPeriod={mainMarketTypesByPeriod}
                  countDownDisplayTimeout={15 * 60}
                  withSportIcon
                  countDownCompleteHandler={countDownCompleteHandler}
                />
              )
            );
          })}
        </EventDateGroup>
      ))
      .filter(({ props }) => props.children.filter(Boolean)?.length);
  }, [filteredEvents, sports, countDownCompleteHandler]);

  if (loading && isEmpty(eventDateGroupsArray)) {
    return <SportLoader />;
  }

  return (
    <>
      {!sportId && (
        <FilterBySport
          onChange={(sportId) => {
            setSelectedSportId(sportId);
          }}
          selectedSportId={selectedSportId}
          className='sports-filter--sports'
        />
      )}

      {showTimeFilter && <FilterByTime time={timeFilter} onChange={setTimeFilter} />}

      <div className='sport-page__content sport-page__content--upcoming'>
        {/* The "All Sport" Upcoming page does NOT have a "selectedSport" */}
        {selectedSport && isEmpty(eventDateGroupsArray) ? (
          <SportNothingFound
            className='nothing-found--upcoming'
            selectedSport={selectedSport}
            timeFilter={timeFilter}
          />
        ) : null}

        {!isEmpty(eventDateGroupsArray) ? (
          <div className='tournament tournament--classic'>
            {eventDateGroupsArray.map((EventDateGroupComponent) => EventDateGroupComponent)}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Upcoming;
