import React from 'react';
import { at, isEmpty } from 'lodash';
import { useApplicationState } from '@apollo/core';
import OrderBetslipControls from './OrderBetslipControls';
import Label from '../../../shared/components/I18n/Label';

const OrderBetslip = ({ betItems, selectedBet, disabled }) => {
  const [orderBet, setOrderBet] = React.useState(false);
  const applicationState = useApplicationState();
  const [authenticated, currency] = at(applicationState, ['authenticated', 'wallet.currency']);

  const handleToggleOrderBet = React.useCallback(() => setOrderBet((orderBet) => !orderBet), []);

  // Not available for unauthorized users
  if (!authenticated || isEmpty(betItems) || isEmpty(selectedBet)) {
    return null;
  }

  return (
    <div className='bet-slip__order-bet controls'>
      <div className='controls__block'>
        <div className='switchBlockInfo'>
          <Label message='turn_order_bet_on' />
        </div>
        <div className='toggle'>
          <label className='switch'>
            <input
              type='checkbox'
              disabled={disabled}
              onChange={handleToggleOrderBet}
              checked={Boolean(orderBet)}
            />
            <span className='slider' />
          </label>
        </div>
      </div>

      {orderBet && (
        <OrderBetslipControls
          betItems={betItems}
          selectedBet={selectedBet}
          currency={currency}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default OrderBetslip;
