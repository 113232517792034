import React from 'react';
import moment from 'moment/moment';
import { map } from 'lodash';
import Loader from '../../../../shared/components/Loader';
import TransactionHistoryItem from './TableList/TransactionHistoryItem';

const TransactionHistoryMobile = ({ transactions, cancelWithdrawalHandler, totalPages }) => {
  const transactionsByDate = transactions?.reduce((acc, transaction) => {
    const { creationDate } = transaction;
    const date = moment(creationDate).format('YYYY-MM-DD');
    if (acc[date]) {
      acc[date].push(transaction);
    } else {
      acc[date] = [transaction];
    }
    return acc;
  }, {});

  if (!transactions || (transactions.length === 0 && totalPages > 0)) {
    return <Loader />;
  }

  return (
    <div className='--mobile'>
      {map(Object.keys(transactionsByDate), (date) => (
        <div key={date}>
          <div className='bet_date'>{moment(date).format('dddd, DD MMM')}</div>
          <div>
            {map(transactionsByDate[date], (transaction) => (
              /* <TransactionHistoryItemMobile
                key={transaction.creationDate}
                transaction={transaction}
                onCancelWithdrawal={cancelWithdrawalHandler}
              /> */
              <TransactionHistoryItem
                key={`${transaction.id}_${transaction.creationDate}`}
                operation={transaction}
                onCancelWithdrawal={cancelWithdrawalHandler}
                isMobile
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TransactionHistoryMobile;
