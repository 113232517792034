import React from 'react';
// import Loader from 'components/Loader';

const TournamentEventSkeletonBlock = (
  <div className='sBlock__content__element'>
    <div className='market'>
      <div className='description'>
        <div className='arrowNavigation-wrapper' />
        <div className='info'>
          <div className='info__name'>
            <div className='win'>
              <span className='participant-name' />
            </div>
            <div className='lose'>
              <span className='participant-name' />
            </div>
          </div>
          <div className='info__details'>
            <div className='event-live-status liveBlock'>
              <span className='event-status-period period' />
              <span className='event-live-time time' />
            </div>
            <div className='score'>
              <span className='win' />
              <span className='lose' />
            </div>
          </div>
        </div>
      </div>
      <div className='odds'>
        <div>
          <div className='market'>
            <span className='odd' />
            <span className='odd' />
          </div>
          <div className='market'>
            <span className='oddName' />
            <span className='odd' />
            <span className='oddName' />
            <span className='odd' />
            <div className='market-expanded-event-gap' />
          </div>
          <div className='market'>
            <span className='oddName' />
            <span className='odd' />
            <span className='odd' />
            <div className='market-expanded-event-gap' />
          </div>
          <span className='extra' />
        </div>
      </div>
    </div>
  </div>
);

const renderTournamentSkeletonBlocks = (min, max) =>
  new Array(
    // Displaying min - max events in skeleton tournament
    Math.round(Math.random() * (max - min)) + min
  )
    .fill('')
    .map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div className='sBlock__content__element' key={index}>
        {TournamentEventSkeletonBlock}
      </div>
    ));

export default function TournamentLoader(props) {
  const { min = 1, max = 1 } = props;

  const TournamentsBlock = React.useMemo(() => renderTournamentSkeletonBlocks(min, max), []);

  return (
    <div className='sBlock'>
      <div className='sBlock__title '>
        <div className='sBlock__title__left'>
          <div className='icon__sport ' />
          <div className='flag__sport ' />
          <div className='name__sport'>
            <span className='tournament-name' />
          </div>
        </div>
        <div className='sBlock__title__right'>
          <div className='odds__name'>
            <div className='market-header'>
              <span />
              <span />
            </div>
            <div className='market-header'>
              <span />
              <span />
              <span />
              <span />
              <div className='market-expanded-tournament-gap' />
            </div>
            <div className='market-header'>
              <span />
              <span />
              <span />
              <div className='market-expanded-tournament-gap' />
            </div>
            <span className='extra' />
          </div>
        </div>
      </div>

      <div className='sBlock__content'>{TournamentsBlock}</div>
    </div>
  );
}
