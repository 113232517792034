import React from 'react';
import { map } from 'lodash';
import Market from './Market';

const MarketRow = ({ event, markets, suspended, disabled, template }) => (
  <div className='event__market-row'>
    {map(markets, (market) => (
      <Market
        key={market.marketTypeId}
        market={market}
        event={event}
        suspended={suspended}
        disabled={disabled}
        template={template}
      />
    ))}
  </div>
);

export default React.memo(MarketRow);
