import React from 'react';
import _ from 'lodash';
import { useApplicationState, centsToDecimal } from '@apollo/core';
import useTranslate from '../../shared/components/I18n/Interpreter';
import HeaderTitle from '../../shared/components/HeaderTitle/HeaderTitle';
import { currencySymbols } from '../../core/constants';

const placeCup = (place) => (
  <>
    {place === 1 && <img src='./static/assets/images/first_cup.svg' alt={place} />}
    {place === 2 && <img src='./static/assets/images/second_cup.svg' alt={place} />}
    {place === 3 && <img src='./static/assets/images/third_cup.svg' alt={place} />}
  </>
);

const CompetitionTable = ({ data }) => {
  const t = useTranslate();

  const applicationState = useApplicationState();
  const accountId = _.get(applicationState, 'account.id');

  if (_.isEmpty(data)) {
    return null;
  }

  return (
    <div className='tournaments-box'>
      <HeaderTitle size='xs' title='Leaderboard' translated>
        {/* <button onClick={() => setModalVisible(!modalVisible)} type="button" className="tournaments-box__all">All ({leaderboardTable.length})</button> */}
      </HeaderTitle>
      <table className='promotion-table'>
        <thead className='promotion-table__wrapper'>
          <tr className='promotion-table__header'>
            <th className='promotion-table__th'>{t('place')}</th>
            <th className='promotion-table__th'>{t('account_id')}</th>
            <th className='promotion-table__th'>{t('prize')}</th>
          </tr>
        </thead>
        <tbody className='promotion-table__wrapper'>
          {
            data.map(({ clientId, reward }, index) => (
              <tr key={clientId} className='promotion-table__content'>
                <td className='promotion-table__td'>
                  <span className='promotion-table__place'>{index + 1}</span>
                  {placeCup(index + 1)}
                </td>
                <td className='promotion-table__td'>
                  <div className='promotion-table__user'>
                    {clientId === accountId ? t('me') : clientId}
                  </div>
                </td>
                <td className='promotion-table__td'>
                  {reward ? (
                    <>
                      {/* TODO: remove hardcode here */}
                      {reward.financialReward && `${currencySymbols.EUR} ${centsToDecimal(reward.financialReward)}`}
                      &nbsp;&nbsp;
                      {reward.otherReward}
                    </>
                  ) : '--'}
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
};

export default CompetitionTable;
