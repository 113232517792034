import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { betHistoryConstants, useBetHistory } from '@apollo/core';
import BetHistoryFilterList from './BetHitoryFilterList';
import HistoryFilterListItem from '../HitoryFilterListItem';
import DateRangePicker from '../../../../shared/components/DateRangePicker/DateRangePicker';

const { dateRangeOptions, rangeName } = betHistoryConstants;

const BetHistoryFilterListContainer = () => {
  const {
    activeTimeFilter,
    setActiveTimeFilter,
    setRange,
    range,
  } = useBetHistory();

  return (
    <div className='history-filter bet-history'>
      <div className='history-filter__result'>
        <BetHistoryFilterList />
      </div>
      <div className='history-filter__date'>
        {
          _.map(dateRangeOptions, (timeFilter) => {
            const isChecked = timeFilter === activeTimeFilter;

            return (
              <HistoryFilterListItem
                key={timeFilter}
                message={timeFilter}
                name={timeFilter}
                isChecked={isChecked}
                onClick={() => setActiveTimeFilter(timeFilter)}
                radio
              />
            );
          })
        }
        <div className='date-range-picker'>
          <DateRangePicker
            disabled={activeTimeFilter !== rangeName}
            stateDateWrapper={(from, to) => {
              setRange({
                from: moment(from).startOf('day').valueOf(),
                to: moment(to).endOf('day').valueOf(),
              });
            }}
            initialStartDate={moment(_.get(range, 'from'))}
            initialEndDate={moment(_.get(range, 'to'))}
          />
        </div>
      </div>
      {/* <BetHistoryExport/> */}
    </div>
  );
};

export default BetHistoryFilterListContainer;
