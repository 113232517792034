import React from 'react';
import FormCom from '../../../../shared/components/Form/FormCom';

const DefaultPaymentForm = ({
  selectedPayment,
  depositForm,
  confirmationHandler,
  submitHandler,
  isCordova,
}) => (
  <div className='row payments-list__default'>
    <FormCom
      form={depositForm}
      additionalValues={{
        pmId: selectedPayment.paymentId || selectedPayment.id,
        isCordova: isCordova.toString(),
      }}
      action={confirmationHandler}
      buttonText='deposit'
      onSubmit={submitHandler}
    />
  </div>
);

export default DefaultPaymentForm;
