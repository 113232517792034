import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { centsToDecimal, useCashout } from '@apollo/core';
import Label from '../I18n/Label';
import MoneyInput from '../Form/MoneyInput/MoneyInput';
import Range from '../Range/Range';

const PartialCashoutPopup = () => {
  const {
    onPartialCashout,
    isPartialCashoutLoading,
    amount,
    currencySymbol,
    estimatedPartialCashOut,
    setIsPartialCashoutExpanded,
    isPartialCashoutExpanded,
    setPartialCashoutAmount,
    partialCashOutAmount,
    partialCashoutCoefficient,
    minPartialCashOut,
  } = useCashout();

  const newBetAmount = useMemo(() => (
    amount - partialCashOutAmount / partialCashoutCoefficient
  ), [amount, partialCashOutAmount, partialCashoutCoefficient]);

  const breakpoint = useMemo(() => {
    const range = _.chain(estimatedPartialCashOut).subtract(minPartialCashOut).round().value();
    const firstBreakPoint = centsToDecimal(minPartialCashOut);
    const secondBreakPoint = centsToDecimal(_.chain(range * 0.25).round().add(minPartialCashOut).value());
    const thirdBreakpoint = centsToDecimal(_.chain(range * 0.5).round().add(minPartialCashOut).value());
    const fourthBreakpoint = centsToDecimal(_.chain(range * 0.75).round().add(minPartialCashOut).value());
    const fifthBreakpoint = centsToDecimal(estimatedPartialCashOut);

    return [
      firstBreakPoint,
      secondBreakPoint,
      thirdBreakpoint,
      fourthBreakpoint,
      fifthBreakpoint,
    ];
  }, [estimatedPartialCashOut]);

  const closePartialCashout = useCallback(() => {
    setIsPartialCashoutExpanded(false);
  }, [setIsPartialCashoutExpanded]);

  const handleSetPartialCashoutAmount = useCallback((amount) => {
    setPartialCashoutAmount(amount);
  }, [setPartialCashoutAmount]);

  const isPartialCashoutAmountBig = partialCashOutAmount > estimatedPartialCashOut;
  const isPartialCashoutAmountSmall = partialCashOutAmount < minPartialCashOut;

  if (!isPartialCashoutExpanded) return null;

  return (
    <div className='cashoutBlock'>
      <div
        className='closeIcon  AkIcon-close-icon'
        onClick={closePartialCashout}
      />
      <div className='cashoutBlock__title'>
        <Label message='partial_cash_out' />
      </div>
      <div className='cashoutBlock__content'>
        <div className='inputBlock'>
          <MoneyInput
            value={partialCashOutAmount}
            onChange={handleSetPartialCashoutAmount}
          />
        </div>
        <div className='partialContent'>
          <Range
            min={minPartialCashOut}
            max={estimatedPartialCashOut}
            initial={partialCashOutAmount}
            formatFn={centsToDecimal}
            onChange={setPartialCashoutAmount}
          />
          <div className='dots' />
          <div className='values'>
            {breakpoint.map((breakpoint) => (
              <span key={breakpoint}>
                {currencySymbol}
                {breakpoint}
              </span>
            ))}
          </div>
        </div>
        {(isPartialCashoutAmountBig || isPartialCashoutAmountSmall) && (
          <Label
            className='error-message'
            message={
              (isPartialCashoutAmountBig && 'partial_cashout_amount_big')
              || (isPartialCashoutAmountSmall && 'partial_cashout_amount_small')
            }
          />
        )}
        <button
          type='button'
          className='bBasePrimary'
          disabled={isPartialCashoutAmountBig || isPartialCashoutAmountSmall || isPartialCashoutLoading}
          onClick={onPartialCashout}
        >
          {(isPartialCashoutAmountBig || isPartialCashoutAmountSmall) ? (
            <Label message='order_cashout' />
          ) : (
            <>
              <Label
                message='cashout'
                render={(translation) => (
                  <div>
                    {translation}
                    {' '}
                    {currencySymbol}
                    {centsToDecimal(partialCashOutAmount)}
                  </div>
                )}
              />
              <Label
                message='partial_cashout_new_bet_amount'
                render={(translation) => (
                  <div>
                    {translation}
                    {' '}
                    {currencySymbol}
                    {centsToDecimal(newBetAmount)}
                  </div>
                )}
              />
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default PartialCashoutPopup;
