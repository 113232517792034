import React from 'react';
import moment from 'moment';
import { SingleDatePicker, isInclusivelyBeforeDay } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import useTranslate from '../I18n/Interpreter';

const DateRangePickerWrapper = ({
  initialStartDate,
  initialEndDate,
  initialFocusedInput,
  stateDateWrapper,
  disabled,
}) => {
  const t = useTranslate();
  const [focusedInput, setFocusedInput] = React.useState(initialFocusedInput);
  const [startDate, setStartDate] = React.useState(initialStartDate);
  const [endDate, setEndDate] = React.useState(initialEndDate);

  const onStartDatesChange = (startDate) => {
    const endDateToSet = endDate && startDate.isBefore(endDate) ? endDate : moment();
    stateDateWrapper(startDate, endDateToSet);
    setStartDate(startDate);
    setEndDate(endDateToSet);
  };

  const onEndDatesChange = (endDate) => {
    const startDateToSet = startDate && endDate.isAfter(startDate) ? startDate : endDate;
    stateDateWrapper(startDateToSet, endDate);
    setStartDate(startDateToSet);
    setEndDate(endDate);
  };

  return (
    <div>
      <div className='position-left'>
        <SingleDatePicker
          placeholder={t('date')}
          date={startDate}
          block={false}
          disabled={disabled}
          readOnly
          noBorder
          hideKeyboardShortcutsPanel
          displayFormat='DD/MM/YYYY'
          renderCalendarInfo={() => {}}
          isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
          onDateChange={onStartDatesChange} // PropTypes.func.isRequired,
          numberOfMonths={1}
          onFocusChange={({ focused }) => setFocusedInput(focused ? 1 : 0)}
          focused={focusedInput === 1}
        />
        <span className='icon AkIcon-calendar-icon' />
      </div>
      <span>{t('to')}</span>
      <div className='position-right'>
        <SingleDatePicker
          placeholder={t('date')}
          date={endDate}
          block={false}
          disabled={disabled}
          readOnly
          noBorder
          hideKeyboardShortcutsPanel
          displayFormat='DD/MM/YYYY'
          renderCalendarInfo={() => {}}
          isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
          onDateChange={onEndDatesChange} // PropTypes.func.isRequired,
          numberOfMonths={1}
          onFocusChange={({ focused }) => setFocusedInput(focused ? 2 : 0)}
          focused={focusedInput === 2}
        />
        <span className='icon AkIcon-calendar-icon' />
      </div>
    </div>
  );
};

export default DateRangePickerWrapper;
