import React from 'react';
import { NavLink } from 'react-router-dom';
import Label from '../../../shared/components/I18n/Label';

const GenericTypeButton = ({ path, label, icon = null }) => (
  <div className='type__container type__container--generic'>
    <NavLink className='race-link-all ' to={path}>
      <div className='type__container-inner'>
        <div className='type__item-icon-blank' />
        <div className='type__item-group-icon'>
          {icon}
          <span className='type__item-label'>{label}</span>
        </div>
      </div>

      <div className='type__title'>
        <div>
          <Label message={label} />
        </div>
      </div>
    </NavLink>
  </div>
);
export default GenericTypeButton;
