import React from 'react';
import EventLinks from '../../EventLinks';
import EventLiveStatus from './EventLiveStatus';
import EventStartTime from './EventStartTime';

const EventStats = ({ event, isLiveService, isLivePage, withCountDown, children }) => (
  <div className='event__stats'>
    {children}
    <EventLinks event={event} />
    {isLiveService ? (
      <EventLiveStatus event={event} showLiveStatus={!isLivePage} />
    ) : (
      <EventStartTime event={event} withCountDown={withCountDown} />
    )}
  </div>
);

export default EventStats;
