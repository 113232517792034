import { map } from 'lodash';
import React from 'react';
import { DateService, isMultilegsMarket, useApplicationState } from '@apollo/core';
import { BET_EVENT_TYPE } from '@apollo/core/src/constants';
import { getRaceNumbersFromOutcomeInfo } from '../../../../../core/utils';
import TransactionEventDetailsDesktop from './TransactionEventDetailsDesktop';
import TransactionEventDetailsMobile from './TransactionEventDetailsMobile';
import MultiBetTitle from './MultiBetTitle';
import Accordion from '../../../../../shared/components/Accordion/Accordion';
import useTranslate from '../../../../../shared/components/I18n/Interpreter';

const Wrapper = ({ isMulti, isMobile, children }) => {
  const t = useTranslate();

  return isMulti && isMobile ? (
    <Accordion className='event-legs' collapsed titleOpen={t('hide_legs_more')}>
      {children}
    </Accordion>
  ) : (
    children
  );
};

const TransactionEventDetails = ({ outcomes, transaction, flexiPercentage }) => {
  const { layout } = useApplicationState();

  return (
    <div>
      <MultiBetTitle outcomes={outcomes} />
      <Wrapper isMulti={outcomes?.length > 1} isMobile={layout.mobileDevice}>
        {map(outcomes, (outcome) => {
          const eventStartTimeDate =
            outcome &&
            outcome.eventStartTime &&
            DateService.getFormattedDateWithTime(outcome.eventStartTime);

          const raceNumbers = isMultilegsMarket(outcome.marketTypeId)
            ? getRaceNumbersFromOutcomeInfo(outcome)
            : [];

          const eventName = isMultilegsMarket(outcome.marketTypeId)
            ? `${outcome.localizedEventName.split(' R')[0]} (${raceNumbers.join(', ')})`
            : outcome.localizedEventName;

          const marketName = outcome.marketName || outcome.outcomeName;
          const subTitle = `${outcome.localizedSportName}
          ${outcome.sportService !== BET_EVENT_TYPE.RACING ? `- ${outcome.localizedCategoryName}` : ''}
          - ${outcome.localizedTournamentName}`;

          return layout.mobileDevice ? (
            <TransactionEventDetailsMobile
              key={`${outcome.marketId}_${outcome.outcomeName}`}
              transaction={transaction}
              outcome={outcome}
              marketName={marketName}
              eventName={eventName}
              eventStartTimeDate={eventStartTimeDate}
              raceNumbers={raceNumbers}
              flexiPercentage={flexiPercentage}
              subTitle={subTitle}
            />
          ) : (
            <TransactionEventDetailsDesktop
              key={`${outcome.marketId}_${outcome.outcomeName}`}
              transaction={transaction}
              outcome={outcome}
              marketName={marketName}
              eventName={eventName}
              eventStartTimeDate={eventStartTimeDate}
              raceNumbers={raceNumbers}
              flexiPercentage={flexiPercentage}
              subTitle={subTitle}
            />
          );
        })}
      </Wrapper>
    </div>
  );
};

export default TransactionEventDetails;
