import React from 'react';
import { get } from 'lodash';
import cx from 'classnames';
import { EventLink } from '../../../shared/components/Sport/SportLinks';
import ParticipantLogo from '../../../shared/components/Logo/ParticipantLogo';

const EMPTY_EVENT_PARTICIPANT = {
  fullName: '',
  id: '',
};

const EventListItem = ({ event, active }) => {
  const homeParticipant = get(event, 'participants.home', EMPTY_EVENT_PARTICIPANT);
  const homeScore = get(event, 'info.score.homeScore');

  const awayParticipant = get(event, 'participants.away', EMPTY_EVENT_PARTICIPANT);
  const awayScore = get(event, 'info.score.awayScore');

  const className = cx('teams', { active });

  return (
    <EventLink className={className} event={event}>
      <div className='teams'>
        <div className='teams__left'>
          <span className='text'>{homeParticipant.fullName}</span>
          <ParticipantLogo participant={homeParticipant} />
          <span className='score'>{homeScore}</span>
        </div>

        <div className='line'>
          <span>-</span>
        </div>
        <div className='teams__right'>
          <span className='score'>{awayScore}</span>
          <ParticipantLogo participant={awayParticipant} />
          <span className='text'>{awayParticipant.fullName}</span>
        </div>
      </div>
    </EventLink>
  );
};

export default EventListItem;
