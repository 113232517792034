import React from 'react';
import { SiteConfigManager } from '@apollo/core';

import {
  CLIENT_PROFILE_COMPONENT_TYPES,
  clientProfileRoutes,
  COMPONENT_TYPES,
  responsibleGamingAvailableRoutes,
} from '@apollo/routing';
import Label from '../../../shared/components/I18n/Label';
import ResponsibleGamingRouter from './ResponsibleGamingRouter';
import ClientProfileLinks from '../ClientProfileLinks';

const { CLIENT_PROFILE } = COMPONENT_TYPES;
const { RESPONSIBLE_GAMING } = CLIENT_PROFILE_COMPONENT_TYPES;
const { responsibleGaming } = clientProfileRoutes;

const ResponsibleGaming = () => {
  const { tabs = [] } = SiteConfigManager.getSubComponentOptions(
    CLIENT_PROFILE,
    RESPONSIBLE_GAMING
  );

  return (
    <div className='main__page__account__block'>
      <div className='main__page__account__block__title'>
        <div className='name'>
          <span>
            <Label message='responsible_gaming' />
          </span>
        </div>

        <ClientProfileLinks
          tabs={tabs}
          availableRoutes={responsibleGamingAvailableRoutes}
          parentRoute={responsibleGaming}
        />
      </div>

      <ResponsibleGamingRouter tabs={tabs} />
    </div>
  );
};

export default ResponsibleGaming;
