import React, { useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { useApplicationState } from '@apollo/core';
import Dropdown from '../../../../shared/components/Dropdown/Dropdown';
import { AnimatedDropdown } from '../../../Animations/AnimatedDropdown';

const TournamentHeader = ({ filterItems = [], activeItemId, hide }) => {
  const { layout } = useApplicationState();
  const [isExpanded, setExpanded] = useState(false);
  const toggleRef = useRef(null);

  const activeItem = useMemo(
    () => filterItems.find((i) => i.id === activeItemId),
    [filterItems, activeItemId],
  );

  const handleDropdownItemClick = (item) => {
    setExpanded(false);
    item.onClick(item.id);
  };

  if (hide) {
    return null;
  }

  return (
    <div className='tournament__header tournament__header--bet-types'>
      <Dropdown onExpanded={setExpanded} toggleRef={toggleRef} className='bet-types__selector'>
        <div ref={toggleRef} className='bet-types__selector__toggler'>
          {activeItem?.name}
        </div>
        <AnimatedDropdown isExpanded={isExpanded} className='bet-types__selector__dropdown'>
          {filterItems.map((item) => (
            <div key={item.id} onClick={() => handleDropdownItemClick(item)}>
              {item.name}
            </div>
          ))}
        </AnimatedDropdown>
      </Dropdown>
      <div className='tournament__header__market-row'>
        {filterItems.map(({ name, id, onClick }) => (
          <div
            className={cx(['sport-filter-option', activeItemId === id && 'active'])}
            key={id}
            onClick={() => onClick(id)}
          >
            {name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TournamentHeader;
