import React, { useCallback, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { indexOf, map, toNumber } from 'lodash';
import { constants, DateService, SiteConfigManager, useSettings } from '@apollo/core';
import BetDefaultView from './BetDefaultView';
import BetEditView from './BetEditView';
import Label from '../../../shared/components/I18n/Label';

const { BET_STATUSES, BET_INTERCEPT_STATUSES, REJECTION_REASON } = constants;

const betHeaderIcons = {
  [BET_STATUSES.PENDING_APPROVAL]: 'loading-spinner',
  [BET_STATUSES.UNSETTLED]: 'icon AkIcon-check-icon',
  [REJECTION_REASON.ODDS_REDUCED]: 'icon AkrIcon-warning',
  [BET_STATUSES.CANCELLED]: 'icon AkIcon-close-icon',
  [BET_STATUSES.REJECTED]: 'icon AkIcon-close-icon',
};

const betHeaderMessages = {
  [BET_STATUSES.UNSETTLED]: 'bet_place',
  [BET_INTERCEPT_STATUSES.PARTIALLY_APPROVED]: 'bet_place_partial',
  [REJECTION_REASON.ODDS_REDUCED]: 'betslip_error_outcome_odds_changed',
  [BET_STATUSES.PENDING_APPROVAL]: 'bet_processing',
  [BET_STATUSES.CANCELLED]: 'bet_not_accepted',
  [BET_STATUSES.REJECTED]: 'bet_not_accepted',
};

const interceptRejectionStatus = [BET_STATUSES.CANCELLED, BET_STATUSES.REJECTED];

const BetHeader = ({ betStatus, resolution, rejectionReason, removeReport }) => (
  <div className='itemList__header bets__block__title'>
    <span className={betHeaderIcons[rejectionReason] ?? betHeaderIcons[betStatus] ?? ''} />
    <Label
      message={
        betHeaderMessages[rejectionReason]
        ?? betHeaderMessages[resolution]
        ?? betHeaderMessages[betStatus]
        ?? 'bet_placed'
      }
    />
    {betStatus !== BET_STATUSES.PENDING_APPROVAL && (
      <div className='close' onClick={removeReport}>
        ×
      </div>
    )}
  </div>
);

const MyBetItem = ({ bet, replaceBet, removeReport, collapsedMulti = false }) => {
  const {
    betId,
    createTime,
    editBetAllowed,
    betStatus,
    originalBets,
    resolution,
    rejectionReason,
  } = bet;
  const [isEditMode, setEditMode] = React.useState(false);
  const { lang } = useSettings();

  const closeReportIn = toNumber(SiteConfigManager.getConfig('config.config.betslip.close_report_in')) || 30;

  useEffect(() => {
    if (
      interceptRejectionStatus.includes(betStatus)
      && rejectionReason !== REJECTION_REASON.ODDS_REDUCED
      && closeReportIn
      && removeReport
    ) {
      // hide rejected reports
      setTimeout(
        () => {
          removeReport(betId);
        },
        closeReportIn * 1000 - 1,
      );
    }
  }, [betStatus, rejectionReason, closeReportIn, removeReport, betId]);

  const infoIcons = useMemo(() => {
    const reasons = map(originalBets, ({ cashOutReason }) => cashOutReason);
    const icons = [];
    if (indexOf(reasons, 'PARTIAL_CASH_OUT') !== -1) {
      icons.push('AkIcon-cashout-icon');
    }

    if (indexOf(reasons, 'EDIT_BET') !== -1) {
      icons.push('AkIcon-edited-bet-icon');
    }
    return icons;
  }, [originalBets]);

  const date = DateService.getFormattedDateWithTime(createTime);
  const handleToggleEditMode = useCallback(() => setEditMode((editMode) => !editMode), []);

  // TODO: Create and wrap views in EditableView
  const BetView = React.useMemo(
    () => (isEditMode && editBetAllowed ? BetEditView : BetDefaultView),
    [isEditMode, editBetAllowed],
  );

  const betBlockClassName = cx([
    'bets__block bets__betslip',
    betStatus === BET_STATUSES.UNSETTLED && 'pending',
    betStatus === BET_STATUSES.WIN && 'win',
    betStatus === BET_STATUSES.LOSE && 'lose',
    betStatus === BET_STATUSES.PENDING_APPROVAL && 'process',
    interceptRejectionStatus.includes(betStatus)
      && rejectionReason === REJECTION_REASON.ODDS_REDUCED
      && 'changed',
    interceptRejectionStatus.includes(betStatus)
      && rejectionReason === REJECTION_REASON.ODDS_REDUCED
      && 'changed',
    interceptRejectionStatus.includes(betStatus)
      && rejectionReason !== REJECTION_REASON.ODDS_REDUCED
      && 'cancelled',
  ]);

  const handleRemoveReport = () => removeReport(betId);

  return (
    <div className={betBlockClassName}>
      <div className='bet-header-wrapper'>
        <BetHeader
          betStatus={betStatus}
          resolution={resolution}
          rejectionReason={rejectionReason}
          removeReport={handleRemoveReport}
        />

        <div className='bets__block__title'>
          {infoIcons.map((icon) => (
            <div className='betsIcon' key={icon}>
              <span className={icon} />
            </div>
          ))}
          <div className='name'>
            <Label message='id' />
          </div>
          <div className='number'>
            #
            {betId}
          </div>
          <div className='date'>{date}</div>
        </div>
      </div>

      <BetView
        bet={bet}
        replaceBet={replaceBet}
        setEditMode={setEditMode}
        editBetAllowed={editBetAllowed}
        toggleEditMode={handleToggleEditMode}
        originalBets={originalBets}
        collapsedMulti={collapsedMulti}
      />
    </div>
  );
};

export default MyBetItem;
