import React from 'react';
import { isEmpty, map } from 'lodash';
import { useRacingState } from '@apollo/core/src/state/racing/racing';
import RaceRunner from '../RaceRunner';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import Label from '../../../../shared/components/I18n/Label';
import Odds from '../../../../shared/components/Odds/Odds';

const availableFields = [
  {
    title: 'VIC',
    dataKey: 'vic',
    win: true,
    place: true,
  },
  {
    title: 'NSW',
    dataKey: 'nsw',
    win: true,
    place: true,
  },
  {
    title: 'QLD',
    dataKey: 'qld',
    win: true,
    place: true,
  },
  {
    title: 'SP',
    dataKey: 'spDiv',
  },
  {
    title: 'TF',
    dataKey: 'tfDiv',
  },
];

const RaceDividendTable = () => {
  const [racingState] = useRacingState();
  const { activeRace } = racingState;
  const runnersByNumber = React.useMemo(
    () => activeRace?.runners.reduce(
      (acc, runner) => ({
        ...acc,
        [runner.number]: runner,
      }),
      {},
    ),
    [activeRace],
  );
  const t = useTranslate();
  const topRunners = activeRace?.uiResults?.topRunners ?? [];

  const runners = topRunners?.map((runner) => ({
    ...runner,
    ...runnersByNumber?.[runner.runnerNumber],
  }));

  const returnValue = (r, mt) => {
    if (mt?.win && mt?.place) {
      let win = '';
      let place = '';
      if (r?.[`${mt?.dataKey}WinDiv`]) {
        win = r?.[`${mt?.dataKey}WinDiv`];
      }
      if (r?.[`${mt?.dataKey}PlcDiv`]) {
        place = r?.[`${mt?.dataKey}PlcDiv`];
      }
      let valueData = '';

      if (win && place) {
        valueData = (
          <>
            {' '}
            <div>
              <Odds value={win} />
            </div>
            <div>
              <Odds value={place} />
            </div>
          </>
        );
      }
      if (win && !place) {
        valueData = (
          <div>
            <Odds value={win} />
          </div>
        );
      }
      if (!win && place) {
        valueData = (
          <div>
            <Odds value={place} />
          </div>
        );
      }

      return (
        <div
          key={`${mt?.title}`}
          className='event__market-row-market event__market-row--race-dividend'
        >
          <div className='event__market-row--race-dividend-value'>{valueData}</div>
        </div>
      );
    }

    return (
      <div
        key={`${mt?.title}`}
        className='event__market-row-market event__market-row--race-dividend bold'
      >
        <div>{r?.[mt?.dataKey] ? <Odds value={r?.[mt?.dataKey]} /> : null}</div>
      </div>
    );
  };

  return (
    <>
      <div className='tournament__header'>
        <div className='event event--classic'>
          <div className='event__main-period'>
            <div className='event__info'>
              <div className='event-participants'>
                <div className='event-participants__participant'>
                  <Label message='Win Or Place' />
                </div>
              </div>
            </div>
            <div className='event__market-row'>
              {isEmpty(availableFields) === false
                && map(availableFields, (mt) => (
                  <div
                    key={`${mt?.title}`}
                    className='event__market-row-market event__market-row--race-dividend'
                  >
                    {t(mt?.title)}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <div className='event-group event-group--races'>
        {isEmpty(runners) === false
          && map(runners, (r) => (
            <div key={r.runnerId} className='event event--classic'>
              <div className='event__main-period'>
                <RaceRunner runner={r} activeRace={activeRace} showRunnerImage />
                <div className='event__market-row'>
                  {!r.scratched && isEmpty(availableFields) === false
                    ? map(availableFields, (mt) => returnValue(r, mt))
                    : null}
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};
export default RaceDividendTable;
