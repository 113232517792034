import React, { useCallback, useState } from 'react';
import printJS from 'print-js';
import {
  NotificationManager,
} from '@apollo/core';
import cx from 'classnames';
import _ from 'lodash';
import useTranslate from '../components/I18n/Interpreter';
import BasePopup from './BasePopup';
import Label from '../components/I18n/Label';
import FormCom from '../components/Form/FormCom';
import { sendUserInfoToClientForm } from '../components/Form/formConfig/auth';

const OneClickSignUp = (props) => {
  const [id, data, onClose = _.noop] = _.at(props, ['id', 'data', 'data.onClose']);
  const t = useTranslate();

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = useCallback(() => {
    setIsExpanded((isExpanded) => !isExpanded);
  }, [setIsExpanded]);

  const handleContinueButtonClick = useCallback(
    () => {
      NotificationManager.removeNotification(id);
      onClose();
    },
    [id, onClose],
  );

  const downloadTxt = useCallback((e) => {
    e.preventDefault();
    const text = `${t('Id')}: ${data.clientId}\n${t('Login')}: ${data.login}\n${t('Password')}: ${data.password}`;
    const element = document.createElement('a');
    element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
    element.setAttribute('download', 'Credentials.txt');

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();

    document.body.removeChild(element);
  }, [data]);

  const copy = useCallback((e) => {
    e.preventDefault();
    const text = `${t('Id')}: ${data.clientId}\n${t('Login')}: ${data.login}\n${t('Password')}: ${data.password}`;
    const inp = document.createElement('textarea');
    document.body.appendChild(inp);
    inp.value = text;
    inp.select();
    document.execCommand('copy', false);
    document.body.removeChild(inp);
  }, [data]);

  const print = useCallback((e) => {
    e.preventDefault();
    printJS({
      printable: [data],
      properties: [
        {
          field: 'clientId',
          displayName: `${t('Id')}`,
        },
        {
          field: 'login',
          displayName: `${t('Login')}`,
        },
        {
          field: 'password',
          displayName: `${t('Password')}`,
        },
      ],
      type: 'json',
    });
  }, [data]);

  const sendToEmail = useCallback(() => {
    toggleExpand();
  });

  const buttonClassName = cx(
    'bBase',
    { active: isExpanded },
  );
  return (
    <BasePopup
      onClose={handleContinueButtonClick}
      title='Registration was successful'
      message='signup_report'
      icon='success'
    >
      <div>
        <div className='user-info-container'>
          <div>
            <span className='title'>{t('Id')}</span>
            <span className='sub-title'>{data.clientId}</span>
          </div>
          <div>
            <span className='title'>{t('Login')}</span>
            <span className='sub-title'>{data.login}</span>
          </div>
          <div>
            <span className='title'>{t('Password')}</span>
            <span className='sub-title'>{data.password}</span>
          </div>
        </div>

        <div className='action-buttons-group'>
          <div className='bBasePrimary' onClick={copy} title={t('Copy')}>
            <span className='AkIcon-copy-icon' />
            <span>{t('Copy')}</span>
          </div>

          <div className='bBasePrimary' onClick={downloadTxt} title={t('Download')}>
            <span className='AkIcon-download-icon' />
            <span>{t('Download')}</span>
          </div>

          <div className='bBasePrimary' onClick={print} title={t('Print')}>
            <span className='AkIcon-print-icon' />
            <span>{t('Print')}</span>
          </div>

          {/* <div className={buttonClassName} onClick={sendToEmail}> */}
          {/*  <span className='AkIcon-send-to-mail-icon' /> */}
          {/* </div> */}
        </div>

        <div>
          <Label message='fill_in_profile_data' />
        </div>

        {
          isExpanded && (
            <FormCom
              form={sendUserInfoToClientForm}
              buttonText='Send'
              className='input-with-button-form'
            />
          )
        }
      </div>
    </BasePopup>
  );
};

export default OneClickSignUp;
