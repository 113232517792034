import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import _ from 'lodash';
import { CompetitionApi, SiteConfigManager } from '@apollo/core';
import { COMPONENT_TYPES } from '@apollo/routing';
import HeaderTitle from '../../shared/components/HeaderTitle/HeaderTitle';
import CompetitionCard from './CompetitionCard';
import CompetitionDetails from './CompetitionDetails';

const { PROMOTION_TOURNAMENTS } = COMPONENT_TYPES;

const Competition = () => {
  const { id } = useParams();
  const { push: historyPush } = useHistory();
  const competitionPath = SiteConfigManager.getComponentPath(PROMOTION_TOURNAMENTS);

  const [competitions, setCompetitions] = useState([]);
  const [activeCompetition, setActiveCompetition] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const { competitions } = await CompetitionApi.fetchCompetitions();
      setCompetitions(competitions);

      if (!_.isEmpty(competitions)) {
        if (id) {
          const competition = competitions.find((item) => Number(item.id) === Number(id));

          if (competition) {
            setActiveCompetition(competition);
          } else {
            setActiveCompetition(competitions[0]);
          }
        } else {
          const activeId = _.get(competitions, '[0].id');
          historyPush(`${competitionPath}/${activeId}`);
          setActiveCompetition(competitions[0]);
        }
      }
    };

    fetch();
  }, [competitionPath]);

  return (
    <div className='tournaments tournaments_modal'>
      <div className='tournaments__container'>
        <div className='tournaments__info'>
          <div className='tournaments-box'>
            <HeaderTitle size='xs' title='tournaments' translated />
          </div>
          <div className='tournaments-promotions'>
            <div className='tournaments-promotions__list'>
              {
                _.map(competitions, (competition, index) => (
                  <Link to={`${competitionPath}/${competition.id}`} key={competition.id} className='tournaments-promotions-card'>
                    <div className='tournaments-promotions-card__wrapper'>
                      <CompetitionCard
                        competition={competition}
                        index={index}
                        activeCompetition={activeCompetition}
                        setActiveCompetition={setActiveCompetition}
                      />
                    </div>
                  </Link>
                ))
              }
            </div>
          </div>
        </div>

        {activeCompetition && <CompetitionDetails competition={activeCompetition} />}
      </div>
    </div>
  );
};

export default Competition;
