import React from 'react';

const LangItem = ({ code, label, onClick }) => (
  <a onClick={() => onClick(code)}>
    <span className={`lang-flag flag--${code}`} />
    <span>{label}</span>
  </a>
);

export default LangItem;
