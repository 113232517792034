import React, { useRef } from 'react';

import { BetTypeProvider, constants, useBetslipStateUpdate } from '@apollo/core';
import Label from '../../../shared/components/I18n/Label';

// TODO: remove this component
const BetTypePickerContainer = (props) => {
  const { className, betTypes, selectedBet, isSingleBets, confirming } = props;

  // React.useEffect(() => () => setSelectedBetTypeIndex(0), []);

  const finalOddsSection = React.useMemo(() => {
    if (!selectedBet || selectedBet.betType === constants.BET_TYPE.SYSTEM) {
      return null;
    }

    // TODO: unify selectedBet
    /* const odds = selectedBet.betType === constants.BET_TYPE.SINGLE
      ? selectedBet.outcomes[0].odds
      : selectedBet.odds; */

    return (
      <div className='rightSide'>
        {/* <Label className='oddName' message='odds' />
        <span className='oddAmount'>
          <Odds value={odds} />
        </span> */}
      </div>
    );
  }, [selectedBet]);

  if (!selectedBet) {
    return null;
  }

  return (
    <div className={className}>
      <div>
        <BetTypeDropdownPicker
          selectedBetType={selectedBet}
          betTypes={betTypes}
          confirming={confirming}
        />
      </div>
      {!isSingleBets && finalOddsSection}
    </div>
  );
};

const BetTypeDropdownPicker = (props) => {
  const { selectedBetType, betTypes, confirming } = props;

  const betslipStateUpdate = useBetslipStateUpdate();

  const [isExpanded, setExpanded] = React.useState(false);
  const toggleRef = useRef(null);

  // Display selected bet type
  const selectedBetTypeComponent = React.useMemo(() => {
    if (selectedBetType.betType === constants.BET_TYPE.SYSTEM) {
      return (
        <div ref={toggleRef} className='toggleBlock__title'>
          <Label message='bet_type_system' />
          <span>{` ${selectedBetType.winCombinationSize}/${selectedBetType.outcomeAmount}`}</span>
        </div>
      );
    }

    return (
      <div ref={toggleRef} className='toggleBlock__title'>
        <Label message={`bet_type_${selectedBetType.betType || 'single'}`} />
      </div>
    );
  }, [selectedBetType]);

  const handleBetTypeSelect = React.useCallback(
    (betIndex) => {
      betslipStateUpdate({
        selectedBetType: betTypes[betIndex].betType || constants.BET_TYPE.SINGLE,
      });

      setExpanded(false);
    },
    [betslipStateUpdate, setExpanded, betTypes]
  );

  return (
    <BetTypeProvider betType={selectedBetType} key={selectedBetType.id}>
      {/* {!confirming
        && (betTypes && betTypes.length > 1 ? (
          <Dropdown
            onExpanded={setExpanded}
            toggleRef={toggleRef}
            className='toggleBlock'
          >
            {selectedBetTypeComponent}
            {isExpanded && (
              <BetTypeDropdownList
                betTypes={betTypes}
                onSelect={handleBetTypeSelect}
              />
            )}
          </Dropdown>
        ) : (
          <div className='toggleBlock--empty'>
            <Label message='bet_type_single' />
          </div>
        ))} */}
    </BetTypeProvider>
  );
};

const BetTypeDropdownList = (props) => {
  const { betTypes, onSelect } = props;

  const DropdownList = React.useMemo(
    () =>
      betTypes.map((bet, betIndex) => {
        const handleBetTypeSelect = () => onSelect(betIndex);

        return (
          <div onClick={handleBetTypeSelect} key={bet.id}>
            <Label message={`bet_type_${bet.betType}`} />
            {bet.betType === constants.BET_TYPE.SYSTEM && (
              <span>{`${bet.winCombinationSize}/${bet.outcomeAmount}`}</span>
            )}
          </div>
        );
      }),
    [betTypes, onSelect]
  );

  return <div className='toggleBlock__dropdown'>{DropdownList}</div>;
};

export default BetTypePickerContainer;
