import React from 'react';
import { map } from 'lodash';
import Label from '../../../../shared/components/I18n/Label';
import TransactionHistoryItem from './TableList/TransactionHistoryItem';

const TransactionHistoryDesktop = ({ transactions, cancelWithdrawalHandler, totalPages }) => (
  <>
    <div className='history-table-header'>
      <div className='history-table-row transaction'>
        <div className='history-table-cell'>
          <Label className='date' message='date' />
        </div>
        <div className='history-table-cell'>
          <span className='time'>
            <Label message='transaction_type' />
            <span>{' /'}</span>
            <Label message='id' />
          </span>
        </div>
        <div className='history-table-cell'>
          <Label className='time' message='event' />
        </div>
        <div className='history-table-cell'>
          <Label className='betId' message='odds' />
        </div>
        <div className='history-table-cell'>
          <Label className='betId' message='amount' />
        </div>
        <div className='history-table-cell'>
          <Label className='betId' message='running_balance' />
        </div>
        <div className='history-table-cell'>&nbsp;</div>
      </div>
    </div>
    <div className='history-table-body'>
      {map(transactions, (operation) => (
        <TransactionHistoryItem
          key={`${operation.id}_${operation.creationDate}`}
          operation={operation}
          onCancelWithdrawal={cancelWithdrawalHandler}
        />
      ))}
    </div>
  </>
);

export default TransactionHistoryDesktop;
