import React from 'react';
import { SiteConfigManager } from '@apollo/core';

import { COMPONENT_TYPES } from '@apollo/routing';
import { useLocation } from 'react-router-dom';

const { SPORT_LIVE } = COMPONENT_TYPES;

const useIsLivePage = () => {
  const livePath = SiteConfigManager.getComponentPath(SPORT_LIVE);
  const { pathname } = useLocation();

  return React.useMemo(() => {
    const pathComponents = pathname.split('/');

    return `/${pathComponents[1]}` === livePath;
  }, [pathname]);
};

export default useIsLivePage;
