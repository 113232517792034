import React, { useCallback, useMemo, useState } from 'react';
import { SiteConfigManager, useApplicationState, useSettings } from '@apollo/core';
import { isEmpty, map, uniq } from 'lodash';
import { RacingActions, useRacingState } from '@apollo/core/src/state/racing/racing';
import { STATUS } from '@apollo/core/src/constants';
import Label from '../../../../shared/components/I18n/Label';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import { MARKET_PLACES, RACE_STATUS } from '../../../../core/constants';
import Odds from '../../../../shared/components/Odds/Odds';
import ExoticOutcome from './ExoticOutcome';
import ToggleCheckbox from '../../../../shared/components/ToggleCheckbox/ToggleCheckbox';
import Selections from './Selections';
import { getSortedRunners } from '../../../../core/utils';
import RaceFlucs from '../../raceFlucs/RaceFlucs';
import RaceFlucsHeader from '../../raceFlucs/RaceFlucsHeader';
import RaceRunnerRow from '../RaceRunnerRow';

const ExoticMarketPage = ({
  runners,
  marketData = { outcomes: [] },
  raceStatic,
  marketType,
  displaySettings = {},
}) => {
  const enableExotics = SiteConfigManager.getConfig('config.config.racing.enableExotics');
  const { debugMode } = useSettings();
  const { isFlucs, isSpeedMap, isForm } = displaySettings;

  const t = useTranslate();
  const [isBoxed, setIsBoxed] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [racingState, racingDispatcher] = useRacingState();
  const { layout } = useApplicationState();
  const { activeRace, outcomesByRunnerId } = racingState;

  const isDisabled = useMemo(
    () => ![STATUS.ACTIVE, RACE_STATUS.OPEN].includes(activeRace.status),
    [activeRace],
  );

  const setSelection = useCallback(
    (selection = []) => {
      const payload = {};
      payload[`${marketType?.toLowerCase()}Selections`] = selection;

      racingDispatcher({
        type: RacingActions.RACING_STATE_UPDATE,
        payload,
      });
    },
    [racingDispatcher, marketType],
  );

  const toggleIsBoxed = () => {
    // setSelection();
    const selection = racingState[`${marketType?.toLowerCase()}Selections`] || [];
    if (!isBoxed) {
      // will be boxed soon :)
      setSelection(
        // find all selected runners and select the same runners for boxed
        uniq(selection.map((s) => s.runner))?.map((r) => ({
          runner: r,
          place: 1,
        })),
      );
    } else {
      // will be not boxed
      const res = [];
      uniq(selection.map((s) => s.runner))?.forEach((r) => {
        MARKET_PLACES[marketType]?.forEach((p) => {
          res.push({ runner: r, place: p });
        });
      });

      setSelection(res);
    }
    setIsBoxed(!isBoxed);
  };

  const toggleIsFixed = () => {
    setIsFixed(!isFixed);
  };

  const [sortByField, setSortByField] = useState({
    field: 'runner',
    asc: true,
  });

  const onSort = (field) => {
    setSortByField({
      field,
      asc: sortByField.field === field ? !sortByField.asc : true,
    });
  };

  const sortedRunners = useMemo(() => {
    const sortedRunners = getSortedRunners(
      runners,
      outcomesByRunnerId,
      sortByField.field,
      sortByField.asc,
    );
    return sortedRunners.sort((a, b) => a.scratched - b.scratched);
  }, [runners, sortByField, outcomesByRunnerId]);

  if (enableExotics === false) {
    return null;
  }

  return (
    <div className='exotic_race_wrapper'>
      <div className='tournament tournament--classic racelist'>
        <div className='tournament__header'>
          <div className='event event--classic'>
            <div className='event__main-period'>
              <div className='event__info'>
                <div className='event-participants'>
                  <div
                    className={`event-participants__participant sorting ${
                      sortByField.field === 'runner' && (sortByField.asc ? 'asc' : 'desc')
                    }`}
                    onClick={() => onSort('runner')}
                  >
                    <Label message='Runner' />
                  </div>
                </div>
                <div className='event-participants event-participants__control'>
                  <div className='toggle-boxed'>
                    <Label message='Boxed' />
                    <ToggleCheckbox
                      className='event__outcome'
                      disabled={isDisabled}
                      checked={isBoxed}
                      onChangedHandler={() => {
                        toggleIsBoxed();
                      }}
                    />
                  </div>

                  {debugMode && (
                    <div className='toggle-fixed'>
                      <Label message='fixed' />
                      <ToggleCheckbox
                        checked={isFixed}
                        onChangedHandler={() => {
                          toggleIsFixed();
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              {!isEmpty(activeRace?.fwinOutcomesWithOddsHistories) ? <RaceFlucsHeader /> : null}
              <div
                className={`event__market-row
                ${layout.mobileDevice ? 'mobile__values' : 'desktop__values'}
                ${isBoxed ? 'boxed' : ''}`}
              >
                {!isEmpty(runners) && marketData && !_.isEmpty(marketData?.outcomes) && (
                  <div className='exotic-field-header'>
                    <div className='odds-with-selected-label'>
                      <div className='selected-field-label event__market-row-market event__market-row-market-exotic'>
                        <span className='selected-field-label'>{t('#exotics_select_field')}</span>
                      </div>
                      <div className='event__market-row-market__outcome-container'>
                        {MARKET_PLACES[marketType]?.map((place, i) => (
                          <div
                            key={marketType + place}
                            className={`event__market-row-market event__market-row-market--checkbox ${
                              isBoxed ? 'event__market-row-market--boxed' : ''
                            }`}
                          >
                            <ExoticOutcome
                              race={activeRace}
                              place={place}
                              disabledBoxed={isBoxed && i > 0}
                              runners={runners}
                              marketType={marketType}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <span
                      className={`sorting ${
                        sortByField.field === 'F_WIN' && (sortByField.asc ? 'asc' : 'desc')
                      }`}
                      onClick={() => onSort('F_WIN')}
                    >
                      {isFixed ? t('exotic_fixed') : t('exotic_win')}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='tournament__header sub-header' style={{}}>
          <div className='runner event-participants'>
            <div
              className={`event-participants__participant sorting ${
                sortByField.field === 'runner' && (sortByField.asc ? 'asc' : 'desc')
              }`}
              onClick={() => onSort('runner')}
            >
              <Label message='Runner' />
            </div>
          </div>
          <div className='event__market-row-market event__market-row-market-exotic'>
            {isFixed ? t('exotic_fixed') : t('exotic_win')}
          </div>
        </div>

        <div className='event-group event-group--races'>
          {marketData
            && !isEmpty(sortedRunners)
            && map(sortedRunners, (r) => (
              <RaceRunnerRow
                key={r.runnerId}
                runner={r}
                runnerStatic={raceStatic?.runners?.find((runner) => runner.runnerId === r.runnerId)}
                activeRace={activeRace}
                isFlucs={isFlucs}
                isForm={isForm}
                isSpeedMap={isSpeedMap}
              >
                <RaceFlucs activeRace={activeRace} runner={r} />
                <div className={`event__market-row ${isBoxed ? 'boxed' : ''}`}>
                  <div className='event__market-row-market-container'>
                    <div className='event__market-row-market event__market-row-market-exotic'>
                      <div className='exotic__row_wrapper'>
                        <div className='exotic__row_values'>
                          <div key={`${r.runnerId}`} className='exotic__row_odds'>
                            {!r.scratched && outcomesByRunnerId?.[r.runnerId]?.F_WIN != null && (
                              <span className='odds'>
                                <Odds
                                  value={outcomesByRunnerId?.[r.runnerId]?.F_WIN?.odds}
                                  decimal={2}
                                  minDecimal={2}
                                />
                              </span>
                            )}
                            {/* {activeRace.status === RACE_STATUS.OPEN */}
                            {/* && favOddRunner === r.runnerId && ( */}
                            {/*  <Label message='fav' className='favorite' /> */}
                            {/*  )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='event__market-row-market__outcome-container '>
                      {!r.scratched
                        && outcomesByRunnerId?.[r.runnerId]?.F_WIN != null
                        && MARKET_PLACES[marketType]?.map((place, i) => (
                          <div
                            key={marketType + place}
                            className={`event__market-row-market event__market-row-market--checkbox ${
                              isBoxed ? 'event__market-row-market--boxed' : ''
                            }`}
                          >
                            <ExoticOutcome
                              race={activeRace}
                              runner={r}
                              disabledBoxed={isBoxed && i > 0}
                              place={place}
                              marketType={marketType}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </RaceRunnerRow>
            ))}
        </div>
        {/* <div className='tournament__header tournament__header--last'>
          <div className='event event--classic'>
            <div className='event__main-period'>
              <div className='event__info' />
              <div className='selected-field-label'>
                {t('#exotics_select_field')}
              </div>
              <div
                className={`event__market-row
                ${layout.mobileDevice ? 'mobile__values' : 'desktop__values'}
                ${isBoxed ? 'boxed' : ''}`}
              >
                {!isEmpty(runners) && marketData && (
                  <>
                    {MARKET_PLACES[marketType]?.map((place, i) => (
                      <div
                        key={marketType + place}
                        className={`event__market-row-market event__market-row-market--checkbox ${
                          isBoxed ? 'event__market-row-market--boxed' : ''
                        }`}
                      >
                        <ExoticOutcome
                          race={activeRace}
                          place={place}
                          disabledBoxed={isBoxed && i > 0}
                          runners={runners}
                          marketType={marketType}
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div> */}
        {marketData && !isEmpty(sortedRunners) ? <Selections marketType={marketType} /> : null}
      </div>
    </div>
  );
};

export default ExoticMarketPage;
