import React from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import Label from '../../shared/components/I18n/Label';

export default function BetslipSignInButton() {
  const {
    location,
    push,
  } = useHistory();

  const handleSignin = React.useMemo(
    () => {
      const updatedQueryString = qs.stringify({
        cmd: 'signin',
      });

      const pathname = `${location.pathname}?${updatedQueryString}`;

      return function onClickHandler(e) {
        e.preventDefault();
        push(pathname);
      };
    },
    [location.pathname, push],
  );

  return (
    <button type='button' className='buttonBet' onClick={handleSignin}>
      <Label message='betslip_place_auth_required' />
    </button>
  );
}
