import React, { useMemo } from 'react';
import _ from 'lodash';
import Label from '../../../../shared/components/I18n/Label';

const EventLiveStatus = ({ event, showLiveStatus }) => {
  const {
    info: {
      score: { liveStatus, matchClock },
    },
  } = event;

  const time = useMemo(() => {
    const numberMatchClock = _.toNumber(matchClock);
    if (!_.isFinite(numberMatchClock) || numberMatchClock < 0) {
      return '';
    }

    return `${(numberMatchClock / 60).toFixed(0)}"`;
  }, [matchClock]);

  return (
    <div className='event-live-status'>
      {showLiveStatus && <Label className='event-live-label live' message='live' />}
      <span className='event-status-period period'>{liveStatus}</span>
      <span className='event-live-time time'>{time}</span>
    </div>
  );
};

export default EventLiveStatus;
