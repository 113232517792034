import React, { useEffect, useMemo } from 'react';
import qs from 'query-string';
import { Bus, SiteConfigManager, useSettings } from '@apollo/core';
import { SportsActions, SportSelector, useSportState } from '@apollo/core/src/state/sport/sport';
import { useLocation, useParams } from 'react-router-dom';
import { isUrlNamesMatch } from '@apollo/routing/src/utils';
import { Helmet } from 'react-helmet-async';
import { COMPONENT_TYPES } from '@apollo/routing';
import { appEvents } from '../../core/constants';
import { LocalConfig } from '../../core/utils';
import useTranslate from '../../shared/components/I18n/Interpreter';

const SportsPathHandler = (props) => {
  const { children } = props;

  const { rootDomain } = useSettings();
  const [sportsState, sportsDispatcher] = useSportState();
  const {
    selectedSportUrlName,
    selectedCategoryUrlName,
    selectedTournamentUrlName,
    selectedEventUrlName,
    selectedSportId,
    selectedCategoryId,
    selectedTournamentId,
    selectedEventId,
  } = sportsState;
  const t = useTranslate();
  const { pathname } = useLocation();
  const routeParams = useParams();
  const { sportName, categoryName, tournamentName, eventName } = routeParams;

  const sports = SportSelector.selectSports();
  const categories = SportSelector.selectCategoriesBySportId(selectedSportId);
  const tournaments = SportSelector.selectTournamentsByCategoryId(selectedCategoryId);
  const events = SportSelector.selectEventsByTournamentId(selectedTournamentId);
  const currentEvent = SportSelector.selectEventById(selectedEventId);

  const { search } = useLocation();
  const selectedEventIdParam = useMemo(() => {
    const params = qs.parse(search);
    return params.e;
  }, [search]);

  useEffect(() => {
    let sportId = selectedSportId;
    if (!sportId || sportName !== selectedSportUrlName) {
      if (sportName) {
        const sport = sports.find((s) => isUrlNamesMatch(s.urlName, sportName));
        sportId = sport ? sport.id : null;
      }
    }

    let categoryId = selectedCategoryId;
    if (!categoryId || categoryName !== selectedCategoryUrlName) {
      if (categoryName) {
        const category = categories.find((c) => isUrlNamesMatch(c.urlName, categoryName));
        categoryId = category ? category.id : null;
      }
    }

    let tournamentId = selectedTournamentId;
    if (!tournamentId || tournamentName !== selectedTournamentUrlName) {
      if (tournamentName) {
        const tournament = tournaments.find((t) => isUrlNamesMatch(t.urlName, tournamentName));
        tournamentId = tournament ? tournament.id : null;
      }
    }

    // let eventId = selectedEventId;
    // if (!eventId || eventName !== selectedEventUrlName) {
    //   if (eventName) {
    //     const event = events.find((e) => isUrlNamesMatch(e.urlName, eventName));
    //     eventId = event ? event.id : null;
    //   }
    // }

    sportsDispatcher({
      type: SportsActions.SPORT_STATE_UPDATE,
      payload: {
        selectedSportId: sportId,
        selectedCategoryId: categoryId,
        selectedTournamentId: tournamentId,
        selectedEventId: selectedEventIdParam,
      },
    });
  }, [
    selectedSportUrlName,
    selectedCategoryUrlName,
    selectedTournamentUrlName,
    selectedEventUrlName,
    selectedEventIdParam,
    sports,
    categories,
    tournaments,
    events,
  ]);

  useEffect(() => {
    Bus.emit(appEvents.resetScroll);

    sportsDispatcher({
      type: SportsActions.SPORT_STATE_UPDATE,
      payload: {
        selectedSportId: null,
        selectedCategoryId: null,
        selectedTournamentId: null,
        selectedEventId: selectedEventIdParam,
        selectedSportUrlName: encodeURIComponent(sportName || '') || null,
        selectedCategoryUrlName: encodeURIComponent(categoryName || '') || null,
        selectedTournamentUrlName: encodeURIComponent(tournamentName || '') || null,
        selectedEventUrlName: encodeURIComponent(eventName || '') || null,
      },
    });
  }, [sportName, categoryName, tournamentName, eventName, selectedEventIdParam]);

  const metaTitle = useMemo(() => {
    const nameParts = [];
    if (eventName) {
      nameParts.push(decodeURIComponent(eventName));
    } else if (currentEvent?.urlName) {
      nameParts.push(decodeURIComponent(currentEvent.urlName));
    }
    if (tournamentName) {
      nameParts.push(decodeURIComponent(tournamentName));
    }
    if (categoryName) {
      nameParts.push(decodeURIComponent(categoryName));
    }
    if (sportName) {
      nameParts.push(decodeURIComponent(sportName));
    }
    return nameParts.join(', ');
  }, [sportName, categoryName, tournamentName, eventName, currentEvent]);

  const headMeta = useMemo(
    () => ({
      title: `${LocalConfig.get('public.title')} ${t('page_title')}`,
      description: `${metaTitle}. ${LocalConfig.get('public.description')}`,
      keywords: `${metaTitle}, ${LocalConfig.get('public.keywords')}`,
    }),
    [metaTitle],
  );

  const prematchPath = SiteConfigManager.getComponentPath(COMPONENT_TYPES.SPORT_PREMATCH);

  const baseEventCanonical = useMemo(() => {
    const parts = [`${rootDomain}${prematchPath}`];
    if (selectedSportUrlName) {
      parts.push(selectedSportUrlName);
    }
    if (selectedCategoryUrlName) {
      parts.push(selectedCategoryUrlName);
    }
    if (selectedTournamentUrlName) {
      parts.push(selectedTournamentUrlName);
    }
    return parts.join('/');
  }, [rootDomain, selectedCategoryUrlName, selectedSportUrlName, selectedTournamentUrlName]);

  const linkCanonical = useMemo(() => {
    if (currentEvent) {
      return `${baseEventCanonical}/${currentEvent.urlName}?e=${currentEvent.id}`;
    }
    // if (eventName && isEmpty(selectedEventIdParam)) {
    //   return baseEventCanonical;
    // }
    return baseEventCanonical;
  }, [currentEvent, eventName, selectedEventIdParam, baseEventCanonical]);

  return (
    <>
      <Helmet>
        <title>{`${metaTitle} Sport - ${headMeta.title}`}</title>
        <meta name='description' content={headMeta.description} />
        <meta name='keywords' content={headMeta.keywords} />
        {linkCanonical && <link rel='canonical' href={linkCanonical} />}
      </Helmet>
      {children}
    </>
  );
};
export default SportsPathHandler;
