import React, { useEffect } from 'react';
import _ from 'lodash';
import { constants, isVisible } from '@apollo/core';

const getOutrightMarkets = (event) => _.chain(event)
  .get('marketTypes')
  .orderBy('orderPosition')
  .map((marketType) => {
    const [marketTypeId, name, markets] = _.at(marketType, ['id', 'name', 'markets']);
    const market = _.chain(markets).values().get('[0]').value();

    return market ? { ...market, name, marketTypeId } : { status: constants.STATUS.HIDDEN };
  })
  .filter(isVisible)
  .value();
const getOutrightMarketOptions = (markets) => _.map(markets, (market) => ({
  text: market.name,
  value: market.id,
}));

const useOutrightMarket = (event) => {
  const { markets, marketOptions } = React.useMemo(() => {
    const markets = getOutrightMarkets(event);
    const marketOptions = getOutrightMarketOptions(markets);

    return {
      markets,
      marketOptions,
    };
  }, [event]);

  const [activeMarketId, setActiveMarketId] = React.useState(_.get(markets, '[0].id'));
  const activeMarket = React.useMemo(() => _.find(markets, { id: activeMarketId }), [markets, activeMarketId]);
  const setActiveMarketIdWithValidation = React.useCallback(
    (marketId) => {
      if (!_.find(markets, { id: marketId })) {
        return;
      }

      setActiveMarketId(marketId);
    },
    [markets],
  );

  useEffect(() => {
    if (!activeMarket && !_.isEmpty(markets)) {
      setActiveMarketId(_.get(markets, '[0].id'));
    }
  }, [activeMarket]);

  return {
    markets,
    activeMarket,
    setActiveMarketId: setActiveMarketIdWithValidation,
    marketOptions,
  };
};

export default useOutrightMarket;
