import React, { useEffect, useMemo, useState } from 'react';
import { useRacingState } from '@apollo/core/src/state/racing/racing';
import { isMultilegsMarket, SiteConfigManager, useApplicationState } from '@apollo/core';
import { AnimatePresence, motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import Loader from '../../../shared/components/Loader';
import { generateRaceLink } from '../../../core/utils';
import { getMarketLegsFromMarket } from './utils';

const SwipeableTab = ({ children }) => {
  const [racingState] = useRacingState();
  const { layout } = useApplicationState();

  const navigateOnSwipe = SiteConfigManager.getConfig('config.config.racing.navigateOnSwipe');

  const { activeRace, activeMeet, activeMarketTypeId, marketsByType } = racingState;
  const [currentTab, setCurrentTab] = useState(activeRace?.number || 1);

  useEffect(() => {
    setCurrentTab(activeRace?.number);
  }, [activeRace?.number]);

  const history = useHistory();
  const marketTypeName = activeMarketTypeId?.split(':')[0] || activeMarketTypeId;

  const multiLegs = useMemo(
    () => getMarketLegsFromMarket(marketsByType[activeMarketTypeId]),
    [activeMarketTypeId, marketsByType]
  );

  const handleSwipe = (nextRaceNumber) => {
    const toUrl = generateRaceLink({
      raceDate: activeMeet.date,
      raceType: activeMeet.raceType.typeName,
      raceCountry: activeMeet.venue.country,
      raceVenue: activeMeet.venue.venueName,
      raceNumber: nextRaceNumber,
      marketType:
        activeRace && isMultilegsMarket(marketTypeName) && multiLegs?.includes(nextRaceNumber)
          ? activeMarketTypeId
          : null,
    });

    history.push(toUrl);
  };

  const handleSwipeLeft = () => {
    if (!activeMeet?.races) {
      return;
    }
    const raceNumbers = (activeMeet.races || []).map((r) => r.number);
    const activeRaceNumberIndex = raceNumbers.indexOf(activeRace?.number);

    if (activeRaceNumberIndex < raceNumbers.length - 1) {
      const nextRaceNumber = raceNumbers[activeRaceNumberIndex + 1];
      setCurrentTab(nextRaceNumber);
      handleSwipe(nextRaceNumber);
    }
  };

  const handleSwipeRight = () => {
    if (!activeMeet?.races) {
      return;
    }
    const raceNumbers = (activeMeet.races || []).map((r) => r.number);
    const activeRaceNumberIndex = raceNumbers.indexOf(activeRace?.number);

    if (activeRaceNumberIndex > 0) {
      const nextRaceNumber = raceNumbers[activeRaceNumberIndex - 1];
      setCurrentTab(nextRaceNumber);
      handleSwipe(nextRaceNumber);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    preventScrollOnSwipe: true,
    trackTouch: true,
    trackMouse: true,
    delta: 100,
  });

  if (!navigateOnSwipe || !layout.mobileDevice) {
    return children;
  }

  return (
    <AnimatePresence initial={false} custom={activeRace?.number}>
      <motion.div
        key={activeRace?.number}
        custom={activeRace?.number}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={{
          left: 0.3,
          right: 0.3,
        }}
        drag='x'
        {...swipeHandlers}
      >
        {activeRace?.number === currentTab ? children : <Loader />}
      </motion.div>
    </AnimatePresence>
  );
};

export default SwipeableTab;
