import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Bus, useApplicationState } from '@apollo/core';
import { generateRaceLink, getSpecialDate } from '../../../core/utils';

const RaceLink = ({
  race = {},
  raceDate = race.meeting?.date,
  raceType = race.raceType?.typeName,
  raceCountry = race.venue?.country,
  raceVenue = race.venue?.venueName,
  raceNumber = race.number,
  marketTab,
  marketType,
  active,
  children,
  className,
  refProps,
}) => {
  const { layout } = useApplicationState();
  const _raceDate = useMemo(() => getSpecialDate(raceDate), [raceDate]);

  const toUrl = generateRaceLink({
    raceDate: _raceDate,
    raceType,
    raceCountry,
    raceVenue,
    raceNumber,
    marketTab,
    marketType,
  });

  const toRaceMain = generateRaceLink({
    raceDate: _raceDate,
    raceType,
  });

  const classes = cx(className, {
    active,
  });

  const onClickHandler = React.useCallback(() => {
    if (layout.desktopDevice === false) {
      Bus.send({
        event: Bus.events.layout.setSideBarRight,
        data: false,
      });
    }
  }, [layout.desktopDevice]);

  return (
    <Link
      to={active ? toRaceMain : toUrl}
      className={classes}
      onClick={onClickHandler}
      ref={refProps}
      draggable='false'
    >
      {children}
    </Link>
  );
};
export default RaceLink;
