import React, { useMemo } from 'react';
import cx from 'classnames';
import { shape, string, number } from 'prop-types';
import DOMPurify from 'dompurify';
import { buildCdnUrl } from '../../../core/utils';

const BannerSliderItem = React.memo(({ bannerData, index, activeIndex }) => {
  const hasMobileImage = useMemo(
    () => !!bannerData.mobile_image_url,
    [bannerData.mobile_image_url],
  );

  const className = cx('banner-slider__slide', {
    active: index === activeIndex,
    'has-mobile-img': hasMobileImage,
  });

  // const cleanHtml = { __html: DOMPurify.sanitize(bannerData.description, { KEEP_CONTENT: true }) };

  const imgElem = useMemo(
    () => (
      <>
        <img
          className='banner-slider__slide_img banner-slider__slide_img--desktop'
          src={buildCdnUrl(bannerData.image_url, 'LOGOS_CDN_URL')}
          alt={bannerData.title}
          loading='lazy'
        />
        {hasMobileImage && (
          <img
            className='banner-slider__slide_img banner-slider__slide_img--mobile'
            src={buildCdnUrl(bannerData.mobile_image_url, 'LOGOS_CDN_URL')}
            alt={bannerData.title}
            loading='lazy'
          />
        )}
      </>
    ),
    [bannerData],
  );

  if (bannerData.action_url) {
    return (
      <a
        className={className}
        href={bannerData.action_url}
        target={bannerData.action_url_target || '_self'}
      >
        {imgElem}
      </a>
    );
  }

  return <div className={className}>{imgElem}</div>;
});

BannerSliderItem.propTypes = {
  index: number.isRequired,
  activeIndex: number.isRequired,
  bannerData: shape({
    title: string.isRequired,
    image_url: string.isRequired,
    mobile_image_url: string,
    description: string.isRequired,
  }).isRequired,
};

export default BannerSliderItem;
