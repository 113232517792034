import classNames from 'classnames';
import React from 'react';
import { ordinalSuffixOf } from '../../../../core/utils';

export const RaceRunnerImage = ({ runner, showFinish = false, masked = false, className }) => {
  const { silkImageUrl, finishPosition, name } = runner;
  return silkImageUrl ? (
    <div className={`runnerImage runnerImage-wrapper ${className}`}>
      <div className={classNames('runnerImage', { 'runnerImage--masked': masked })}>
        <img
          src={silkImageUrl}
          className={classNames('event-participants__participant-logo logo race', {
            'AkrIcon-championship': !silkImageUrl,
            showFinishPosition: showFinish,
          })}
          alt={name}
        />
      </div>
      {showFinish ? (
        <span className='runnerFinishPosition'>{ordinalSuffixOf(finishPosition)}</span>
      ) : null}
    </div>
  ) : null;
};
