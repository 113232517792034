export const sendUserInfoToClientForm = {
  name: 'sendUserInfoToClient',
  fields: [
    {
      component: 'text',
      name: 'email',
      label: '',
      rules: [],
      placeholder: 'Your email address'
    },
  ],
};
