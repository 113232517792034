import React from 'react';
import { COMPONENT_TYPES, sportsbookRoutes } from '@apollo/routing';
import { SiteConfigManager } from '@apollo/core';
import { useRouteMatch } from 'react-router-dom';

const { favorites } = sportsbookRoutes;
const { SPORT_PREMATCH } = COMPONENT_TYPES;

const useIsFavoritePage = () => {
  const prematchPath = SiteConfigManager.getComponentPath(SPORT_PREMATCH);

  return useRouteMatch(`${prematchPath}/${favorites}`);
};

export default useIsFavoritePage;
