import React, { useMemo } from 'react';
import { TournamentProvider, usePreferences } from '@apollo/core';

import Tournament from '../Tournament/Tournament';

const LiveTournaments = ({ tournaments, mainMarketTypesByPeriod }) => {
  const { getFavoriteTournaments, preferences } = usePreferences();
  const sortedTournaments = useMemo(
    () => getFavoriteTournaments(tournaments),
    [tournaments, preferences]
  );

  return sortedTournaments.map((tournament) => {
    const { id } = tournament;

    return (
      <TournamentProvider tournament={tournament} key={id}>
        <Tournament mainMarketTypesByPeriod={mainMarketTypesByPeriod} />
      </TournamentProvider>
    );
  });
};

export default React.memo(LiveTournaments);
