import React from 'react';
import useCasinoHistory from './useCasinoHistory';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import CasinoHistoryFilter from './CasinoHistoryFilter';
import Svg from '../../../../shared/components/svg/Svg';
import CasinoHistoryTableList from './TableList/CasinoHistoryTableList';
import Pagination from '../../../../shared/components/Pagination';
import Loader from '../../../../shared/components/Loader';

const CasinoHistory = () => {
  const {
    operations,
    activeTimeFilter,
    currentPage,
    totalPages,
    setActiveTimeFilter,
    setCurrentPage,
    range,
    setRange,
  } = useCasinoHistory();
  const t = useTranslate();

  return (
    <>
      <CasinoHistoryFilter
        activeTimeFilter={activeTimeFilter}
        setActiveTimeFilter={setActiveTimeFilter}
        range={range}
        setRange={setRange}
      />
      {totalPages === 0 && operations.length === 0 ? (
        <div className='empty-state-history'>
          <div className='svg-container'>
            <Svg name='emptyState' />
          </div>
          <span>{t('There is no items yet')}</span>
        </div>
      ) : (
        <div className='history-table'>
          <div className='history-table-header'>
            <div className='history-table-row casino'>
              <div className='history-table-cell'>
                <span className='date'>{t('game')}</span>
              </div>
              <div className='history-table-cell'>
                <span className='date'>{t('name')}</span>
              </div>
              <div className='history-table-cell'>
                <span className='time'>{t('date_id')}</span>
              </div>
              <div className='history-table-cell'>
                <span className='betId'>{t('bet')}</span>
              </div>
              <div className='history-table-cell'>
                <span className='result'>{t('return')}</span>
              </div>
            </div>
          </div>
          <div className='history-table-body'>
            {!operations || operations.length === 0 ? (
              <Loader />
            ) : (
              <CasinoHistoryTableList items={operations} />
            )}
          </div>

          {operations.length > 0 && (
            <Pagination
              pages={totalPages}
              currentPage={currentPage}
              changeCurrentPage={setCurrentPage}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CasinoHistory;
