import React from 'react';
import Odds from '../../../shared/components/Odds';
import Label from '../../../shared/components/I18n/Label';

const RaceFlucs = ({ runner, activeRace, toShow = { open: true, fluc1: true, fluc2: true } }) => {
  const oddsHistories =
    activeRace?.fwinOutcomesWithOddsHistories &&
    activeRace?.fwinOutcomesWithOddsHistories[runner.number.toString()]?.oddsHistories;

  if (_.isEmpty(oddsHistories)) {
    if (
      runner.scratched &&
      !_.isEmpty(activeRace?.fwinOutcomesWithOddsHistories) &&
      Object.values(activeRace?.fwinOutcomesWithOddsHistories).some((item) => item.oddsHistories)
    ) {
      return <div className='event__flucs' />;
    }
    return null;
  }

  const fluc1 = oddsHistories.length > 1 && oddsHistories[oddsHistories.length - 1];
  const fluc2 = oddsHistories.length > 2 && oddsHistories[oddsHistories.length - 2];

  return (
    <div className='event__flucs'>
      {toShow.open ? (
        <div className='event__flucs-item event__flucs-item--open'>
          {oddsHistories[0].odds ? (
            <>
              <Label className='flucs-label' message='flucs open' />
              <div className='flucs-value'>
                <Odds
                  value={oddsHistories[0].odds}
                  minDecimal={oddsHistories[0].odds < 10 ? 2 : 0}
                />
              </div>
            </>
          ) : null}
        </div>
      ) : null}
      {toShow.fluc1 ? (
        <div className='event__flucs-item event__flucs-item--fluc1'>
          {fluc1.odds ? (
            <>
              <Label className='flucs-label' message='flucs 1' />
              <div className='flucs-value'>
                <Odds value={fluc1.odds} minDecimal={fluc1.odds < 10 ? 2 : 0} />
              </div>
            </>
          ) : null}
        </div>
      ) : null}
      {toShow.fluc2 ? (
        <div className='event__flucs-item event__flucs-item--fluc2'>
          {fluc2.odds ? (
            <>
              <Label className='flucs-label' message='flucs 2' />
              <div className='flucs-value'>
                <Odds value={fluc2.odds} minDecimal={fluc2.odds < 10 ? 2 : 0} />
              </div>
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default RaceFlucs;
