import React from 'react';
import UploadDocumentItem from './UploadDocumentItem';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import Label from '../../../../shared/components/I18n/Label';

export default function UploadDocumentList({ documents }) {
  const t = useTranslate();
  if (documents.length === 0) {
    return (
      <div className='verify not-file'>
        <Label message='identity_not_verified' />
      </div>
    );
  }

  return (
    <div className='verify'>
      {documents.map((doc, idx) => (
        <UploadDocumentItem document={doc} key={idx} />
      ))}
    </div>
  );
}
