import React from 'react';
import _ from 'lodash';

import { SiteConfigManager, useApplicationState } from '@apollo/core';

import { useHistory } from 'react-router-dom';
import ClientProfileRouter from './ClientProfileRouter';
import MainBody from '../../shared/components/MainBody/MainBody';
import KeyPad from '../Betslip/PlaceBetControls/KeyPad';
import Loader from '../../shared/components/Loader';

const MainPageClientProfile = () => {
  const applicationState = useApplicationState();
  const history = useHistory();
  const [authenticated, resolved, layout] = _.at(applicationState, [
    'authenticated',
    'resolved',
    'layout',
  ]);

  React.useEffect(() => {
    if (!resolved || authenticated) {
      return;
    }
    const redirectPath =
      SiteConfigManager.getExtraConfig('components.0.path') ||
      SiteConfigManager.getExtraConfig('components.0.defaultPath', '/');
    const redirectTo = `${redirectPath}?cmd=signin&redirect=true`;

    history.push(redirectTo);
  }, [resolved, authenticated]);

  if (!resolved) {
    return <Loader />;
  }

  return (
    <MainBody className='main__body--client-profile'>
      <div className='main__page__account'>
        <ClientProfileRouter />
        {layout.keyPadMoneyInput && layout.mobileDevice ? (
          <KeyPad
            key={layout.keyPadMoneyInput}
            inputParentSelector='.main__page__account__block'
            fixed
          />
        ) : null}
      </div>
    </MainBody>
  );
};
export default MainPageClientProfile;
