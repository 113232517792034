import { useMemo, useRef } from 'react';
import _ from 'lodash';
import { safeJSONparse } from '../../../core/utils';

const useCurrentParticipantTurn = ({ info }) => {
  const currentParticipantTurn = useMemo(() => {
    const rawExtraData = _.get(info, 'score.extraData');
    const extraData = safeJSONparse(rawExtraData);
    const turn = _.get(extraData, 'Turn');

    if (!turn || turn === '0') {
      return null;
    }

    return turn === '1' ? 'home' : 'away';
  }, [info]);

  const previousTurnRef = useRef(currentParticipantTurn);

  return useMemo(() => {
    const previousCurrentParticipantTurn = previousTurnRef.current;

    if (currentParticipantTurn && previousCurrentParticipantTurn !== currentParticipantTurn) {
      previousTurnRef.current = currentParticipantTurn;

      return currentParticipantTurn;
    }

    // If there are no currentParticipantTurn use previous currentParticipantTurn
    return previousCurrentParticipantTurn;
  }, [currentParticipantTurn]);
};

export default useCurrentParticipantTurn;
