import React from 'react';
import { SportsbookProvider, constants } from '@apollo/core';

import { useParams } from 'react-router-dom';

const LiveSportsbook = ({ children }) => {
  const routeParams = useParams();
  const { sportName, categoryName, tournamentName } = routeParams;

  const options = {
    id: 'live',
    sportService: constants.SPORT_SERVICE.LIVE,

    selectedSportUrlName: sportName,
    selectedCategoryUrlName: categoryName,
    selectedTournamentUrlName: tournamentName,
  };

  return <SportsbookProvider value={options}>{children}</SportsbookProvider>;
};
export default LiveSportsbook;
