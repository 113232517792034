import React from 'react';
import cx from 'classnames';
import { string, bool } from 'prop-types';
import useTranslate from '../I18n/Interpreter';

const HeaderTitle = ({
  modal, title, subtitle, size, children, translated,
}) => {
  const t = useTranslate();

  const headerTitleClassName = cx(
    'header-title',
    modal && 'header-title_modal',
    size && size,
  );

  return (
    <div className={headerTitleClassName}>
      <div className='header-title__wrapper'>
        {subtitle && <div className='header-title__subname'>{t(subtitle)}</div>}
        {title && translated && <div className='header-title__name'>{t(title)}</div>}
        {title && !translated && <div className='header-title__name'>{title}</div>}
      </div>
      {children}
    </div>
  );
};

HeaderTitle.propTypes = {
  modal: bool,
  translated: bool,
  title: string,
  subtitle: string,
  size: string,
};

HeaderTitle.defaultProps = {
  modal: false,
  translated: false,
  title: '',
  subtitle: '',
  size: 'xs',
};

export default HeaderTitle;
