import React, { useState } from 'react';
import Label from '../../../../shared/components/I18n/Label';
import RunnerForm from './RunnerForm';
import RecentRuns from './RecentRuns';

const TABS = {
  form: 'form',
  recentRuns: 'recentRuns',
};

const RunnerStatic = ({ runnerStatic }) => {
  const { staticInfo } = runnerStatic || {};
  const [activeTab, setActiveTab] = useState(TABS.form);

  if (!staticInfo) {
    return null;
  }

  return (
    <div className='runner-static'>
      <div className='runner-static__nav'>
        <div className={`runner-static__nav--item ${activeTab === TABS.form ? 'active' : ''}`} onClick={() => setActiveTab(TABS.form)}>
          <Label message='race form' />
        </div>

        <div className={`runner-static__nav--item ${activeTab === TABS.recentRuns ? 'active' : ''} `} onClick={() => setActiveTab(TABS.recentRuns)}>
          <Label message='recent runs' />
        </div>
      </div>
      <div className='runner-static__content'>
        {activeTab === TABS.form ? <RunnerForm staticInfo={staticInfo} /> : null }
        {activeTab === TABS.recentRuns ? <RecentRuns runs={staticInfo.participant?.runs} /> : null }
      </div>
    </div>
  );
};

export default RunnerStatic;
