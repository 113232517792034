import React, { useState } from 'react';
import _ from 'lodash';

import BetHistoryMultipleTypeSubRow from './BetHistoryMultipleTypeSubRow';
import useTranslate from '../../../../../shared/components/I18n/Interpreter';
import Label from '../../../../../shared/components/I18n/Label';
import HistoryTransactionList from '../../HistoryTransactionList';

const amountOfOutcomesToHide = 3;

const BetHistoryMultipleTypeSubRowList = ({
  outcomes, bet, originalBets,
}) => {
  const t = useTranslate();

  const [isExpanded, setIsExpanded] = useState(outcomes.length < amountOfOutcomesToHide);

  const outcomesToRender = isExpanded ? outcomes : _.slice(outcomes, 0, amountOfOutcomesToHide);

  return (
    <>
      <div className='history-table-row header-multiple'>
        <div className='bet-history-row'>
          <div className='history-table-cell'>
            <Label message='date' />
          </div>
          <div className='history-table-cell'>
            <Label message='event' />
          </div>
          <div className='history-table-cell'>
            <Label message='selection' />
          </div>
          {/* <div className='history-table-cell'>
            <Label message='final score' />
          </div> */}
          <div className='history-table-cell' />
          <div className='history-table-cell'>
            <Label message='odds' />
          </div>
          <div className='history-table-cell to-return' />
          <div className='history-table-cell'>
            <Label message='result' />
          </div>
        </div>
      </div>
      <div className='history-table-body-multiple'>
        {
          _.map(outcomesToRender, (outcome) => (
            <BetHistoryMultipleTypeSubRow
              key={outcome.outcomeId}
              outcome={outcome}
              bet={bet}
            />
          ))
        }
        {!isExpanded && outcomes.length > amountOfOutcomesToHide && (
          <div className='history-table-row load-more-events'>
            <span onClick={() => setIsExpanded(true)}>
              {`${outcomes.length - amountOfOutcomesToHide} `}
              {t('more_events')}
            </span>
          </div>
        )}
        <HistoryTransactionList originalBets={originalBets} />
      </div>
    </>

  );
};

export default BetHistoryMultipleTypeSubRowList;
