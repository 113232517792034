import React, { useCallback, useMemo } from 'react';
import { centsToDecimal, useApplicationState } from '@apollo/core';
import { at } from 'lodash';
import { BET_TYPE, BONUS_TYPE } from '@apollo/core/src/constants';
import Theme from 'themeSource/custom';
import useTranslate from '../../../../../shared/components/I18n/Interpreter';
import {
  checkForExotic,
  checkForMultilegs,
  transactionPropertiesParser,
} from '../../../../../core/utils';
import TransactionHistoryItemDesktop from './TransactionHistoryItemDesktop';
import TransactionHistoryItemMobile from './TransactionHistoryItemMobile';

const TransactionHistoryItem = ({ operation, onCancelWithdrawal, isMobile }) => {
  const applicationState = useApplicationState();
  const [region] = at(applicationState, ['account.region']);

  const ThemeTransactionHistoryItemMobile = Theme.TransactionHistoryItemMobile || TransactionHistoryItemMobile;

  const disableCancel = useMemo(() => !region || region.toUpperCase() === 'SA', [region]);

  const { id, cashBalanceAfter, properties } = operation;

  const t = useTranslate();

  const bet = useMemo(() => properties?.BET || {}, [properties]);
  const outcomes = useMemo(() => transactionPropertiesParser(properties), [properties]);

  const bonusInfoList = useMemo(() => {
    if (bet?.bonusInfoList?.length > 0) {
      return bet.bonusInfoList;
    }
    return null;
  }, [bet]);

  const bonusType = useMemo(() => bonusInfoList?.[0]?.bonusType || null, [bonusInfoList]);

  const originalBetOdds = useMemo(() => {
    if (bet.outcomes?.length > 1) {
      // has no boosted odds for multi bet
      return null;
    }
    if (bonusType !== BONUS_TYPE.ODDS_BOOST) {
      return null;
    }
    return bet.outcomes[0].odds;
  }, [bet, bonusType]);

  const decimalCashBalance = useMemo(() => centsToDecimal(cashBalanceAfter), [cashBalanceAfter]);

  const cancelWithdrawalHandler = useCallback(() => {
    onCancelWithdrawal(id);
  }, [id]);

  const isExoticOrMultilegs = useMemo(() => checkForExotic(bet) || checkForMultilegs(bet), [bet]);

  const flexiPercentage = React.useMemo(() => {
    if (isExoticOrMultilegs) {
      return `(${bet.outcomes[0].outcomeInfo.combos} ${t('Combos').replace(':', '')},
      ${Number.parseFloat(bet.betMoney.betUnitAmount).toFixed(
    3,
  )}${t('betslip_percentage')})`.toLowerCase();
    }
    return '';
  }, [bet, isExoticOrMultilegs, t]);

  const isBetTransaction = outcomes.length > 0;

  const betUpdatedOdds = useMemo(
    () => (bet?.betType === BET_TYPE.SINGLE
      && bonusInfoList?.some((bonus) => [BONUS_TYPE.ODDS_BOOST, BONUS_TYPE.PROMO_ODDS].includes(bonus.bonusType))
      ? { odds: bet?.betOdds }
      : bet?.updatedOdds?.find((o) => `${o.outcomeId}` === bet.outcomes[0]?.outcomeId)),
    [bet, bonusInfoList],
  );

  return isMobile ? (
    <ThemeTransactionHistoryItemMobile
      transaction={operation}
      outcomes={outcomes}
      oddsAmount={bet?.betOdds}
      bonusType={bonusType}
      bonusInfoList={bonusInfoList}
      originalBetOdds={originalBetOdds}
      decimalCashBalance={decimalCashBalance}
      flexiPercentage={flexiPercentage}
      isBetTransaction={isBetTransaction}
      disableCancel={disableCancel}
      cancelWithdrawalHandler={cancelWithdrawalHandler}
      betType={bet?.betType}
      betUpdatedOdds={betUpdatedOdds}
    />
  ) : (
    <TransactionHistoryItemDesktop
      transaction={operation}
      outcomes={outcomes}
      oddsAmount={bet?.betOdds}
      bonusType={bonusType}
      bonusInfoList={bonusInfoList}
      originalBetOdds={originalBetOdds}
      decimalCashBalance={decimalCashBalance}
      flexiPercentage={flexiPercentage}
      isBetTransaction={isBetTransaction}
      disableCancel={disableCancel}
      cancelWithdrawalHandler={cancelWithdrawalHandler}
      betType={bet?.betType}
      betUpdatedOdds={betUpdatedOdds}
    />
  );
};

export default TransactionHistoryItem;
