import React from 'react';
import { isEmpty } from 'lodash';

import { actions, useBetslipDispatch, useBetslipState } from '@apollo/core';
import Label from '../../shared/components/I18n/Label';

export default function BetsErrors() {
  const { errors } = useBetslipState();

  const betslipDispatch = useBetslipDispatch();

  return React.useMemo(() => {
    if (isEmpty(errors)) {
      return null;
    }

    const publishedErrorCodes = {};

    return Object.keys(errors).map((errorRequestId) => {
      const error = errors[errorRequestId];

      if (error?.violations?.every((v) => v.violationInfo?.outcomeId)) {
        // don't show errors with outcome id because it's already shown in betItem
        return null;
      }

      const handleErrorRemove = () =>
        betslipDispatch({
          type: actions.BETSLIP_CLEAR_ERROR,
          payload: error.requestId || 'global',
        });

      const publishErrorCode = (errorCode) => {
        publishedErrorCodes[errorCode] = errorCode;
      };

      if (isEmpty(error.violations)) {
        return (
          <InternalError key={error.code} error={error} handleErrorRemove={handleErrorRemove} />
        );
      }

      return (
        <ValidationError
          key={error.requestId}
          error={error}
          publishedErrorCodes={publishedErrorCodes}
          publishErrorCode={publishErrorCode}
          handleErrorRemove={handleErrorRemove}
        />
      );
    });
  }, [errors, betslipDispatch]);
}

const InternalError = (props) => {
  const { handleErrorRemove, error } = props;

  return (
    <div className='betError' onClick={handleErrorRemove}>
      <Label className='error' message='betslip_error_internal_error' params={error} />
    </div>
  );
};

const ValidationError = ({ handleErrorRemove, error, publishedErrorCodes, publishErrorCode }) => {
  const { violations, betDto } = error;

  const newViolations = violations.filter((violation) => !publishedErrorCodes[violation.code]);

  return newViolations.length ? (
    <div className='betError' onClick={handleErrorRemove}>
      {violations.map((violation, index) => {
        const { violationInfo } = violation;

        if (publishedErrorCodes[violation.code]) {
          return null; // don't show the same errors
        }

        publishErrorCode(violation.code);

        // Providing extra outcome data to label params
        // If outcomeId provided
        if (violationInfo && violationInfo.outcomeId) {
          const extraOutcomeData = betDto.outcomes.find(
            (outcome) => outcome.outcomeId === violationInfo.outcomeId
          );

          // If outcome data exist in bet dto
          // Passing it as additional label param
          if (extraOutcomeData && extraOutcomeData.outcomeInfo) {
            const params = {
              ...violationInfo,
              ...extraOutcomeData.outcomeInfo,
            };

            return (
              <Label
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className='error'
                params={params}
                message={`betslip_error_${violation.code}`}
              />
            );
          }
        }

        return (
          <Label
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className='error'
            params={violationInfo}
            message={`betslip_error_${violation.code}`}
          />
        );
      })}
    </div>
  ) : null;
};
