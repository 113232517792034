import React, { useMemo } from 'react';
import { useApplicationState } from '@apollo/core';
import Accordion from '../../../shared/components/Accordion/Accordion';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import SportOutcomeLogo from '../../Sports/SportOutcome/SportOutcomeLogo';
import MultiStatusIcon from '../MyBets/MultiStatusIcon';

const BetSubItemViewSgm = ({ legs, event, sgmLegsDataDefault, collapsedMulti }) => {
  const { layout } = useApplicationState();
  const t = useTranslate();

  const sgmLegsData = useMemo(
    () =>
      sgmLegsDataDefault ??
      Object.values(event.marketTypes).reduce((acc, marketType) => {
        const outcomes = Object.values(marketType.markets || {}).reduce(
          (acc, market) =>
            acc.concat(
              Object.values(market?.outcomes || {})
                .filter((outcome) => legs.findIndex((legId) => legId === outcome.id) !== -1)
                .map((outcome) => ({
                  name: outcome.name,
                  marketTypeName: marketType.name,
                  parameter: outcome.specifiers,
                }))
            ),
          []
        );
        return acc.concat(outcomes);
      }, []),
    [event?.marketTypes, legs, sgmLegsDataDefault]
  );

  const subItems = sgmLegsData.map((leg, i) => (
    <div key={`${i}_${leg.name}`} className='sub-item'>
      <div className='event__outcome-container'>
        <SportOutcomeLogo name={leg.name} event={event} className='event__outcome-logo'>
          <span className='event__outcome-logo event__outcome-logo--empty' />
        </SportOutcomeLogo>
        <div className='event__outcome-details'>
          <div className='sub-item__index'>
            {i + 1}
            .&nbsp;
          </div>
          <div className='sub-item__name'>{leg.name}</div>
          <div className='sub-item__market'>
            {leg?.marketTypeName}
            <MultiStatusIcon outcomeInfo={leg?.outcomeStatus} betType='SGM' />
          </div>
        </div>
      </div>
    </div>
  ));

  return layout.mobileDevice && collapsedMulti ? (
    <Accordion className='event-legs' collapsed titleOpen={t('hide_legs_more')}>
      {subItems}
    </Accordion>
  ) : (
    subItems
  );
};

export default BetSubItemViewSgm;
