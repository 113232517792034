import React from 'react';
import cx from 'classnames';
import { STATUS } from '@apollo/core/src/constants';
import { RACE_STATUS } from '../../../../core/constants';

const T_MINUTE = 60;
const T_2MINUTE = 60 * 2;
const T_HOUR = 60 * 60;
const T_2HOUR = 60 * 60 * 2;
const T_DAY = 60 * 60 * 24;

export default class RaceCountdown extends React.Component {
  constructor(props) {
    super(props);
    this.secondsTo = null;
    this.minutesTo = null;
    this.hoursTo = null;
    this.daysTo = null;
    this.completeText = props.completeText || ' - ';
    this.secondsDifference = 1;
    this.dynamicInterval = 1000;
    this.subscription = null;
    this.date = props.date || new Date('Jan 01 2023 00:00:00');
    this.state = { countdownText: '' };
  }

  componentDidMount() {
    if (this.completeText !== STATUS.ABANDONED) {
      this.countdownUpdate();
    }
  }

  componentDidUpdate(prevProps) {
    const { date } = this.props;
    if (
      this.completeText !== STATUS.ABANDONED
      && date
      && prevProps.date
      && prevProps.date?.getTime() !== date?.getTime()
    ) {
      clearTimeout(this.subscription);
      this.date = new Date(date);
      this.countdownUpdate();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.subscription);
  }

  countdownUpdate() {
    const { onComplete } = this.props;

    const timeDifference = this.date.getTime() - new Date().getTime();
    this.secondsDifference = timeDifference / 1000;
    this.secondsTo = Math.floor((Math.abs(timeDifference) / 1000) % 60);
    this.minutesTo = Math.floor((Math.abs(timeDifference) / (1000 * 60)) % 60);
    this.hoursTo = Math.floor((Math.abs(timeDifference) / (1000 * 60 * 60)) % 24);
    this.daysTo = Math.floor(Math.abs(timeDifference) / (1000 * 60 * 60 * 24));

    this.timePassed = timeDifference < 0 ? '-' : '';
    let countdownText = '';
    if (Math.abs(this.secondsDifference) < T_MINUTE) {
      countdownText = `${this.timePassed}${this.secondsTo}s`;
      this.dynamicInterval = 1000;
    } else if (
      Math.abs(this.secondsDifference) > T_MINUTE
      && Math.abs(this.secondsDifference) < T_2MINUTE
    ) {
      countdownText = `${this.timePassed}${this.minutesTo}m ${this.secondsTo}s`;
      this.dynamicInterval = 1000;
    } else if (Math.abs(this.secondsDifference) < T_2MINUTE) {
      countdownText = `${this.timePassed}${this.minutesTo}m ${this.secondsTo}s`;
      this.dynamicInterval = 1000;
    } else if (Math.abs(this.secondsDifference) < T_HOUR) {
      countdownText = `${this.timePassed}${this.minutesTo}m`;
      this.dynamicInterval = 10000;
    } else if (Math.abs(this.secondsDifference) < T_2HOUR) {
      countdownText = `${this.timePassed}${this.hoursTo}h ${this.minutesTo}m`;
      this.dynamicInterval = 10000;
    } else if (Math.abs(this.secondsDifference) < T_DAY) {
      countdownText = `${this.timePassed}${this.hoursTo}h`;
      this.dynamicInterval = 60000;
    } else {
      countdownText = `${this.timePassed}${this.daysTo}d ${this.hoursTo}h`;
      this.dynamicInterval = 60000;
    }
    this.setState({ countdownText });

    if (this.secondsDifference > 0 || this.completeText === RACE_STATUS.OPEN) {
      this.subscription = setTimeout(() => {
        this.countdownUpdate();
      }, this.dynamicInterval);
    } else {
      clearTimeout(this.subscription);
      if (onComplete != null) {
        onComplete();
      }
    }
  }

  render() {
    const { completeText } = this.props;

    const { countdownText } = this.state;

    const className = cx('race-countdown', {
      'race-countdown--success':
        this.secondsDifference <= 0 && this.completeText !== RACE_STATUS.OPEN,
      'race-countdown--danger':
        (this.completeText === RACE_STATUS.OPEN && this.secondsDifference < 0)
        || (this.secondsDifference > 0 && this.secondsDifference < 300),
      'race-countdown--warning': this.secondsDifference > 300 && this.secondsDifference < 600,
      'race-countdown--info': this.secondsDifference > 600 && this.secondsDifference < 1800,
      'race-countdown--light': this.secondsDifference > 1800,
    });

    let text = '';

    if (
      (this.completeText !== STATUS.ABANDONED && this.secondsDifference > 0)
      || this.completeText === RACE_STATUS.OPEN
    ) {
      text = countdownText;
    }
    return (
      <div className={className}>
        {this.completeText === STATUS.ABANDONED
          ? STATUS.ABANDONED.slice(0, 5)
          : this.secondsDifference <= 0 && this.completeText !== RACE_STATUS.OPEN && completeText}
        {text}
      </div>
    );
  }
}
