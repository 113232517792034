import React from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import {
  clientProfileRoutes,
  COMPONENT_TYPES,
  historyRoutes,
} from '@apollo/routing';
import { SiteConfigManager } from '@apollo/core';
import Label from '../I18n/Label';

const ToBetHistoryButton = (props) => {
  const {
    label = 'bets',
    disabled,
    className,
    children,
    ...otherProps
  } = props;

  const clientProfilePath = SiteConfigManager.getComponentPath(
    COMPONENT_TYPES.CLIENT_PROFILE,
  );
  const { push: historyPush } = useHistory();
  const goToBetHistory = React.useCallback(() => {
    historyPush(
      `${clientProfilePath}/${clientProfileRoutes.history}/${historyRoutes.betHistory}`,
    );
  }, [historyPush, clientProfilePath]);

  const buttonClassName = cx('btn', disabled && 'button-disabled', className);

  return (
    <button
      type='button'
      className={buttonClassName}
      onClick={goToBetHistory}
      {...otherProps}
    >
      <Label message={label} />
      {children}
    </button>
  );
};

export default ToBetHistoryButton;
