import React, { useEffect } from 'react';
import { RacingActions, useRacingState } from '@apollo/core/src/state/racing/racing';
import { Redirect, useParams } from 'react-router-dom';
import { SiteConfigManager } from '@apollo/core';
import { COMPONENT_TYPES } from '@apollo/routing';
import { RaceTypesMap } from '@apollo/core/src/constants';
import RaceLoader from './RaceLoader';

const RaceReroute = () => {
  const racingPath = SiteConfigManager.getExtraConfig(
    `pathList.${COMPONENT_TYPES.RACING}`,
  );

  const [racingState, racingDispatcher] = useRacingState();
  const {
    activeRace,
  } = racingState;

  // The activeRace is used here instead of the racingState,
  // because racingState isn't updated in time and as a result we get incorrect redirect
  const raceDate = activeRace?.date;
  const raceType = activeRace?.raceType?.typeName;
  const raceCountry = activeRace?.country;
  const raceNumber = activeRace?.number;
  const raceVenue = activeRace?.venue?.venueName;

  const routeParams = useParams();
  const {
    id,
  } = routeParams;

  useEffect(() => {
    racingDispatcher({
      type: RacingActions.RACING_SET_ACTIVE_RACE_ID,
      payload: id,
    });
  }, [id]);

  useEffect(() => {
    if (!activeRace) {
      return;
    }
    racingDispatcher({
      type: RacingActions.RACING_PATH_UPDATE,
      payload: {
        raceDate: activeRace.date, // dateKey format (2023-02-21)
        raceType: RaceTypesMap[activeRace.type]?.typeName,
        raceCountry: activeRace.country,
        raceVenue: activeRace.venueName,
        raceNumber: activeRace.number,
      },
    });
  }, [activeRace]);

  if (!id) {
    return (<Redirect to={`${racingPath}`} />);
  }

  if (!activeRace || !raceDate || !raceType || !raceCountry || !raceVenue || !raceNumber) {
    return <RaceLoader />;
  }

  if (id && activeRace?.raceId && (id !== activeRace?.raceId)) {
    return <RaceLoader />;
  }

  return (
    <Redirect
      to={`${racingPath}/${raceDate}/${raceType}/${raceCountry}/${raceVenue}/${raceNumber}`}
    />
  );
};
export default RaceReroute;
