import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SiteConfigManager } from '@apollo/core';
import { COMPONENT_TYPES, SportsbookRouter } from '@apollo/routing';
import Live from './Live';
import Prematch from './Prematch';

const Sportsbook = () => {
  const prematchPath = SiteConfigManager.getExtraConfig(
    `pathList.${COMPONENT_TYPES.SPORT_PREMATCH}`,
  );
  const livePath = SiteConfigManager.getExtraConfig(`pathList.${COMPONENT_TYPES.SPORT_LIVE}`);

  return (
    <Switch>
      <Route path={prematchPath}>
        <SportsbookRouter>
          <Prematch />
        </SportsbookRouter>
      </Route>
      <Route path={livePath}>
        <SportsbookRouter>
          <Live />
        </SportsbookRouter>
      </Route>
    </Switch>
  );
};

export default Sportsbook;
