import React, { useMemo, useState } from 'react';
import cx from 'classnames';
import {
  getEventMainMarketsByPeriod,
  isHidden,
  isInactive,
  isSuspended,
  SiteConfigManager,
  useApplicationState,
} from '@apollo/core';
import _ from 'lodash';
import { SPORT_SERVICE } from '@apollo/core/src/constants';
import useIsLivePage from '../../useIsLivePage';
import MarketRow from './Market/MarketRow';
import EventSubPeriod from './EventSubPeriod';
import { EventLink, TournamentLink } from '../../../../shared/components/Sport/SportLinks';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import EventParticipantsBlock from './EventParticipantsBlock';
import EventStats from './EventStats';
import SgmIcon from './SgmIcon';
import BetReturnBonusIcon from '../../../../shared/components/Bonus/BetReturnBonusIcon';
import PromoOddsBonusIcon from '../../../../shared/components/Bonus/PromoOddsBonusIcon';

const EventClassic = ({
  event,
  mainMarketTypesByPeriod,
  activeMarketTypeId,
  className,
  withCountDown,
  withSportIcon,
  template,
}) => {
  const enableSameGameMultiBets = SiteConfigManager.getConfig(
    'config.config.sportsbook.enableSameGameMultiBets',
  );
  const t = useTranslate();

  // Legacy event state
  // const event = useEventState();

  const isLivePage = useIsLivePage();
  const { fullPeriod, subPeriods } = useMemo(
    () => getEventMainMarketsByPeriod(mainMarketTypesByPeriod, event),
    [mainMarketTypesByPeriod, event],
  );

  /*
  * Example of how to subscribe an individual event for Web Socket (EXPERIMENTAL_EVENT) updates
  * Alternatively use: <DedicatedEventProvider event={event} key={event.id} />
  *
  React.useEffect(() => {
    const topic = [
      {
        topic: SubscriptionManager.subscriptionTopics.EXPERIMENTAL_EVENT,
        key: event.id,
      },
    ];
    SubscriptionManager.notifierSubscribe(topic);
    return () => {
      SubscriptionManager.notifierUnsubscribe(topic);
    };
  }, [event]);
   */

  const {
    layout: { desktopDevice },
  } = useApplicationState();

  const hasSubPeriods = Boolean(subPeriods.length);
  const [isEventExpanded] = useState(hasSubPeriods);
  const isLiveService = event.sportService === SPORT_SERVICE.LIVE;
  const isEventSuspended = isSuspended(event);
  const isEventDisabled = isInactive(event);

  const isSgmEnabled = useMemo(
    () => enableSameGameMultiBets && event?.isSgmEnabled,
    [enableSameGameMultiBets, event],
  );

  const markets = useMemo(
    () => fullPeriod.markets.filter(({ marketTypeId }) => marketTypeId === activeMarketTypeId),
    [fullPeriod.markets, activeMarketTypeId],
  );

  const eventClassName = cx(
    'event event--classic',
    {
      'event--with-sub-periods': hasSubPeriods,
      'event__info--mobile': desktopDevice === false,
    },
    className,
  );

  const isEventHidden = isHidden(event);
  if (isEventHidden) {
    return null;
  }

  const marketTypesCount = _.get(event, 'marketTypesCount');
  if (!marketTypesCount) {
    return null;
  }

  return (
    <div
      className={cx('event-group__wrapper', {
        'event-group__sgm-wrapper': className.includes('_sgm'),
      })}
    >
      {desktopDevice === false ? (
        <div className='event__info__mobile'>
          <EventStats
            event={event}
            isLivePage={isLivePage}
            isLiveService={isLiveService}
            withCountDown={withCountDown}
          />
          <div className='d-flex left-side'>
            {isSgmEnabled && <SgmIcon title={t('sgm')} event={event} />}
            {withSportIcon && (
              <TournamentLink
                className='event__info-tournament'
                sportUrlName={event.sportUrlName}
                categoryUrlName={event.categoryUrlName}
                tournamentUrlName={event.tournamentUrlName}
              >
                {`${event.categoryName} - ${event.tournamentName}`}
              </TournamentLink>
            )}
          </div>

          <BetReturnBonusIcon event={event} />
          <PromoOddsBonusIcon event={event} />
          <EventLink className='event__extras' event={event}>
            {`${marketTypesCount} ${marketTypesCount > 1 ? t('markets') : t('market')}`}
          </EventLink>
        </div>
      ) : null}

      <div className={eventClassName}>
        <div className={cx('event__info', `event__info--${event?.sportName?.toLowerCase()}`)}>
          <div className='event__stats-wrapper'>
            <EventParticipantsBlock event={event} withSportIcon={withSportIcon} />
            {desktopDevice === true && (
              <EventLink className='event__extras' event={event}>
                {`${marketTypesCount} ${marketTypesCount > 1 ? t('markets') : t('market')}`}
                <BetReturnBonusIcon event={event} />
                <PromoOddsBonusIcon event={event} />
              </EventLink>
            )}
            {isSgmEnabled && desktopDevice === true && <SgmIcon title={t('sgm')} event={event} />}
          </div>

          {desktopDevice === true && (
            <EventStats
              event={event}
              isLivePage={isLivePage}
              isLiveService={isLiveService}
              withCountDown={withCountDown}
            />
          )}
        </div>
        <div className='event__main-period'>
          <MarketRow
            event={event}
            markets={markets}
            suspended={isEventSuspended}
            disabled={isEventDisabled}
            template={template}
          />
          <EventLink className='event__extras' event={event}>
            <span className='event__extras-value'>
              <span className='event__extras-prefix'>+</span>
              <span className='event__extras-number'>{`${marketTypesCount}`}</span>
            </span>
            <span className='event__extras-name'>
              {marketTypesCount > 1 ? t('markets') : t('market')}
            </span>
          </EventLink>
        </div>

        {hasSubPeriods
          && isEventExpanded
          && _.map(subPeriods, (period) => {
            const { periodTypeId, periodTypeName, markets } = period;

            return (
              <EventSubPeriod
                key={periodTypeId}
                id={periodTypeId}
                name={periodTypeName}
                markets={markets}
                disabled={isEventDisabled}
                suspended={isEventSuspended}
                event={event}
                template={template}
              />
            );
          })}
      </div>
    </div>
  );
};

export default EventClassic;
