import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { ResponsibleApi } from '@apollo/core';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import ToastManager from '../../../../core/ToastManager';
import {
  onSetRealityCheckSubmit,
  realityCheckForm,
} from '../../../../shared/components/Form/formConfig/responsibleGaming';
import FormCom from '../../../../shared/components/Form/FormCom';

const RealityCheck = () => {
  const t = useTranslate();
  const [{ isLoading, period }, setState] = useState({ isLoading: true });

  useEffect(() => {
    ResponsibleApi.getRealityCheckSettings()
      .then(({ period }) => setState({ period, isLoading: false }))
      .catch((error) => {
        console.error(error);

        setState({ isLoading: false });
      });
  }, []);

  const renderInfoMessage = useMemo(
    () => <div className='block'>{t('reality_check_message')}</div>,
    [t]
  );

  const submitSetRealityCheckHandler = useCallback(
    ({ period }) => {
      ToastManager.success(
        period === 'NEVER'
          ? t('reality_check_period_notification_disabled')
          : t('reality_check_period_notification', {
              period: t(
                _.chain(realityCheckForm)
                  .get('fields')
                  .find({ name: 'period' })
                  .get('options')
                  .find({ value: period })
                  .get('text')
                  .value()
              ),
            })
      );
    },
    [t]
  );

  if (isLoading) {
    return null;
  }

  return (
    <div className='main__page__account__block__content'>
      <div className='details self'>
        <div className='details__inputs '>
          <FormCom
            buttonText='submit'
            initialValues={{ period }}
            className='active'
            action={onSetRealityCheckSubmit}
            onSubmit={submitSetRealityCheckHandler}
            form={realityCheckForm}
            formHints={renderInfoMessage}
          />
        </div>
      </div>
    </div>
  );
};

export default RealityCheck;
