
function openWindow(url, target, options) {
  // With dual screen fix
  const screenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const screenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
  const dX = ((window.screen.width - options.width) / 2) + screenLeft;
  const dY = ((window.screen.height - options.height) / 2) + screenTop;

  const features = `top=${dY},left=${dX},width=${options.width},height=${options.height}`;

  const win = window.open(
    '',
    target || '_blank',
    features,
  );

  win.opener = null;
  win.location = url;

  return win;
}

export function openPaymentWindow(url) {
  return openWindow(
    url,
    '_blank',
    {
      height: 620,
      width: 810,
    },
  );
}

export function openWithdrawWindow(url) {
  return openWindow(
    url,
    '_blank',
    {
      height: 620,
      width: 810,
    },
  );
}
