import React from 'react';
import { SiteConfigManager } from '@apollo/core';
import { cmsStaticBlock } from '../../../core/constants';
import StaticBlock from '../StaticBlock/StaticBlock';

const MaintenanceAlert = () => {
  const techMaintenance = SiteConfigManager.getConfig('config.maintenanceMode');

  let content = '';
  let classes = '';
  if (techMaintenance?.platform) {
    content = 'maintenance-platform';
    classes = 'maintenance-platform';
  } else if (techMaintenance?.sports) {
    content = 'maintenance-sports';
    classes = 'maintenance-sports';
  } else if (techMaintenance?.racing) {
    content = 'maintenance-racing';
    classes = 'maintenance-racing';
  } else if (techMaintenance?.payments) {
    content = 'maintenance-payments';
    classes = 'maintenance-payments';
  } else {
    return null;
  }

  return (
    <div className={`maintenance-alert ${classes}`}>
      <StaticBlock options={{ source: content }} />
    </div>
  );
};

export default MaintenanceAlert;
