import React, { useCallback } from 'react';
import { isFeedOffline, isHidden, isSuspended, useBetslipState, useEventState } from '@apollo/core';
import cx from 'classnames';

import useIsShowingLogo from '../../useIsShowingLogo';
import { getEventMainMarket } from '../../../../core/utils';
import TopEventInfo from './TopEventInfo';
import MainMarket from './MainMarket';
import { EventLink } from '../../../../shared/components/Sport/SportLinks';

const TopEvent = ({ className = '' }) => {
  const event = useEventState();

  const {
    settings: { feedStates },
  } = useBetslipState();

  const { externalId, sportName, tournamentName, sportId, sportService } = event;

  const mainMarket = getEventMainMarket(event);

  const suspended = isFeedOffline(feedStates[mainMarket?.providerName]);

  const isEventSuspended = suspended || isSuspended(event);
  const isLogosAvailable = useIsShowingLogo();

  // const { updateStatscoreEventData } = useStatscore();

  const onClickSportStats = useCallback(() => {
    // updateStatscoreEventData(externalId, sportService);
  }, [externalId, sportService]);

  const isEventHidden = isHidden(event);

  if (isEventHidden) {
    return null;
  }

  const classname = cx(className, 'top-event-item', {
    clipped: !isLogosAvailable,
  });

  return mainMarket ? (
    <div className={classname}>
      <div className='top-event-item__head'>
        <span className='top-event-item__type'>
          <span className={`icon sportIcon-${sportId}`} />
          <span className='name'>{sportName}</span>
        </span>

        <span className='top-event-item__name'>{tournamentName}</span>

        <span className='top-event-item__icons'>
          {externalId && (
            <span
              title='statistic'
              className='icon AkIcon-stats-icon'
              onClick={onClickSportStats}
            />
          )}
        </span>
      </div>

      <EventLink event={event} className='top-event-item__event-link'>
        <TopEventInfo event={event} />
      </EventLink>

      <MainMarket
        className='top-event-item__market'
        market={mainMarket}
        event={event}
        suspended={isEventSuspended}
      />
    </div>
  ) : null;
};

export default TopEvent;
