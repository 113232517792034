import React from 'react';
import cx from 'classnames';
import Svg from '../svg/Svg';

const Loader = ({ iconName = 'loader', className }) => (
  <div className={cx('loader', className)}>
    <Svg name={iconName} />
  </div>
);

export default Loader;
