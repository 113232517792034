import React, { useMemo } from 'react';
import { SportSelector, useSportState } from '@apollo/core/src/state/sport/sport';
import SportsbookFilter from '../../SportsbookFilter/SportsbookFilter';
import TournamentFilterOption from './TournamentFilterOption';

const TournamentFilter = () => {
  const [sportsState, sportsDispatcher] = useSportState();
  const { initialized, loading, selectedCategoryId, selectedTournamentUrlName } = sportsState;

  const tournaments = SportSelector.selectTournamentsByCategoryId(selectedCategoryId);

  const filteredTournaments = useMemo(
    () => tournaments.filter((t) => t.totalCount > 0),
    [tournaments],
  );

  if ((!initialized && loading) || !selectedTournamentUrlName) {
    return <div className='tournament-filter-placeholder' />;
  }

  return (
    <SportsbookFilter
      options={filteredTournaments}
      value={selectedTournamentUrlName}
      valueProperty='urlName'
      ItemComponent={TournamentFilterOption}
      className='sports-filter--tournament'
    />
  );
};
export default TournamentFilter;
