import React from 'react';
import cx from 'classnames';
import Label from '../../../shared/components/I18n/Label';

const FilterMoreButton = ({ isDropdownExpanded, onToggle, isActive }) => {
  const className = cx({
    'sportsbook-filter-more-button': true,
    active: isActive,
    expanded: isDropdownExpanded,
  });

  return (
    <div className={className} onClick={onToggle}>
      <div className='sportsbook-filter-more-button-icon' />
      <Label className='sportsbook-filter-more-button-text' message='more' />
    </div>
  );
};

export default FilterMoreButton;
