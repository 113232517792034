import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { find, isFunction } from 'lodash';
import Select from '../../Select';
import { getIsFieldRequired } from '../../../../../core/utils';

const SelectField = ({ field, onChange, onFocus, onBlur, ...restProps }) => {
  const {
    touched,
    disabled,
    value: formValue,
    autofocus,
    errors,
    warnings,
    active,
    label,
    placeholder,
    rules,
    name,
    options: initialOptions,
    withTranslate = true,
    translateOptions = true,
  } = field;
  const areFieldsAsync = useMemo(() => isFunction(initialOptions), [initialOptions]);
  const [options, setOptions] = useState(areFieldsAsync ? [] : initialOptions);

  useEffect(() => {
    if (areFieldsAsync) {
      (async () => {
        const loadedOptions = await initialOptions();

        setOptions(loadedOptions);
      })();
    }
  }, [areFieldsAsync, initialOptions]);

  useEffect(() => {
    if (!areFieldsAsync && initialOptions !== options) {
      setOptions(initialOptions);
    }
  }, [areFieldsAsync, initialOptions, options]);

  const value = useMemo(
    () => find(options, (option) => option.value === formValue),
    [formValue, options],
  );
  const changeHandler = useCallback(
    (option) => {
      onChange(option.value);
    },
    [onChange],
  );

  const error = touched && errors[0] ? errors[0].message : '';

  return (
    <Select
      disabled={disabled}
      touched={touched}
      value={value}
      autofocus={autofocus}
      error={error}
      errors={errors}
      rules={rules}
      warnings={warnings}
      active={active}
      label={label}
      placeholder={placeholder}
      name={name}
      options={options}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={changeHandler}
      isRequiredSymbol={getIsFieldRequired(rules)}
      withTranslate={withTranslate}
      translateOptions={translateOptions}
      {...restProps}
    />
  );
};

export default memo(SelectField);
