import React from 'react';
import _ from 'lodash';
import useMarketExpandedDropdown from './useMarketExpandedDropdown';
import MarketExpandedRow from './MarketExpandedRow';

const MarketExpandedDropdown = ({ markets, event, marketTypeId, suspended, disabled, onClose }) => {
  const { dropdownRef, dropdownClassName } = useMarketExpandedDropdown(onClose);

  return (
    <div className={dropdownClassName} ref={dropdownRef}>
      {_.map(markets, (market) => (
        <MarketExpandedRow
          key={market.id}
          market={market}
          event={event}
          marketTypeId={marketTypeId}
          suspended={suspended}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

export default MarketExpandedDropdown;
