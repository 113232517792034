import React, { useMemo, useCallback } from 'react';
import cx from 'classnames';
import Label from '../I18n/Label';

const Checkbox = ({ name, label, withTranslate, onChange, checked, disabled, children }) => {
  const toggleChange = useCallback(() => {
    onChange(!checked);
  }, [onChange, checked]);

  const renderLabel = useMemo(() => {
    if (children) {
      return children;
    }

    const content = withTranslate ? <Label message={label} /> : <span>{label}</span>;

    return <label htmlFor='checkbox2'>{content}</label>;
  }, [label, withTranslate]);

  const checkboxClassName = React.useMemo(
    () => cx(['registrationCheck', disabled && 'disabled']),
    [disabled]
  );

  return (
    <div className='signup__form__top__check'>
      <div className={checkboxClassName}>
        <input
          id={name}
          name={name}
          type='checkbox'
          tabIndex='0'
          onClick={toggleChange}
          checked={checked}
          disabled={disabled}
        />
        {renderLabel}
      </div>
    </div>
  );
};

export default Checkbox;
