import React from 'react';
import cx from 'classnames';
import { useApplicationState } from '@apollo/core';
import { getCurrencySymbol } from '../../../../core/utils';

const ActionButtons = (props) => {
  const { field = {}, onChange } = props;
  const blockClassName = cx('block block__wrap', field?.className);

  const {
    account: { currency },
  } = useApplicationState();

  const currencySymbol = getCurrencySymbol(currency);

  const handleClick = (value) => {
    onChange(value);
  };

  return (
    <div className={blockClassName}>
      {field.options?.map((value) => (
        <button
          key={value}
          type='button'
          className='btn btn-primary-outline action-button'
          onClick={() => handleClick(value)}
        >
          +
          {currencySymbol}
          {value}
        </button>
      ))}
    </div>
  );
};

export default ActionButtons;
