import React from 'react';

import { DateService, useEventState } from '@apollo/core';
import { get } from 'lodash';
import Label from '../../shared/components/I18n/Label';
import GameStat from '../../shared/components/GameStat';
import ResultDetailsFilter from './ResultDetails/ResultDetailsFilter';
import ResultDetailMarkets from './ResultDetails/ResultDetailsMarketGroup';
import EventTop from '../../shared/components/EventTop/EventTop';

const ResultsDetails = () => {
  const selectedEvent = useEventState();

  const startTime = get(selectedEvent, 'startTime');
  const formattedStartTime = DateService.getFormattedDateWithTime(startTime);
  const startTimeLabel = startTime && <span>{formattedStartTime}</span>;

  if (selectedEvent.error) {
    return (
      <div className='main__page__right'>
        <div className='eventView'>
          <Label message='event_not_found' />
        </div>
      </div>
    );
  }

  return (
    <div className='main__page__right'>
      <div className='eventView'>
        <div className='eventView__top'>
          <EventTop sportId={selectedEvent.sportId}>
            <div className='date'>
              {startTimeLabel}
              <GameStat />
            </div>
          </EventTop>
        </div>

        <ResultDetailsFilter />
        {selectedEvent.marketTypes && (
          <div className='eventView__market'>
            <ResultDetailMarkets event={selectedEvent} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ResultsDetails;
