import React from 'react';
import _ from 'lodash';

const MarketFilterItemsArray = [
  'All',
  '1st Half',
  '2nd Half',
  'Handicap',
  'Events',
  'Special',
];

const ResultDetailsFilter = () => (
  <div className='eventView__filter'>
    {_.map(MarketFilterItemsArray, (value) => (
      <span key={value}>{value}</span>
    ))}
  </div>
);

export default ResultDetailsFilter;
