import React, { useCallback, useMemo } from 'react';
import { centsToDecimal, DateService } from '@apollo/core';
import _ from 'lodash';
import { sportService } from '../../../core/constants';
import Label from '../../../shared/components/I18n/Label';
import Odds from '../../../shared/components/Odds';
import { getCurrencySymbol } from '../../../core/utils';

const { LIVE } = sportService;

const MyOrderItem = ({ bet, disableRemove, removeOrderItem }) => {
  const {
    id,
    createTime,
    outcomes,
    betAmount,
    betCurrency,
    minDesiredOdds: desiredOdds,
    hasError,
  } = bet;

  const date = DateService.getFormattedDate(createTime);
  const currencySymbol = getCurrencySymbol(betCurrency);
  const amountToDisplay = centsToDecimal(betAmount);
  const amountPossibleWin = centsToDecimal(betAmount * desiredOdds);

  const handleDeleteOrder = useCallback(() => {
    removeOrderItem(id);
  }, [removeOrderItem, id]);

  const errorMessage = useMemo(
    () =>
      hasError && (
        <div className='betError'>
          <Label className='error' message='order bet can not cancel' />
        </div>
      ),
    [hasError]
  );

  return (
    <div className='bets__block'>
      <div className='bets__block__title'>
        <div className='betsIcon' />
        <div className='number'>#{id}</div>
        <div className='date'>{date}</div>
      </div>

      <div className='bets__block__content'>
        {_.map(
          outcomes,
          ({ currentOdds: odds, outcomeId, outcomeName, marketName, sportService, eventName }) => (
            <div className='coupon' key={outcomeId}>
              <div className='coupon__block'>
                <div className='coupon__outcomeName'>{outcomeName}</div>
                <div className='coupon__odd'>
                  <Odds value={odds} decimal={3} minDecimal={1} />
                </div>
              </div>
              <div className='coupon__block'>
                <div className='coupon__outcome'>{marketName}</div>
              </div>
              <div className='coupon__block'>
                <div className='coupon__teams'>
                  {sportService === LIVE && <span className='isLive' />}
                  <span className='teams'>{eventName}</span>
                </div>
              </div>
            </div>
          )
        )}

        <div className='result'>
          <div className='result__block'>
            <Label message='ordered bet' render={(t) => <div className='name'>{t}</div>} />
            <div className='order'>
              {currencySymbol}
              {amountToDisplay}
            </div>
          </div>
          <div className='result__block'>
            <Label message='To return' render={(t) => <div className='name'>{t}</div>} />
            <div className='order'>
              <span>
                {currencySymbol}
                {amountPossibleWin}
              </span>
            </div>
          </div>
          <div className='result__block'>
            <Label message='ordered_odds' render={(t) => <div className='name'>{t}</div>} />
            <div className='order'>
              <Odds value={desiredOdds} decimal={3} minDecimal={1} />
            </div>
          </div>
        </div>
        {errorMessage}
        <button
          type='button'
          className='buttonCancel'
          onClick={handleDeleteOrder}
          disabled={disableRemove || hasError}
        >
          <Label message='cancel' />
        </button>
      </div>
    </div>
  );
};

export default MyOrderItem;
