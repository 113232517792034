import React, { useMemo } from 'react';
import { RaceLogicMarkets, RaceMarketsTabs, RaceTypesMap } from '@apollo/core/src/constants';
import { isExoticMarket, isMultilegsMarket } from '@apollo/core';
import Label from '../../../shared/components/I18n/Label';
import Svg from '../../../shared/components/svg/Svg';
import BetOdds from './BetOdds';
import { RacingIcon } from '../../../shared/components/Racing/RacingIcon';
import RaceCountdown from '../../../shared/components/Racing/RaceCountdown/RaceCountdown';
import { getOutcomeSelections, getRunnerByNum, isBoxedSelections } from '../../../core/utils';
import { MARKET_PLACES } from '../../../core/constants';
import { RaceRunnerImage } from '../../Racing/RacePage/RaceRunnerImage/RaceRunnerImage';
import { RacingMarketTypeName } from '../../../shared/components/Racing/RacingMarketTypeName';

const BetItemViewRacing = (props) => {
  const {
    event,
    runner,
    runners,
    outcome,
    disabled,
    suspended,
    loading,
    market,
    oddsToDisplay,
    assignedBonus,
  } = props;

  const isExotic = isExoticMarket(market.type);

  const isMulitlegs = isMultilegsMarket(market.type);

  const warningWillBeRemoved = disabled
    && !suspended
    && !loading
    && !(
      market.typeUiPosition === RaceMarketsTabs.EXTRA
      || market.type === RaceLogicMarkets.COMBINED
      || isExotic
      || isMulitlegs
    );

  const activeRunnersCount = runners.filter((runner) => !runner.scratched).length;

  const eventName = useMemo(() => {
    if (isMulitlegs) {
      return (
        <>
          <span>
            {event.venueName}
            {' '}
            <Label message='races' />
            {` (${market.legs})`}
          </span>
          <RaceCountdown
            key={`${event?.raceId}-${event?.status}-${event?.result}`}
            date={new Date(event.startTime)}
            completeText={event.result || event.status}
          />
        </>
      );
    }

    return (
      <>
        <span>{`${event.venueName} R${event.number}`}</span>
        <RaceCountdown
          key={`${event?.raceId}-${event?.status}-${event?.result}`}
          date={new Date(event.startTime)}
          completeText={event.result || event.status}
        />
      </>
    );
  }, [event]);

  const outcomeName = React.useMemo(() => {
    if (isExotic || isMulitlegs) {
      return <RacingMarketTypeName marketType={market.type} />;
    }
    if (outcome.name) {
      return <span>{outcome.name}</span>;
    }
    return (
      (runner.number && runner.name && <span>{`${runner.number}. ${runner.name}`}</span>) || null
    );
  }, [runner, outcome, market.type]);

  const selections = React.useMemo(() => getOutcomeSelections(outcome), [outcome]);

  const outcomeDetails = React.useMemo(() => {
    if (
      market.typeUiPosition === RaceMarketsTabs.EXTRA
      || market.typeUiPosition === RaceMarketsTabs.WP_TAB
    ) {
      return (
        <div className='event__name'>
          <RacingMarketTypeName marketType={market.type} />
        </div>
      );
    }
    if (isMulitlegs) {
      return (
        <>
          <div className='event__outcome-name'>{outcomeName}</div>
          {market.legs.split('-').map((leg, index) => {
            const filteredSelections = selections.filter((sel) => sel.place === index + 1);

            return (
              <div className='event__name event__name--hard' key={leg}>
                <div className='place-container event__outcome-logo'>
                  <span className='place'>{`R${leg}`}</span>
                </div>
                <div>{filteredSelections.map((sel) => sel.runner).join(', ')}</div>
              </div>
            );
          })}
        </>
      );
    }
    if (isExotic) {
      return MARKET_PLACES[market.type]?.map((p) => {
        const filteredSelections = selections.filter(
          (sel) => sel.place === (isBoxedSelections(outcome) ? 1 : p),
        );

        return (
          <div className='event__name event__name--hard' key={p}>
            <div className='place-container event__outcome-logo'>
              <Label className='place' message={`place${p}`} />
            </div>
            <div>
              {filteredSelections.map((sel) => sel.runner.replace('Boxed-', '')).join(', ')}
            </div>
          </div>
        );
      });
    }
    if (market.type === RaceLogicMarkets.COMBINED) {
      return selections?.map((sel) => {
        const runnerNum = sel.runner;
        const runner = getRunnerByNum(runners, runnerNum);
        return (
          <div className='event__name event__name--hard' key={sel.runner + sel.place}>
            {runnerNum}
            .&nbsp;
            {runner?.name}
          </div>
        );
      });
    }
    return null;
  }, [market, outcome, eventName, runners, selections, isExotic, isMulitlegs]);

  return (
    <>
      <div className='event event--racing'>
        <div className='event__name'>
          <div className='event-icon'>
            <RacingIcon id={event?.type} name={RaceTypesMap?.[event?.type]?.typeName} />
          </div>
          <div>{eventName}</div>
        </div>
        <div className='event__outcome-container'>
          {runner?.silkImageUrl ? (
            <RaceRunnerImage
              runner={runner}
              masked={event?.type !== 'DOG'}
              className='event__outcome-logo'
            />
          ) : (
            <span className='event__outcome-logo event__outcome-logo--empty' />
          )}
          <div className='event__outcome-details'>
            {!isMulitlegs && (
              <div className='event__outcome-name'>
                {isBoxedSelections(outcome) && (
                  <>
                    <Label message='boxed' />
                    &nbsp;
                  </>
                )}
                {outcomeName}
              </div>
            )}

            {outcomeDetails}
          </div>
        </div>

        {warningWillBeRemoved && (
          <div className='warning'>
            <Svg name='warning' />
            <Label message='bet_is_inactive' />
          </div>
        )}
      </div>

      <BetOdds
        market={market}
        outcomeId={outcome.outcomeId}
        odds={outcome.odds}
        oddsToDisplay={oddsToDisplay}
        assignedBonus={assignedBonus}
      />
    </>
  );
};

export default BetItemViewRacing;
