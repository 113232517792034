import React, { useState } from 'react';
import cx from 'classnames';
import Label from '../../../shared/components/I18n/Label';
import Dropdown from '../../../shared/components/Dropdown/Dropdown';
import { AnimatedDropdown } from '../../Animations/AnimatedDropdown';

const FILTER_BY_TIME = [null, 3, 6, 12, 24, 48];

const FilterByTime = ({ time, onChange }) => {
  const [timeFilter, setTimeFilter] = useState(time ?? null);

  const [isExpanded, setExpanded] = useState(false);

  const handleChange = (newTime) => {
    setTimeFilter(newTime);
    onChange(newTime);
  };

  return (
    <div className='filter-by-time__block'>
      <Dropdown onExpanded={setExpanded} className='filter-by-time'>
        <div>
          <Label message={`Next ${timeFilter}hr`} />
        </div>
        <AnimatedDropdown isExpanded={isExpanded} className='filter-by-time__dropdown'>
          {FILTER_BY_TIME.map((time) => (
            <div
              key={time}
              className={cx('filter-by-time__item', {
                active: timeFilter === time,
              })}
              onClick={() => handleChange(time)}
            >
              <Label message={`${time}hr`} />
            </div>
          ))}
        </AnimatedDropdown>
      </Dropdown>
    </div>
  );
};

export default FilterByTime;
