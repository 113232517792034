import React from 'react';
import { SiteConfigManager } from '@apollo/core';

import {
  availableClientProfileRoutes,
  availablePaymentRoutes,
  CLIENT_PROFILE_COMPONENT_TYPES,
  clientProfileRoutes,
  COMPONENT_TYPES,
} from '@apollo/routing';
import Label from '../../../shared/components/I18n/Label';
import PaymentRouter from './PaymentRouter';
import ClientProfileLinks from '../ClientProfileLinks';

const { CLIENT_PROFILE } = COMPONENT_TYPES;
const { PAYMENT } = CLIENT_PROFILE_COMPONENT_TYPES;
const { payment } = clientProfileRoutes;

const Payment = () => {
  const { tabs = [] } = SiteConfigManager.getSubComponentOptions(CLIENT_PROFILE, PAYMENT);

  return (
    <div className='main__page__account__block'>
      <div className='main__page__account__block__title'>
        <div className='name'>
          <Label message='deposit_withdraw' />
        </div>

        <ClientProfileLinks
          tabs={tabs}
          availableRoutes={availablePaymentRoutes}
          parentRoute={payment}
        />
      </div>
      <PaymentRouter tabs={tabs} />
    </div>
  );
};

export default Payment;
