import React from 'react';
import { hasMarketTypes, isHidden } from '@apollo/core';
import { BonusSelector } from '../../../state/Bonus/Bonus';
import { RACE_STATUS } from '../../../core/constants';
import useTranslate from '../I18n/Interpreter';

const PromoOddsBonusIcon = ({ race, event }) => {
  const t = useTranslate();
  const eventId = race?.raceId || event?.id;

  const isVisible = race
    ? race.status === RACE_STATUS.OPEN
    : event && hasMarketTypes(event) && !isHidden(event);

  if (!BonusSelector.hasActivePromoOdds(eventId) || !isVisible) {
    return null;
  }

  return <i className='icons icons icon--promo-odds' title={t('promo odds')} />;
};

export default PromoOddsBonusIcon;
