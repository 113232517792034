import React, { useEffect, useMemo } from 'react';
import { find } from 'lodash';
import { SiteConfigManager, useApplicationState } from '@apollo/core';

import { clientProfileRoutes, COMPONENT_TYPES, paymentRoutes } from '@apollo/routing';
import { PAYMENT_METHODS_OPERATION_TYPE_DEPOSIT, usePaymentMethods } from '@apollo/react-forms';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Theme from 'themeSource/custom';
import PaymentMethodList from './PaymentMethodList/PaymentMethodList';
import { cmsStaticBlock, paymentTypes } from '../../../core/constants';
import StaticBlock from '../../../shared/components/StaticBlock/StaticBlock';
import Loader from '../../../shared/components/Loader';
import PaymentFormByType from './DepositPaymentForms/PaymentFormByType';
import { BonusSelector } from '../../../state/Bonus/Bonus';
import BonusBannerItem from '../../../shared/components/Bonus/BonusBannerItem';

const { payment } = clientProfileRoutes;
const { deposit } = paymentRoutes;
const { CLIENT_PROFILE } = COMPONENT_TYPES;

const Deposit = () => {
  const ThemePaymentMethodList = Theme.PaymentMethodList || PaymentMethodList;

  const { paymentMethods, isLoading } = usePaymentMethods(PAYMENT_METHODS_OPERATION_TYPE_DEPOSIT);
  const clientProfilePath = SiteConfigManager.getComponentPath(CLIENT_PROFILE);
  const { account, wallet } = useApplicationState();
  const {
    params: { paymentId },
  } = useRouteMatch();
  const { push: historyPush } = useHistory();

  const clientId = `${account?.id}`;

  useEffect(() => {
    if (paymentId || isLoading || paymentMethods.length === 0) return;

    historyPush(`${clientProfilePath}/${payment}/${deposit}/${paymentMethods[0].id}`);
  }, [paymentMethods, paymentId, isLoading, historyPush, clientProfilePath]);

  const selectedPayment = useMemo(
    () => find(paymentMethods, ({ id }) => `${id}` === paymentId),
    [paymentId, paymentMethods],
  );

  const activeDepositBonuses = BonusSelector.selectActiveDepositBonuses();

  const depositBonuses = useMemo(
    // only unique depositBonuses bonuses
    () => activeDepositBonuses.reduce((acc, bonus) => {
      if (!acc.some((o) => o?.id === bonus?.bonus?.id)) {
        acc.push(bonus.bonus);
      }
      return acc;
    }, []),
    [activeDepositBonuses],
  );

  if (isLoading) {
    return <Loader />;
  }

  if (paymentMethods.length === 0) {
    return null;
  }

  return (
    <>
      {wallet && wallet.balance <= 0 && (
        <div className='main__page__account__block__content'>
          <div className='details self deposit'>
            <StaticBlock options={{ source: cmsStaticBlock.MakeADeposit }} />
            {/* <button
                type='button'
                className='editLink'
                style={{ display: 'inline-block' }}
                onClick={doNotSetHandler}
              >
                <span className='icon AkIcon-close-icon' />
                { t('do_not_make_deposit') }
              </button> */}
          </div>
        </div>
      )}

      {depositBonuses?.map((bonus) => (
        <BonusBannerItem
          bonus={bonus || {}}
          key={bonus.id}
          classes='bet-return-bonus-banner--deposit'
        />
      ))}

      <div className='main__page__account__block__content deposit-withdrawal'>
        <ThemePaymentMethodList
          payments={paymentMethods}
          route={`${clientProfilePath}/${payment}/${deposit}`}
          type={paymentTypes.DEPOSIT}
          active={selectedPayment}
          paymentId={paymentId}
          clientId={clientId}
        >
          <PaymentFormByType
            selectedPayment={selectedPayment}
            paymentId={paymentId}
            clientId={clientId}
          />
        </ThemePaymentMethodList>
        <StaticBlock options={{ source: cmsStaticBlock.DepositFooter }} />
      </div>
    </>
  );
};

export default Deposit;
