import React, { useMemo } from 'react';
import _ from 'lodash';
import { outcomeStatuses, SPECIAL_MARKETS } from '../../../../../core/constants';
import { getRenderedOutcomeStatus } from '../../../../../core/utils';
import Odds from '../../../../../shared/components/Odds';
import Label from '../../../../../shared/components/I18n/Label';
import DateTime from '../../../../../shared/components/DateTime/DateTime';
import BetItemEventLink from '../../../../../shared/components/BetItemEventLink/BetItemEventLink';
import MultiStatusIcon from '../../../../Betslip/MyBets/MultiStatusIcon';

const BetHistoryMultipleTypeSubRow = ({ outcome, bet }) => {
  const { outcomeInfo, odds, awayScore, homeScore } = outcome;

  const {
    sportName,
    localizedSportName,
    categoryName,
    localizedCategoryName,
    tournamentName,
    localizedTournamentName,
    eventName,
    localizedEventName,
    marketName,
    marketTypeId,
    localizedMarketName,
    outcomeName,
    localizedOutcomeName,
    eventStartTime,
    finalAwayScore,
    finalHomeScore,
    outcomeStatus,
    sameGameMultiLegs,
  } = outcomeInfo;

  const localSportName = localizedSportName || sportName || '';
  const localCategoryName = localizedCategoryName || categoryName || '';
  const localTournamentName = localizedTournamentName || tournamentName || '';
  const localEventName = localizedEventName || eventName || '';

  const finalBetScore = useMemo(
    () =>
      _.isNumber(finalHomeScore) && _.isNumber(finalAwayScore)
        ? `${_.toNumber(finalHomeScore)}:${_.toNumber(finalAwayScore)}`
        : '',
    [finalHomeScore, finalAwayScore]
  );

  const initialBetScore = useMemo(
    () =>
      _.isNumber(homeScore) && _.isNumber(awayScore)
        ? `${_.toNumber(homeScore)}:${_.toNumber(awayScore)}`
        : '',
    [homeScore, awayScore]
  );

  const historyRowClassName = `history-table-row bet-history ${outcomeStatus}`;

  return (
    <div className={historyRowClassName}>
      <div className='bet-history-row'>
        <div className='history-table-cell date'>
          <span className='sub-title'>
            <DateTime dateTime={eventStartTime} />
          </span>
        </div>

        <div className='history-table-cell date'>
          <div className='event-with-score'>
            <BetItemEventLink className='event-name' outcomeInfo={outcomeInfo}>
              {initialBetScore && <span className='score'>{initialBetScore}</span>}
              <span className='event'>{localEventName}</span>
            </BetItemEventLink>
          </div>
          <span className='sub-title'>
            {`${localSportName} - ${localCategoryName} - ${localTournamentName}`}
          </span>
        </div>

        <div className='history-table-cell can-cashout'>
          <div className='col'>
            {sameGameMultiLegs.length ? (
              <>
                <span className='market-name'>
                  {sameGameMultiLegs?.length} <Label message='legs' />{' '}
                  {localizedMarketName || marketName}
                </span>
                <div className='event-legs'>
                  {sameGameMultiLegs.map((leg) => (
                    <div key={leg.outcomeId} className='event-leg'>
                      <span className='market-name'>{leg.localizedOutcomeName}</span>
                      <div className='sub-title'>
                        {leg.localizedMarketName.split(',').join(', ')}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                <span className='market-name'>
                  {localizedMarketName || marketName}{' '}
                  <MultiStatusIcon outcomeInfo={outcomeInfo} betType={bet.betType} />
                </span>
                <span className='sub-title'>
                  {localizedOutcomeName.split(',').join(', ') || outcomeName.split(',').join(', ')}
                </span>
              </>
            )}
          </div>
        </div>

        {/* <div className='history-table-cell bet-source'>
          {
            finalBetScore
              ? (
                <span className='final-score' onClick={onClickEventName}>
                  {finalBetScore}
                </span>
              )
              : <span className='empty-score'>-:-</span>
          }
        </div> */}

        <div className='history-table-cell'>
          <span />
        </div>

        <div className='history-table-cell'>
          {outcomeStatus === outcomeStatuses.NOT_DEFINED && SPECIAL_MARKETS[marketTypeId] ? (
            <Label message='tbd' />
          ) : (
            <span className='odds'>
              <Odds value={odds} decimal={3} minDecimal={1} />
            </span>
          )}
        </div>

        <div className='history-table-cell to-return' />
        <div className='history-table-cell status-label'>
          <Label message={getRenderedOutcomeStatus(outcomeStatus)} />
        </div>
      </div>
    </div>
  );
};

export default BetHistoryMultipleTypeSubRow;
