import React, { useMemo } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { sportService } from '../../../../core/constants';
import useIsShowingLogo from '../../useIsShowingLogo';
import Label from '../../../../shared/components/I18n/Label';
import Logo from '../../../../shared/components/Logo/Logo';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import TopEventTime from './TopEventTime';
import BetReturnBonusIcon from '../../../../shared/components/Bonus/BetReturnBonusIcon';
import PromoOddsBonusIcon from '../../../../shared/components/Bonus/PromoOddsBonusIcon';

const { LIVE } = sportService;

const TopEventInfo = ({ event, onRedirect }) => {
  const [
    homeFullName,
    homeTeamId,
    homeTeamLogoUri,
    awayFullName,
    awayTeamId,
    awayTeamLogoUri,
    sportService,
    homeScore,
    awayScore,
    isSgmEnabled,
  ] = _.at(event, [
    'participants.home.fullName',
    'participants.home.id',
    'participants.home.logoUri',
    'participants.away.fullName',
    'participants.away.id',
    'participants.away.logoUri',
    'sportService',
    'info.score.homeScore',
    'info.score.awayScore',
    'isSgmEnabled',
  ]);

  const t = useTranslate();
  const isLive = sportService === LIVE;

  const homeClassName = useMemo(
    () => cx({
      home: true,
      win: homeScore > awayScore,
      lose: awayScore > homeScore,
    }),
    [homeScore, awayScore],
  );

  const awayClassName = useMemo(
    () => cx({
      away: true,
      win: awayScore > homeScore,
      lose: homeScore > awayScore,
    }),
    [homeScore, awayScore],
  );

  const isShowingLogo = useIsShowingLogo();

  const renderCenterSide = isLive ? (
    <div className='top-event-status top-event-status--live'>
      <Label className='top-event-status__label' message='live' />
      <span className='top-event-status__score'>
        <span className={homeClassName}>{homeScore}</span>
        <span className='text'>:</span>
        <span className={awayClassName}>{awayScore}</span>
      </span>
      <span className='top-event-status__time'>
        <TopEventTime event={event} />
      </span>
    </div>
  ) : (
    <div className='top-event-status top-event-status--prematch'>
      <div className='top-event-status-icons'>
        {isSgmEnabled ? (
          <span className='top-event-status__sgm'>
            <i className='icons icon--sgm' title={t('sgm')} />
          </span>
        ) : null}
        <BetReturnBonusIcon event={event} />
        <PromoOddsBonusIcon event={event} />
      </div>
      <div className='top-event-status__participants'>
        <div className='top-event-info__participant'>{homeFullName}</div>
        <span>
          <Label message='versus' />
        </span>
        <div className='top-event-info__participant'>{awayFullName}</div>
      </div>
      <span className='top-event-status__time'>
        <TopEventTime event={event} />
      </span>
    </div>
  );

  return isShowingLogo ? (
    <div className='top-event-info top-event-info--with-logo' onClick={onRedirect}>
      <div className='top-event-info__body'>
        <div className='top-event-info__left'>
          <Logo participantId={homeTeamId} logoUri={homeTeamLogoUri} size='xl' alt={homeFullName} />
          <div className='top-event-info__participant'>{homeFullName}</div>
        </div>
        <div className='top-event-info__center'>{renderCenterSide}</div>
        <div className='top-event-info__right'>
          <Logo participantId={awayTeamId} logoUri={awayTeamLogoUri} size='xl' alt={awayFullName} />
          <div className='top-event-info__participant'>{awayFullName}</div>
        </div>
      </div>
    </div>
  ) : (
    <div className='top-event-info' onClick={onRedirect}>
      <div className='top-event-info__body'>
        <div className='top-event-info__left'>
          <span className='top-event-info__participant top-event-info__participant--win'>
            {homeFullName}
          </span>
          <span className='top-event-info__participant top-event-info__participant--lose'>
            {awayFullName}
          </span>
        </div>
        <div className='top-event-info__right'>{renderCenterSide}</div>
      </div>
    </div>
  );
};

export default TopEventInfo;
