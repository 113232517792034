import React from 'react';
import _ from 'lodash';
import { useApplicationState } from '@apollo/core';
import CasinoHistoryTableListItem from './CasinoHistoryTableListItem';
import { getCurrencySymbol } from '../../../../../core/utils';

const CasinoHistoryTableList = ({ items }) => {
  const applicationState = useApplicationState();
  const currency = _.get(applicationState, 'wallet.currency');
  const currencySymbol = getCurrencySymbol(currency);

  return items.map(({ createTime, id, gameName, txIn, txOut, imgUrl }) => (
    <CasinoHistoryTableListItem
      key={id}
      createTime={createTime}
      betId={id}
      gameName={gameName}
      txIn={txIn}
      txOut={txOut}
      currencySymbol={currencySymbol}
      img={imgUrl}
    />
  ));
};
export default CasinoHistoryTableList;
