import React from 'react';
import Slider from 'react-slick';
import { isEmpty, map } from 'lodash';

import { DedicatedEventProvider } from '@apollo/core';

import { SportSelector } from '@apollo/core/src/state/sport/sport';
import TopEvent from './TopEvent/TopEvent';

const TopEvents = () => {
  const topEvents = SportSelector.selectTopEvents({ limit: 10 });

  if (isEmpty(topEvents)) {
    return null;
  }

  return (
    <div className='top-events'>
      <Slider
        className='slick-slider--top-events'
        draggable={false}
        slidesToShow={1}
        slidesToScroll={1}
        speed={400}
        dots={false}
        infinite
        autoplay
        autoplaySpeed={10000}
        variableWidth
      >
        {map(topEvents, (event) => (
          <DedicatedEventProvider event={event} key={event.id}>
            <TopEvent />
          </DedicatedEventProvider>
        ))}
      </Slider>
    </div>
  );
};

export default TopEvents;
