import React from 'react';
import Label from '../../../shared/components/I18n/Label';

export default function TotalStakeSection(props) {
  const { totalStake, currencySymbol, label } = props;

  if (!totalStake) {
    return null;
  }

  // const possibleWin = (betAmount * selectedBet.odds).toFixed(2);

  return (
    <div className='rightSide total-amount'>
      <Label
        className='total-amount__title'
        message={label || 'total_stake'}
      />
      <span className='total-amount__amount'>
        {currencySymbol}
        {totalStake}
      </span>
    </div>
  );
}
