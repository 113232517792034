import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import {
  Bus,
  ObservableOddsProvider,
  useApplicationState,
  useBetslipState,
  useGlobalBetslipState,
} from '@apollo/core';
import { betslipIndex, myBetsIndex, myOrdersIndex } from './constants';
import Label from '../../shared/components/I18n/Label';
import Betslip from './Betslip';
import MyBets from './MyBets';
import MyOrders from './MyOrders/MyOrders';
import PlaceBetControls from './PlaceBetControls/PlaceBetControls';

const BetslipContainer = () => {
  const { authenticated, layout, betsCount } = useApplicationState();
  const { reports } = useGlobalBetslipState();

  const { betItems, settings, loading } = useBetslipState();

  const { orderBet: isOrderBetAllowed } = settings;

  const [tabIndex, setTabIndex] = useState(betslipIndex);
  const [isScrollBottomPending, setIsScrollBottomPending] = useState(false);
  const mainPageRightRef = React.useRef();

  const handleBackToMainScreen = useCallback(() => {
    Bus.send({
      event: Bus.events.layout.setSideBarRight,
      data: false,
    });
  }, []);

  // FIXME: Tab navigation logic should be in separate component
  const setBetslipTab = useCallback(() => {
    if (loading) return;
    setTabIndex(betslipIndex);
  }, [loading]);

  const setMyBetsTab = useCallback(() => {
    if (loading) return;
    setTabIndex(myBetsIndex);
  }, [loading]);

  const setMyOrdersTab = useCallback(() => {
    if (loading) return;
    setTabIndex(myOrdersIndex);
  }, [loading]);

  const scrollBottomHandler = useCallback(() => {
    mainPageRightRef.current.scrollTop = mainPageRightRef.current.scrollHeight;
  }, []);

  React.useEffect(() => {
    if (isScrollBottomPending && !loading) {
      scrollBottomHandler();
      setIsScrollBottomPending(false);
    }
  }, [loading, isScrollBottomPending]);

  React.useEffect(() => {
    if (betItems.length < 1 && reports.length < 1 && !layout.desktopDevice) {
      Bus.send({
        event: Bus.events.layout.setSideBarRight,
        data: false,
      });
    }
  }, [betItems.length, reports.length, layout.desktopDevice]);

  // Adding handlers for betslip adding new bet
  React.useEffect(() => {
    const handleBetslipBetAddition = (params) => {
      const { addition } = params;
      // Any new bets in main & quick betslip
      // will switch to the betslip tab
      if (addition) {
        setTabIndex(betslipIndex);
        // Scrolling to bottom

        if (!loading) {
          scrollBottomHandler();
        } else {
          setIsScrollBottomPending(true);
        }
      }
    };

    Bus.on(`betslip:main:${Bus.events.bet.snapshot}`, handleBetslipBetAddition);
    Bus.on(`betslip:quick:${Bus.events.bet.snapshot}`, handleBetslipBetAddition);

    return () => {
      Bus.off(`betslip:main:${Bus.events.bet.snapshot}`, handleBetslipBetAddition);
      Bus.off(`betslip:quick:${Bus.events.bet.snapshot}`, handleBetslipBetAddition);
    };
  }, []);

  const className = cx('betslip-bets', {
    empty: betItems.length === 0,
  });

  const isBetslipActive = tabIndex === betslipIndex;
  const isMyBetsActive = tabIndex === myBetsIndex;
  const isMyOrdersActive = tabIndex === myOrdersIndex;

  const betslipTabClassName = cx('bets__nav__item', {
    active: isBetslipActive,
    'bets__nav__item--mobile': layout.mobileDevice,
  });

  const myBetsTabClassName = cx('bets__nav__item', {
    active: isMyBetsActive,
  });

  const myOrdersTabClassName = cx('bets__nav__item', {
    active: isMyOrdersActive,
  });

  // todo: Render each tab individually
  // inc. body and footer

  const betSlipTab = () => (
    <>
      <div className='sidebar-body sidebar-body--betslip'>
        <div className={className}>
          <div className='bets'>
            <Betslip active />
          </div>
        </div>
      </div>

      {/* {layout.mobileDevice && !isEmpty(reports) ? null : (
        /*    (
        <div className='Sidebar-foot Sidebar-foot--bets'>
          <div className='bets__button'>
            <ToBetHistoryButton className='btn-betslip' label='bet_history' />
          </div>
        </div>
      )  */
      /* )} */}
      <div className='sidebar-foot sidebar-foot--betslip'>
        <PlaceBetControls />
      </div>
    </>
  );

  const myBetsTab = () => {
    if (authenticated !== true) {
      return;
    }
    return (
      <>
        <div className='sidebar-body sidebar-body--bets'>
          <div className={className}>
            <div className='bets'>
              <MyBets active />
            </div>
          </div>
        </div>
        {/* <div className='Sidebar-foot Sidebar-foot--bets'> */}
        {/*  <div className='bets__button'> */}
        {/*    <ToBetHistoryButton className='btn-betslip' label='bet_history' /> */}
        {/*  </div> */}
        {/* </div> */}
      </>
    );
  };

  const myOrdersTab = () => {
    if (authenticated !== true) {
      return;
    }
    return (
      <div className='sidebar-body sidebar-body--orders'>
        <div className={className}>
          <div className='bets'>
            <MyOrders active />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='main-sidebar__container' ref={mainPageRightRef}>
      <ObservableOddsProvider bets={betItems}>
        <div className='sidebar-head' onClick={layout.mobileDevice ? handleBackToMainScreen : null}>
          <div className='bets__nav'>
            <div className={betslipTabClassName} onClick={setBetslipTab}>
              {layout.mobileDevice === true ? (
                <div className='backToMain'>
                  <i className='icons icon--close' />
                </div>
              ) : null}
              {betItems?.find((bet) => bet.betNow) ? (
                <Label message='bet now' />
              ) : (
                <Label message='betslip' />
              )}
            </div>
            {authenticated && layout.mobileDevice === false && (
              <>
                <div className={myBetsTabClassName} onClick={setMyBetsTab}>
                  <Label message='my_bets' />
                </div>
                {isOrderBetAllowed && (
                  <div className={myOrdersTabClassName} onClick={setMyOrdersTab}>
                    <Label message='my_orders' />
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {isBetslipActive && betSlipTab()}
        {isMyBetsActive && myBetsTab()}
        {isOrderBetAllowed && isMyOrdersActive && myOrdersTab()}
      </ObservableOddsProvider>
    </div>
  );
};

export default BetslipContainer;
