import React from 'react';
import { EventLink } from '../../../../shared/components/Sport/SportLinks';

const SgmIcon = ({ event, title }) => (
  <EventLink event={event} urlParams={{ isSgm: true }} className='sgm-icon'>
    <i className='icons icon--sgm-color' title={title} />
  </EventLink>
);

export default SgmIcon;
