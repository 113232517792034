import cx from 'classnames';
import React from 'react';
import { useParams } from 'react-router-dom';
import { TranslationProvider } from '@apollo/core';
import { onSignUpSubmit, useActiveForm } from '@apollo/react-forms';
import CordovaProvider from '../../../core/Cordova/CordovaProvider';
import SignUpForm from '../SignUp/SignUpForm';
import StaticBlock from '../StaticBlock/StaticBlock';
import { cmsStaticBlock } from '../../../core/constants';

const AffiliatePage = () => {
  const routeParams = useParams();
  const { affiliateId = 'none' } = routeParams;

  const classes = cx('affiliate-page', `affiliate-page--${String(affiliateId).toLowerCase()}`);
  const activeForm = useActiveForm('CLIENT_REGISTRATION', onSignUpSubmit);

  return (
    <CordovaProvider>
      <TranslationProvider>
        <div className={classes}>
          <header className='affiliate-page__header'>
            <div className='affiliate-page__container' />
          </header>
          <main className='affiliate-page__body'>
            <div className='affiliate-page__container'>
              <SignUpForm activeForm={activeForm} affiliateId={affiliateId} />
            </div>
          </main>
          <footer className='affiliate-page__footer'>
            <div className='affiliate-page__container'>
              <StaticBlock options={{ source: cmsStaticBlock.Footer }} />
            </div>
          </footer>
        </div>
      </TranslationProvider>
    </CordovaProvider>
  );
};

export default AffiliatePage;
