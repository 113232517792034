import React from 'react';
import cx from 'classnames';
import { isUrlNamesMatch } from '@apollo/routing';

const SportFilterOption = ({ option, value, onSelect }) => (
  <div
    className={cx('sport-filter-option sport-filter-option--default item', {
      active: isUrlNamesMatch(option.value, value),
    })}
    onClick={() => onSelect(option.value)}
  >
    <div className='sport-filter-option__container sport-filter-option__container--name'>
      <div className='sport-filter-option__name'>{option.text}</div>
    </div>
  </div>
);

export default SportFilterOption;
