import React from 'react';
import Label from '../../components/I18n/Label';
import AuthBlock from '../AuthBlock/AuthBlock';
import StaticBlock from '../../components/StaticBlock/StaticBlock';
import { cmsStaticBlock } from '../../../core/constants';

const Blocked = () => (
  <AuthBlock allowClose={false}>
    <div className='b-row'>
      <div className='auth-block-modal__form b-col'>
        <header className='auth-block-modal__container__header'>
          <h1 className='auth-block-modal__container__header__title'>
            <Label message='blocked' />
          </h1>
        </header>
        <div className='auth-block-modal__form__top'>
          <div className='auth-block-modal__form__top__inputs stretch'>
            <div className='block'>
              <StaticBlock options={{ source: cmsStaticBlock.ClientBlocked }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </AuthBlock>
);

export default Blocked;
