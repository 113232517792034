import React, { useMemo, useRef } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { centsToDecimal, useCashout } from '@apollo/core';
import Dropdown from '../../Dropdown/Dropdown';
import Label from '../../I18n/Label';
import Range from '../../Range/Range';

const PartialCashoutPopup = () => {
  const {
    onPartialCashout,
    isPartialCashOutAllowed,
    isPartialCashoutLoading,
    amount,
    currencySymbol,
    estimatedPartialCashOut,
    setIsPartialCashoutExpanded,
    isPartialCashoutExpanded,
    setPartialCashoutAmount,
    partialCashOutAmount,
    partialCashoutCoefficient,
    minPartialCashOut,
  } = useCashout();

  const toggleRef = useRef(null);

  const newBetAmount = useMemo(
    () => amount - partialCashOutAmount / partialCashoutCoefficient,
    [amount, partialCashOutAmount, partialCashoutCoefficient]
  );

  const breakpoint = useMemo(() => {
    const range = _.chain(estimatedPartialCashOut).subtract(minPartialCashOut).round().value();
    const firstBreakPoint = centsToDecimal(minPartialCashOut);
    const secondBreakPoint = centsToDecimal(
      _.chain(range * 0.25)
        .round()
        .add(minPartialCashOut)
        .value()
    );
    const thirdBreakpoint = centsToDecimal(
      _.chain(range * 0.5)
        .round()
        .add(minPartialCashOut)
        .value()
    );
    const fourthBreakpoint = centsToDecimal(
      _.chain(range * 0.75)
        .round()
        .add(minPartialCashOut)
        .value()
    );
    const fifthBreakpoint = centsToDecimal(estimatedPartialCashOut);

    return [firstBreakPoint, secondBreakPoint, thirdBreakpoint, fourthBreakpoint, fifthBreakpoint];
  }, [estimatedPartialCashOut]);

  const cashoutButtonClassName = useMemo(
    () => cx('bBase', { isLoading: isPartialCashoutLoading }),
    [isPartialCashoutLoading]
  );

  if (!estimatedPartialCashOut || !isPartialCashOutAllowed) return null;

  return (
    <Dropdown onExpanded={setIsPartialCashoutExpanded} toggleRef={toggleRef}>
      <div className='bToggle AkIcon-setting-slick-icon' ref={toggleRef} />
      {isPartialCashoutExpanded && (
        <div className='partial'>
          <div className='title'>
            <Label message='partial_cash_out' />
          </div>
          {minPartialCashOut !== estimatedPartialCashOut && (
            <div className='partial__content'>
              <Label
                message='choose_how_much_to_cash_out'
                render={(translation) => <div className='name'>{translation}</div>}
              />
              <Range
                min={minPartialCashOut}
                max={estimatedPartialCashOut}
                initial={estimatedPartialCashOut}
                formatFn={centsToDecimal}
                onChange={setPartialCashoutAmount}
              />
              <div className='dots' />
              <div className='values'>
                {breakpoint.map((breakpoint) => (
                  <span key={breakpoint}>
                    {currencySymbol}
                    {breakpoint}
                  </span>
                ))}
              </div>
            </div>
          )}
          <button type='button' className={cashoutButtonClassName} onClick={onPartialCashout}>
            <Label
              message='cashout'
              render={(translation) => (
                <div>
                  {translation} {currencySymbol}
                  {centsToDecimal(partialCashOutAmount)}
                </div>
              )}
            />
            <Label
              message='partial_cashout_new_bet_amount'
              render={(translation) => (
                <div>
                  {translation} {currencySymbol}
                  {centsToDecimal(newBetAmount)}
                </div>
              )}
            />
          </button>
        </div>
      )}
    </Dropdown>
  );
};

export default PartialCashoutPopup;
