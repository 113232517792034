import React, { useMemo } from 'react';
import { useApplicationState } from '@apollo/core';
import cx from 'classnames';
import _ from 'lodash';
import MarketRow from './Market/MarketRow';
import { EventLink } from '../../../../shared/components/Sport/SportLinks';

const EventSubPeriod = ({ name, id, markets, event, suspended, disabled, template }) => {
  const applicationState = useApplicationState();
  const [authenticated, resolved] = _.at(applicationState, ['authenticated', 'resolved']);
  const { info, outcomesCountByPeriodId } = event;

  const score = useMemo(() => {
    const periodInfo = _.get(info, 'stats.periodInfo');

    if (periodInfo) {
      const currentPeriodInfo = _.find(periodInfo, { periodNumber: id });
      const homeScore = _.get(currentPeriodInfo, 'homeScore');
      const awayScore = _.get(currentPeriodInfo, 'awayScore');
      if (homeScore && awayScore) {
        return `( ${homeScore.replace('.0', '')} : ${awayScore.replace('.0', '')} )`;
      }
    }
    return '';
  }, [id, info]);

  const periodOutcomesAmount = _.get(outcomesCountByPeriodId, id, 0);

  const infoClassName = cx('event-sub-period--info', { authenticated: resolved && authenticated });

  return (
    <div className='event-sub-period'>
      <EventLink className={infoClassName} event={event}>
        <div className='event-sub-period--name'>{name}</div>
        <div className='event-sub-period--score'>{score}</div>
      </EventLink>
      <MarketRow
        template={template}
        event={event}
        markets={markets}
        suspended={suspended}
        disabled={disabled}
      />
      <EventLink className='event__extras' event={event}>
        {periodOutcomesAmount && `+${periodOutcomesAmount}`}
      </EventLink>
    </div>
  );
};

export default EventSubPeriod;
