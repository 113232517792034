import React from 'react';
import { multiBetLabels } from '../../../../../core/utils';
import Label from '../../../../../shared/components/I18n/Label';

const MultiBetTitle = ({ outcomes, className = 'multi-title' }) => {
  if (outcomes.length > 1) {
    const multiBetType = multiBetLabels[outcomes.length] || `${outcomes.length} folds`;
    return (
      <div className={className}>
        <Label message='multibet' />
        {' - '}
        <Label message={multiBetType} />
      </div>
    );
  }
  return null;
};

export default MultiBetTitle;
