import React from 'react';
import {
  getAvailablePages,
  mergeRoutes,
  SiteConfigManager,
  useApplicationState,
} from '@apollo/core';

import {
  clientProfileRoutes,
  COMPONENT_TYPES,
  responsibleGamingRoutes,
  staticRoutes,
} from '@apollo/routing';

import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { map } from 'lodash';

import { paymentRoutes } from '@apollo/routing/src/routes/accountRoutes';
import StaticPage from '../../features/StaticPage/StaticPage';
import SignUp from '../../features/SignUp/SignUp';
import Sportsbook from '../../features/Sports/Sportsbook';
import ResultsPage from '../../features/ResultsPage/ResultsPage';
import Racing from '../../features/Racing/Racing';
import Home from '../../features/Home/Home';
import { compareVersions, scrollToTop } from '../utils';

const availableGeneralRoutes = {
  [COMPONENT_TYPES.CLIENT_PROFILE]: {},
  [COMPONENT_TYPES.PROMOTIONS]: {},
  [COMPONENT_TYPES.PROMOTION_TOURNAMENTS]: {},
};

const MainRouter = () => {
  const { pathname, hash } = useLocation();
  const { push: historyPush } = useHistory();
  const { account, wallet, layout } = useApplicationState();

  React.useEffect(() => {
    if (window.location.search && window.location.search.includes('cmd=')) {
      return;
    }

    if (account && account.login && !account.limitsSet) {
      const { CLIENT_PROFILE } = COMPONENT_TYPES;
      const clientProfilePath = SiteConfigManager.getComponentPath(CLIENT_PROFILE);
      const { responsibleGaming } = clientProfileRoutes;
      const { selfRegulation } = responsibleGamingRoutes;

      const path = `${clientProfilePath}/${responsibleGaming}/${selfRegulation}`;
      if (!pathname || !pathname.includes(path)) {
        historyPush(path);
      }
    } else if (account && account.login && wallet && wallet.balance <= 0) {
      const { CLIENT_PROFILE } = COMPONENT_TYPES;
      const clientProfilePath = SiteConfigManager.getComponentPath(CLIENT_PROFILE);
      const { payment } = clientProfileRoutes;
      const { deposit } = paymentRoutes;
      const path = `${clientProfilePath}/${payment}/${deposit}`;
      if (!pathname || !pathname.includes(path)) {
        historyPush(path);
      }
    }
  }, [account]);

  const minVersions = SiteConfigManager.getConfig('config.minVersions');
  React.useEffect(() => {
    // console.log('Versions:', APP_VERSION, VERSION);
    // console.log('Min. Versions:', minVersions);
    let minVersionPass = false;
    if (CORDOVA) {
      minVersionPass = compareVersions(APP_VERSION, minVersions?.mobileMinVersion);
    } else {
      minVersionPass = compareVersions(VERSION, minVersions?.desktopMinVersion);
    }
    if (!minVersionPass) {
      historyPush(`${pathname}?cmd=version&redirect=true`);
    }
  }, [minVersions]);

  React.useEffect(() => {
    if (hash !== '#ns') {
      // skip page scrolling if location contains #ns
      scrollToTop({ isMobileDevice: layout.mobileDevice });
    }
  }, [pathname, hash]);

  const routes = [
    ...getAvailablePages(availableGeneralRoutes, SiteConfigManager.getExtraConfig('components')),
    {
      path: staticRoutes.staticPage,
      optionalPath: '/:slug',
      component: StaticPage,
    },
    {
      path: staticRoutes.signup,
      component: SignUp,
    },
  ];

  const mergedRoutes = mergeRoutes(routes);
  const racingPath = SiteConfigManager.getComponentPath(COMPONENT_TYPES.RACING);
  const sportPrematchPath = SiteConfigManager.getComponentPath(COMPONENT_TYPES.SPORT_PREMATCH);
  const sportLivePath = SiteConfigManager.getComponentPath(COMPONENT_TYPES.SPORT_LIVE);
  const sportArchivedPath = SiteConfigManager.getComponentPath(COMPONENT_TYPES.RESULTS);

  return (
    <Switch>
      <Route path={[racingPath, `${racingPath}-venue`]} component={Racing} />

      <Route path={[sportPrematchPath, sportLivePath]} component={Sportsbook} />

      <Route path={sportArchivedPath} component={ResultsPage} />

      {map(mergedRoutes, ({ path, component }) => (
        <Route key={path} path={path} component={component} />
      ))}

      <Route path='' component={Home} />

      <Redirect to='' />
    </Switch>
  );
};

export default MainRouter;
