import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { betHistoryConstants, useApplicationState } from '@apollo/core';
import { transactionTypes } from '../../../../core/constants';
import HistoryFilterListItem from '../HitoryFilterListItem';
import DateRangePicker from '../../../../shared/components/DateRangePicker/DateRangePicker';

import TransactionHistoryExport from './TransactionHistoryExport';

const { dateRangeOptions, rangeName } = betHistoryConstants;

const {
  DEPOSIT,
  DEPOSIT_CREATE,
  DEPOSIT_COMPLETE,
  DEPOSIT_CANCEL,
  MANUAL_CORRECTION,
  WITHDRAWAL,
  WITHDRAWAL_CREATE,
  WITHDRAWAL_COMPLETE,
  WITHDRAWAL_CANCEL,
  BONUS_ACTIVATE,
  BONUS_COMPLETE,
  BONUS_DEACTIVATE,
  CORRECTION_BALANCE_DEPOSIT,
  CORRECTION_BALANCE_WITHDRAWAL,
  CORRECTION_BONUS_DEPOSIT,
  CORRECTION_BONUS_WITHDRAWAL,
  BET_PLACE,
  BET_LOSE,
  BET_WIN,
  BET_ROLLBACK,
  BET_VOID,
  BET_CANCEL,
  BET_REJECT,
} = transactionTypes;

const OTHERS = 'OTHERS';

export const WITHDRAWALS_FILTERS = [
  WITHDRAWAL,
  WITHDRAWAL_CREATE,
  WITHDRAWAL_CANCEL,
  WITHDRAWAL_COMPLETE,
];

export const DEPOSITS_FILTERS = [
  DEPOSIT,
  DEPOSIT_COMPLETE,
  DEPOSIT_CANCEL,
  DEPOSIT_CREATE,
  MANUAL_CORRECTION,
];

export const BONUSES_FILTERS = [
  BONUS_ACTIVATE,
  BONUS_COMPLETE,
  BONUS_DEACTIVATE,
  BONUS_ACTIVATE,
  BONUS_COMPLETE,
  BONUS_DEACTIVATE,
];

export const OTHERS_FILTERS = [
  BET_VOID,
  BET_PLACE,
  BET_LOSE,
  BET_WIN,
  BET_ROLLBACK,
  BET_CANCEL,
  BET_REJECT,
  CORRECTION_BALANCE_DEPOSIT,
  CORRECTION_BALANCE_WITHDRAWAL,
  CORRECTION_BONUS_DEPOSIT,
  CORRECTION_BONUS_WITHDRAWAL,
];

const TransactionHistoryFilterList = ({
  activeTimeFilter,
  setActiveTimeFilter,
  activeTypeFilters,
  setActiveTypeFilters,
  range,
  setRange,
}) => {
  const { layout } = useApplicationState();
  const depositChecked = activeTypeFilters.some((item) => DEPOSITS_FILTERS.includes(item));
  const withdrawalChecked = activeTypeFilters.some((item) => WITHDRAWALS_FILTERS.includes(item));
  const othersChecked = activeTypeFilters.some((item) => OTHERS_FILTERS.includes(item));

  return (
    <div className='history-filter'>
      {!layout.mobileDevice ? (
        <div className='history-filter__result'>
          <HistoryFilterListItem
            key={DEPOSIT}
            message={DEPOSIT}
            onClick={() => setActiveTypeFilters(
              depositChecked
                ? _.filter(activeTypeFilters, (type) => !DEPOSITS_FILTERS.includes(type))
                : [...activeTypeFilters, ...DEPOSITS_FILTERS],
            )}
            isChecked={depositChecked}
          />

          <HistoryFilterListItem
            key={WITHDRAWAL}
            message={WITHDRAWAL}
            onClick={() => setActiveTypeFilters(
              withdrawalChecked
                ? _.filter(activeTypeFilters, (type) => !WITHDRAWALS_FILTERS.includes(type))
                : [...activeTypeFilters, ...WITHDRAWALS_FILTERS],
            )}
            isChecked={withdrawalChecked}
          />

          <HistoryFilterListItem
            key={OTHERS}
            message={OTHERS}
            onClick={() => setActiveTypeFilters(
              othersChecked
                ? _.filter(activeTypeFilters, (type) => !OTHERS_FILTERS.includes(type))
                : [...activeTypeFilters, ...OTHERS_FILTERS],
            )}
            isChecked={othersChecked}
          />
        </div>
      ) : null}
      <div className='history-filter__right'>
        {!layout.mobileDevice ? (
          <div className='history-filter__date'>
            {_.map(dateRangeOptions, (rangeOption) => {
              const isChecked = rangeOption === activeTimeFilter;

              return (
                <HistoryFilterListItem
                  key={rangeOption}
                  message={rangeOption}
                  name={rangeOption}
                  isChecked={isChecked}
                  onClick={() => setActiveTimeFilter(rangeOption)}
                  radio
                />
              );
            })}
            <div className='date-range-picker'>
              <DateRangePicker
                disabled={activeTimeFilter !== rangeName}
                stateDateWrapper={(from, to) => {
                  setRange({
                    from: moment(from).startOf('day').valueOf(),
                    to: moment(to).endOf('day').valueOf(),
                  });
                }}
                initialStartDate={moment(_.get(range, 'from'))}
                initialEndDate={moment(_.get(range, 'to'))}
              />
            </div>
          </div>
        ) : null}

        <TransactionHistoryExport
          activeTimeFilter={activeTimeFilter}
          activeTypeFilters={activeTypeFilters}
          range={range}
        />
      </div>
    </div>
  );
};

export default TransactionHistoryFilterList;
