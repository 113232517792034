import React, { useMemo } from 'react';
import { SportSelector, useSportState } from '@apollo/core/src/state/sport/sport';
import { isHidden, SiteConfigManager } from '@apollo/core';
import { chain } from 'lodash';
import { hasMarketTypes } from '@apollo/core/src/utils/status';
import Template from './ScoreTemplates';
import SportEvents from '../../Home/Tabs/Sports/SportEvents';
import { getEventMainMarket } from '../../../core/utils';

const DetailPageHeader = ({ event }) => {
  const [sportsState] = useSportState();
  const { selectedTournamentId } = sportsState;
  const showTournamentEvents = SiteConfigManager.getConfig(
    'config.config.sportsbook.showTournamentEventsOnDetailsPage'
  );

  const selectedTournament = SportSelector.selectTournamentById(selectedTournamentId);
  const events = SportSelector.selectEventsByTournamentId(selectedTournamentId);

  const filteredEvents = useMemo(
    () =>
      chain(events)
        .filter((e) => {
          if (isHidden(e) || !hasMarketTypes(e)) {
            return false;
          }
          if (e.outright) {
            return false;
          }
          return getEventMainMarket(e);
        })
        .value(),
    [event, events]
  );

  if (!selectedTournament) {
    return null;
  }

  return (
    <>
      {showTournamentEvents ? (
        <SportEvents
          key={selectedTournament.id}
          title={selectedTournament.name}
          events={filteredEvents}
        />
      ) : null}
      <Template event={event} />
    </>
  );
};

export default DetailPageHeader;
