import React from 'react';
import qs from 'query-string';
import cx from 'classnames';
import { Link, matchPath, withRouter } from 'react-router-dom';

const CmdLink = (props) => {
  const {
    // Store value
    location,
    history,
    // Navigational props
    path,
    cmd,
    // Styling
    activeClassName = 'active',
    className,
    style,
    children,
    ...properties
  } = props;

  const parsedQuery = qs.parse(location.search);
  const parsedQueryPath = parsedQuery.path || '/';

  const isQueryPathMatches = matchPath(parsedQueryPath, {
    exact: path === '/',
    path,
  });

  const linkClassName = cx(className, {
    [activeClassName]: isQueryPathMatches,
  });

  if (path) {
    parsedQuery.path = path;
  }

  if (cmd) {
    parsedQuery.cmd = cmd;
  }

  const updatedQueryString = qs.stringify(parsedQuery);

  const pathname = `${location.pathname}?${updatedQueryString}`;

  const onClickHandler = (e) => {
    e.preventDefault();

    history.push(pathname);
  };

  return (
    <Link
      to={pathname}
      className={linkClassName}
      onClick={onClickHandler}
      style={style}
    >
      {children}
    </Link>
  );
};

export default withRouter(
  CmdLink,
);
