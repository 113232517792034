import React from 'react';
import SpeedMapItem from './SpeedMapItem';
import { RaceRunnerImage } from '../RaceRunnerImage/RaceRunnerImage';
import Label from '../../../../shared/components/I18n/Label';

const SPEED_BANDS = ['BACKMARKER', 'MIDFIELD', 'PACE', 'LEADER', 'NO DATA'];

const SpeedMap = ({ activeRace, runners = [] }) => (runners.length ? (
  <div className={`speed-map speed-map--${activeRace?.type?.toLowerCase()}`}>
    <h3 className='speed-map__title'>
      <Label message='race speed map' />
    </h3>
    <div className='speed-map__legend'>
      {SPEED_BANDS.map((band) => (
        <div
          key={band}
          className={`speed-label speed-label--${band.toLowerCase()} dot-band dot-band--${band.toLowerCase()}`}
        >
          {band}
        </div>
      ))}
    </div>
    {runners
      .filter((runner) => !runner.scratched)
      .map((runner) => (
        <div className='speed-map__runner' key={runner.runnerId}>
          <div className='event-participants__participant race'>
            <span className='event-participants__participant-index'>
              {runner.number}
            </span>
            <RaceRunnerImage
              runner={runner}
              masked={activeRace?.type !== 'DOG'}
            />
            {/* <span className='event-participants__participant-name race bold'>
            {runner.name}
          </span> */}
          </div>

          <SpeedMapItem
            runnerStatic={runner}
            label={`${runner.number}. ${runner.name}`}
          />
        </div>
      ))}
  </div>
) : null);

export default SpeedMap;
