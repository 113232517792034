import React, { useCallback, useMemo } from 'react';
import useTranslate from '../../../shared/components/I18n/Interpreter';

const HistoryFilterListItem = ({
  text, onClick, isChecked, radio, message,
}) => {
  const t = useTranslate();

  if (radio) {
    return (
      <div className='filter-item radio' onClick={onClick}>
        <label className='radio-container container'>
          <input type='radio' name='radio' checked={isChecked} readOnly />
          <span className='checkmark' />
          <span>{t(message)}</span>
        </label>
      </div>
    );
  }
  return (
    <div
      className='filter-item'
      onClick={onClick}
    >
      <input
        type='checkbox'
        checked={isChecked}
        readOnly
      />
      <label>
        {text ? <span>{t(text)}</span> : <span>{t(message)}</span>}
      </label>
    </div>
  );
};

export default HistoryFilterListItem;
