import React, { useCallback, useMemo } from 'react';
import Label from '../../../../../shared/components/I18n/Label';
import MoneyInput from '../../../../../shared/components/Form/MoneyInput/MoneyInput';

const LimitInput = ({ field, onChange, ...props }) => {
  const { warning } = field;

  const onChangeHandler = useCallback(
    (value) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );

  const renderWarning = useMemo(() => {
    if (!warning) {
      return null;
    }

    const { message, params } = warning;

    return <Label className='warning-message' message={message} params={params} />;
  }, [warning]);

  return (
    <div className='block'>
      <MoneyInput {...props} {...field} onChange={onChangeHandler} focused />
      {renderWarning}
    </div>
  );
};

export default LimitInput;
