import React from 'react';
import _ from 'lodash';
import Label from '../I18n/Label';
import { getParsedFormErrorParameters } from '../../../core/utils';

const GlobalErrors = ({ globalErrors }) => {
  if (!globalErrors.length) {
    return null;
  }

  const renderErrors = _.map(globalErrors, (error) => {
    const { type, message, violationInfo } = error;
    const errorContent = type && type !== 'USER_IS_LOCKED'
      ? <Label message={type} params={getParsedFormErrorParameters({ ...violationInfo, message })} />
      : message;

    return (
      <li key={type} className='errorBlock'>
        {errorContent}
      </li>
    );
  });

  return (
    <ul className='block error__list'>
      {renderErrors}
    </ul>
  );
};

export default GlobalErrors;
