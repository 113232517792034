import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  deleteCredentials,
  DEPOSIT_PROCESSING_TYPE,
  getCredentials,
  getDepositForm,
  onDepositSubmit,
} from '@apollo/react-forms';
import { capitalize, get, isEmpty } from 'lodash';
import { DeferredPromise, NotificationManager, useSettings } from '@apollo/core';
import { useLocation } from 'react-router-dom';
import DefaultPaymentForm from './DefaultPaymentForm';
import PayIdPaymentForm from './PayIdPaymentForm';
import DebitCardPaymentForm from './DebitCardPaymentForm';
import { cmsStaticBlock } from '../../../../core/constants';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import PaymentFormFooter from '../PaymentFormFooter';
import ToastManager from '../../../../core/ToastManager';
import Label from '../../../../shared/components/I18n/Label';
import { openPaymentWindow } from '../../../../core/openWindow';
import StaticBlock from '../../../../shared/components/StaticBlock/StaticBlock';

const BITCOIN_METHOD = 'coinspaid';
const GATEWAY_RESPONSE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  CANCEL: 'CANCEL',
};

const PaymentFormByType = ({ selectedPayment, paymentId, clientId }) => {
  const t = useTranslate();
  const { isCordova } = useSettings();

  const [savedCredentials, setSavedCredentials] = useState([]);
  const [savedCredentialsLoading, setSavedCredentialsLoading] = useState(false);
  const [selectedCredentialsIndex, setSelectedCredentialsIndex] = useState();

  const selectedPaymentName = capitalize(get(selectedPayment, 'name', ''));

  const renderFormFooter = useMemo(
    () => (
      <PaymentFormFooter
        payment={selectedPayment}
        staticBlockSource={`${cmsStaticBlock.DepositPrefix}-${selectedPayment?.integrator}`}
        type='DEPOSIT'
      />
    ),
    [selectedPayment],
  );

  const depositForm = useMemo(() => {
    const form = selectedPayment ? getDepositForm(selectedPayment) : null;
    // todo: This logic should be moved to the Apollo react Forms Lib
    if (form && selectedCredentialsIndex >= 0) {
      const propsToRemove = [
        'assignToken',
        'paymentMethod',
        'cardnumber',
        'expmonth',
        'expyear',
        'saveMyDataForLater',
      ];
      form.fields = form.fields.filter(
        (f) => !propsToRemove.find((inputName) => f.name === inputName),
      );
      const hosteddataid = form.fields.find((obj) => obj.name === 'hosteddataid');
      if (hosteddataid) {
        hosteddataid.value = savedCredentials[selectedCredentialsIndex]?.data?.token;
      }
    }
    return form;
  }, [selectedPayment, savedCredentials, selectedCredentialsIndex]);

  // Redirect Gateway Response
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const gatewayResponse = searchParams.get('response');

  const handleGatewayResponse = (response) => {
    if (response) {
      switch (response.toUpperCase()) {
        case GATEWAY_RESPONSE.SUCCESS:
          ToastManager.success(() => <Label message='gateway_deposit_success' />);
          break;

        case GATEWAY_RESPONSE.ERROR:
          ToastManager.error(() => <Label message='gateway_deposit_error' />);
          break;

        case GATEWAY_RESPONSE.CANCEL:
        default:
          ToastManager.warning(() => <Label message='gateway_deposit_cancel' />);
          break;
      }
    }
  };

  useEffect(() => {
    handleGatewayResponse(gatewayResponse);
  }, [gatewayResponse]);

  const buildTransparentForm = (formElem) => {
    const inputs = formElem.querySelectorAll('input,select');
    const inputHtml = Array.from(inputs).reduce((html, el) => {
      if (el.disabled) {
        return html;
      }
      return `${html}<input type='hidden' id='${el.id}' name='${el.name}' value='${el.value}' />`;
    }, '');

    const formId = formElem.getAttribute('id');
    const formName = formElem.getAttribute('name') || formId;
    const formAction = formElem.getAttribute('action');
    const formMethod = formElem.getAttribute('method');
    const html = `<html lang='en'><head><title>Redirecting</title></head>
            <body style='background: #000f44; color: #FFF;'><form id='${formId}' name='${formName}'
              action='${formAction}'
              method='${formMethod}'>${inputHtml}</form>
              <script type='text/javascript'>
              document.getElementById('${depositForm.name}').submit();
              </script>
            </body></html>`;
    return `data:text/html;base64,${btoa(html)}`;
  };

  const windowRef = useRef(null);
  const windowInterval = useRef(null);

  const openWindowHandler = (event) => {
    clearInterval(windowInterval.current);

    const url = new URL(event.url);
    if (url.pathname.includes('/account/payment/deposit/')) {
      // We've returned to our website, so we can close the gateway window
      clearInterval(windowInterval.current);
      handleGatewayResponse(url.searchParams.get('response'));
      windowRef.current.close();
      windowRef.current = null;
    } else {
      // Look for errors thrown by the gateway
      windowInterval.current = setInterval(() => {
        windowRef.current.executeScript(
          {
            code: 'document.body.innerHTML',
          },
          (values) => {
            const name = values[0];
            if (name.includes('transaction may not be completed')) {
              clearInterval(windowInterval.current);
              handleGatewayResponse(GATEWAY_RESPONSE.ERROR);
              windowRef.current.close();
              windowRef.current = null;
            }
          },
        );
      }, 500);
    }
  };

  const submitHandler = useCallback(
    (props) => {
      const { redirectUrl, processingType, transactionToken, gatewayData } = props;

      if (!isEmpty(redirectUrl) && redirectUrl.startsWith(BITCOIN_METHOD)) {
        NotificationManager.display({
          type: 'bitcoin',
          message: 'bitcoin',
          data: {
            redirectUrl,
          },
        });
        return;
      }

      switch (processingType) {
        case DEPOSIT_PROCESSING_TYPE.EFT:
          ToastManager.success(() => <Label message='deposit_request_created' />);
          break;

        case DEPOSIT_PROCESSING_TYPE.FORM_POST:
          {
            const formElem = document.getElementById(depositForm.name);
            if (formElem) {
              // Populate form input values with data from the API
              const gatewayProps = JSON.parse(gatewayData || '');
              if (gatewayProps) {
                Object.keys(gatewayProps).forEach((k) => {
                  if (gatewayProps[k] != null) {
                    const elem = formElem.querySelector(`[name=${k}]`);
                    if (elem) {
                      elem.value = gatewayProps[k];
                    }
                  }
                });
                if (gatewayProps.formAction) {
                  formElem.setAttribute('action', gatewayProps.formAction);
                }
              }

              // Exclude these inputs from the form post
              // const propsToDisable = ['saveMyDataForLater', 'hosteddataid', 'assignToken'];
              const propsToDisable = selectedCredentialsIndex >= 0
                ? ['saveMyDataForLater', 'assignToken']
                : ['saveMyDataForLater', 'hosteddataid'];

              propsToDisable.forEach((k) => {
                const elem = formElem.querySelector(`[name=${k}]`);
                if (elem) {
                  elem.disabled = true;
                }
              });

              if (isCordova) {
                const pageContentUrl = buildTransparentForm(formElem);
                windowRef.current = window.open(
                  pageContentUrl,
                  '_blank',
                  'location=no,hideurlbar=yes,toolbar=no',
                );
                windowRef.current.addEventListener('loadstart', openWindowHandler);
              } else {
                formElem.submit();
              }
            }
          }
          break;

        case DEPOSIT_PROCESSING_TYPE.REDIRECT:
          if (isEmpty(redirectUrl) === true) {
            return;
          }
          if (isCordova) {
            // const ref = openPaymentWindow(redirectUrl);
            windowRef.current = window.open(
              redirectUrl,
              '_blank',
              'location=no,hideurlbar=yes,toolbar=no',
            );
            windowRef.current.addEventListener('loadstart', openWindowHandler);
          } else {
            window.location = redirectUrl;
          }
          break;

        case DEPOSIT_PROCESSING_TYPE.POPUP:
        default:
          if (isEmpty(redirectUrl) === true) {
            return;
          }
          windowRef.current = openPaymentWindow(redirectUrl);
          break;
      }
    },
    [depositForm, isCordova, savedCredentials, selectedCredentialsIndex],
  );

  const confirmationHandler = useCallback(
    (formData, _ref) => {
      const deferred = new DeferredPromise();
      NotificationManager.display({
        type: 'confirm',
        title: 'confirm_deposit',
        // $[amount!$]
        message: (
          <Label
            className='client-info__label'
            message='confirm_deposit_amount'
            params={{
              amount: formData.chargetotal || formData.amount,
            }}
          />
        ),
        data: {
          children: <StaticBlock options={{ source: cmsStaticBlock.ConfirmADeposit }} />,
          onConfirm: () => {
            deferred.resolve(onDepositSubmit(formData, _ref));
          },
          onClose: () => {
            deferred.reject(null);
          },
        },
      });
      return deferred;
    },
    [depositForm, isCordova],
  );

  const latestLoadCredentialsRequest = useRef();

  const loadCredentials = useCallback(() => {
    setSavedCredentialsLoading(true);
    const request = (latestLoadCredentialsRequest.current = getCredentials({
      pmId: paymentId,
      clientId,
    })
      .then((res) => {
        setSavedCredentials(
          res.items.map((item) => ({
            ...item,
            data: JSON.parse(item.data),
          })),
        );
        setSelectedCredentialsIndex(res.items.length > 0 ? 0 : undefined);
      })
      .finally(() => {
        if (request === latestLoadCredentialsRequest.current) {
          setSavedCredentialsLoading(false);
        }
      }));
  }, [paymentId, clientId, setSelectedCredentialsIndex, setSavedCredentials]);

  const deleteSavedCredentialsHandler = useCallback(
    ({ index }) => {
      deleteCredentials({
        pmId: paymentId,
        clientId,
        id: savedCredentials[index]?.id,
      })
        .then(() => {
          loadCredentials();
        })
        .catch(() => {
          loadCredentials();
        });
    },
    [paymentId, clientId, savedCredentials, loadCredentials],
  );

  const selectSavedCredentialsHandler = useCallback(
    (index) => {
      setSelectedCredentialsIndex(index);
    },
    [depositForm, setSelectedCredentialsIndex],
  );

  useEffect(() => {
    if (paymentId && clientId) {
      loadCredentials();
    }
  }, [paymentId, clientId, loadCredentials]);

  return (
    <div className='details'>
      <div className='details__title'>
        <span className='icon AIcon-wallet'> </span>
        <span>{t('deposit_with', { name: selectedPaymentName })}</span>
      </div>
      <div className='details__inputs'>
        {selectedPayment.depositType === DEPOSIT_PROCESSING_TYPE.FORM_POST ? (
          <DebitCardPaymentForm
            selectedCredentialsIndex={selectedCredentialsIndex}
            savedCredentials={savedCredentials}
            selectSavedCredentialsHandler={selectSavedCredentialsHandler}
            deleteSavedCredentialsHandler={deleteSavedCredentialsHandler}
          />
        ) : null}
        {selectedPayment.depositType === DEPOSIT_PROCESSING_TYPE.PAYID ? (
          <PayIdPaymentForm
            selectedPayment={selectedPayment}
            savedCredentials={savedCredentials}
            savedCredentialsLoading={savedCredentialsLoading}
            loadCredentials={loadCredentials}
          />
        ) : (
          <DefaultPaymentForm
            selectedPayment={selectedPayment}
            depositForm={depositForm}
            confirmationHandler={confirmationHandler}
            submitHandler={submitHandler}
            isCordova={isCordova}
          />
        )}
      </div>
      <div className='payment__static-content'>{renderFormFooter}</div>
    </div>
  );
};
export default PaymentFormByType;
