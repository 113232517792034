import React from 'react';
import ParticipantLogo from '../Logo/ParticipantLogo';

const OutcomeLogo = ({ event, isHome, isAway, outcomeName = '', className = '' }) => {
  if (isHome || outcomeName.startsWith(event?.participants?.home?.shortName)) {
    return (
      <ParticipantLogo participant={event?.participants?.home} size='xl' className='outcome-logo' />
    );
  }

  if (isAway || outcomeName.startsWith(event?.participants?.away?.shortName)) {
    return (
      <ParticipantLogo participant={event?.participants?.away} size='xl' className={className} />
    );
  }

  return null;
};

export default OutcomeLogo;
