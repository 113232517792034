import React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import Label from '../../../shared/components/I18n/Label';

const Navigation = ({ types, onChange, activeType }) => {
  if (types.length === 1) {
    return null;
  }

  return (
    <div className='block__content__types'>
      {
      _.map(types, (type) => {
        const { title } = type;
        const elementClassName = cx(['element', type === activeType && 'active']);

        return (
          <div
            key={title}
            onClick={() => onChange(type)}
            className={elementClassName}
          >
            <Label message={title} />
          </div>
        );
      })
    }
    </div>
  );
};

export default Navigation;
