import React from 'react';

const ToggleCheckbox = ({
  onChangedHandler = null,
  disabled = false,
  checked = false,
}) => (
  <div className='toggle'>
    <label className={`switch ${disabled ? 'disabled' : ''}`}>
      <input
        type='checkbox'
        disabled={disabled}
        onChange={onChangedHandler}
        checked={Boolean(checked)}
      />
      <span className='slider' />
    </label>
  </div>
);

export default ToggleCheckbox;
