import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { func, number } from 'prop-types';
import { useApplicationState } from '@apollo/core';
import { scrollToTop } from '../../../core/utils';

const Pagination = ({ pages, currentPage, changeCurrentPage }) => {
  const { layout } = useApplicationState();

  const items = [];
  let leftSide = currentPage - 2;
  let rightSide = currentPage + 2;

  if (rightSide > pages - 1) rightSide = pages - 1;
  if (leftSide <= 0) leftSide = 0;

  const onChangeCurrentPage = (newCurrentPage) => {
    changeCurrentPage(newCurrentPage);
    scrollToTop({ isMobileDevice: layout.mobileDevice });
  };

  const onClickNext = useCallback(() => {
    if (currentPage < pages - 1) {
      onChangeCurrentPage(currentPage + 1);
    }
  }, [currentPage, pages, changeCurrentPage]);

  const onClickPrev = useCallback(() => {
    if (currentPage >= 1) {
      onChangeCurrentPage(currentPage - 1);
    }
  }, [currentPage, changeCurrentPage]);

  const classNamePrev = useMemo(
    () => cx('pagination-item prev', { disabled: currentPage === 0 }),
    [currentPage],
  );

  const classNameNext = useMemo(
    () => cx('pagination-item next', { disabled: currentPage === pages - 1 }),
    [currentPage, pages],
  );

  const classNameCurrent = (item) => cx('pagination-item', { active: currentPage === item });

  for (let item = leftSide; item <= rightSide; item++) {
    items.push(
      <div
        key={item}
        className={classNameCurrent(item)}
        onClick={() => {
          onChangeCurrentPage(item);
        }}
      >
        {item + 1}
      </div>,
    );
  }

  if (pages === 0) return null;

  if (pages === 1) {
    return (
      <div className='pagination-container'>
        {' '}
        {items}
        {' '}
      </div>
    );
  }

  return (
    <div className='pagination-container'>
      <span className={classNamePrev} onClick={onClickPrev} />
      {items}
      <span className={classNameNext} onClick={onClickNext} />
    </div>
  );
};

export default Pagination;

Pagination.propTypes = {
  pages: number,
  currentPage: number,
  changeCurrentPage: func.isRequired,
};

Pagination.defaultProps = {
  pages: 1,
  currentPage: 1,
};
