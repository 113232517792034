import * as React from 'react';
import { SportSelector, useSportState } from '@apollo/core/src/state/sport/sport';
import useHasTournamentOnlyOutrightEvents from '../useHasTournamentOnlyOutrightEvents';
import { EVENT_LIST_TEMPLATE, getEventListTemplateBySport } from '../../../../core/utils';

const useEventListTemplate = () => {
  const [template, setTemplateState] = React.useState(EVENT_LIST_TEMPLATE.CLASSIC);

  const [sportsState] = useSportState();
  const { selectedTournamentId } = sportsState;

  const selectedTournament = SportSelector.selectTournamentById(selectedTournamentId);
  const events = SportSelector.selectEventsByTournamentId(selectedTournamentId);

  const hasOnlyOutrightEvents = useHasTournamentOnlyOutrightEvents(events);

  const setTemplate = React.useCallback(
    (newTemplate) => {
      if (newTemplate) {
        setTemplateState(newTemplate);
      } else if (hasOnlyOutrightEvents) {
        setTemplateState(EVENT_LIST_TEMPLATE.OUTRIGHT);
      } else {
        setTemplateState(
          selectedTournament
            ? getEventListTemplateBySport(selectedTournament?.sportName)
            : EVENT_LIST_TEMPLATE.CLASSIC,
        );
      }
    },
    [hasOnlyOutrightEvents, selectedTournament],
  );

  React.useEffect(() => {
    setTemplate();
  }, [setTemplate]);

  return [template, setTemplate, selectedTournament];
};

export default useEventListTemplate;
