import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BetManager, Bus, RacingManager, useApplicationState } from '@apollo/core';
import { isEmpty, map } from 'lodash';
import { RacingActions, useRacingState } from '@apollo/core/src/state/racing/racing';
import { BET_EVENT_TYPE, SRM_MARKETS_LIST } from '@apollo/core/src/constants';
import Theme from 'themeSource/custom';
import Label from '../../../../shared/components/I18n/Label';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import { RACE_STATUS } from '../../../../core/constants';
import Odds from '../../../../shared/components/Odds/Odds';
import Loader from '../../../../shared/components/Loader/Loader';
import SRMOdds from './SRMOdds.js';
import { formatOdds, getSortedRunners, oddsAnimation } from '../../../../core/utils';
import RaceRunnerRow from '../RaceRunnerRow';
import SelectedOddsButtons from '../../../../shared/components/SelectedOdds/SelectedOddsButtons';

const SameRaceMultiPage = ({ runners, raceStatic, displaySettings = {} }) => {
  const t = useTranslate();
  const { layout } = useApplicationState();
  const [isExpanded, setIsExpanded] = useState(!!Theme.EXPANDED_SELECTION);
  const [outcome, setOutcome] = useState(null);
  const [isFetchingOutcome, setIsFetchingOutcome] = useState(false);
  const [racingState, racingDispatcher] = useRacingState();
  const { activeRace, srmSelections, marketsByType } = racingState;

  const { isFlucs, isSpeedMap, isForm } = displaySettings;

  const clearSelection = useCallback(() => {
    racingDispatcher({
      type: RacingActions.RACING_STATE_UPDATE,
      payload: {
        srmSelections: [],
      },
    });
    setOutcome();
  }, []);

  const addToBet = useCallback(
    (e) => {
      if (layout.mobileDevice) {
        oddsAnimation(
          e.target.classList.contains('btn') ? e.target : e.target.parentNode,
          true,
          outcome?.odds ? formatOdds(outcome?.odds, 3, 1) : '',
        );
      }
      BetManager.selectBet({
        betEventType: BET_EVENT_TYPE.RACING,
        event: activeRace,
        eventId: activeRace.raceId,
        marketTypeId: 'SAME_RACE_MULTI',
        marketId: marketsByType?.SAME_RACE_MULTI?.marketId,
        outcomeId: outcome.outcomeId,
        // todo
        // Only a single bet of the same group can exist in the betslip
        // We need to identify if betGroups are required for Racing
        betGroups: [0],
        isNew: true,
      });
    },
    [outcome],
  );

  const betNow = useCallback(() => {
    BetManager.selectBet({
      betEventType: BET_EVENT_TYPE.RACING,
      event: activeRace,
      eventId: activeRace.raceId,
      marketTypeId: 'SAME_RACE_MULTI',
      marketId: marketsByType?.SAME_RACE_MULTI?.marketId,
      outcomeId: outcome.outcomeId,
      // todo
      // Only a single bet of the same group can exist in the betslip
      // We need to identify if betGroups are required for Racing
      betGroups: [0],
      isNew: true,
      betNow: true,
    });

    Bus.send({
      event: Bus.events.layout.setSideBarRight,
      data: true,
    });
  }, [activeRace, outcome, marketsByType]);

  useEffect(() => {
    const selectionsObj = Object.assign({}, ...srmSelections);
    if (srmSelections.length > 1) {
      setIsFetchingOutcome(true);
      RacingManager.getSameRaceMultiOutcome({
        raceId: activeRace.raceId,
        marketId: marketsByType?.SAME_RACE_MULTI?.marketId,
        selections: selectionsObj,
      })
        .then(({ outcome }) => {
          setOutcome(outcome);
          setIsFetchingOutcome(false);
        })
        .catch((err) => {
          setIsFetchingOutcome(false);
        });
    }
  }, []);

  const runnersByNum = runners.reduce((acc, r) => ({ ...acc, [r.number]: r }), {});

  const srmOddsRunners = runners.reduce((acc, run) => {
    const srmodds = run?.runnerOdds;
    return [...acc, { runner: run, scratched: run.scratched, ...srmodds }];
  }, []);

  let favOddRunner = null;
  let favOddValue = Infinity;
  srmOddsRunners?.forEach((r) => {
    const odds = r.srmWinOdds;
    if (!r.scratched && odds < favOddValue) {
      favOddRunner = r.runnerId;
      favOddValue = odds;
    }
  });

  const [sortByField, setSortByField] = useState({
    field: 'runner',
    asc: true,
  });

  const onSort = (field) => {
    setSortByField({
      field,
      asc: sortByField.field === field ? !sortByField.asc : true,
    });
  };

  const sortedRunners = useMemo(() => {
    const sortedRunners = getSortedRunners(srmOddsRunners, {}, sortByField.field, sortByField.asc);
    return sortedRunners.sort((a, b) => a.scratched - b.scratched);
  }, [srmOddsRunners, sortByField]);

  return (
    <div className='single_race_multi_wrapper'>
      <div className='tournament tournament--classic racelist'>
        <div className='tournament__header'>
          <div className='event event--classic'>
            <div className='event__main-period'>
              <div className='event__info'>
                <div className='event-participants'>
                  <div
                    className={`event-participants__participant sorting ${
                      sortByField.field === 'runner' && (sortByField.asc ? 'asc' : 'desc')
                    }`}
                    onClick={() => onSort('runner')}
                  >
                    <Label message='Runner' />
                  </div>
                </div>
              </div>
              <div className='event__market-row'>
                <div className='event__market-row-market'>
                  <div className='srm__row_wrapper'>
                    <div className='srm__row_values'>
                      <span
                        className={`sorting ${
                          sortByField.field === SRM_MARKETS_LIST[0]
                          && (sortByField.asc ? 'asc' : 'desc')
                        }`}
                        onClick={() => onSort(SRM_MARKETS_LIST[0])}
                      >
                        {t(SRM_MARKETS_LIST[0])}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='event-group event-group--races'>
          {!isEmpty(sortedRunners)
            && map(sortedRunners, (r) => (
              <RaceRunnerRow
                key={r.runnerId}
                runner={r.runner}
                runnerStatic={raceStatic?.runners?.find((runner) => runner.runnerId === r.runnerId)}
                activeRace={activeRace}
                isFlucs={isFlucs}
                isForm={isForm}
                isSpeedMap={isSpeedMap}
              >
                <div className='event__market-row'>
                  <div className='event__market-row-market'>
                    <div className='srm__row_wrapper'>
                      {SRM_MARKETS_LIST.map((mt, index) => (
                        <div className='srm__row_values' key={mt}>
                          <div>
                            <div className='srm__row_market__name'>{t(mt)}</div>
                            <SRMOdds
                              setOutcome={setOutcome}
                              race={activeRace}
                              runner={r?.runner}
                              odds={r?.[mt]}
                              selected={
                                srmSelections.findIndex(
                                  (selection) => selection[r?.number] === index + 1,
                                ) > -1
                              }
                              id={index + 1}
                              setIsFetchingOutcome={setIsFetchingOutcome}
                            />
                          </div>
                          {activeRace.status === RACE_STATUS.OPEN
                          && favOddRunner === r.runnerId
                          && !r.scratched
                          && index === 0 ? (
                            <Label message='fav' className='favorite' />
                            ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </RaceRunnerRow>
            ))}
        </div>
        {srmSelections?.length ? (
          <div className='selectedOdds'>
            <div className='collapse__wrapper'>
              <div className='header' onClick={() => setIsExpanded(!isExpanded)}>
                <div className='icon'>
                  <span className={`AIcon-angle-${isExpanded ? 'down' : 'up'}`} />
                </div>
                <div className='title'>
                  {isExpanded ? (
                    <Label message='Hide Selections' />
                  ) : (
                    <Label message='Show Selections' />
                  )}
                </div>
              </div>
              {isExpanded ? (
                <div className='content'>
                  {srmSelections.map((sel, index) => {
                    const runner = Object.keys(sel)?.[0];
                    return (
                      <div key={index} className='row'>
                        <div className='index'>
                          {index + 1}
                          .
                        </div>
                        <div className='name'>
                          {runnersByNum?.[runner]?.name}
                          {' '}
                          (
                          {runner}
                          )
                        </div>
                        <div className='market'>{t(SRM_MARKETS_LIST[sel[runner] - 1])}</div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
              <div className='action_section'>
                <div className='legs'>
                  <span>{t('Legs')}</span>
                  {srmSelections && <span className='ml-1'>{srmSelections.length}</span>}
                  <div className='odds'>
                    {!outcome && !isFetchingOutcome ? (
                      <span className='multi-add-selection'>{t('add_selection')}</span>
                    ) : null}
                    {outcome && !isFetchingOutcome ? (
                      <>
                        <span>{t('odds')}</span>
                        <span className='value value-odds'>
                          {outcome && <Odds value={outcome?.odds} decimal={3} minDecimal={1} />}
                        </span>
                      </>
                    ) : null}
                    {isFetchingOutcome && <Loader className='fetchingOutcome' />}
                  </div>
                </div>
                <div className='add_to_bet'>
                  <SelectedOddsButtons
                    addToBet={(e) => addToBet(e)}
                    betNow={betNow}
                    addDisabled={!outcome}
                    clearSelection={clearSelection}
                    clearDisabled={!srmSelections?.length}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SameRaceMultiPage;
