import React, { createContext } from 'react';
import useStatscoreState from '../../../state/StatScore/useStatscoreState';
import { sportService } from '../../../core/constants';

const { PREMATCH, LIVE } = sportService;

export const StatscoreContext = createContext();

const StatscoreProvider = ({ children }) => {
  const statscoreState = useStatscoreState();

  return <StatscoreContext.Provider value={statscoreState}>{children}</StatscoreContext.Provider>;
};

export default StatscoreProvider;
