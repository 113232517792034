import React, { useCallback, useEffect, useMemo } from 'react';
import { capitalize, find, get, isEmpty, isUndefined } from 'lodash';
import {
  DeferredPromise,
  NotificationManager,
  SiteConfigManager,
  useApplicationState,
} from '@apollo/core';

import { clientProfileRoutes, COMPONENT_TYPES, paymentRoutes } from '@apollo/routing';

import {
  deleteCredentials,
  getCredentials,
  getWithdrawalForm,
  onWithdrawalSubmit,
  PAYMENT_METHODS_OPERATION_TYPE_WITHDRAWAL,
  usePaymentMethods,
  WITHDRAWAL_PROCESSING_TYPE,
} from '@apollo/react-forms';

import { useHistory, useRouteMatch } from 'react-router-dom';
import Theme from 'themeSource/custom';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import PaymentFormFooter from './PaymentFormFooter';
import ToastManager from '../../../core/ToastManager';
import Label from '../../../shared/components/I18n/Label';
import PaymentMethodList from './PaymentMethodList/PaymentMethodList';
import FormCom from '../../../shared/components/Form/FormCom';
import { handleGlobalErrorsWithToast } from '../../../core/utils';
import { cmsStaticBlock, paymentTypes } from '../../../core/constants';
import SelectField from '../../../shared/components/Form/Fields/SelectField';
import { openWithdrawWindow } from '../../../core/openWindow';
import StaticBlock from '../../../shared/components/StaticBlock/StaticBlock';
import Loader from '../../../shared/components/Loader';

const { payment } = clientProfileRoutes;
const { withdrawal } = paymentRoutes;
const { CLIENT_PROFILE } = COMPONENT_TYPES;

const Withdraw = () => {
  const ThemePaymentMethodList = Theme.PaymentMethodList || PaymentMethodList;
  const { paymentMethods, isLoading } = usePaymentMethods(
    PAYMENT_METHODS_OPERATION_TYPE_WITHDRAWAL,
  );
  const {
    params: { paymentId },
  } = useRouteMatch();
  const { push: historyPush } = useHistory();
  const clientProfilePath = SiteConfigManager.getComponentPath(CLIENT_PROFILE);

  const t = useTranslate();
  const applicationState = useApplicationState();
  const clientId = `${get(applicationState, 'account.id')}`;

  const [savedCredentials, setSavedCredentials] = React.useState([]);

  const [selectedCredentialsIndex, setSelectedCredentialsIndex] = React.useState();

  useEffect(() => {
    if (paymentId || isLoading || paymentMethods.length === 0) {
      return;
    }
    historyPush(`${clientProfilePath}/${payment}/${withdrawal}/${paymentMethods[0].id}`);
  }, [paymentMethods, paymentId, isLoading, clientProfilePath, historyPush]);

  const selectedPayment = useMemo(
    () => find(paymentMethods, ({ id }) => `${id}` === paymentId),
    [paymentId, paymentMethods],
  );

  const selectedPaymentName = capitalize(get(selectedPayment, 'name', ''));
  const selectedPaymentType = get(selectedPayment, 'withdrawalType', '');

  const renderFormFooter = useMemo(
    () => (
      <PaymentFormFooter
        payment={selectedPayment}
        staticBlockSource={`${cmsStaticBlock.WithdrawalPrefix}-${selectedPayment?.integrator}`}
        type={paymentTypes.WITHDRAWAL}
      />
    ),
    [selectedPayment],
  );

  const withdrawalForm = useMemo(
    () => (selectedPayment ? getWithdrawalForm(selectedPayment) : null),
    [selectedPayment],
  );

  const submitHandler = useCallback(
    ({ redirectUrl, processingType }) => {
      ToastManager.success(() => <Label message='withdrawal request created' />);

      switch (processingType) {
        case WITHDRAWAL_PROCESSING_TYPE.EFT:
          {
            // do nothing
          }
          break;

        case WITHDRAWAL_PROCESSING_TYPE.REDIRECT:
        default:
          if (isEmpty(redirectUrl) === true) {
            return;
          }
          openWithdrawWindow(redirectUrl);
          break;
      }
    },
    [withdrawalForm],
  );

  const confirmationHandler = useCallback(
    (formData, _ref) => {
      const deferred = new DeferredPromise();
      NotificationManager.display({
        type: 'confirm',
        title: 'confirm_withdrawal',
        // $[amount!$]
        message: (
          <Label
            className='client-info__label'
            message='confirm_withdrawal_amount'
            params={{ amount: formData.amount }}
          />
        ),
        data: {
          children: <StaticBlock options={{ source: cmsStaticBlock.ConfirmAWithdrawal }} />,
          onConfirm: () => {
            deferred.resolve(onWithdrawalSubmit(formData, _ref));
          },
          onClose: () => {
            deferred.reject(null);
          },
        },
      });
      return deferred;
    },
    [withdrawalForm],
  );

  const loadsCredentials = useCallback(() => {
    getCredentials({ pmId: paymentId, clientId }).then((res) => {
      setSelectedCredentialsIndex(undefined);
      setSavedCredentials(
        res.items.map((item) => ({
          ...item,
          data: JSON.parse(item.data),
        })),
      );
    });
  }, [paymentId, clientId]);

  const deleteWithdrawalHandler = useCallback(
    ({ index }) => {
      deleteCredentials({ pmId: paymentId, clientId, id: savedCredentials[index]?.id })
        .then(() => {
          loadsCredentials();
        })
        .catch(() => {
          loadsCredentials();
        });
    },
    [paymentId, clientId, savedCredentials, loadsCredentials],
  );

  useEffect(() => {
    if (paymentId && clientId) {
      loadsCredentials();
    }
  }, [paymentId, clientId, loadsCredentials]);

  if (isLoading) {
    return <Loader />;
  }

  if (paymentMethods.length === 0) {
    return null;
  }

  return (
    <div className='main__page__account__block__content deposit-withdrawal'>
      <StaticBlock options={{ source: cmsStaticBlock.MakeAWithdrawal }} />

      <ThemePaymentMethodList
        payments={paymentMethods}
        route={`${clientProfilePath}/${payment}/${withdrawal}`}
        type={paymentTypes.WITHDRAWAL}
        active={selectedPayment}
      >
        <div className='details'>
          <div className='details__title'>
            <span className='icon AIcon-wallet'> </span>
            <span>{t('withdrawal_with', { name: selectedPaymentName })}</span>
          </div>
          <div className='details__inputs'>
            {selectedPaymentType === WITHDRAWAL_PROCESSING_TYPE.EFT && (
              <div className='row'>
                <SelectField
                  field={{
                    name: 'stored_accounts',
                    label: 'stored_accounts',
                    placeholder: 'stored_accounts',
                    value: selectedCredentialsIndex,
                    translateOptions: false,
                    options: [
                      { text: t('create_new'), value: undefined },
                      ...savedCredentials.map((withdrawal, index) => ({
                        text: withdrawal.data?.accountName,
                        value: index,
                      })),
                    ],
                  }}
                  onChange={(e) => setSelectedCredentialsIndex(e)}
                />
                {!isUndefined(selectedCredentialsIndex) && (
                  <div className='block__btn'>
                    <span
                      className='icon AkrIcon-trash'
                      onClick={() => deleteWithdrawalHandler({ index: selectedCredentialsIndex })}
                    />
                  </div>
                )}
              </div>
            )}
            <FormCom
              key={selectedCredentialsIndex}
              form={withdrawalForm}
              action={confirmationHandler}
              additionalValues={{ pmId: paymentId, clientId }}
              buttonText='withdrawal'
              onSubmit={submitHandler}
              initialValues={
                savedCredentials.length && !isUndefined(selectedCredentialsIndex)
                  ? {
                    ...savedCredentials[selectedCredentialsIndex].data,
                    metaId: `${savedCredentials[selectedCredentialsIndex].id}`,
                  }
                  : {}
              }
              onError={handleGlobalErrorsWithToast}
            />
          </div>
          <div className='payment__static-content'>{renderFormFooter}</div>
        </div>
      </ThemePaymentMethodList>
    </div>
  );
};

export default Withdraw;
