import React from 'react';
import cx from 'classnames';

const IconFavorite = ({ isActive, className, ...props }) => {
  const iconClassName = cx(
    {
      'icon-favorite-new AkIcon-favorite-icon': true,
      active: isActive,
    },
    className,
  );

  return (
    <i {...{ className: iconClassName, ...props }} />
  );
};

export default IconFavorite;
