import React from 'react';

import {
  clientProfileRoutes,
  CLIENT_PROFILE_COMPONENT_TYPES,
  paymentRoutes,
} from '@apollo/routing';

import Deposit from './Deposit';
import Withdraw from './Withdraw';
import ClientProfileSubRouter from '../ClientProfileSubRouter';

const { payment } = clientProfileRoutes;
const {
  deposit,
  withdrawal,
} = paymentRoutes;

const getAvailableRoutes = (clientProfilePath) => ({
  [CLIENT_PROFILE_COMPONENT_TYPES.PAYMENT_DEPOSIT]: {
    path: `${clientProfilePath}/${payment}/${deposit}`,
    optionalPath: '/:paymentId?',
    component: Deposit,
  },
  [CLIENT_PROFILE_COMPONENT_TYPES.PAYMENT_WITHDRAWAL]: {
    path: `${clientProfilePath}/${payment}/${withdrawal}`,
    optionalPath: '/:paymentId?',
    component: Withdraw,
  },
});

const PaymentRouter = ({ tabs }) => (
  <ClientProfileSubRouter
    tabs={tabs}
    getAvailableRoutes={getAvailableRoutes}
    parentRoute={payment}
  />
);

export default PaymentRouter;
