import React, { useCallback, useMemo, useRef, useState } from 'react';
import { identity, isObject, map, noop } from 'lodash';
import cx from 'classnames';
import { any, array, bool, func, string } from 'prop-types';
import useTranslate from '../../I18n/Interpreter';
import Label from '../../I18n/Label';
import Dropdown from '../../Dropdown/Dropdown';
import ErrorBlock from '../Input/ErrorBlock';

const defaultOptionRenderer = (option, translate) => (
  <span>{translate(isObject(option) ? option.text : option)}</span>
);

const Select = ({
  label,
  name,
  value,
  disabled,
  autoFocus,
  onFocus,
  onBlur,
  onChange,
  placeholder,
  error,
  touched,
  errors,
  rules,
  options,
  optionRenderer,
  isRequiredSymbol,
  withTranslate,
  translateOptions,
}) => {
  const toggleRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(autoFocus);
  const [isActive, setIsActive] = useState(false);
  const t = useTranslate();

  const onExpandedHandle = useCallback(
    (expanded) => {
      if (expanded === isExpanded) {
        return;
      }
      if (expanded === true) {
        setIsActive(true);
        setIsExpanded(true);
        onFocus(name);
      } else if (expanded === false) {
        setIsActive(false);
        setIsExpanded(false);
        onBlur(name);
      }
    },
    [isExpanded, name, onBlur, onFocus],
  );

  const selectClassName = React.useMemo(
    () => cx([
      'select',
      disabled && 'disabled',
      isActive && 'active',
      touched && !errors[0] && 'success',
    ]),
    [disabled, touched, errors, isActive],
  );

  const selectValueClassName = cx('select-value', 'select-option', value ? null : 'placeholder');

  const renderLabel = useMemo(
    () => label && (
    <div className='ui__title'>
      {withTranslate ? <Label message={label} /> : label}
      {isRequiredSymbol && <span className='required'> *</span>}
    </div>
    ),
    [label],
  );

  const renderOptions = useMemo(
    () => isExpanded && (
    <div className='select-options'>
      {map(options, (option, index) => (
        <div
          key={option.value + index}
          className='select-option'
          onClick={() => onChange(option)}
        >
          {optionRenderer(option, translateOptions ? t : identity)}
        </div>
      ))}
    </div>
    ),
    [options, isExpanded, withTranslate, onChange],
  );

  const placeholderValue = withTranslate ? t(placeholder) : placeholder;

  return (
    <div className='block block--input-select'>
      {renderLabel}
      <div className='block__wrap' ref={toggleRef}>
        <Dropdown
          onExpanded={onExpandedHandle}
          disabled={disabled}
          // toggleRef={toggleRef}
        >
          <div className={selectClassName}>
            {renderOptions}
            <div className={selectValueClassName}>
              {value ? optionRenderer(value, translateOptions ? t : identity) : placeholderValue}
            </div>
          </div>
        </Dropdown>
        <ErrorBlock
          withTranslate={withTranslate}
          error={error}
          // errors={errors}
          // rules={rules}
        />
      </div>
      <input id={name} name={name} value={value?.value || ''} type='hidden' disabled={disabled} />
    </div>
  );
};

Select.propTypes = {
  label: string,
  value: any,
  active: bool,
  touched: bool,
  disabled: bool,
  autoFocus: bool,
  onFocus: func,
  onBlur: func,
  onChange: func,
  placeholder: string,
  error: string,
  errors: array,
  rules: array,
  options: array,
  optionRenderer: func,
  isRequiredSymbol: bool,
  withTranslate: bool,
  translateOptions: bool,
};

Select.defaultProps = {
  label: null,
  value: null,
  active: false,
  touched: false,
  disabled: false,
  autoFocus: false,
  onFocus: noop,
  onBlur: noop,
  onChange: noop,
  placeholder: null,
  error: null,
  errors: [],
  rules: [],
  options: [],
  optionRenderer: defaultOptionRenderer,
  isRequiredSymbol: false,
  withTranslate: false,
  translateOptions: false,
};

export default Select;
