import React, { useMemo } from 'react';
import { RaceTypesMap } from '@apollo/core/src/constants';
import { DateService, isMultilegsMarket } from '@apollo/core';
import {
  getOutcomeSelections,
  getRaceNumbersFromOutcomeInfo,
  isBoxedSelections,
} from '../../../core/utils';
import { RacingIcon } from '../../../shared/components/Racing/RacingIcon';
import Label from '../../../shared/components/I18n/Label';
import BetCouponTeams from './BetCouponTeams';
import { MARKET_PLACES } from '../../../core/constants';
import BetItemEventLink from '../../../shared/components/BetItemEventLink/BetItemEventLink';
import MultiStatusIcon from './MultiStatusIcon';

const ExoticSeletions = ({ outcome }) => {
  const { marketTypeId, parameterValue } = outcome.outcomeInfo;
  const selections = useMemo(
    () => getOutcomeSelections({ ...outcome, selection: `${parameterValue}` }),
    [outcome, parameterValue]
  );

  const isBoxed = useMemo(
    () =>
      isBoxedSelections({
        ...outcome,
        selection: outcome.outcomeInfo.parameterValue,
      }),
    [outcome]
  );

  return isBoxed ? (
    <div className='event__name event__name--hard'>
      <Label message='boxed' />
      :&nbsp;
      {selections.map((sel) => sel.runner.replace('Boxed-', '')).join(', ')}
    </div>
  ) : (
    MARKET_PLACES[marketTypeId]?.map((p, index) => (
      <div className='event__name event__name--hard' key={p}>
        <Label message={`place${p}`} />
        :&nbsp;
        {selections
          .filter((sel) => sel.place === p)
          .map((sel) => sel.runner)
          .join(', ')}
        <MultiStatusIcon outcomeInfo={outcome?.outcomeInfo} outcomePosition={index} />
      </div>
    ))
  );
};

const MultilegsSeletions = ({ outcome }) => {
  const { outcomeName, marketName } = outcome.outcomeInfo;

  if (!_.isString(marketName) || !_.isString(outcomeName)) {
    return null;
  }

  const raceNumbers = getRaceNumbersFromOutcomeInfo(outcome.outcomeInfo);
  const runners = outcomeName.split('/');

  return raceNumbers?.map((num, index) => (
    <div className='event__name event__name--hard' key={num}>
      {`R${num}`}
      :&nbsp;
      {runners[index]?.replaceAll(',', ', ')}
      <MultiStatusIcon outcomeInfo={outcome?.outcomeInfo} outcomePosition={index} />
    </div>
  ));
};

const BetExoticOrMultilegsSelections = ({ outcome }) => {
  const { marketTypeId, sportId, eventStartTime } = outcome.outcomeInfo;

  const isMultilegs = isMultilegsMarket(marketTypeId);
  return (
    <div className='event event--racing'>
      <div className='event__outcome-name'>
        <BetItemEventLink outcomeInfo={outcome?.outcomeInfo}>
          <RacingIcon id={sportId} name={RaceTypesMap?.[sportId]?.typeName} />
          <Label message={marketTypeId} />
        </BetItemEventLink>
      </div>
      <div className='coupon__block-details'>
        <BetCouponTeams outcome={outcome} />
        <div className='event-time event-time--bet'>
          {DateService.getFormattedDateWithTime(eventStartTime)}
        </div>
        {isMultilegs ? (
          <MultilegsSeletions outcome={outcome} />
        ) : (
          <ExoticSeletions outcome={outcome} />
        )}
      </div>
    </div>
  );
};

export default BetExoticOrMultilegsSelections;
