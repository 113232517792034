import React from 'react';
import cx from 'classnames';
import { betHistoryConstants } from '@apollo/core';
import { BET_STATUSES } from '@apollo/core/src/constants';
import Label from '../../../shared/components/I18n/Label';

const { BET_STATUS_ALL, BET_STATUS_SETTLED } = betHistoryConstants;
const { UNSETTLED } = BET_STATUSES;

export const myBetsFilters = [
  // {
  //   name: 'bet_status_to_sell',
  //   betStatus: BET_STATUS_TO_SELL,
  // },
  {
    name: 'Unsettled',
    betStatus: UNSETTLED,
  },
  {
    name: 'Settled',
    betStatus: BET_STATUS_SETTLED,
  },
  {
    name: 'All bets',
    betStatus: BET_STATUS_ALL,
  },
];

const MyBetsFilter = ({ onSelectBetStatus, selectedBetStatus }) => (
  <div className='bets__filter'>
    {
        myBetsFilters.map(({ betStatus, name }) => (
          <div
            key={name}
            className={cx(['bets__filter__item', betStatus === selectedBetStatus && 'active'])}
            onClick={() => onSelectBetStatus(betStatus)}
          >
            <Label
              message={name}
              render={(msg) => msg}
            />
          </div>
        ))
      }
  </div>
);

export default MyBetsFilter;
