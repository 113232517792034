import React from 'react';
import _ from 'lodash';
import { DateService, SettingsManager } from '@apollo/core';
import moment from 'moment';
import { getIso2FromIso3 } from '@apollo/core/src/utils';
import { sportService } from '../../../../core/constants';

const { PREMATCH } = sportService;

const TopEventTime = ({ event }) => {
  const [startTime, sportService, matchClock] = _.at(event, [
    'startTime',
    'sportService',
    'info.score.matchClock',
  ]);

  if (sportService === PREMATCH) {
    const date = DateService.getFormattedDate(startTime);

    // const time = DateService.getFormattedTime(startTime);
    const { lang } = SettingsManager.settings;
    const time = moment(startTime)
      .locale(getIso2FromIso3(lang) || 'en')
      .format('HH:mm');

    return (
      <>
        <span className='date'>{date}</span>
        <span className='time'>{time}</span>
      </>
    );
  }

  if (_.toNumber(matchClock) === -1) {
    return '';
  }

  if (matchClock) {
    return `${(_.toNumber(matchClock) / 60).toFixed(0)}\``;
  }

  return '';
};

export default TopEventTime;
