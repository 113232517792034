import React, { useCallback } from 'react';
import { onChangePasswordSubmit, useActiveForm } from '@apollo/react-forms';
import { Bus } from '@apollo/core';
import ToastManager from '../../../core/ToastManager';
import Label from '../../../shared/components/I18n/Label';
import FormCom from '../../../shared/components/Form/FormCom';
import Loader from '../../../shared/components/Loader';

const ChangePassword = () => {
  const { submit, activeStep } = useActiveForm('CHANGE_PASSWORD', onChangePasswordSubmit);

  const submitHandler = useCallback(() => {
    ToastManager.success(() => <Label message='New password was changed successfully' />);
    Bus.emit(Bus.events.auth.logout);
  }, []);

  return (
    <div className='wrapper wrapper--profile'>
      <div className='details-profile details-profile--password change__password'>
        {activeStep ? (
          <>
            {/* <div className='logo AIcon-lock' /> */}
            <h2 className='content-header'>
              <Label message='Change password' />
            </h2>
            <FormCom
              action={submit}
              form={activeStep}
              onSubmit={submitHandler}
              buttonText='submit'
            />
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default ChangePassword;
