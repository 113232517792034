import React from 'react';
import { SportsbookProvider } from '@apollo/core';

import {
  SelectedCategoryProvider,
  SelectedEventQueryRoute,
  SelectedSportProvider,
  SelectedTournamentProvider,
  SportsbookRouter,
} from '@apollo/routing';

import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import SportList from './SportList/SportList';
import TournamentList from './TournamentList/TournamentList';
import EventList from './EventList/EventList';
import ResultsTimeFilter from './ResultsTimeFilter/ResultsTimeFilter';
import ResultsCalendar from './ResultsCalendar/ResultsCalendar';
import ResultsSportLoader from './ResultsSportLoader';
import ResultsCategoryLoader from './ResultsCategoryLoader';
import { getDayEnd, getDayStart } from '../../core/utils';
import { sportService } from '../../core/constants';
import SportsbookLoader from '../Sports/SportsbookLoader';
import EventLoader from '../Sports/EventLoader';
import ResultsDetails from './ResultsDetails';
import BetslipContainer from '../Betslip/BetSlipContainer';
import MainBody from '../../shared/components/MainBody/MainBody';
import SportsPathHandler from '../Sports/SportsPathHandler';
import SidebarRightContainer from '../../shared/components/Sidebar/SidebarRightContainer';

/*
  Returning initial date filters for results page
  By default - selecting current day in filter
  Current day is single filter type
  For range dates should be used range filter type
*/
function getInitialDateFilters(eventStartTime) {
  const date = moment(eventStartTime);
  const fromDate = getDayStart(date);
  const toDate = getDayEnd(date);

  return {
    dateFilterType: 'single',
    fromDate,
    toDate,
  };
}

const ResultsSportsbookRouter = ({ children }) => (
  <SportsbookRouter>
    <ResultsPage>{children}</ResultsPage>
  </SportsbookRouter>
);

const ResultsPage = () => {
  const routeParams = useParams();
  const { sportName, categoryName, tournamentName } = routeParams;

  const history = useHistory();

  const eventStartTime = _.get(history, 'location.state.eventStartTime');

  const initialDateFilters = getInitialDateFilters(eventStartTime);

  const options = {
    id: 'results',
    sportService: sportService.ARCHIVED,

    selectedSportUrlName: sportName,
    selectedCategoryUrlName: categoryName,
    selectedTournamentUrlName: tournamentName,

    fromDate: initialDateFilters.fromDate,
    toDate: initialDateFilters.toDate,
    dateFilterType: initialDateFilters.dateFilterType,
  };

  return (
    <SportsbookProvider value={options}>
      <SportsPathHandler>
        <div className='main__page main__page--results'>
          <div className='main__sidebar main__sidebar--left'>
            <div className='main__page__left '>
              <SportsbookLoader>
                <div className='main__page__left__tree'>
                  <div className='bottom'>
                    <SportList />
                  </div>
                </div>

                <SelectedSportProvider>
                  <ResultsSportLoader>
                    <SelectedCategoryProvider>
                      <ResultsCategoryLoader>
                        <div className='main__page__left__tree'>
                          <div className='bottom'>
                            <TournamentList />
                          </div>
                        </div>
                        <div className='main__page__left__list'>
                          <div className='resultGame'>
                            <SelectedTournamentProvider>
                              <EventList />
                            </SelectedTournamentProvider>
                          </div>
                        </div>
                      </ResultsCategoryLoader>
                    </SelectedCategoryProvider>
                  </ResultsSportLoader>
                </SelectedSportProvider>
              </SportsbookLoader>
            </div>
          </div>

          <MainBody className='main__body--sport-results'>
            <div className='main__page__navigation'>
              <ResultsCalendar className='datePicker' />
              <ResultsTimeFilter className='dateFilter' />
            </div>

            <SelectedEventQueryRoute>
              <EventLoader>
                <ResultsDetails />
              </EventLoader>
            </SelectedEventQueryRoute>
          </MainBody>
        </div>
      </SportsPathHandler>
    </SportsbookProvider>
  );
};
export default ResultsSportsbookRouter;
