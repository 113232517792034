import React from 'react';
import { SportProvider } from '@apollo/core';
import cx from 'classnames';

import SportListItemHeader from './SportListItemHeader';
import CategoryList from './CategoryList';

const SportListItem = (props) => {
  const { onToggleOpenedSportUrlName, sport, selected, active } = props;

  const toggleExpand = React.useCallback(
    () => onToggleOpenedSportUrlName(sport.urlName),
    [onToggleOpenedSportUrlName, sport.urlName],
  );

  const className = cx('bottom__block two', {
    active,
    selected,
  });

  return (
    <>
      <SportListItemHeader
        className={className}
        sportName={sport.name}
        toggleExpand={toggleExpand}
        active={active}
        id={sport.id}
      />

      {active && (
        <SportProvider sport={sport}>
          <CategoryList />
        </SportProvider>
      )}
    </>
  );
};

export default SportListItem;
