import React from 'react';
import { useApplicationState, usePreferences } from '@apollo/core';
import _ from 'lodash';
import IconFavorite from '../../../../../shared/components/IconFavorite/IconFavorite';

const SoccerScoreTemplateIcons = ({ event }) => {
  const id = _.get(event, 'id');
  const { selectFavoriteEvent, getIsEventFavorite } = usePreferences();
  const isFavorite = getIsEventFavorite({ eventId: id });
  const applicationState = useApplicationState();
  const [authenticated, resolved] = _.at(applicationState, ['authenticated', 'resolved']);

  const renderFavoriteIcon = React.useMemo(() => {
    if (!authenticated || !resolved) {
      return null;
    }

    const selectFavoriteEventHandler = () => {
      selectFavoriteEvent({ eventId: id });
    };

    return <IconFavorite isActive={isFavorite} onClick={selectFavoriteEventHandler} />;
  }, [authenticated, resolved, isFavorite, selectFavoriteEvent, id]);

  return (
    <div className='additional'>
      {/* <EventExternalIconList event={event} /> */}
      {renderFavoriteIcon}
    </div>
  );
};

export default SoccerScoreTemplateIcons;
