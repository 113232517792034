import React, {
  useRef, useState, useEffect, useCallback,
} from 'react';

import {
  isSuspended,
  isInactive,
  BetManager,
  useHighlightedOutcome, Bus,
} from '@apollo/core';

import cx from 'classnames';
import _ from 'lodash';
import Odds from '../../../shared/components/Odds';

const ANIMATION_TIMEOUT_MS = 3000;

export default function EditBetMarketSwitcherOdds({
  className = '',
  event,
  marketType,
  market,
  outcome,
  suspended,
  disabled,
}) {
  const {
    selected,
    editing,
  } = useHighlightedOutcome(outcome.id);

  const prevOddsRef = useRef(_.toNumber(outcome.odds));
  const resetDirectionClassTimerRef = useRef(null);
  const [directionClassName, setDirectionClassName] = useState(null);

  const isOutcomeSuspended = suspended || isSuspended(outcome);
  const isOutcomeDisabled = disabled || isInactive(outcome);

  // Preventing from adding suspended/disabled/editing bets
  const isOutcomeInvalid = (isOutcomeSuspended || isOutcomeDisabled || editing);
  const onClickHandle = useCallback(
    () => {
      if (isOutcomeInvalid) {
        return;
      }

      BetManager.selectBet({
        event,
        eventId: event.id,
        marketTypeId: marketType.id,
        marketId: market.id,
        outcomeId: outcome.id,
        betGroups: marketType.betGroups,
        isNew: true,
      });

      // Show Betslip
      Bus.send({
        event: Bus.events.layout.setSideBarRight,
        data: true,
      });
    },
    [event, outcome, marketType, market, isOutcomeInvalid],
  );

  useEffect(() => {
    const newOdds = _.toNumber(outcome.odds);
    const prevOdds = prevOddsRef.current;
    const dOdds = newOdds - prevOdds;
    prevOddsRef.current = newOdds;

    if (!dOdds) {
      return;
    }

    // window.clearTimeout(resetDirectionClassTimerRef.current);
    setDirectionClassName(dOdds > 0 ? 'up' : 'down');
    resetDirectionClassTimerRef.current = window.setTimeout(
      () => {
        setDirectionClassName(null);
      },
      ANIMATION_TIMEOUT_MS,
    );
    return () => {
      window.clearTimeout(resetDirectionClassTimerRef.current);
    };
  }, [outcome.odds]);

  const outcomeClassName = cx(className, 'odd', directionClassName, {
    disabled: (isOutcomeSuspended || isOutcomeDisabled),
    selected: editing || selected,
  });

  if (isOutcomeDisabled && !isOutcomeSuspended) {
    return null;
  }

  return (
    <span
      className={outcomeClassName}
      onClick={onClickHandle}
    >
      <span className='name'>{outcome.name}</span>
      <span className='odd'>
        {
          directionClassName && (
            <i key='arrow' className={`arrow AkIcon-long-arrow-${directionClassName}-icon`} />
          )
        }

        <Odds value={outcome.odds} />

        {
          (isOutcomeSuspended || isOutcomeDisabled) && (
            <div key='suspend' className='disabled-cover' />
          )
        }
      </span>
    </span>
  );
}
