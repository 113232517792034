import React from 'react';

const FilterDropdown = ({ onClose, children }) => {
  // const ref = React.useRef();
  React.useEffect(() => {
    const handleClick = (event) => {
      // NOTE: Next code closes dropdown only on outside click. Use it if needed
      // let targetEl = event.target;
      //
      // do {
      //   if (targetEl === ref.current) {
      //     return;
      //   }
      //
      //   targetEl = targetEl.parentNode;
      // } while (targetEl);

      onClose();
    };

    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, [onClose]);

  return <div className='sportsbook-filter-dropdown'>{children}</div>;
};

export default FilterDropdown;
