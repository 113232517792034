import React, { useState, useCallback, useMemo } from 'react';
import { noop, isString } from 'lodash';
import cx from 'classnames';
import {
  func, shape, array, bool, string,
} from 'prop-types';
import useTranslate from '../../../I18n/Interpreter';
import { getFieldError } from '../../../../../core/utils';
import Label from '../../../I18n/Label';

const FileField = ({ field, onChange }) => {
  const {
    label,
    placeholder,
    disabled,
    name,
    withTranslate,
  } = field;

  const t = useTranslate();
  const error = getFieldError(field);

  const [dragOver, setDragOver] = useState(false);
  const [fileName, setFileName] = useState(false);

  const handleChange = useCallback((event) => {
    const file = event.target.files[0];
    onChange(file);
    setDragOver(false);
    setFileName(file.name);
  }, [onChange]);

  const handleDragOverEvent = useCallback(() => {
    setDragOver(true);
  }, []);

  const handleDragLeaveEvent = useCallback(() => {
    setDragOver(false);
  }, []);

  const renderError = useMemo(() => {
    if (!error || !isString(error)) {
      return null;
    }

    return (
      <ul className='error__list'>
        <li className='errorBlock '>

          {withTranslate
            ? <Label message={error} />
            : error}

        </li>
      </ul>
    );
  }, [error, withTranslate]);

  const classes = React.useMemo(() => cx(
    'file-input-label',
    {
      'border-active': dragOver,
      disabled,
    },
  ), [disabled, dragOver]);

  return (
    <>
      {label && <Label message={label} />}
      <div className='file-input-wrapper'>
        <label htmlFor={`upload-${name}`} className={classes}>
          {fileName
            ? <span className='file-input-placeholder file-input-name'>{fileName}</span>
            : (
              <span className='file-input-placeholder'>
                <div className='icon-container svg-file' />
                {/* <span className='type-doc'> */}
                {/*   <span>{t('Upload your')}</span> */}
                {/*   <span>{`{${t('Driving License')}}`}</span> */}
                {/* </span> */}

                <span className='desc-doc-block'>
                  <span>{t('Upload your')}</span>
                  {' '}
                  <span className='desc-doc'>{t(placeholder)}</span>
                </span>
                <span className='desc-doc-drag'>{t('Drag and drop or browse your file here')}</span>
              </span>
            )}
          <div className='file-input-bg' />
          <input
            className='file-input'
            id={`upload-${name}`}
            type='file'
            disabled={disabled}
            onBlur={handleDragLeaveEvent}
            onFocus={handleDragOverEvent}
            onDragEnter={handleDragOverEvent}
            onDragLeave={handleDragLeaveEvent}
            onChange={handleChange}
          />
          { renderError }

        </label>
      </div>
    </>
  );
};

FileField.propTypes = {
  field: shape({
    touched: bool,
    errors: array,
    warnings: array,
    label: string,
    placeholder: string,
    name: string.isRequired,
    disabled: bool,
  }).isRequired,
  onChange: func,
};

FileField.defaultProps = {
  onChange: noop,
};

export default FileField;
