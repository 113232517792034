import React, { useCallback, useMemo, useRef, useState } from 'react';
import _, { noop } from 'lodash';
import cx from 'classnames';
import { array, bool, func, oneOf, string } from 'prop-types';
import { useApplicationState } from '@apollo/core';
import useTranslate from '../../I18n/Interpreter';
import Label from '../../I18n/Label';
import ErrorBlock from './ErrorBlock';
import { getCurrencySymbol } from '../../../../core/utils';

const Input = ({
  type,
  label,
  name,
  success,
  disabled,
  autoFocus,
  onFocus,
  onBlur,
  onChange,
  placeholder,
  error,
  errors,
  rules,
  required,
  value,
  step,
  inputMode,
  isRequiredSymbol,
  amountIcon,
  withTranslate,
  readOnly,
  buttonCopy,
  verify,
  edit,
  leftContent = null,
  // optional autocomplete options
  autocompleteOptions,
}) => {
  const applicationState = useApplicationState();
  const currency = _.get(applicationState, 'wallet.currency');
  const t = useTranslate();

  const inputRef = useRef(null);
  const [isShowing, setIsShowing] = useState(false);
  const [isActive, setIsActive] = useState(false);
  // const [isEditable, setIsEditable] = useState(!withActionButtons);

  const copyToClipboard = useCallback(() => {
    inputRef.current.select();
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
  }, [inputRef]);

  const onBlurHandle = (e) => {
    setIsActive(false);
    onBlur(e);
  };

  const onFocusHandle = (e) => {
    setIsActive(true);
    onFocus(e);
  };

  // const toggleEdit = useCallback(() => {
  //   setIsEditable((prevIsEditable) => !prevIsEditable);
  // }, []);

  const currencySymbol = getCurrencySymbol(currency);
  const inputType = useMemo(() => {
    if (type !== 'password') {
      return type;
    }

    return isShowing ? 'text' : 'password';
  }, [isShowing, type]);

  const renderShowSymbol = useMemo(() => {
    if (type !== 'password') {
      return false;
    }

    const showSymbolClassName = cx('ui__eye-icon', isShowing && '_visible');

    return <div className={showSymbolClassName} onClick={() => setIsShowing(!isShowing)} />;
  }, [isShowing, type]);

  const renderAmountIcon = useMemo(
    () => amountIcon && (
    <div className='amountIcon'>
      <span>{currencySymbol}</span>
    </div>
    ),
    [amountIcon, currencySymbol],
  );

  const renderLabel = useMemo(
    () => label && (
    <div className='ui__title'>
      {withTranslate ? <Label message={label} /> : label}
      {isRequiredSymbol && <span className='required'> *</span>}
    </div>
    ),
    [label, withTranslate, isRequiredSymbol],
  );

  const renderBlockedEditButton = useMemo(() => {
    if (disabled) {
      return (
        <div className='input-action-button-group'>
          <div className='input-action-button'>
            <span className='icon AIcon-lock' />
          </div>
        </div>
      );
    }

    if (edit) {
      return (
        <div className='input-action-button-group'>
          <div className='input-action-button'>
            <div className='svg-edit-pen' />
          </div>
        </div>
      );
    }

    if (buttonCopy) {
      return (
        <div className='input-action-button-group'>
          {document.queryCommandSupported('copy') && (
            <div className='input-action-button' onClick={copyToClipboard}>
              <div className='svg-copy' />
            </div>
          )}
        </div>
      );
    }

    return null;
  }, [disabled, edit, buttonCopy, copyToClipboard]);

  const renderVerifyButton = useMemo(() => {
    if (verify) {
      return (
        <div className='input-action-button-group'>
          <div className='input-action-button'>
            <span className='icon AIcon-varified-user' />
          </div>
        </div>
      );
    }
    if (verify === false) {
      return (
        <>
          <div className='input-action-button-group'>
            <div className='input-action-button'>
              <span className='icon AIcon-unvarified-user' />
            </div>
          </div>
          <div className='bBase verify-btn'>
            <span>Verify</span>
          </div>
        </>
      );
    }
  }, [verify]);

  const inputClassName = cx([
    'ui__field',
    error && 'error',
    success && 'success',
    verify && 'verify',
    verify === false && 'not-verify',
  ]);

  const rowClassName = cx(['row', verify === false && 'not-verify']);

  const containerClassName = cx([
    'block block---input',
    error && 'error',
    success && 'success',
    verify && 'verify',
    verify === false && 'not-verify',
    value && 'has-value',
    isActive && 'active',
    inputType,
  ]);

  const renderInput = (
    <input
      ref={inputRef}
      id={name}
      name={name}
      value={value}
      type={inputType}
      className={inputClassName}
      inputMode={inputMode}
      onChange={onChange}
      step={step}
      onFocus={onFocusHandle}
      onBlur={onBlurHandle}
      placeholder={withTranslate ? t(placeholder) : placeholder}
      autoFocus={autoFocus}
      disabled={disabled}
      readOnly={readOnly}
      required={required || undefined}
      list={`${name}-options`}
    />
  );

  return (
    <div className={containerClassName}>
      {renderLabel}
      <div className='block__wrap'>
        <label className={rowClassName}>
          {renderAmountIcon}
          {renderInput}
          {renderShowSymbol}
          {renderBlockedEditButton}
          {renderVerifyButton}
          {leftContent}

          {autocompleteOptions && autocompleteOptions?.length ? (
            <datalist id={`${name}-options`}>
              {autocompleteOptions.map((option, i) => (
                <option value={option} key={`${i}-${option}`} />
              ))}
            </datalist>
          ) : null}
        </label>
        <ErrorBlock withTranslate={withTranslate} error={error} errors={errors} rules={rules} />
      </div>
    </div>
  );
};

Input.defaultProps = {
  type: 'text',
  label: null,
  disabled: false,
  autoFocus: false,
  placeholder: '',
  error: '',
  onFocus: noop,
  onBlur: noop,
  onChange: noop,
  value: '',
  errors: [],
  rules: [],
  isRequiredSymbol: false,
  amountIcon: false,
  withTranslate: false,
  readOnly: false,
  success: false,
};

Input.propTypes = {
  type: oneOf(['text', 'password', 'date', 'tel', 'number']),
  value: string,
  label: string,
  name: string.isRequired,
  success: bool,
  disabled: bool,
  autoFocus: bool,
  placeholder: string,
  error: string,
  errors: array,
  rules: array,
  onFocus: func,
  onBlur: func,
  onChange: func,
  isRequiredSymbol: bool,
  amountIcon: bool,
  withTranslate: bool,
  readOnly: bool,
};

export default Input;
