import React from 'react';
import {
  clientProfileRoutes,
  historyRoutes,
  CLIENT_PROFILE_COMPONENT_TYPES,
} from '@apollo/routing';
import BetHistory from './BetHistory/BetHistory';
import MyOrders from './MyOrders/MyOrders';
import TransactionHistory from './TransactionHistory/TransactionHistory';
import CasinoHistory from './CasinoHistory/CasinoHistory';
import ClientProfileSubRouter from '../ClientProfileSubRouter';

const { history } = clientProfileRoutes;
const {
  betHistory,
  transactionHistory,
  casinoHistory,
  myOrders,
} = historyRoutes;

const getAvailableRoutes = (clientProfilePath) => ({
  [CLIENT_PROFILE_COMPONENT_TYPES.HISTORY_BET_HISTORY]: {
    path: `${clientProfilePath}/${history}/${betHistory}`,
    component: BetHistory,
  },
  [CLIENT_PROFILE_COMPONENT_TYPES.HISTORY_MY_ORDERS]: {
    path: `${clientProfilePath}/${history}/${myOrders}`,
    component: MyOrders,
  },
  [CLIENT_PROFILE_COMPONENT_TYPES.HISTORY_TRANSACTION_HISTORY]: {
    path: `${clientProfilePath}/${history}/${transactionHistory}`,
    component: TransactionHistory,
  },
  [CLIENT_PROFILE_COMPONENT_TYPES.HISTORY_CASINO_HISTORY]: {
    path: `${clientProfilePath}/${history}/${casinoHistory}`,
    component: CasinoHistory,
  },
});

const HistoryRouter = ({ tabs }) => (
  <ClientProfileSubRouter
    tabs={tabs}
    getAvailableRoutes={getAvailableRoutes}
    parentRoute={history}
  />
);

export default HistoryRouter;
