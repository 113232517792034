import { constants } from '@apollo/core';

const { DISPLAY_LAYOUTS } = constants;

// it contains default mapping for market type if no exists marketType.displayLayout form server
// TODO: DON'T EDIT THIS FILE. Use sportsbook/entities-settings in BO to change displayLayout for market.

export const marketTypesMappingObject = {
  3382: { id: 3382, display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR },
  2200: { id: 308, display_layout: DISPLAY_LAYOUTS.HANDICAP },
  1419: { id: 1419, display_layout: DISPLAY_LAYOUTS.HANDICAP },
  1438: { id: 308, display_layout: DISPLAY_LAYOUTS.GAME_SCORE },
  308: { id: 308, display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR },
  310: { id: 310, display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR },
  312: { id: 312, display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR },
  314: { id: 314, display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR },
  316: { id: 316, display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR },
  318: { id: 318, display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR },
  320: { id: 320, display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR },
  332: { id: 332, display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR },
  336: { id: 336, display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR },
  338: { id: 338, display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR },
  340: { id: 340, display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR },
  3383: { id: 3383, display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR },
  2: {
    id: 2,
    name: 'Match Winner',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  8: {
    id: 8,
    name: '1st Half/Match',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  12: {
    id: 12,
    name: 'Correct Score',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  14: {
    id: 14,
    name: 'Double Chance',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  16: {
    id: 16,
    name: '1st Quarter - Correct Score',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  18: {
    id: 18,
    name: '3-way Handicap',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.HANDICAP,
  },
  24: {
    id: 24,
    name: '1st Half - Double Chance',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  28: {
    id: 28,
    name: '1st Quarter - Winner',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  32: {
    id: 32,
    name: '3rd Quarter - Winner',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  34: {
    id: 34,
    name: '4rd Quarter - Winner',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  58: {
    id: 58,
    name: '1st Half - 3-way Handicap',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.HANDICAP,
  },
  78: {
    id: 78,
    name: 'Highest Scoring Half',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  98: {
    id: 98,
    name: '2nd Quarter - Correct Score',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  140: {
    id: 140,
    name: '3rd Quarter - Correct Score',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  152: {
    id: 152,
    name: '3rd Quarter - Double Chance',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  154: {
    id: 154,
    name: '4th Quarter - Double Chance',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  164: {
    id: 164,
    name: '1st Half - 1X2',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  194: {
    id: 194,
    name: '1st Quarter - Total 3-way',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  196: {
    id: 196,
    name: '2nd Quarter - Total 3-way',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  198: {
    id: 198,
    name: '3rd Quarter - Total 3-way',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  200: {
    id: 200,
    name: '4th Quarter - Total 3-way',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  204: {
    id: 204,
    name: '1st Quarter - Race To',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.RACE_TO,
  },
  206: {
    id: 206,
    name: '2nd Quarter - Race To',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.RACE_TO,
  },
  208: {
    id: 208,
    name: '3rd Quarter - Race To',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.RACE_TO,
  },
  210: {
    id: 210,
    name: '4th Quarter - Race To',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.RACE_TO,
  },
  228: {
    id: 228,
    name: '1st Half/Match (inc. overtime)',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  234: {
    id: 234,
    name: '2nd Half - Total 3-way',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  238: {
    id: 238,
    name: '1st Quarter - Double Chance',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  240: {
    id: 240,
    name: '2nd Quarter - Double Chance',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  242: {
    id: 242,
    name: 'Winning Margin',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.WINNING_MARGIN,
  },
  294: {
    id: 294,
    name: '1st Half - Race To',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.RACE_TO,
  },
  296: {
    id: 296,
    name: '2nd Half - Race To',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.RACE_TO,
  },
  346: {
    id: 346,
    name: 'Match Winner',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  352: {
    id: 352,
    name: 'HT/FT',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  356: {
    id: 356,
    name: 'Correct Score',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_SCORE_SORTED,
  },
  358: {
    id: 358,
    name: 'Double Chance',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  360: {
    id: 360,
    name: '1st Half - Correct Score',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_SCORE_SORTED,
  },
  366: {
    id: 366,
    name: '3-way Handicap',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.HANDICAP,
  },
  368: {
    id: 368,
    name: 'First Team To Score',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  372: {
    id: 372,
    name: 'First Card',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  390: {
    id: 390,
    name: '1st Half - 1X2',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  392: {
    id: 392,
    name: '2nd Half - 1X2',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  404: {
    id: 404,
    name: '1st Half - First Team To Score',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  406: {
    id: 406,
    name: 'Last Team To Score',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  410: {
    id: 410,
    name: 'Next Goal',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.NEXT_GOAL,
  },
  412: {
    id: 412,
    name: '1st Half - 3-way Handicap',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.HANDICAP,
  },
  426: {
    id: 426,
    name: 'Highest Scoring Half',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  452: {
    id: 452,
    name: 'In Which Half First Goal Will Be Scored?',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  454: {
    id: 454,
    name: '2nd Half - First Team To Score',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  466: {
    id: 466,
    name: '2nd Half - Correct Score',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_SCORE_SORTED,
  },
  500: {
    id: 500,
    name: 'Number of Goals',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.NUMBER_OF_GOALS,
  },
  508: {
    id: 508,
    name: '1st Half - Number of Goals',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.NUMBER_OF_GOALS,
  },
  520: {
    id: 520,
    name: 'In Which Half (Competitor1) Will Score More Goals?',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  522: {
    id: 522,
    name: 'In Which Half (Competitor2) Will Score More Goals?',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  524: {
    id: 524,
    name: '1st Half - (Competitor1) Number Of Goals',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.NUMBER_OF_GOALS,
  },
  526: {
    id: 526,
    name: '1st Half - (Competitor2) Number Of Goals',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.NUMBER_OF_GOALS,
  },
  528: {
    id: 528,
    name: '2nd Half - (Competitor2) Number Of Goals',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.NUMBER_OF_GOALS,
  },
  530: {
    id: 530,
    name: '2nd Half - (Competitor1) Number Of Goals',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.NUMBER_OF_GOALS,
  },
  532: {
    id: 532,
    name: '(Competitor1) Number Of Goals',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.NUMBER_OF_GOALS,
  },
  534: {
    id: 534,
    name: '(Competitor2) Number Of Goals',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.NUMBER_OF_GOALS,
  },
  536: {
    id: 536,
    name: '2nd Half - Double Chance',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  550: {
    id: 550,
    name: '10 Minutes (00:00 вЂ“ 9:59) - Winner ',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  554: {
    id: 554,
    name: '2nd Half - Number of Goals',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.NUMBER_OF_GOALS,
  },
  608: {
    id: 608,
    name: '15 Minutes - Winner',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  642: {
    id: 642,
    name: 'Remaining Match',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  644: {
    id: 644,
    name: 'Goals Range',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  652: {
    id: 652,
    name: '1st Half - Remaining',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  664: {
    id: 664,
    name: 'Corners Range',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  668: {
    id: 668,
    name: '2nd Half - Team With Most Corners',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  686: {
    id: 686,
    name: 'Outright Winner',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  708: {
    id: 708,
    name: 'Total Corners 3-way',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  716: {
    id: 716,
    name: 'Total 3-way',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  718: {
    id: 718,
    name: '1st Half - Next Goal',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.NEXT_GOAL,
  },
  722: {
    id: 722,
    name: 'extratime - Next Goal',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.NEXT_GOAL,
  },
  724: {
    id: 724,
    name: 'Race To',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.RACE_TO,
  },
  756: {
    id: 756,
    name: 'Corners Result',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  758: {
    id: 758,
    name: '1st Half - Corners Result',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  766: {
    id: 766,
    name: 'Match Winner & Both Teams To Score',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  772: {
    id: 772,
    name: 'extratime - Winner',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  776: {
    id: 776,
    name: 'Winner & Total Goals',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  780: {
    id: 780,
    name: 'Exact Match Winner & Both Teams To Score',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  784: {
    id: 784,
    name: 'Corners - 3-way Handicap',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.HANDICAP,
  },
  786: {
    id: 786,
    name: '1st Half - Double Chance',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  800: {
    id: 800,
    name: 'Correct Score Shootout',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_SCORE_SORTED,
  },
  816: {
    id: 816,
    name: 'Next Corner',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.NEXT_GOAL,
  },
  818: {
    id: 818,
    name: 'To Finish In Top Half',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  820: {
    id: 820,
    name: 'To Finish In Bottom Half',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  822: {
    id: 822,
    name: 'To Finish Bottom',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  824: {
    id: 824,
    name: 'Not To Finish In Top-4',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  826: {
    id: 826,
    name: 'To Be Relegated',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  828: {
    id: 828,
    name: 'Not To Be Relegated',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  834: {
    id: 834,
    name: 'To Be Promoted',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  868: {
    id: 868,
    name: 'To Finish In Top-7',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  870: {
    id: 870,
    name: 'To Finish In Top-6',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  872: {
    id: 872,
    name: 'To Finish In Top-3',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  874: {
    id: 874,
    name: 'To Finish In Top-2',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  876: {
    id: 876,
    name: 'To Finish In Top-4',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  884: {
    id: 884,
    name: 'Anytime Goalscorer',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  886: {
    id: 886,
    name: 'First Player To Score',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  888: {
    id: 888,
    name: 'Last Player To Score',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  890: {
    id: 890,
    name: 'Player To Score 2 Or More Goals',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  892: {
    id: 892,
    name: 'Player To Score 3 Or More Goals',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  906: {
    id: 906,
    name: 'Race To Corners',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.RACE_TO,
  },
  914: {
    id: 914,
    name: 'Player To Be Booked',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  916: {
    id: 916,
    name: 'Player To Be Sent Off',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  924: {
    id: 924,
    name: '1st Half Corners - Total 3-way',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  926: {
    id: 926,
    name: 'First Goal Method',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  930: {
    id: 930,
    name: 'Cards - 3-way Handicap',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.HANDICAP,
  },
  940: {
    id: 940,
    name: 'Next Player To Score',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  956: {
    id: 956,
    name: '(Competitor1) First Goalscorer',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  958: {
    id: 958,
    name: '(Competitor1) Last Goalscorer',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  960: {
    id: 960,
    name: '(Competitor2) First Goalscorer',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  962: {
    id: 962,
    name: '(Competitor2) Last Goalscorer',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  964: {
    id: 964,
    name: 'Yellow Cards Result',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  966: {
    id: 966,
    name: 'Double Chance Yellow Cards',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  974: {
    id: 974,
    name: 'Booking Points Total 3-way',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  976: {
    id: 976,
    name: '(Competitor1) Booking Points Total 3-way',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  978: {
    id: 978,
    name: '(Competitor2) Booking Points Total 3-way',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  980: {
    id: 980,
    name: '1st Half - Booking Points Total 3-way',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1001: {
    id: 1001,
    name: 'Correct Score',
    sport: 'Tennis',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  1002: {
    id: 1002,
    name: '1st Set - Correct Score',
    sport: 'Tennis',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  1025: {
    id: 1025,
    name: '2nd Set - Correct Score',
    sport: 'Tennis',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  1043: {
    id: 1043,
    name: '3rd Set - Correct Score',
    sport: 'Tennis',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  1046: {
    id: 1046,
    name: 'Outright Winner',
    sport: 'Tennis',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1049: {
    id: 1049,
    name: 'Point Winner',
    sport: 'Tennis',
    display_layout: DISPLAY_LAYOUTS.POINT_WINNER,
  },
  1050: {
    id: 1050,
    name: 'Game Winner',
    sport: 'Tennis',
    display_layout: DISPLAY_LAYOUTS.POINT_WINNER,
  },
  1056: {
    id: 1056,
    name: '1st Set - Race To',
    sport: 'Tennis',
    display_layout: DISPLAY_LAYOUTS.NEXT_GOAL,
  },
  1057: {
    id: 1057,
    name: '2nd Set - Race To',
    sport: 'Tennis',
    display_layout: DISPLAY_LAYOUTS.NEXT_GOAL,
  },
  1058: {
    id: 1058,
    name: '3rd Set - Race To',
    sport: 'Tennis',
    display_layout: DISPLAY_LAYOUTS.NEXT_GOAL,
  },
  1067: {
    id: 1067,
    name: '4th Set - Correct Score',
    sport: 'Tennis',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  1106: {
    id: 1106,
    name: 'Game To Deuce',
    sport: 'Tennis',
    display_layout: DISPLAY_LAYOUTS.GAME_TO_DEUCE,
  },
  1107: {
    id: 1107,
    name: 'To Have A Break Point In The Game',
    sport: 'Tennis',
    display_layout: DISPLAY_LAYOUTS.GAME_TO_DEUCE,
  },
  1113: {
    id: 1113,
    name: 'Game Score',
    sport: 'Tennis',
    display_layout: DISPLAY_LAYOUTS.GAME_SCORE,
  },
  1114: {
    id: 1114,
    name: '1st Set - Lead After',
    sport: 'Tennis',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1115: {
    id: 1115,
    name: '2nd Set - Lead After',
    sport: 'Tennis',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1119: {
    id: 1119,
    name: 'To Break Serve In Set',
    sport: 'Tennis',
    display_layout: DISPLAY_LAYOUTS.GAME_TO_DEUCE,
  },
  1200: {
    id: 1200,
    name: 'Winner',
    sport: 'E-Games',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1204: {
    id: 1204,
    name: 'Correct Score',
    sport: 'E-Games',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  1205: {
    id: 1205,
    name: 'Double Chance',
    sport: 'E-Games',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1299: {
    id: 1299,
    name: '1st Map - Race To Kills',
    sport: 'E-Games',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1300: {
    id: 1300,
    name: '2nd Map - Race To Kills',
    sport: 'E-Games',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1301: {
    id: 1301,
    name: '3rd Map - Race To Kills',
    sport: 'E-Games',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1404: {
    id: 1404,
    name: 'Correct Score',
    sport: 'Table Tennis',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  1406: {
    id: 1406,
    name: '1st Game - Correct Score',
    sport: 'Table Tennis',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  1427: {
    id: 1427,
    name: '2nd Game - Correct Score',
    sport: 'Table Tennis',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  1437: {
    id: 1437,
    name: 'Point Winner',
    sport: 'Table Tennis',
    display_layout: DISPLAY_LAYOUTS.POINT_WINNER,
  },
  1441: {
    id: 1441,
    name: '1st Game - Race To',
    sport: 'Table Tennis',
    display_layout: DISPLAY_LAYOUTS.POINT_WINNER,
  },
  1443: {
    id: 1443,
    name: '3rd Game - Race To',
    sport: 'Table Tennis',
    display_layout: DISPLAY_LAYOUTS.POINT_WINNER,
  },
  1444: {
    id: 1444,
    name: '4th Game - Race To',
    sport: 'Table Tennis',
    display_layout: DISPLAY_LAYOUTS.POINT_WINNER,
  },
  1445: {
    id: 1445,
    name: '5th Game - Race To',
    sport: 'Table Tennis',
    display_layout: DISPLAY_LAYOUTS.POINT_WINNER,
  },
  1505: {
    id: 1505,
    name: 'Correct Score',
    sport: 'Volleyball',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  1506: {
    id: 1506,
    name: '1st Set - Correct Score',
    sport: 'Volleyball',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  1539: {
    id: 1539,
    name: '2nd Set - Correct Score',
    sport: 'Volleyball',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  1550: {
    id: 1550,
    name: '3rd Set - Correct Score',
    sport: 'Volleyball',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  1554: {
    id: 1554,
    name: 'Point Winner',
    sport: 'Volleyball',
    display_layout: DISPLAY_LAYOUTS.POINT_WINNER,
  },
  1562: {
    id: 1562,
    name: '1st Set - Race To',
    sport: 'Volleyball',
    display_layout: DISPLAY_LAYOUTS.POINT_WINNER,
  },
  1563: {
    id: 1563,
    name: '2nd Set - Race To',
    sport: 'Volleyball',
    display_layout: DISPLAY_LAYOUTS.POINT_WINNER,
  },
  1564: {
    id: 1564,
    name: '3rd Set - Race To',
    sport: 'Volleyball',
    display_layout: DISPLAY_LAYOUTS.POINT_WINNER,
  },
  1565: {
    id: 1565,
    name: '4th Set - Race To',
    sport: 'Volleyball',
    display_layout: DISPLAY_LAYOUTS.POINT_WINNER,
  },
  1566: {
    id: 1566,
    name: '5th Set - Race To',
    sport: 'Volleyball',
    display_layout: DISPLAY_LAYOUTS.POINT_WINNER,
  },
  1570: {
    id: 1570,
    name: '4th Set - Correct Score',
    sport: 'Volleyball',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  1571: {
    id: 1571,
    name: '5th Set - Correct Score',
    sport: 'Volleyball',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  1581: {
    id: 1581,
    name: 'Extra Points in Set',
    sport: 'Volleyball',
    display_layout: DISPLAY_LAYOUTS.GAME_TO_DEUCE,
  },
  1600: {
    id: 1600,
    name: 'Match Winner',
    sport: 'Handball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1603: {
    id: 1603,
    name: 'HT/FT',
    sport: 'Handball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1606: {
    id: 1606,
    name: 'Double Chance',
    sport: 'Handball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1609: {
    id: 1609,
    name: '3-way Handicap',
    sport: 'Handball',
    display_layout: DISPLAY_LAYOUTS.HANDICAP,
  },
  1624: {
    id: 1624,
    name: 'Next Goal',
    sport: 'Handball',
    display_layout: DISPLAY_LAYOUTS.NEXT_GOAL,
  },
  1625: {
    id: 1625,
    name: '1st Half - 3-way Handicap',
    sport: 'Handball',
    display_layout: DISPLAY_LAYOUTS.HANDICAP,
  },
  1631: {
    id: 1631,
    name: 'Highest Scoring Half',
    sport: 'Handball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1657: {
    id: 1657,
    name: 'Outright Winner',
    sport: 'Handball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1659: {
    id: 1659,
    name: '1st Half - Winner',
    sport: 'Handball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1671: {
    id: 1671,
    name: '1st Half - Double Chance',
    sport: 'Handball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1672: {
    id: 1672,
    name: '2nd Half - Double Chance',
    sport: 'Handball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1705: {
    id: 1705,
    name: 'Correct Score',
    sport: 'Badminton',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_SCORE_SORTED,
  },
  1707: {
    id: 1707,
    name: '1st Game - Correct Score',
    sport: 'Badminton',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_SCORE_SORTED,
  },
  1721: {
    id: 1721,
    name: '2nd Game - Correct Score',
    sport: 'Badminton',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_SCORE_SORTED,
  },
  1727: {
    id: 1727,
    name: '3rd Game - Correct Score',
    sport: 'Badminton',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_SCORE_SORTED,
  },
  1731: {
    id: 1731,
    name: '1st Set - Race To',
    sport: 'Badminton',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_SCORE_SORTED,
  },
  1750: {
    id: 1750,
    name: 'Match Winner (reg. time)',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1755: {
    id: 1755,
    name: 'Correct Score (reg. time)',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_SCORE_SORTED,
  },
  1756: {
    id: 1756,
    name: 'Double Chance (reg. time)',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1757: {
    id: 1757,
    name: '1st Period - Correct Score',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_SCORE_SORTED,
  },
  1758: {
    id: 1758,
    name: '3-way Handicap (reg. time)',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.HANDICAP,
  },
  1765: {
    id: 1765,
    name: '1st Period - Winner',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1766: {
    id: 1766,
    name: '2nd Period - Winner',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1767: {
    id: 1767,
    name: '3rd Period - Winner',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1774: {
    id: 1774,
    name: 'Last Team To Score (reg. time)',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1775: {
    id: 1775,
    name: 'Next Goal (reg. time)',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.NEXT_GOAL,
  },
  1782: {
    id: 1782,
    name: 'Highest Scoring Period (reg. time)',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1792: {
    id: 1792,
    name: '2nd Period - Correct Score',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_SCORE_SORTED,
  },
  1799: {
    id: 1799,
    name: 'Number of Goals (reg. time)',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.NUMBER_OF_GOALS,
  },
  1803: {
    id: 1803,
    name: '(Competitor1) Number Of Goals (reg. time)',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.NUMBER_OF_GOALS,
  },
  1804: {
    id: 1804,
    name: '(Competitor2) Number Of Goals (reg. time)',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.NUMBER_OF_GOALS,
  },
  1815: {
    id: 1815,
    name: '3rd Period - Correct Score',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_SCORE_SORTED,
  },
  1820: {
    id: 1820,
    name: '3rd Period - Double Chance',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1831: {
    id: 1831,
    name: '1st Period - Total 3-way',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1832: {
    id: 1832,
    name: '2nd Period - Total 3-way',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1833: {
    id: 1833,
    name: '3rd Period - Total 3-way',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1834: {
    id: 1834,
    name: 'Total 3-way (reg. time)',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1839: {
    id: 1839,
    name: 'Race To (reg. time)',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.RACE_TO,
  },
  1842: {
    id: 1842,
    name: '3-way Handicap (incl. overtime)',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.HANDICAP,
  },
  1849: {
    id: 1849,
    name: '1st Period - Double Chance',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1850: {
    id: 1850,
    name: '2nd Period - Double Chance',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1855: {
    id: 1855,
    name: 'Correct Score (incl. overtime)',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  1856: {
    id: 1856,
    name: 'Player To Score In Anytime (reg. time)',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  1857: {
    id: 1857,
    name: 'First Player To Score (reg. time)',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  1858: {
    id: 1858,
    name: 'Last Player To Score (reg. time)',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  1900: {
    id: 1900,
    name: 'Match Winner',
    sport: 'Rugby Union',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1903: {
    id: 1903,
    name: 'HT/FT',
    sport: 'Rugby Union',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1905: {
    id: 1905,
    name: 'Double Chance',
    sport: 'Rugby Union',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1906: {
    id: 1906,
    name: '3-way Handicap',
    sport: 'Rugby Union',
    display_layout: DISPLAY_LAYOUTS.HANDICAP,
  },
  1918: {
    id: 1918,
    name: '1st Half - 3-way Handicap',
    sport: 'Rugby Union',
    display_layout: DISPLAY_LAYOUTS.HANDICAP,
  },
  1924: {
    id: 1924,
    name: 'Highest Scoring Half',
    sport: 'Rugby Union',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1940: {
    id: 1940,
    name: '2nd Half - Double Chance',
    sport: 'Rugby Union',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1955: {
    id: 1955,
    name: 'Total 3-way',
    sport: 'Rugby Union',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1956: {
    id: 1956,
    name: 'Race To',
    sport: 'Rugby Union',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  1970: {
    id: 1970,
    name: 'Anytime Try Scorer',
    sport: 'Rugby Union',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  2000: {
    id: 2000,
    name: 'Match Winner',
    sport: 'Floorball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2006: {
    id: 2006,
    name: 'Double Chance',
    sport: 'Floorball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2008: {
    id: 2008,
    name: '3-way Handicap',
    sport: 'Floorball',
    display_layout: DISPLAY_LAYOUTS.HANDICAP,
  },
  2018: {
    id: 2018,
    name: 'Next Goal',
    sport: 'Floorball',
    display_layout: DISPLAY_LAYOUTS.NEXT_GOAL,
  },
  2029: {
    id: 2029,
    name: 'Total 3-way',
    sport: 'Floorball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2100: {
    id: 2100,
    name: 'Match Winner',
    sport: 'Rugby League',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2103: {
    id: 2103,
    name: 'HT/FT',
    sport: 'Rugby League',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2105: {
    id: 2105,
    name: 'Double Chance',
    sport: 'Rugby League',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2107: {
    id: 2107,
    name: '3-way Handicap',
    sport: 'Rugby League',
    display_layout: DISPLAY_LAYOUTS.HANDICAP,
  },
  2114: {
    id: 2114,
    name: '2nd Half - Winner',
    sport: 'Rugby League',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2126: {
    id: 2126,
    name: 'Highest Scoring Half',
    sport: 'Rugby League',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2142: {
    id: 2142,
    name: '10 Minutes (00:00 вЂ“ 9:59)- Winner',
    sport: 'Rugby League',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2152: {
    id: 2152,
    name: 'Outright Winner',
    sport: 'Rugby League',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2401: {
    id: 2401,
    name: 'Match winner',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2404: {
    id: 2404,
    name: '1st Half/Match',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2409: {
    id: 2409,
    name: '3-way handicap',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.HANDICAP,
  },
  2416: {
    id: 2416,
    name: '1st Quarter - Winner',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2417: {
    id: 2417,
    name: '2nd Quarter - Winner',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2418: {
    id: 2418,
    name: '3rd Quarter - Winner',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2419: {
    id: 2419,
    name: '4th Quarter - Winner',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2427: {
    id: 2427,
    name: '1st Half - 3-way Handicap',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.HANDICAP,
  },
  2434: {
    id: 2434,
    name: 'Highest Scoring Quarter',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2435: {
    id: 2435,
    name: 'Highest Scoring Half',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2462: {
    id: 2462,
    name: '1st Half - 1X2',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2466: {
    id: 2466,
    name: '1st Quarter - Total 3-way',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2467: {
    id: 2467,
    name: '2nd Quarter - Total 3-way',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2468: {
    id: 2468,
    name: '3rd Quarter - Total 3-way',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2469: {
    id: 2469,
    name: '4th Quarter - Total 3-way',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2470: {
    id: 2470,
    name: 'Total 3-way',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2472: {
    id: 2472,
    name: 'Race To',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2480: {
    id: 2480,
    name: '1st Half - Total 3-way',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2481: {
    id: 2481,
    name: 'First Score Method',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2482: {
    id: 2482,
    name: 'First Score Method (Competitor1)',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2483: {
    id: 2483,
    name: 'First Score Method (Competitor2)',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2484: {
    id: 2484,
    name: '1st Quarter - Double Chance',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2507: {
    id: 2507,
    name: 'Points Range',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2510: {
    id: 2510,
    name: 'Team with Highest Scoring Period 3-way',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2511: {
    id: 2511,
    name: 'First Score Method 6 Way',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2524: {
    id: 2524,
    name: 'Touchdowns Total 3-way',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2526: {
    id: 2526,
    name: 'Field Goals Total 3-way',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2537: {
    id: 2537,
    name: '(Competitor1) Next Touchdown Scorer',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2538: {
    id: 2538,
    name: '(Competitor2) Next Touchdown Scorer',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2539: {
    id: 2539,
    name: 'Next Touchdown Scorer',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2540: {
    id: 2540,
    name: 'Player To Score Touchdown In Anytime',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  2541: {
    id: 2541,
    name: 'Player To Score 2 Or More Touchdowns',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  2542: {
    id: 2542,
    name: 'Player To Score 3 Or More Touchdowns',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_SCORE_SORTED,
  },
  2551: {
    id: 2551,
    name: 'First Player To Score Touchdown',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  2552: {
    id: 2552,
    name: 'Last Player To Score Touchdown',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  2553: {
    id: 2553,
    name: 'First Player To Score Touchdown (Competitor1)',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  2554: {
    id: 2554,
    name: 'First Player To Score Touchdown (Competitor2)',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  2562: {
    id: 2562,
    name: '3-way Handicap',
    sport: 'Baseball',
    display_layout: DISPLAY_LAYOUTS.HANDICAP,
  },
  2567: {
    id: 2567,
    name: '1st Inning - Winner',
    sport: 'Baseball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2568: {
    id: 2568,
    name: '2nd Inning - Winner',
    sport: 'Baseball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2569: {
    id: 2569,
    name: '3rd Inning - Winner',
    sport: 'Baseball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2570: {
    id: 2570,
    name: '4th Inning - Winner',
    sport: 'Baseball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2575: {
    id: 2575,
    name: '5th Inning - Winner',
    sport: 'Baseball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2588: {
    id: 2588,
    name: 'Highest Scoring Period',
    sport: 'Baseball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2619: {
    id: 2619,
    name: 'Race To',
    sport: 'Baseball',
    display_layout: DISPLAY_LAYOUTS.RACE_TO,
  },
  2621: {
    id: 2621,
    name: '6th Inning - Winner',
    sport: 'Baseball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2622: {
    id: 2622,
    name: '7th Inning - Winner',
    sport: 'Baseball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2627: {
    id: 2627,
    name: '3-way Handicap (inc. extra innings)',
    sport: 'Baseball',
    display_layout: DISPLAY_LAYOUTS.HANDICAP,
  },
  2630: {
    id: 2630,
    name: '1st Inning/Match (inc. extra innings)',
    sport: 'Baseball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2631: {
    id: 2631,
    name: '8th Inning - Winner',
    sport: 'Baseball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2632: {
    id: 2632,
    name: '9th Inning  - Winner',
    sport: 'Baseball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2659: {
    id: 2659,
    name: 'Winner',
    sport: 'Futsal',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2662: {
    id: 2662,
    name: 'HT/FT',
    sport: 'Futsal',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2664: {
    id: 2664,
    name: 'Correct Score',
    sport: 'Futsal',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_SCORE_SORTED,
  },
  2665: {
    id: 2665,
    name: 'Double Chance',
    sport: 'Futsal',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2666: {
    id: 2666,
    name: '1st Half - Correct Score',
    sport: 'Futsal',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_SCORE_SORTED,
  },
  2668: {
    id: 2668,
    name: '3-way Handicap',
    sport: 'Futsal',
    display_layout: DISPLAY_LAYOUTS.HANDICAP,
  },
  2674: {
    id: 2674,
    name: '1st Half - Double Chance',
    sport: 'Futsal',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2677: {
    id: 2677,
    name: '1st Half - 1X2',
    sport: 'Futsal',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2678: {
    id: 2678,
    name: '2nd Half - Winner',
    sport: 'Futsal',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2682: {
    id: 2682,
    name: 'Next Goal',
    sport: 'Futsal',
    display_layout: DISPLAY_LAYOUTS.NEXT_GOAL,
  },
  2686: {
    id: 2686,
    name: 'Highest Scoring Half',
    sport: 'Futsal',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2693: {
    id: 2693,
    name: '2nd Half - Correct Score',
    sport: 'Futsal',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_SCORE_SORTED,
  },
  2702: {
    id: 2702,
    name: '2nd Half - Double Chance',
    sport: 'Futsal',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2870: {
    id: 2870,
    name: 'To Finish In Top-4',
    sport: 'Australian Rules',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2871: {
    id: 2871,
    name: 'To Finish In Top-8',
    sport: 'Australian Rules',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2892: {
    id: 2892,
    name: 'Next Goal',
    sport: 'Bandy',
    display_layout: DISPLAY_LAYOUTS.NEXT_GOAL,
  },
  2912: {
    id: 2912,
    name: 'Correct Score',
    sport: 'Beach Volleyball',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  2913: {
    id: 2913,
    name: '1st Set - Correct score',
    sport: 'Beach Volleyball',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  2927: {
    id: 2927,
    name: '2nd et - Correct score',
    sport: 'Beach Volleyball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_SCORE_SORTED,
  },
  2940: {
    id: 2940,
    name: 'Point Winner',
    sport: 'Beach Volleyball',
    display_layout: DISPLAY_LAYOUTS.POINT_WINNER,
  },
  2955: {
    id: 2955,
    name: '1X2',
    sport: 'Boxing & UFC',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2979: {
    id: 2979,
    name: 'Top Nationality - Australian',
    sport: 'Golf',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2980: {
    id: 2980,
    name: 'Top Nationality - European',
    sport: 'Golf',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2981: {
    id: 2981,
    name: 'Top Nationality - Great Britain & Ireland',
    sport: 'Golf',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  2982: {
    id: 2982,
    name: 'Top Nationality - Rest Of The World',
    sport: 'Golf',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  3049: {
    id: 3049,
    name: 'Correct score',
    sport: 'Snooker',
    display_layout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
  },
  3053: {
    id: 3053,
    name: 'Race To',
    sport: 'Snooker',
    display_layout: DISPLAY_LAYOUTS.POINT_WINNER,
  },
  3065: {
    id: 3065,
    name: 'Match Winner',
    sport: 'Waterpolo',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  3084: {
    id: 3084,
    name: 'Next Goal',
    sport: 'Waterpolo',
    display_layout: DISPLAY_LAYOUTS.NEXT_GOAL,
  },
  3201: {
    id: 3201,
    name: 'Winning Margin Exact',
    sport: 'Football',
    display_layout: DISPLAY_LAYOUTS.WINNING_MARGIN,
  },
  3205: {
    id: 3205,
    name: 'Winning Margin - Teams (incl. overtime)',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.WINNING_MARGIN,
  },
  3206: {
    id: 3206,
    name: 'Winning Margin Ranges',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.WINNING_MARGIN,
  },
  3207: {
    id: 3207,
    name: 'Winning Margin - Teams With Any Other Score',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.WINNING_MARGIN,
  },
  3209: {
    id: 3209,
    name: '1st Set - Winning Margin Teams',
    sport: 'Volleyball',
    display_layout: DISPLAY_LAYOUTS.WINNING_MARGIN,
  },
  3210: {
    id: 3210,
    name: '2nd Set - Winning Margin Teams',
    sport: 'Volleyball',
    display_layout: DISPLAY_LAYOUTS.WINNING_MARGIN,
  },
  3211: {
    id: 3211,
    name: '3rd Set - Winning Margin Teams',
    sport: 'Volleyball',
    display_layout: DISPLAY_LAYOUTS.WINNING_MARGIN,
  },
  3212: {
    id: 3212,
    name: '4th Set - Winning Margin Teams',
    sport: 'Volleyball',
    display_layout: DISPLAY_LAYOUTS.WINNING_MARGIN,
  },
  3213: {
    id: 3213,
    name: '5th Set - Winning Margin Teams',
    sport: 'Volleyball',
    display_layout: DISPLAY_LAYOUTS.WINNING_MARGIN,
  },
  3214: {
    id: 3214,
    name: 'Winning Margin - Teams (incl. overtime)',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.WINNING_MARGIN,
  },
  3217: {
    id: 3217,
    name: '1st Game - Winning Margin Teams',
    sport: 'Table Tennis',
    display_layout: DISPLAY_LAYOUTS.WINNING_MARGIN,
  },
  3219: {
    id: 3219,
    name: '3rd Game - Winning Margin Teams',
    sport: 'Table Tennis',
    display_layout: DISPLAY_LAYOUTS.WINNING_MARGIN,
  },
  3220: {
    id: 3220,
    name: '4th Game - Winning Margin Teams',
    sport: 'Table Tennis',
    display_layout: DISPLAY_LAYOUTS.WINNING_MARGIN,
  },
  3221: {
    id: 3221,
    name: '5th Game - Winning Margin Teams',
    sport: 'Table Tennis',
    display_layout: DISPLAY_LAYOUTS.WINNING_MARGIN,
  },
  3301: {
    id: 3301,
    name: 'Player To Score 2 Or More Goals',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  3302: {
    id: 3302,
    name: 'Player To Score 3 Or More Goals',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  3305: {
    id: 3305,
    name: '(Competitor1) Last Player To Score Touchdown',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  3306: {
    id: 3306,
    name: '(Competitor2) Last Player To Score Touchdown',
    sport: 'American Football',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  3309: {
    id: 3309,
    name: 'Player To Score Most Sixes',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  3311: {
    id: 3311,
    name: '2nd Quarter - Winner',
    sport: 'Basketball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  3316: {
    id: 3316,
    name: '2nd Half - Winner',
    sport: 'Rugby Union',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  3318: {
    id: 3318,
    name: '1st Half - Winner',
    sport: 'Rugby League',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  3319: {
    id: 3319,
    name: 'Outright Winner',
    sport: 'Snooker',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  3320: {
    id: 3320,
    name: '2nd Half - Winner',
    sport: 'Handball',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  3321: {
    id: 3321,
    name: 'Winner',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  3326: {
    id: 3326,
    name: 'Double Chance',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  3328: {
    id: 3328,
    name: '1st Inning - Winner',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  3332: {
    id: 3332,
    name: 'To Score Most Sixes',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  3343: {
    id: 3343,
    name: 'Highest Opening Partnership',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  3346: {
    id: 3346,
    name: 'Method Of Dismissal 6-Way',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  3352: {
    id: 3352,
    name: '(Competitor1) Total Runs Odd/Even in Over',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR,
  },
  3353: {
    id: 3353,
    name: '(Competitor2) Total Runs Odd/Even in Over',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR,
  },
  3355: {
    id: 3355,
    name: '(Competitor1) Total Runs in Over',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR,
  },
  3356: {
    id: 3356,
    name: '(Competitor2) Total Runs in Over',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR,
  },
  3371: {
    id: 3371,
    name: 'Total Runs Odd/Even in Over',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR,
  },
  3372: {
    id: 3372,
    name: 'Total Runs in Over',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR,
  },
  3374: {
    id: 3374,
    name: '1st Inning - (Competitor1) Total Runs In Over',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR,
  },
  3375: {
    id: 3375,
    name: '1st Inning - (Competitor2) Total Runs In Over',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR,
  },
  3376: {
    id: 3376,
    name: '2nd Inning - (Competitor1) Total Runs In Over',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR,
  },
  3377: {
    id: 3377,
    name: '2nd Inning - (Competitor2) Total Runs In Over',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR,
  },
  3378: {
    id: 3378,
    name: '1st Inning - (Competitor1) Odd/Even Runs In Over',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR,
  },
  3379: {
    id: 3379,
    name: '1st Inning - (Competitor2) Odd/Even Runs In Over',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR,
  },
  3380: {
    id: 3380,
    name: '2nd Inning - (Competitor1) Odd/Even Runs In Over',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR,
  },
  3381: {
    id: 3381,
    name: '2nd Inning - (Competitor2) Odd/Even Runs In Over',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.CRICKET_SEPARATOR,
  },
  3387: {
    id: 3387,
    name: 'To Score Most Fours',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  3392: {
    id: 3392,
    name: 'Most Run Outs',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  3393: {
    id: 3393,
    name: '(Competitor1) Top Batsman',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  3394: {
    id: 3394,
    name: '(Competitor2) Top Batsman',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  3395: {
    id: 3395,
    name: '(Competitor1) Top Bowler',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  3396: {
    id: 3396,
    name: '(Competitor2) Top Bowler',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  3397: {
    id: 3397,
    name: 'Man Of The Match',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  3398: {
    id: 3398,
    name: 'Batsman To Score a Fifty in The match',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  3400: {
    id: 3400,
    name: 'Race To 10 Runs',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
  },
  3415: {
    id: 3415,
    name: 'Team Of Top Batsman',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  3445: {
    id: 3445,
    name: '1st Wicket Method',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  3750: {
    id: 3750,
    name: '1st Inning Top Team Bowler (Competitor1)',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  3800: {
    id: 3800,
    name: '1st Inning Top Team Bowler (Competitor2)',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  3850: {
    id: 3850,
    name: '1st Inning Top Team Batsman (Competitor1)',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  3900: {
    id: 3900,
    name: '1st Inning Top Team Batsman (Competitor2)',
    sport: 'Cricket',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  231175: {
    id: 231175,
    name: 'Match Points Race To:',
    sport: 'Rugby League',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  231172: {
    id: 231172,
    name: 'Match tries race to:',
    sport: 'Rugby League',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  154764: {
    id: 154764,
    name: 'Correct Score',
    sport: 'Soccer',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_WINNER_SCORE,
  },
  157164: {
    id: 157164,
    name: '1st Half - Correct Score',
    sport: 'Soccer',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_WINNER_SCORE,
  },
  158364: {
    id: 158364,
    name: '2nd Half - Correct Score',
    sport: 'Soccer',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_WINNER_SCORE,
  },
  175864: {
    id: 175864,
    name: 'Correct Score',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_WINNER_SCORE,
  },
  175914: {
    id: 175914,
    name: '1st Period - Correct Score',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_WINNER_SCORE,
  },
  175964: {
    id: 175964,
    name: '2nd Period - Correct Score',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_WINNER_SCORE,
  },
  176014: {
    id: 176014,
    name: '3rd Period - Correct Score',
    sport: 'Ice Hockey',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_WINNER_SCORE,
  },
  176364: {
    id: 176364,
    name: 'Correct Score',
    sport: 'E-Games',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_WINNER_SCORE,
  },
  231174: {
    id: 231174,
    name: '1st Half - Total Points - Race To:',
    sport: 'Rugby League',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  231016: {
    id: 231016,
    name: 'Half-Time / Full-Time Double',
    sport: 'Soccer',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS,
  },
  237644: {
    id: 237644,
    name: 'Margin',
    sport: 'Australian Rules',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_WINNER_SCORE,
  },
  237664: {
    id: 237664,
    name: 'Margin',
    sport: 'Rugby League',
    display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_WINNER_SCORE,
  },
  231142: { id: 231142, display_layout: DISPLAY_LAYOUTS.HANDICAP_DROPDOWN },
  231146: { id: 231146, display_layout: DISPLAY_LAYOUTS.HANDICAP_DROPDOWN },
  231152: { id: 231152, display_layout: DISPLAY_LAYOUTS.HANDICAP_DROPDOWN },
  232285: { id: 232285, display_layout: DISPLAY_LAYOUTS.HANDICAP_DROPDOWN },
  232284: { id: 232284, display_layout: DISPLAY_LAYOUTS.HANDICAP_DROPDOWN },
  232274: { id: 232274, display_layout: DISPLAY_LAYOUTS.HANDICAP_DROPDOWN },
  159264: { id: 159264, display_layout: DISPLAY_LAYOUTS.HANDICAP_DROPDOWN },
  166614: { id: 166614, display_layout: DISPLAY_LAYOUTS.HANDICAP_DROPDOWN },

  230804: { id: 230804, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  230801: { id: 230801, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  230809: { id: 230809, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  230793: { id: 230793, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  230792: { id: 230792, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  230802: { id: 230802, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  230800: { id: 230800, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  230795: { id: 230795, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  // Over/Under
  156164: { id: 156164, display_layout: DISPLAY_LAYOUTS.SPEC_DESC_TWO_COLUMNS_SORTED },
  230716: { id: 230716, display_layout: DISPLAY_LAYOUTS.SPEC_DESC_TWO_COLUMNS_SORTED },
  230788: { id: 230788, display_layout: DISPLAY_LAYOUTS.SPEC_DESC_TWO_COLUMNS_SORTED },
  230789: { id: 230789, display_layout: DISPLAY_LAYOUTS.SPEC_DESC_TWO_COLUMNS_SORTED },
  230717: { id: 230717, display_layout: DISPLAY_LAYOUTS.SPEC_DESC_TWO_COLUMNS_SORTED },
  230791: { id: 230791, display_layout: DISPLAY_LAYOUTS.SPEC_DESC_TWO_COLUMNS_SORTED },
  231034: { id: 231034, display_layout: DISPLAY_LAYOUTS.SPEC_DESC_TWO_COLUMNS_SORTED },
  231052: { id: 231052, display_layout: DISPLAY_LAYOUTS.SPEC_DESC_TWO_COLUMNS_SORTED },
  230722: { id: 230722, display_layout: DISPLAY_LAYOUTS.SPEC_DESC_TWO_COLUMNS_SORTED },
  231050: { id: 231050, display_layout: DISPLAY_LAYOUTS.SPEC_DESC_TWO_COLUMNS_SORTED },
  231018: { id: 231018, display_layout: DISPLAY_LAYOUTS.SPEC_DESC_TWO_COLUMNS_SORTED },
  231248: { id: 231248, display_layout: DISPLAY_LAYOUTS.SPEC_DESC_TWO_COLUMNS_SORTED },
  231473: { id: 231473, display_layout: DISPLAY_LAYOUTS.SPEC_DESC_TWO_COLUMNS_SORTED },
  231483: { id: 231483, display_layout: DISPLAY_LAYOUTS.SPEC_DESC_TWO_COLUMNS_SORTED },
  231497: { id: 231497, display_layout: DISPLAY_LAYOUTS.SPEC_DESC_TWO_COLUMNS_SORTED },
  156014: { id: 156014, display_layout: DISPLAY_LAYOUTS.SPEC_DESC_TWO_COLUMNS_SORTED },
  231022: { id: 231022, display_layout: DISPLAY_LAYOUTS.SPEC_DESC_TWO_COLUMNS_SORTED },

  233215: { id: 233215, display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_WINNER_SCORE },
  // NRL
  231173: { id: 231173, display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS },
  231176: { id: 231176, display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS },
  231181: { id: 231181, display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS },
  231378: { id: 231378, display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS },
  231379: { id: 231379, display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS },
  231414: { id: 231414, display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS },
  231415: { id: 231415, display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS },
  // AFL
  232334: { id: 232334, display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_GROUPED_BY_SPECIFIERS },
  232332: { id: 232332, display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_GROUPED_BY_SPECIFIERS },
  232333: { id: 232333, display_layout: DISPLAY_LAYOUTS.THREE_COLUMNS_GROUPED_BY_SPECIFIERS },
  232406: { id: 232406, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232417: { id: 232417, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232435: { id: 232435, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232431: { id: 232431, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232418: { id: 232418, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  231728: { id: 231728, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232476: { id: 232476, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232426: { id: 232426, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232432: { id: 232432, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232434: { id: 232434, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232477: { id: 232477, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232488: { id: 232488, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232489: { id: 232489, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232402: { id: 232402, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232428: { id: 232428, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232430: { id: 232430, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232433: { id: 232433, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232436: { id: 232436, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232484: { id: 232484, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232485: { id: 232485, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232495: { id: 232495, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232496: { id: 232496, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232427: { id: 232427, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232419: { id: 232419, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232429: { id: 232429, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232481: { id: 232481, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232482: { id: 232482, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232486: { id: 232486, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232487: { id: 232487, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232414: { id: 232414, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
  232423: { id: 232423, display_layout: DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS },
};
export const getDisplayLayoutById = (id) => (marketTypesMappingObject[id]
  && marketTypesMappingObject[id].display_layout) || null;
