import React from 'react';
import cx from 'classnames';
import { oneOf, bool, string } from 'prop-types';
import Label from '../I18n/Label';

const Button = (props) => {
  const {
    label, type, icon, disabled, children, className, ...otherProps
  } = props;

  const buttonClassName = cx(
    'button',
    `button-${type}`,
    disabled && 'button-disabled',
    className,
  );

  return (
    <button type='button' className={buttonClassName} {...otherProps}>
      {icon && <span className={`icon ${icon}`} />}
      {icon && label && '\u00A0\u00A0'}
      {label && (
      <Label
        message={label}
      />
      )}
    </button>
  );
};

Button.propTypes = {
  type: oneOf([
    'button',
    'submit',
    'primary',
    'primary-ghost',
    'neutral',
    'neutral-ghost',
    'danger',
    'danger-ghost',
    'success',
    'success-ghost',
    'warning',
    'cancel',
  ]),
  disabled: bool,
  label: string,
};

Button.defaultProps = {
  type: 'primary',
  disabled: false,
  label: '',
};

export default Button;
