import React from 'react';
import { Link } from 'react-router-dom';

const HeaderLogoItem = () => (
  <Link
    className='header__logo'
    to='/'
  />
);

export default React.memo(HeaderLogoItem);
