import React, { useMemo } from 'react';
import {
  number,
  string,
} from 'prop-types';
import { centsToDecimal, DateService } from '@apollo/core';
import { buildCdnUrl, LocalConfig } from '../../../../../core/utils';

const CasinoHistoryTableListItem = ({
  createTime, betId, gameName, txIn, txOut, currencySymbol, img,
}) => {

  const date = DateService.getFormattedDateWithTime(createTime);

  const decimalAmount = useMemo(() => (
    centsToDecimal(txOut)
  ), [txOut]);

  const decimalWinAmount = useMemo(() => (
    txIn ? centsToDecimal(txIn) : ''
  ), [txIn]);

  return (
    <div className={`history-table-row casino ${(decimalWinAmount ? 'WIN' : 'LOSE')}`}>
      <div className='history-table-cell'>
        <img className='img-preview' src={buildCdnUrl(img)} alt='' />
      </div>
      <div className='history-table-cell'>
        <span>{gameName}</span>
      </div>
      <div className='history-table-cell date'>
        <span>{date}</span>
        <span className='sub-title'>{betId}</span>
      </div>
      <div className='history-table-cell'>
        <span className='bet'>{`${currencySymbol}${decimalAmount}`}</span>
      </div>
      <div className='history-table-cell'>
        <span className='result'>
          <span>{decimalWinAmount ? `${currencySymbol}${decimalWinAmount}` : '-'}</span>
        </span>
      </div>
    </div>
  );
};

CasinoHistoryTableListItem.propTypes = {
  createTime: number.isRequired,
  betId: number.isRequired,
  gameName: string.isRequired,
  txIn: number,
  txOut: number.isRequired,
  currencySymbol: string.isRequired,
};

CasinoHistoryTableListItem.defaultProps = {
  txIn: 0,
};

export default CasinoHistoryTableListItem;
