import React from 'react';
import cx from 'classnames';
import MarketExpandedDropdown from './MarketExpandedDropdown';

const MarketExpanded = ({ event, markets, marketTypeId, suspended, disabled }) => {
  const [isDropdownExpanded, setIsDropdownExpanded] = React.useState(false);

  const expandDropdownHandler = React.useCallback(() => {
    setIsDropdownExpanded((isExpanded) => !isExpanded);
  }, [setIsDropdownExpanded, isDropdownExpanded]);

  const closeDropdownHandler = React.useCallback(() => {
    setIsDropdownExpanded(false);
  }, [setIsDropdownExpanded]);

  const renderDropdown = React.useMemo(() => {
    if (!isDropdownExpanded) {
      return null;
    }

    return (
      <MarketExpandedDropdown
        event={event}
        markets={markets}
        marketTypeId={marketTypeId}
        onClose={closeDropdownHandler}
        suspended={suspended}
        disabled={disabled}
      />
    );
  }, [closeDropdownHandler, isDropdownExpanded, event, markets, marketTypeId, suspended, disabled]);

  const buttonClassName = React.useMemo(
    () => cx(['market-expanded-button', isDropdownExpanded && 'expanded']),
    [isDropdownExpanded],
  );

  return (
    <>
      {renderDropdown}
      <div className={buttonClassName} onMouseDown={expandDropdownHandler} />
    </>
  );
};

export default MarketExpanded;
