import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Theme from 'themeSource/custom';
import MobileFooter from './MobileFooter';

const MobileFooterRouting = React.memo(() => (
  <Switch>
    <Route path='/sportsbook' render={() => null} />
    <Route path='/racing' render={() => null} />
    <Route path='/account' render={() => null} />
    <Route path='' component={MobileFooter} />
  </Switch>
));

const MobileFooterContent = Theme.MobileFooterRouting ?? MobileFooterRouting;

const MobileFooterContainer = () => <MobileFooterContent />;

export default MobileFooterContainer;
