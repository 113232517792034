import React from 'react';
import { map } from 'lodash';
import cx from 'classnames';
import { isFeedOffline, isInactive, isSuspended, useBetslipState } from '@apollo/core';
import { getExpandedMarkets, orderByOrderPosition } from '../../../../../core/utils';
import MarketExpanded from '../MarketExpanded/MarketExpanded';
import SportOutcome from '../../../SportOutcome/SportOutcome';

const Market = ({ event, market, suspended, disabled, template }) => {
  const {
    settings: { feedStates },
  } = useBetslipState();

  const { outcomes, parameterized, marketTypeId, providerName } = market;
  const orderedOutcomes = orderByOrderPosition(outcomes);
  const isMarketTypeSuspended = suspended || isSuspended(market) || isFeedOffline(feedStates[providerName]);
  const isMarketTypeDisabled = disabled || isInactive(market);

  const className = React.useMemo(
    () => cx('event__market-row-market', {
      parameterized,
    }),
    [parameterized],
  );

  const renderMarketExpanded = React.useMemo(() => {
    if (!parameterized) {
      return null;
    }

    const markets = getExpandedMarkets(event, marketTypeId);

    if (markets.length < 2) {
      return <div className='market-expanded-event-gap' />;
    }

    return (
      <MarketExpanded
        event={event}
        markets={markets}
        marketTypeId={marketTypeId}
        suspended={suspended}
        disabled={disabled}
      />
    );
  }, [parameterized, event, marketTypeId, suspended, disabled]);

  return (
    <div key={marketTypeId} className={className} style={{ flex: orderedOutcomes.length }}>
      {map(orderedOutcomes, (outcome) => {
        if (outcome.fakeOutcome) {
          return (
            <div
              title={`${marketTypeId}, ${market.id}, ${outcome.id}`}
              key={outcome.id}
              className='event__outcome event__outcome--fake'
            >
              -
            </div>
          );
        }

        return (
          <SportOutcome
            key={outcome.id}
            event={event}
            market={market}
            outcome={outcome}
            marketTypeId={market.marketTypeId}
            name={outcome.name}
            suspended={isMarketTypeSuspended}
            disabled={isMarketTypeDisabled}
            template={template}
          />
        );
      })}
      {renderMarketExpanded}
    </div>
  );
};

export default Market;
