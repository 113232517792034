import React, { useCallback } from 'react';
import { useApplicationState, NotificationManager } from '@apollo/core';
import _ from 'lodash';
import Label from '../components/I18n/Label';

const parseQuery = (queryString) => {
  const query = {};
  const pairs = queryString.substring(queryString.indexOf('?') + 1).split('&');
  pairs.forEach((element) => {
    const pair = element.split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  });
  return query;
};

export default function BitcoinPopup({ id, data }) {
  const { redirectUrl } = data;
  const applicationState = useApplicationState();
  const currency = _.get(applicationState, 'wallet.currency');

  const { amount, bitcoin, exchangeRate } = parseQuery(redirectUrl);
  const depositAmount = Math.round(amount * exchangeRate * 100) / 100;

  const handleClose = useCallback(() => {
    NotificationManager.removeNotification(id);
  }, [id]);

  return (
    <div className='popup'>
      <div className='popup__auth crypto'>
        <div className='block'>
          <div className='block__title'>
            <div>
              Bitcoin
            </div>
            <a onClick={handleClose}>
              <Label
                message='close'
                render={(t) => (
                  <i className='icon__close' title={t} />
                )}
              />
            </a>
          </div>

          <div className='block__content'>
            <span className='popup-label-14'>
              <Label message='crypto_deposit_message' />
            </span>
            <a href={`bitcoin:${bitcoin}?amount=${amount}`} className='code-link'>
              <img src={`https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=bitcoin:${bitcoin}?amount=${amount}&choe=UTF-8`} />
            </a>
            <span className='popup-label-12'>
              <Label message='crypto_deposit_action_message' />
            </span>
            <div className='popup-label-container'>
              <span className='popup-label-12'>
                {`${amount} BTC = ${depositAmount} ${currency}`}
              </span>
            </div>
            <div className='popup-link-container'>
              <a href={`bitcoin:${bitcoin}?amount=${amount}`}>
                {bitcoin}
              </a>
            </div>
            <span className='popup-label-12'>
              {`1 BTC = ${exchangeRate} ${currency}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
