import React, { useMemo } from 'react';
import { SiteConfigManager, useApplicationState } from '@apollo/core';
import { COMPONENT_TYPES, isUrlNamesMatch } from '@apollo/routing';
import { useSportState } from '@apollo/core/src/state/sport/sport';
import { useLocation } from 'react-router-dom';
import { sortBy } from 'lodash';
import TournamentsListItem from './TournamentsListItem';

const TournamentsList = ({ tournaments }) => {
  const { authenticated } = useApplicationState();
  const [sportsState] = useSportState();
  const { pathname } = useLocation();

  const { selectedCategoryUrlName, selectedTournamentUrlName, selectedSportUrlName } = sportsState;

  const prematchPath = SiteConfigManager.getExtraConfig(
    `pathList.${COMPONENT_TYPES.SPORT_PREMATCH}`,
  );

  const sortedTournaments = useMemo(
    () => sortBy(tournaments, (t) => t.position).filter((t) => t.totalCount > 0),
    [tournaments],
  );

  return sortedTournaments.map((tournament) => {
    const active = pathname.startsWith(prematchPath)
      && isUrlNamesMatch(tournament.sportUrlName, selectedSportUrlName)
      && isUrlNamesMatch(tournament.categoryUrlName, selectedCategoryUrlName)
      && isUrlNamesMatch(tournament.urlName, selectedTournamentUrlName);

    return (
      <TournamentsListItem
        key={tournament.id}
        authenticated={authenticated}
        active={active}
        tournament={tournament}
      />
    );
  });
};

export default TournamentsList;
