const DBName = 'apollotech.sqlite';
const DBTableName = 'ClientData';
export default class SQLiteStorageAdapter {
  constructor(dbName) {
    this.dbName = dbName || DBName;
  }

  getDBState() {
    // todo: We're risking that 'deviceready' has not been triggered. Need to refactor
    const { sqlitePlugin } = window;
    if (!sqlitePlugin) {
      throw new Error('Cordova SQLite Plugin missing.');
    }

    if (!this.db) {
      this.db = sqlitePlugin.openDatabase({
        name: this.dbName,
        location: 'default',
      });
      this.db.transaction((tx) => {
        tx.executeSql(
          `CREATE TABLE IF NOT EXISTS ${DBTableName}
                     (
                       dataKey TEXT PRIMARY KEY,
                       dataValue
                     )`,
          [],
          (tx, rs) => {
            console.info('SQLite Initialisation success');
          },
          (tx, error) => {
            console.error(`Create Table ERROR: ${error.message}`);
          },
        );
      }, (error) => {
        console.log(`Transaction ERROR: ${error.message}`);
      }, () => {
        console.log('Populated database OK');
      });
    }

    return {
      db: this.db,
      closeDB: () => {
        // this.db = null;
        // this.db.close(() => {
        //   console.log('DB closed!');
        // }, (error) => {
        //   console.error(`Error closing DB:${error.message}`);
        // });
      },
    };
  }

  getItem(key) {
    console.log(`SELECT start: ${key}`);
    const { db } = this.getDBState();
    return new Promise((resolve, reject) => {
      db.transaction((tx) => {
        tx.executeSql(
          `SELECT dataValue
                       FROM ${DBTableName}
                       where dataKey = ?`,
          [key],
          (tx, rs) => {
            console.log('DB Result', key, rs.rows, rs.rows?.item(0)?.dataValue);
            resolve(rs.rows.item(0)?.dataValue);
          },
          (tx, error) => {
            console.log(`SELECT error: ${error.message}`);
          },
        );
      }, (error) => {
        console.log(`Transaction ERROR: ${error.message}`);
      }, () => {
        console.log('Retrieved from database OK');
      });
    });
  }

  setItem(key, value) {
    console.log(`UPDATE start: ${key}`);
    const { db } = this.getDBState();
    return new Promise((resolve, reject) => {
      db.transaction((tx) => {
        tx.executeSql(
          `REPLACE INTO ${DBTableName} (dataKey, dataValue) VALUES (?, ?)`,
          [key, value],
          (tx, rs) => {
            resolve();
          },
          (tx, error) => {
            console.log(`REPLACE error: ${error.message}`);
          },
        );
      }, (error) => {
        console.log(`Transaction ERROR: ${error.message}`);
      }, () => {
        console.log('Populated database OK');
      });
    });
  }

  removeItem(key, value) {
    console.log(`REMOVE start: ${key}`);
    const { db } = this.getDBState();
    return new Promise((resolve, reject) => {
      db.transaction((tx) => {
        tx.executeSql(
          `DELETE
                       FROM ${DBTableName}
                       WHERE dataKey = ?`,
          [key],
          (tx, rs) => {
            resolve();
          },
          (tx, error) => {
            console.log(`DELETE error: ${error.message}`);
          },
        );
      }, (error) => {
        console.log(`Transaction ERROR: ${error.message}`);
      }, () => {
        console.log('Removed from database OK');
      });
    });
  }
}
