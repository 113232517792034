import React from 'react';
import {
  isEmpty,
} from 'lodash';

import {
  EditBetslipProvider,
  BetManager,
} from '@apollo/core';

import EditBetControls from './EditBetControls';
import EditBetsList from './EditBetsList';
import BetsErrors from '../BetsErrors';
import Label from '../../../shared/components/I18n/Label';

export default function BetEditView(props) {
  const {
    bet,
    replaceBet,
    setEditMode,
  } = props;

  const [
    isAddModeEnabled,
    setAddModeEnabled,
  ] = React.useState(false);

  // Edit bet amount increasement
  // Determines how much the bet amount will be increased
  const [
    amountIncreasement,
    setAmountIncreasement,
  ] = React.useState(0);

  React.useEffect(
    () => {
      // Saving previos bet target
      const previousBetTarget = BetManager.target;

      // Enabling edit mode
      if (isAddModeEnabled) {
        BetManager.focus('edit');
      }

      // Reseting previous bet target
      return () => {
        BetManager.focus(previousBetTarget);
      };
    },
    [isAddModeEnabled],
  );

  if (isEmpty(bet)) {
    return null;
  }

  const handleToggleAdding = () => {
    setAddModeEnabled((editBetAddMode) => !editBetAddMode);
  };

  const cancelEditMode = () => {
    setEditMode(false);
  };

  return (
    <EditBetslipProvider id='edit' bet={bet}>
      <div className='coupon__element__subHeader'>
        <Label className='event' message='coupon_event' />
        <Label className='pick' message='coupon_pick' />
        <Label className='odd' message='coupon_odd' />
      </div>

      <BetsErrors />

      <EditBetsList
        editBetMode={isAddModeEnabled}
      />

      {/* <AddedBetsBuffer /> */}

      <div className='coupon__element__editAdd'>
        <div className='editAddButton' onClick={handleToggleAdding}>
          <Label message={isAddModeEnabled ? 'cancel' : 'add_bets'} />
        </div>
      </div>

      <EditBetControls
        bet={bet}
        amountIncreasement={amountIncreasement}
        setAmountIncreasement={setAmountIncreasement}
        replaceBet={replaceBet}
        setEditMode={setEditMode}
      />

      <div className='bets__block__content'>
        <button type='button' className='buttonCancel' onClick={cancelEditMode}>
          <Label message='cancel' />
        </button>
      </div>
    </EditBetslipProvider>
  );
}

/*
function AddedBetsBuffer() {
  return (
    <div className='coupon__element__addedEvents'>

      <div className='coupon__element__addedEvents__header'>
        <div className='info'>
          <span className='info__name'>Added events</span>
          <span className='info__count'>22</span>
        </div>
        <button type='button' className='bAdd'> Add to bet</button>
      </div>

      <div className='coupon__element__content'>
        <div className='team'>
          <span className='close'> ×</span>

          <span className='icon sportIcon-1'> </span>
          <span className='name'>
            <span className='score'>1-1</span>
            <span>Victoria Maryina Gorka vs Shakhtar Pietrykaw</span>
          </span>
        </div>
        <div className='pick editable'>Victoria Maryina Gorka</div>
        <div className='odd'>4.90</div>
      </div>

      <div className='coupon__element__content'>
        <span className='close'> ×</span>

        <div className='team'>
          <span className='icon sportIcon-1'> </span>
          <span className='name'>
            <span>Nimes vs Brest</span>
          </span>
        </div>
        <div className='pick editable'>Brest</div>
        <div className='odd'>3.10</div>
      </div>

    </div>
  );
}
*/
