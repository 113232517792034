import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';

const LinkListItem = ({ link, icon, name, onClick, className = '', refProps }) => {
  const onClickHandler = useCallback(() => {
    onClick(name);
  }, [onClick, name]);

  return (
    <NavLink
      to={link}
      className={className}
      activeClassName='active'
      onClick={onClickHandler}
      ref={refProps}
    >
      {icon ? <span className={`icon AkIcon-${icon}`} /> : null}
      <span>{name}</span>
    </NavLink>
  );
};

export default LinkListItem;
