import React from 'react';
import { SiteConfigManager } from '@apollo/core';

import {
  CLIENT_PROFILE_COMPONENT_TYPES,
  clientProfileRoutes,
  COMPONENT_TYPES,
  historyAvailableRoutes,
} from '@apollo/routing';
import Label from '../../../shared/components/I18n/Label';
import HistoryRouter from './HistoryRouter';
import ClientProfileLinks from '../ClientProfileLinks';

const { CLIENT_PROFILE } = COMPONENT_TYPES;
const { HISTORY } = CLIENT_PROFILE_COMPONENT_TYPES;
const { history } = clientProfileRoutes;

const History = () => {
  const { tabs = [] } = SiteConfigManager.getSubComponentOptions(CLIENT_PROFILE, HISTORY);

  return (
    <div className='main__page__account__block'>
      <div className='main__page__account__block__title'>
        <div className='name'>
          <Label message='history' />
        </div>

        <ClientProfileLinks
          tabs={tabs}
          availableRoutes={historyAvailableRoutes}
          parentRoute={history}
        />
      </div>
      <div className='main__page__account__block__content'>
        <HistoryRouter tabs={tabs} />
      </div>
    </div>
  );
};

export default History;
