import React from 'react';
import { RacingIcon } from '../../Racing/RacingIcon';

const FilterRaceType = ({
  onClick, isChecked, typeName, id,
}) => (
  <div
    className='filter-item'
    onClick={onClick}
  >
    <input
      type='checkbox'
      checked={isChecked}
      readOnly
    />
    <label className='next-to-jump-race-type'>
      <RacingIcon id={id} name={typeName} />
    </label>
  </div>
);

export default FilterRaceType;
