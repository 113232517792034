import React from 'react';
import { usePreferences } from '@apollo/core';
import _ from 'lodash';
import EventListItem from './EventListItem';
import { EVENT_LIST_TEMPLATE, groupEventsByDate } from '../../../../core/utils';
import { EventFilterProvider } from '../../../../state/EventFilter/EventFilter';
import EventListGroupHeader, { MultiMarketsFilter } from './EventListGroupHeader';

const EventList = ({
  events,
  activeMarketTypeId,
  groupByDate,
  isFavoriteList,
  template = EVENT_LIST_TEMPLATE.CLASSIC,
  mainMarketTypesByPeriod,
}) => {
  const { getIsEventFavorite } = usePreferences();

  const eventList = React.useMemo(() => {
    let resultList = _.chain(events)
      .filter(({ outright, marketTypes, status }) => {
        if (
          [EVENT_LIST_TEMPLATE.CLASSIC, EVENT_LIST_TEMPLATE.DATE].includes(template)
          && !marketTypes[activeMarketTypeId]
        ) {
          return false;
        }

        return outright === (template === EVENT_LIST_TEMPLATE.OUTRIGHT);
      })
      .value();

    if (groupByDate) {
      resultList = groupEventsByDate(resultList, getIsEventFavorite);
    }

    if (isFavoriteList) {
      resultList = _.chain(resultList)
        .filter((e) => getIsEventFavorite({ eventId: e.id }))
        .value();
    }

    return resultList;
  }, [events, groupByDate, isFavoriteList, template, activeMarketTypeId]);

  const filteredMainMarketTypesByPeriod = React.useMemo(
    // AP-1848: hide columns/markets without outcomes
    () => ({
      ...mainMarketTypesByPeriod,
      fullPeriod: {
        ...mainMarketTypesByPeriod.fullPeriod,
        marketTypes: mainMarketTypesByPeriod.fullPeriod?.marketTypes?.filter((mt) => events.some((e) => e.marketTypes[mt.id])),
      },
    }),
    [mainMarketTypesByPeriod, events],
  );

  return (
    <EventFilterProvider>
      {template === EVENT_LIST_TEMPLATE.MULTI_MARKETS && (
        <MultiMarketsFilter mainMarketTypesByPeriod={filteredMainMarketTypesByPeriod} />
      )}

      {eventList.map((group) => (
        <EventListGroupHeader
          key={group[0]?.startTime}
          template={template}
          startTime={Number(group[0]?.startTime)}
          mainMarketTypesByPeriod={filteredMainMarketTypesByPeriod}
        >
          {group.length ? (
            group.map((event, index) => (
              <EventListItem
                key={event.id}
                event={event}
                template={template}
                order={getIsEventFavorite({ eventId: event.id }) && !groupByDate ? -1 : index}
                activeMarketTypeId={activeMarketTypeId}
                mainMarketTypesByPeriod={filteredMainMarketTypesByPeriod}
              />
            ))
          ) : (
            <EventListItem
              key={group.id}
              event={group}
              template={template}
              activeMarketTypeId={activeMarketTypeId}
              mainMarketTypesByPeriod={mainMarketTypesByPeriod}
            />
          )}
        </EventListGroupHeader>
      ))}
    </EventFilterProvider>
  );
};

export default EventList;
