import React from 'react';
import { DateService } from '@apollo/core';
import useTranslate from '../../../../../shared/components/I18n/Interpreter';
import { transactionTypes } from '../../../../../core/constants';
import TransactionCancelButton from './TransactionCancelButton';
import { getCurrencySymbol } from '../../../../../core/utils';
import TransactionAmount from './TransactionAmount';
import TransactionEventDetails from './TransactionEventDetails';
import BetCouponOdds from '../../../../Betslip/MyBets/BetCouponOdds';

const { WITHDRAWAL_CREATE } = transactionTypes;

const TransactionHistoryItemDesktop = ({
  transaction,
  outcomes,
  isBetTransaction,
  flexiPercentage,
  disableCancel,
  decimalCashBalance,
  cancelWithdrawalHandler,
  oddsAmount,
  originalBetOdds,
  bonusInfoList,
  bonusType,
  betType,
  betUpdatedOdds,
}) => {
  const { id, type, status, properties, currency, creationDate } = transaction;

  const t = useTranslate();

  const date = DateService.getFormattedDate(creationDate);
  const time = DateService.getFormattedTime(creationDate);
  const currencySymbol = getCurrencySymbol(currency);

  return (
    <div className={`history-table-row transaction ${status} ${type}`}>
      <div className='history-table-cell date'>
        <span>{date}</span>
        <span className='sub-title'>{time}</span>
      </div>
      <div className='history-table-cell date'>
        <span>{t(type)}</span>
        <span className='sub-title'>{id}</span>
      </div>
      <div className='history-table-cell history-table-cell--trans-info'>
        {isBetTransaction ? (
          <TransactionEventDetails
            outcomes={outcomes}
            transaction={transaction}
            flexiPercentage={flexiPercentage}
          />
        ) : (
          <span>{properties?.PM_NAME ?? '-'}</span>
        )}
      </div>
      <div className='history-table-cell'>
        <div className='bet-odds'>
          {oddsAmount ? (
            <>
              {/* <TransactionOdds
                betOdds={oddsAmount}
                outcomes={outcomes}
                originalBetOdds={originalBetOdds}
              /> */}
              <BetCouponOdds
                updatedOdds={betUpdatedOdds}
                bonusInfoList={bonusInfoList}
                betType={betType}
                outcome={{ odds: originalBetOdds || oddsAmount, outcomeInfo: outcomes[0] }}
              />
            </>
          ) : (
            '-'
          )}
        </div>
      </div>
      <div className='history-table-cell'>
        <TransactionAmount
          transaction={transaction}
          bonusInfoList={bonusInfoList}
          bonusType={bonusType}
        />
      </div>
      <div className='history-table-cell'>
        <span className='bet'>{`${currencySymbol}${decimalCashBalance}`}</span>
      </div>
      <div className='history-table-cell verify-status'>
        {!disableCancel && type === WITHDRAWAL_CREATE && (
          <TransactionCancelButton onCancel={cancelWithdrawalHandler} />
        )}

        {/*
        <div className='status-label'>
          <span>
            {t(status === OPERATION_STATUS_FAILED ? 'declined' : status)}
          </span>
        </div>

        {status === OPERATION_STATUS_FAILED && comment && (
          <StatusTooltip message={comment} position='right'>
            <div className='status-label sm'>
              <span>?</span>
            </div>
          </StatusTooltip>
        )}
        */}
      </div>
    </div>
  );
};

export default TransactionHistoryItemDesktop;
