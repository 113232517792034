import { useRef, useCallback, useLayoutEffect } from 'react';

const useScrollToTop = ({ parentMode = false, mountMode = false, unmountMode = false }) => {
  const elementRef = useRef(null);

  const scrollToTopHandler = useCallback(() => {
    const element = _.get(elementRef, `current${parentMode ? '.parentNode' : ''}`);

    if (element) {
      element.scrollTo(0, 0);
    }
  }, [parentMode]);

  useLayoutEffect(() => {
    if (mountMode) {
      scrollToTopHandler();
    }

    if (unmountMode) {
      return () => scrollToTopHandler();
    }
  }, []);

  return {
    elementRef,
    scrollToTopHandler,
  };
};

export default useScrollToTop;
