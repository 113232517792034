import React, { useCallback, useMemo } from 'react';
import {
  useCashout,
  cashoutConstants, centsToDecimal,
} from '@apollo/core';
import _ from 'lodash';
import {
  orderCashoutButtonTextSetMinAmount,
  orderCashoutButtonTextSetMaxAmount,
} from './constants';
import Label from '../I18n/Label';
import MoneyInput from '../Form/MoneyInput/MoneyInput';

const {
  orderCashoutTypes: {
    ORDER_CASHOUT_TYPE,
    ORDER_PARTIAL_CASHOUT_TYPE,
  },
  orderCashoutWarningSmallAmount,
  orderCashoutWarningBigAmount,
} = cashoutConstants;

const OrderCashoutPopup = () => {
  const {
    onOrderCashout,
    setIsOrderCashoutExpanded,
    isOrderCashoutExpanded,
    setOrderCashout,
    setOrderPartialCashout,
    setOrderCashoutType,
    isOrderCashoutLoading,
    orderCashout,
    orderCashoutWarning,
    currencySymbol,
    orderPartialCashout,
    orderCashoutType,
  } = useCashout();

  const onCloseOrderPartialCashout = useCallback(() => {
    setIsOrderCashoutExpanded(false);
  }, [setIsOrderCashoutExpanded]);

  const orderCashoutButtonText = useMemo(() => {
    if (_.isEmpty(orderCashoutWarning)) {
      return 'create_rule';
    }
    if (orderCashoutWarning.type === orderCashoutWarningSmallAmount) {
      return orderCashoutButtonTextSetMinAmount;
    }

    if (orderCashoutWarning.type === orderCashoutWarningBigAmount) {
      return orderCashoutButtonTextSetMaxAmount;
    }
    return 'create_rule_warning';
  }, [orderCashoutWarning]);

  const setTypeToOrderCashout = useCallback(() => {
    setOrderCashoutType(ORDER_CASHOUT_TYPE);
  }, [setOrderCashoutType]);

  const setTypeToOrderPartialCashout = useCallback(() => {
    setOrderCashoutType(ORDER_PARTIAL_CASHOUT_TYPE);
  }, [setOrderCashoutType, setOrderPartialCashout]);

  if (!isOrderCashoutExpanded) return null;

  return (
    <div className='cashoutBlock'>
      <div
        className='closeIcon  AkIcon-close-icon'
        onClick={onCloseOrderPartialCashout}
      />
      <div className='cashoutBlock__title'>
        <Label message='auto_cash_out' />
      </div>
      <div className='cashoutBlock__content'>
        <div className='radioContainer '>
          <div className='inputBlock'>
            <MoneyInput
              value={orderCashout}
              onChange={setOrderCashout}
            />
          </div>
          <label className='container'>
            <input
              type='radio'
              checked={orderCashoutType === ORDER_CASHOUT_TYPE}
              onClick={setTypeToOrderCashout}
              name='radio'
            />
            <span className='checkmark' />
            <Label message='Order full Cashout' />
          </label>
          <label className='container'>
            <input
              type='radio'
              checked={orderCashoutType === ORDER_PARTIAL_CASHOUT_TYPE}
              onClick={setTypeToOrderPartialCashout}
              name='radio'
            />
            <span className='checkmark' />
            <Label message='Order Partial Cashout' />
          </label>
        </div>
        <div className='inputBlock'>
          <MoneyInput
            value={orderPartialCashout}
            disabled={orderCashoutType !== ORDER_PARTIAL_CASHOUT_TYPE}
            onChange={setOrderPartialCashout}
          />
        </div>
        {!_.isEmpty(orderCashoutWarning) && (
          <Label
            message={orderCashoutWarning.type}
            render={(translation) => (
              <span className='error-message'>
                {`${translation} ${currencySymbol}${centsToDecimal(orderCashoutWarning.amount)}`}
              </span>
            )}
          />
        )}
        <button
          type='button'
          className='bBasePrimary'
          onClick={onOrderCashout}
        >
          <Label message={orderCashoutButtonText} />
          {' '}
          {!_.isEmpty(orderCashoutWarning) && (
            <span>{`${centsToDecimal(orderCashoutWarning.amount)}${currencySymbol}`}</span>)}
        </button>
      </div>
    </div>
  );
};

export default OrderCashoutPopup;
