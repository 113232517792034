import React, { useMemo } from 'react';
import { DateService, SettingsManager } from '@apollo/core';
import moment from 'moment';
import { getIso2FromIso3 } from '@apollo/core/src/utils';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import RaceCountdown from '../../../../shared/components/Racing/RaceCountdown/RaceCountdown';
import { getEventDate } from '../../../../core/utils';

const EventStartTime = ({ event, withCountDown, withDate }) => {
  const { startTime } = event;
  const t = useTranslate();

  const time = useMemo(() => {
    if (!startTime) {
      return '';
    }

    const dateRowContent = getEventDate(startTime, t);

    // We want the time display to be different here
    // const timeRowContent = DateService.getFormattedTime(startTime);
    const { lang } = SettingsManager.settings;
    const timeRowContent = moment(startTime)
      .locale(getIso2FromIso3(lang) || 'en')
      .format('HH:mm');

    return (
      <>
        {withDate ? `${dateRowContent} ` : null}
        {timeRowContent}
      </>
    );
  }, [t, startTime, withDate]);

  return (
    <div
      className='event__date-time dateTime'
      title={`id:${event.id}:${event.source?.slice(0, 2)} cId:${event.correlationId}`}
    >
      {withCountDown && (
        <RaceCountdown
          date={new Date(event.startTime)}
          completeText={event.result || event.status}
        />
      )}

      <span className='event__date-time-text'>{time}</span>
    </div>
  );
};

export default EventStartTime;
