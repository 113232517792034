import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {
  AccountManager,
  AnalyticsManager,
  ApiManager,
  Apollo,
  BetManager,
  Bus,
  ExtensionsManager,
  Fuse,
  LocalStorageAdapter,
  SettingsManager,
  SiteConfigManager,
  SportManager,
  StorageManager,
  TranslationProvider,
  WSManager,
} from '@apollo/core';
import Theme from 'themeSource/custom';
import { COMPONENT_TYPES, staticRoutes } from '@apollo/routing';
import { THEME_CONTRAST } from '@apollo/core/src/constants';
import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import history from './app/core/history';
import { LocalConfig } from './app/core/utils';
import { defaultHeaderKeys } from './app/core/constants';
import RootLayout from './app/shared/components/RootLayout';
import PromotionRouter from './app/features/PromotionsPage/PromotionRouter';
import CompetitionRouter from './app/features/Competition/CompetitionRouter';
import MainPageClientProfile from './app/features/ClientProfile/MainPageClientProfile';
import './app/shared/components/Form/formConfig';
import useTranslate from './app/shared/components/I18n/Interpreter';
import ResultsPage from './app/features/ResultsPage/ResultsPage';
import busEventsMapping from './app/core/subscriptions/bus.bindings';
import { resolveFingerprint } from './app/core/fingerprint';
import Layout from './app/shared/components/Layout/Layout';
import AffiliatePage from './app/shared/components/AffiliatePage/AffiliatePage';
import LayoutErrorFallback from './app/shared/components/Layout/LayoutErrorFallback';
import AnimationsProvider from './app/features/Animations/AnimationsProvider';

// Lazy Load these modules
const Sportsbook = React.lazy(() => import('./app/features/Sports/Sportsbook'));
const Racing = React.lazy(() => import('./app/features/Racing/Racing'));

// 'themeSource' is a resolved alias in 'webpack.modules.js'
// eslint-disable-next-line global-require
require('themeSource/scss/main.scss');

// Prime the LocalConfig
window.SYS_CONFIG = CONFIG;

window.BetManager = BetManager;
window.Bus = Bus;
window.AccountManager = AccountManager;
window.SportManager = SportManager;
window.SiteConfigManager = SiteConfigManager;

// Creating apollo extensions manager
window.aem = new ExtensionsManager();

// Note, this is swapped-out to the SQLiteStorageAdapter in Cordova builds
StorageManager.setStorageAdapter(new LocalStorageAdapter());

busEventsMapping(Bus, history);

// Providing project id
const projectId = LocalConfig.get('projectId', '');

// Initializing api default headers
const defaultHeaders = {
  version: CORDOVA ? APP_VERSION : VERSION,
  device: 'desktop',
  [defaultHeaderKeys.ProjectID]: projectId,
  [defaultHeaderKeys.LocalPlatform]: CORDOVA ? 'Cordova' : 'Browser',
};

if (typeof window !== 'undefined') {
  if (typeof window.Intl !== 'undefined') {
    defaultHeaders[defaultHeaderKeys.LocalTimeZone] = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  if (typeof window.navigator !== 'undefined') {
    defaultHeaders[defaultHeaderKeys.LocalLanguage] = navigator.languages === undefined ? navigator.language : navigator.languages[0];
  }
}

// Setting application version & device type
ApiManager.setDefaultHeaders(defaultHeaders);

// NODE_ENV == 'development' | 'production'
const hosts = LocalConfig.get('hosts', {})[NODE_ENV];
// console.log('HOSTS:', hosts);
ApiManager.setHostname('core', `${hosts.gateway}/`);
ApiManager.setHostname('cms', `${hosts.cms}/v1/site/`);
ApiManager.setHostname('ws', `${hosts.notifier}/notifier2`);
ApiManager.setHostname('media', `${hosts.media}/`);
ApiManager.setHostname('cdn', `${hosts.cdn}/`);

// Set Custom Client Settings
SettingsManager.configure({
  buildMode: NODE_ENV,
  lang: 'ENG',
  theme: LocalConfig.get('themeContrast', THEME_CONTRAST.LIGHT),
  oddsFormat: 'decimal',
  isBalanceHidden: false,
  isCordova: !!CORDOVA,
  platform: CORDOVA ? 'Cordova' : 'Browser',
  allowTracking: !CORDOVA, // Disable tracking by default in Cordova and perform ATT.
  trackingId: null,
  gtm: LocalConfig.get('GTM', null),
  fbPix: LocalConfig.get('FBPix', null),
  googleAPIKey: LocalConfig.get('googleAPIKey', null),
  countryWhitelist: LocalConfig.get('countryWhitelist', null),
  clientBlocked: false,
  rootDomain: hosts.client || window.location.origin,
  betslip: LocalConfig.get('betslip', {}),
});

WSManager.connect({
  projectId,
});

// Initializing analytics
AnalyticsManager.init({
  fingerprint: () => resolveFingerprint().then((components) => window.btoa(JSON.stringify(components))),
});

const SuspenseComponent = (Component) => () => {
  const t = useTranslate();
  return (
    <Suspense fallback={<div>{t('loading...')}</div>}>
      <Component />
    </Suspense>
  );
};

SiteConfigManager.setLayoutConfig({
  [COMPONENT_TYPES.RACING]: {
    defaultPath: staticRoutes.racing,
    component: SuspenseComponent(Racing),
  },
  [COMPONENT_TYPES.SPORT_LIVE]: {
    defaultPath: staticRoutes.live,
    component: SuspenseComponent(Sportsbook),
  },
  [COMPONENT_TYPES.SPORT_PREMATCH]: {
    defaultPath: staticRoutes.sportsbook,
    component: SuspenseComponent(Sportsbook),
  },
  [COMPONENT_TYPES.CLIENT_PROFILE]: {
    defaultPath: staticRoutes.clientProfile,
    component: MainPageClientProfile,
  },
  [COMPONENT_TYPES.RESULTS]: {
    defaultPath: staticRoutes.results,
    component: ResultsPage,
  },
  [COMPONENT_TYPES.PROMOTIONS]: {
    defaultPath: staticRoutes.promotions,
    optionalPath: '/:id?',
    component: PromotionRouter,
  },
  [COMPONENT_TYPES.PROMOTION_TOURNAMENTS]: {
    defaultPath: staticRoutes.promotions,
    optionalPath: '/:id?',
    component: CompetitionRouter,
  },
});

// Cordova Apps. must use the 'HashRouter'
const RouterComponent = CORDOVA ? HashRouter : BrowserRouter;

const ThemeLayout = Theme.Layout ?? Layout;
const AffiliateLayout = Theme.AffiliatePage ?? AffiliatePage;
const LayoutError = Theme.LayoutErrorFallback ?? LayoutErrorFallback;

ReactDOM.render(
  <Apollo>
    <Fuse fallback={LayoutError}>
      <HelmetProvider>
        <TranslationProvider>
          <AnimationsProvider>
            <RouterComponent history={history}>
              <Switch>
                <Route path='/signup/:affiliateId'>
                  <AffiliateLayout />
                </Route>
                <Route>
                  <RootLayout>
                    <ThemeLayout />
                  </RootLayout>
                </Route>
              </Switch>
            </RouterComponent>
          </AnimationsProvider>
        </TranslationProvider>
      </HelmetProvider>
    </Fuse>
  </Apollo>,
  document.getElementById('root'),
);
