import React from 'react';

const SET_STATE = 'SET_STATE';

const defaultState = {
  externalId: null,
  sportService: null,
  error: null,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_STATE: {
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      return state;
    }
  }
};

const useStatscoreState = () => {
  const [state, dispatch] = React.useReducer(reducer, defaultState);

  const updateStatscoreEventData = React.useCallback(
    (externalId, sportService) => {
      dispatch({ type: SET_STATE, payload: { externalId, sportService } });
    },
    [],
  );

  const setStatscoreError = React.useCallback((error) => {
    dispatch({ type: SET_STATE, payload: { error } });
  }, []);

  return {
    ...state,
    updateStatscoreEventData,
    setStatscoreError,
  };
};

export default useStatscoreState;
