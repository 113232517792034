import React, { useCallback } from 'react';
import { forEach, isNull, map } from 'lodash';
import { useSettings } from '@apollo/core';

// This matches the pattern: [General Terms and Conditions](/page/terms)
const uriLinksPattern = /^https?:\/\//i;
const textLinksPattern = /(\[[^\]\[]+\]\([^\)\(]+\))/g;
const textPathLinkPattern = /\[([^\[\]]+)\]\(([^\(\)]+)\)/;

const TranslateWithLinks = ({ translate }) => {
  const { isCordova, rootDomain } = useSettings();

  const linkStrings = translate.match(textLinksPattern);

  const handleAnchorClick = useCallback(
    (event) => {
      if (!isCordova) {
        return;
      }
      event.preventDefault();
      const anchor = event.currentTarget;
      const href = anchor.getAttribute('href');
      window.open(href, '_system');
      return false;
    },
    [isCordova],
  );

  if (isNull(linkStrings)) {
    return translate;
  }

  let parsedString = translate;
  const elements = [];

  forEach(linkStrings, (linkString) => {
    const linkStartIndex = parsedString.indexOf(linkString);
    const textBeforeLink = parsedString.slice(0, linkStartIndex);

    elements.push({ text: textBeforeLink });
    parsedString = parsedString.slice(textBeforeLink.length);

    const [, text, link] = linkString.match(textPathLinkPattern);
    elements.push({ text, link });
    parsedString = parsedString.slice(linkString.length);
  });

  if (parsedString.length) {
    elements.push({ text: parsedString });
  }

  return map(elements, ({ text, link }, index) => {
    if (link) {
      const path = uriLinksPattern.test(link) ? link : `${rootDomain}${link}`;
      return (
        <a
          key={`${link}${index}`}
          className='link'
          href={path}
          target='_blank'
          rel='noreferrer'
          onClick={handleAnchorClick}
        >
          {text}
        </a>
      );
    }
    return (
      <span key={`${text}${index}`} className='text'>
        {text}
      </span>
    );
  });
};

export default TranslateWithLinks;
