import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { SiteConfigManager } from '@apollo/core';

import { COMPONENT_TYPES } from '@apollo/routing';

import PromotionsListPage from './PromotionsListPage';
import PromotionPage from './PromotionPage';

const { PROMOTIONS } = COMPONENT_TYPES;

const PromotionsRouter = () => {
  const promotionsPath = SiteConfigManager.getComponentPath(PROMOTIONS);

  return (
    <Switch>
      <Route path={`${promotionsPath}/:id`} component={PromotionPage} />
      <Route path={`${promotionsPath}`} component={PromotionsListPage} />
    </Switch>
  );
};

export default PromotionsRouter;
