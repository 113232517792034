import React, { useCallback } from 'react';
import _ from 'lodash';
import { NotificationManager } from '@apollo/core';
import BasePopup from './BasePopup';

const ConfirmPopup = ({
  id,
  title,
  message,
  data: {
    children,
    onClose = _.noop,
    onConfirm = _.noop,
  },
}) => {
  const handleClose = useCallback(() => {
    try {
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      NotificationManager.removeNotification(id);
    }
  }, [id, onClose]);

  const handleConfirm = useCallback(async () => {
    try {
      onConfirm();
    } catch (error) {
      console.log(error);
    } finally {
      NotificationManager.removeNotification(id);
    }
  }, [id, onConfirm]);

  return (
    <BasePopup
      onClose={handleClose}
      type='popup success'
      title={title}
      message={message}
      icon='info'
      isConfirm
      onConfirm={handleConfirm}
    >
      {children && children}
    </BasePopup>
  );
};

export default ConfirmPopup;
