import React from 'react';
import { centsToDecimal } from '@apollo/core';
import { BET_STATUSES, BONUS_TYPE } from '@apollo/core/src/constants';
import { getCurrencySymbol } from '../../../../core/utils';

const BetHistoryFreeBetBonus = ({ bet }) => {
  const { bonusInfoList = [], betStatus, betMoney } = bet;
  const bonus = bonusInfoList[0] || {};

  // AP-2078: Don't show FreeBetBonus until we are sure about them. Backend parameter is not ready yet.
  return null;

  if (bonus.bonusType !== BONUS_TYPE.BET_RETURN || betStatus !== BET_STATUSES.LOSE) {
    return null;
  }

  const amount =
    bonus.freeBetAmount < bonus.props?.max_bonus_amount
      ? bonus.freeBetAmount
      : bonus.props?.max_bonus_amount;

  const currencySymbol = getCurrencySymbol(betMoney?.currency);
  const decimalAmount = centsToDecimal(amount);

  return (
    <div className='bets__status__label free-bet-bonus'>
      {`+${currencySymbol}${decimalAmount}`}
      <i className='icons icon--freebet-black' />
    </div>
  );
};

export default BetHistoryFreeBetBonus;
