import React from 'react';

import { OutcomeService, SiteConfigManager, useSettings } from '@apollo/core';
import { isNumber } from 'lodash';
import { formatOdds } from '../../../core/utils';

export default function Odds({ value, decimal, minDecimal }) {
  const { oddsFormat } = useSettings();

  return React.useMemo(() => {
    const zeroes = SiteConfigManager.getConfig('config.config.odds_zeroes', false);

    if (value >= 1000) {
      return formatOdds(
        value,
        isNumber(decimal) ? decimal : 1,
        isNumber(minDecimal) ? minDecimal : 1
      );
    }

    if (value) {
      return formatOdds(
        value,
        isNumber(decimal) ? decimal : 2,
        isNumber(minDecimal) ? minDecimal : 2
      );
    }

    /* if (value < 10) {
      return formatOdds(
        value,
        isNumber(decimal) ? decimal : 2,
        isNumber(minDecimal) ? minDecimal : 2,
      );
    }

    if (value < 100) {
      return formatOdds(
        value,
        isNumber(decimal) ? decimal : 1,
        isNumber(minDecimal) ? minDecimal : 1,
      );
    }

    if (value >= 100) {
      return formatOdds(
        value,
        isNumber(decimal) ? decimal : 0,
        isNumber(minDecimal) ? minDecimal : 0,
      );
    } */

    const formatedService = OutcomeService.formatOdds(value, oddsFormat, {
      zeroes,
    });
    return isNaN(formatedService) ? formatedService : formatOdds(formatedService, zeroes, zeroes);
  }, [value, oddsFormat, decimal, minDecimal]);
}
