import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import {
  RacingActions,
  useRacingState,
} from '@apollo/core/src/state/racing/racing';
import { STATUS } from '@apollo/core/src/constants';
import { RACE_STATUS } from '../../../../core/constants';

const ExoticOutcome = ({
  className,
  race,
  runner = {},
  runners,
  place,
  disabledBoxed,
  marketType,
}) => {
  const [racingState, racingDispatcher] = useRacingState();

  const outcomeSelections = useMemo(
    () => racingState[`${marketType?.toLowerCase()}Selections`] || [],
    [marketType, racingState],
  );

  const isDisabled = useMemo(
    () => ![STATUS.ACTIVE, RACE_STATUS.OPEN].includes(race.status)
      || runner?.scratched
      || disabledBoxed,
    [race, runner, disabledBoxed],
  );

  const isSelected = useMemo(
    () => (_.isEmpty(runners)
      ? !!outcomeSelections.find(
        (s) => s.runner === runner.number && s.place === place,
      )
      : runners
        .filter((r) => !r.scratched)
        .every((r) => outcomeSelections.find(
          (s) => s.runner === r.number && s.place === place,
        ))),
    [outcomeSelections, runner, runners, place],
  );

  const clickHandler = useCallback(
    (e) => {
      let selections = [...outcomeSelections];

      if (_.isEmpty(runners)) {
        if (isSelected) {
          selections = selections.filter(
            (s) => !(s.runner === runner?.number && s.place === place),
          );
        } else {
          selections.push({ runner: runner.number, place });
        }
      } else {
        selections = selections.filter((s) => s.place !== place);
        if (!isSelected) {
          selections.push(
            ...runners
              .filter((r) => !r.scratched)
              .map((r) => ({ runner: r.number, place })),
          );
        }
      }

      // console.log(222, selections);
      const payload = {};
      payload[`${marketType?.toLowerCase()}Selections`] = selections;

      racingDispatcher({
        type: RacingActions.RACING_STATE_UPDATE,
        payload,
      });

      e.stopPropagation();
    },
    [marketType, race, runner, runners, outcomeSelections, place, isSelected],
  );

  const outcomeClassName = React.useMemo(
    () => cx(className, 'event__outcome', {
      disabled: isDisabled,
      selected: isSelected,
      'disabled-boxed': disabledBoxed,
      // editing,
      // placed,
      // failed,
      // loading,
      // succeeded,
      'event__outcome--with-name': Boolean(runner.name),
    }),
    [
      className,
      isSelected,
      // editing,
      // placed,
      // failed,
      // loading,
      // succeeded,
      isDisabled,
    ],
  );
  return (
    <div
      className={outcomeClassName}
      {...(!isDisabled && { onClick: clickHandler })}
    >
      <span className='place'>{place}</span>
    </div>
  );
};

export default ExoticOutcome;
