import React from 'react';
import Odds from '../../../../shared/components/Odds';
// import Odds from 'shared/Odds';

const MyOrderOutcomeInfo = ({ outcome }) => {
  const {
    eventName, currentOdds, outcomeName, eventId,
  } = outcome;

  return (
    <div className='history-table-row bet-history'>
      <div className='bet-history-row'>
        <div className='history-table-cell'>
          <span className='sub-title'>{eventId}</span>
        </div>
        <div className='history-table-cell'>
          <div className='event-with-score'>
            <span className='event'>{eventName}</span>
          </div>
          <span className='sub-title' />
        </div>
        <div className='history-table-cell'>
          <span className='odds'>
            <Odds value={currentOdds} />
          </span>
        </div>
        <div className='history-table-cell'>
          <div className='col'>
            <span className='market-name'>{outcomeName}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOrderOutcomeInfo;
