import React, { useMemo } from 'react';
import { SiteConfigManager, useApplicationState } from '@apollo/core';
import { useRacingState } from '@apollo/core/src/state/racing/racing';
import { map } from 'lodash';
import { RaceTypeAll, RaceTypesList, SIDEBAR_ITEMS } from '@apollo/core/src/constants';
import { COMPONENT_TYPES } from '@apollo/routing';
import { Link, useLocation } from 'react-router-dom';
import Label from '../../../shared/components/I18n/Label';
import RacingTreeItem from '../../../shared/components/Racing/RacingTreeItem';
import { RacingIcon } from '../../../shared/components/Racing/RacingIcon';
import { AnimatedDropdown } from '../../Animations/AnimatedDropdown';

const RacingSidebar = ({ isExpanded, handleExpand }) => {
  const { authenticated } = useApplicationState();

  const [racingState] = useRacingState();
  const { dates, raceType } = racingState;

  const racingPath = useMemo(
    () => SiteConfigManager.getExtraConfig(`pathList.${COMPONENT_TYPES.RACING}`),
    []
  );

  const todaysDate = dates?.[0]?.date;

  const { pathname } = useLocation();

  return (
    <>
      <div className='sidebar-tree__section-title'>
        <Link to={`${racingPath}/${todaysDate}`} className='sidebar-tree__section-title--link'>
          <RacingIcon id='HORSE' name='Horses' />
          <Label message='racing' className='name' />
        </Link>
        <span
          className={`icon AIcon-angle-${!isExpanded ? 'down' : 'up'}`}
          onClick={() => handleExpand(SIDEBAR_ITEMS.RACING)}
        />
      </div>

      <AnimatedDropdown isExpanded={isExpanded} className='sidebar-tree__section-data'>
        <div className='sidebar-tree__section sidebar-tree__section--race-types'>
          {map([RaceTypeAll, ...RaceTypesList], (rt) => (
            <RacingTreeItem
              key={rt.id}
              id={rt.id}
              name={rt.typeName}
              raceDate={todaysDate}
              raceType={rt.typeName}
              raceVenue={null}
              raceNumber={null}
              active={raceType === rt.typeName && pathname.startsWith(racingPath)}
              authenticated={authenticated}
              showIcon
            />
          ))}
        </div>
      </AnimatedDropdown>
    </>
  );
};
export default RacingSidebar;
