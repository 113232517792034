import React from 'react'
import _ from 'lodash'

const SET_STATE = 'SET_STATE'
const initialState = {
  input: '',
  key: '',
  isRequired: false
}
const reducer = (state, action) => {
  switch (action.type) {
    case SET_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default: {
      return state
    }
  }
}

const useFormCaptcha = (
  initialKey = '',
  initialIsRequired = false,
  onError,
) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { ...initialState, key: initialKey, isRequired: initialIsRequired }
    )

  const setInput = React.useCallback((input) => {
    dispatch({ type: SET_STATE, payload: { input } })
  }, []);

  const handleError = React.useCallback((error, ...props) => {
    const [isRequired, key] = _.at(
      error,
      ['violationInfo.captchaRequired', 'violationInfo.captchaKey']
    )
    dispatch({ type: SET_STATE, payload: { isRequired, key } })

    onError && onError(error, ...props)
  }, [onError])

  return {
    ...state,
    setInput,
    handleError,
  }
}

export default useFormCaptcha
