import React, { useMemo } from 'react';
import { hasMarketTypes, isHidden } from '@apollo/core';
import { BonusSelector } from '../../../state/Bonus/Bonus';
import BonusBannerItem from './BonusBannerItem';

const BonusBanner = ({ race = null, event = null, isExpanded: isOpen = true }) => {
  const bonuses = Object.values(
    // all bonuses for the client
    BonusSelector.getBetReturnsByEventId(race?.raceId || event?.id) || {},
  );

  const betReturnBonuses = useMemo(
    // only unique betReturn bonuses
    () => bonuses.reduce((acc, bonus) => {
      if (!acc.some((o) => o.id === bonus?.betReturn?.id)) {
        acc.push(bonus.betReturn);
      }
      return acc;
    }, []),
    [bonuses],
  );

  const isShowBanner = race || (event && hasMarketTypes(event) && !isHidden(event));

  return betReturnBonuses.length > 0 && isShowBanner
    ? betReturnBonuses.map((bonus) => (
      <BonusBannerItem bonus={bonus} isOpen={isOpen} key={bonus.id} />
    ))
    : null;
};

export default BonusBanner;
