import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Theme from 'themeSource/custom';
import BetslipContainer from '../../../features/Betslip/BetSlipContainer';

const SidebarRouting = React.memo(() => (
  <Switch>
    <Route>
      <BetslipContainer />
    </Route>
  </Switch>
));

const SidebarContent = Theme.SidebarRightRouting ?? SidebarRouting;

const SidebarRightContainer = () => (
  <div className='main__sidebar main__sidebar--right sidebar'>
    <SidebarContent />
  </div>
);

export default SidebarRightContainer;
