import React from 'react';
import PropTypes from 'prop-types';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';
import {
  map,
} from 'lodash';

const mapRoutes = (routes) => map(routes, ({ path, component }) => (
  <Route
    key={path}
    path={path}
    component={component}
  />
));

const Router = ({ routes, redirectFrom, redirectTo }) => (
  <Switch>
    {
      mapRoutes(routes)
    }
    <Redirect
      from={redirectFrom}
      to={redirectTo}
    />
  </Switch>
);

Router.propTypes = {
  routes: PropTypes.array.isRequired,
  redirectFrom: PropTypes.string.isRequired,
  redirectTo: PropTypes.string.isRequired,
};

export default Router;
