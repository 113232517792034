import React from 'react';
import { JumpingCoinAnimationProvider } from './JumpingCoinAnimation';
import { ParticleAnimationProvider } from './ParticleAnimation';
import { TextAnimationProvider } from './TextAnimation';

const contexts = [ParticleAnimationProvider, TextAnimationProvider, JumpingCoinAnimationProvider];

const AnimationsProvider = ({ children }) =>
  contexts.reduceRight((acc, Comp) => <Comp>{acc}</Comp>, children);

export default AnimationsProvider;
