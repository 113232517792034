import {
  BetManager,
  BETSLIP_ERROR_CODE,
  BETSLIP_VIOLATION,
  Bus,
  constants,
} from '@apollo/core';

import { debounce, get, isEmpty, isNil } from 'lodash';

const { BET_TYPE } = constants;

const validationMethodsMap = {
  [BET_TYPE.SINGLE]: (selectedBet, state) => {
    const {
      betItems,
      settings,
    } = state;
    const maxOdds = get(settings, 'single.maxOdds');

    if (isNil(maxOdds) || isNil(selectedBet.odds)) {
      return false;
    }

    const errors = {};

    selectedBet.outcomes.forEach((outcome) => {
      let { odds } = outcome;
      if (!odds) {
        const betItem = betItems.find((betItem) => betItem.outcomeId === outcome.outcomeId);
        odds = betItem.odds;
      }
      if (odds > maxOdds) {
        errors[outcome.requestId] = {
          requestId: outcome.requestId,
          violations: [
            {
              code: BETSLIP_VIOLATION.MAX_ODDS_AMOUNT_EXCEEDED,
              violationInfo: {
                maxOdds,
              },
            },
          ],
        };
      }
    });

    // If no validation errors was found - then its ok
    if (isEmpty(errors)) {
      return false;
    }

    // Returning finded errors
    return {
      code: BETSLIP_ERROR_CODE.BET_VALIDATION_FAILED,
      errors,
    };
  },

  [BET_TYPE.MULTIPLE]: (selectedBet, state) => {
    const {
      betItems,
      settings,
    } = state;
    const maxOdds = get(settings, 'multiple.maxOdds');
    const maxOutcomes = get(settings, 'multiple.maxOutcomes');
    const violations = [];

    if (!isNil(maxOdds) && !isNil(selectedBet.odds) && !isNil(selectedBet.outcomes)) {
      // Starting to accumulating validation errors
      if (maxOdds && (selectedBet.odds > maxOdds)) {
        violations.push(
          {
            code: BETSLIP_VIOLATION.MAX_ODDS_AMOUNT_EXCEEDED,
            violationInfo: {
              maxOdds,
            },
          },
        );
      }

      if (maxOutcomes && (selectedBet.outcomes.length > maxOutcomes)) {
        violations.push(
          {
            code: BETSLIP_VIOLATION.MAX_OUTCOMES_SIZE_EXCEEDED,
            violationInfo: {
              maxOutcomesSize: maxOutcomes,
            },
          },
        );
      }
    }

    const onlySingleBets = betItems.filter((bet) => bet.onlySingle);

    if (!isEmpty(onlySingleBets)) {
      violations.push(
        {
          code: BETSLIP_VIOLATION.SINGLE_BET_ONLY,
          violationInfo: {
            outcomeIds: onlySingleBets.map((bet) => bet.outcomeId),
          },
        },
      );
    }

    // If no validation errors was found - then its ok
    if (isEmpty(violations)) {
      return false;
    }

    // Returning finded errors
    return {
      code: BETSLIP_ERROR_CODE.BET_VALIDATION_FAILED,
      errors: {
        [selectedBet.requestId]: {
          requestId: selectedBet.requestId,
          violations,
        },
      },
    };
  },

  [BET_TYPE.SYSTEM]: (selectedBet, state) => {
    const {
      betItems,
      settings,
    } = state;
    const maxOdds = get(settings, 'system.maxOdds');
    const maxOutcomes = get(settings, 'system.maxOutcomes');
    const violations = [];

    if (!isNil(maxOdds) && !isNil(selectedBet.odds) && !isNil(selectedBet.outcomes)) {
      // Starting to accumulating validation errors
      if (maxOdds && (selectedBet.odds > maxOdds)) {
        violations.push(
          {
            code: BETSLIP_VIOLATION.MAX_ODDS_AMOUNT_EXCEEDED,
            violationInfo: {
              maxOdds,
            },
          },
        );
      }

      if (maxOutcomes && (selectedBet.outcomes.length > maxOutcomes)) {
        violations.push(
          {
            code: BETSLIP_VIOLATION.MAX_OUTCOMES_SIZE_EXCEEDED,
            violationInfo: {
              maxOutcomesSize: maxOutcomes,
            },
          },
        );
      }
    }

    const onlySingleBets = betItems.filter((bet) => bet.onlySingle);

    if (!isEmpty(onlySingleBets)) {
      violations.push(
        {
          code: BETSLIP_VIOLATION.SINGLE_BET_ONLY,
          violationInfo: {
            outcomeIds: onlySingleBets.map((bet) => bet.outcomeId),
          },
        },
      );
    }

    // If no validation errors was found - then its ok
    if (isEmpty(violations)) {
      return false;
    }

    // Returning finded errors
    return {
      code: BETSLIP_ERROR_CODE.BET_VALIDATION_FAILED,
      errors: {
        [selectedBet.requestId]: {
          requestId: selectedBet.requestId,
          violations,
        },
      },
    };
  },
};

const validate = (state) => {
  const {
    isPossibleMulti,
    betTypes,
    betItems,
    settings,
  } = state;
  // const validationMethod = validationMethodsMap[selectedBet.betType];

  const singleBets = betTypes.find((betType) => betType.betType === BET_TYPE.SINGLE) || {};
  const multiBets = betTypes.find((betType) => betType.betType === BET_TYPE.MULTIPLE);

  let validationErrors = validationMethodsMap[BET_TYPE.SINGLE](singleBets, { betItems, settings });

  if (isPossibleMulti && multiBets) {
    const multiValidationErrors = validationMethodsMap[BET_TYPE.MULTIPLE](multiBets, { betItems, settings });
    if (multiValidationErrors) { // merge errors
      if (validationErrors) {
        validationErrors.errors = { ...validationErrors.errors, ...multiValidationErrors.errors };
      } else {
        validationErrors = multiValidationErrors;
      }
    }
  }

  return validationErrors;
};

const validateSelectedBet = debounce(
  (props) => {
    const {
      isPossibleMulti,
      betTypes,
      betItems,
    } = props;

    // Invoking client validation of selected bet
    const clientValidationError = validate(props);

    if (false && clientValidationError) {
      Bus.emit(Bus.events.bet.validationError, clientValidationError);
    } else {
      const bets = [];
      const singleBets = betTypes.find((betType) => betType.betType === BET_TYPE.SINGLE) || {};
      const multiBets = betTypes.find((betType) => betType.betType === BET_TYPE.MULTIPLE);

      singleBets.outcomes.forEach((outcome) => {
        const bet = BetManager.betProcessor.processBet({ ...singleBets, outcomes: [outcome] });
        const betItem = betItems.find((betItem) => betItem.outcomeId === outcome.outcomeId);
        if (!bet.outcomes[0]?.odds) { // fixme: single bet doesn't contain odds
          bet.outcomes[0].odds = betItem.odds;
        }
        bet.requestId = outcome.requestId;
        bets.push(bet);
      });

      if (isPossibleMulti && multiBets) {
        bets.push(BetManager.betProcessor.processBet(multiBets, betItems));
      }

      BetManager.validateBets(bets).then().catch((err) => console.log(err));
    }
  },
  1000,
);

export default validateSelectedBet;
