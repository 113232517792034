import React from 'react';
import { SIDEBAR_ITEMS } from '@apollo/core/src/constants';

export const SidebarActions = {
  SET_EXPANDED_ITEMS: 'SET_EXPANDED_ITEMS',
  SET_TIME_FILTER: 'SET_TIME_FILTER',
  SET_OPEN_SPORT_URL: 'SET_OPEN_SPORT_URL',
  TOGGLE_OPENED_SPORT: 'TOGGLE_OPENED_SPORT',
};

const sidebarInitialState = {
  sidebarExpandedItems: [SIDEBAR_ITEMS.RACING, SIDEBAR_ITEMS.SPORTS],
  timeFilter: 0,
  openedSportUrlName: null,
};

const createSidebarInitialState = (options) => {
  const { ...restOptions } = options;

  return {
    ...sidebarInitialState,
    ...restOptions,
  };
};

const sidebarReducer = (state, action) => {
  switch (action.type) {
    case SidebarActions.SET_EXPANDED_ITEMS: {
      const newState = { ...state };
      if (newState.sidebarExpandedItems.includes(action.payload.key)) {
        newState.sidebarExpandedItems.splice(
          state.sidebarExpandedItems.indexOf(action.payload.key),
          1,
        );
      } else {
        newState.sidebarExpandedItems.push(action.payload.key);
      }
      return newState;
    }

    case SidebarActions.SET_TIME_FILTER:
      return {
        ...state,
        timeFilter: action.payload.timeFilter,
      };

    case SidebarActions.SET_OPEN_SPORT_URL:
      return {
        ...state,
        openedSportUrlName: action.payload.openedSportUrlName,
      };

    case SidebarActions.TOGGLE_OPENED_SPORT:
      return {
        ...state,
        openedSportUrlName:
          action.payload.openedSportUrlName === state.openedSportUrlName
            ? null
            : action.payload.openedSportUrlName,
      };

    default:
      return state;
  }
};

export const createSidebarState = (options) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, dispatcher] = React.useReducer(
    sidebarReducer,
    options || {},
    createSidebarInitialState,
  );

  return [state, dispatcher];
};

const SidebarContext = React.createContext();

export const SidebarProvider = (props) => {
  const { value, children } = props;

  const [state, dispatcher] = createSidebarState(value);

  return <SidebarContext.Provider value={[state, dispatcher]}>{children}</SidebarContext.Provider>;
};

export const useSidebarState = () => React.useContext(SidebarContext);

const selectState = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state] = useSidebarState();
  return state;
};

export const SidebarSelector = {
  selectState,
};
