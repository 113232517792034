import React, { useCallback, useState } from 'react';
import { useApplicationState } from '@apollo/core';
import { BONUS_TYPE, RaceTypesMap } from '@apollo/core/src/constants';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import BannerSlider from '../../shared/components/BannerSlider/BannerSlider';
import useTranslate from '../../shared/components/I18n/Interpreter';
import Loader from '../../shared/components/Loader';
import { RacingIcon } from '../../shared/components/Racing/RacingIcon';
import { SportIcon } from '../../shared/components/Sport/SportIcon';
import MainBody from '../../shared/components/MainBody/MainBody';
import BonusesNavigation from '../ClientProfile/Bonuses/BonusesNavigation';
import RacingTab from './Tabs/Racing';
import SportsTab from './Tabs/Sports';

const Home = () => {
  const t = useTranslate();
  const { initialized, loading, layout } = useApplicationState();

  const [tabIndex, setTabIndex] = useState(Number(localStorage.getItem('homePageTab')));

  const tabChangeHandler = useCallback(
    (index) => {
      localStorage.setItem('homePageTab', index);
      setTabIndex(index);
    },
    [setTabIndex],
  );

  if (!initialized && loading) {
    return <Loader />;
  }

  return (
    <MainBody className='main__body--home-page'>
      {layout.mobileDevice ? (
        <BonusesNavigation
          bonusTabs={[BONUS_TYPE.ODDS_BOOST, BONUS_TYPE.FREEBET, BONUS_TYPE.BET_RETURN]}
        />
      ) : null}

      <BannerSlider group={1} />

      <Tabs selectedIndex={tabIndex} onSelect={tabChangeHandler}>
        <div className='home-tabs--wrapper'>
          <TabList className='home-tabs'>
            <Tab className='home-tabs__tab-btn' selectedClassName='home-tabs__tab-btn--active'>
              <div className='btn'>
                <RacingIcon id={RaceTypesMap.HORSE?.id} name={RaceTypesMap?.HORSE?.typeName} />
                {t('Racing')}
              </div>
            </Tab>
            <Tab className='home-tabs__tab-btn' selectedClassName='home-tabs__tab-btn--active'>
              <div className='btn'>
                <SportIcon id={1} name={t('sport')} />
                {t('sport')}
              </div>
            </Tab>
          </TabList>
        </div>
        <TabPanel>
          <RacingTab />
        </TabPanel>
        <TabPanel>
          <SportsTab />
        </TabPanel>
      </Tabs>
    </MainBody>
  );
};

export default Home;
