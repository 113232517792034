import React from 'react';
import { SiteConfigManager, useApplicationState } from '@apollo/core';
import { useSportState } from '@apollo/core/src/state/sport/sport';
import { get, isEmpty } from 'lodash';
import Loader from '../../../shared/components/Loader';
import SportTypeButtonList from './Sports/SportTypeButtonList';
import { cmsWidgetPageBlock } from '../../../core/constants';
import WidgetLoader from '../../../shared/components/Cms/Widgets/WidgetLoader';
import Upcoming from '../../../shared/components/Cms/Widgets/Upcoming';
import TopSports from '../../../shared/components/Cms/Widgets/TopSports';
import TopTournaments from '../../../shared/components/Cms/Widgets/TopTournaments';

const SportsTab = () => {
  const { layout } = useApplicationState();
  const [sportState] = useSportState();
  const { initialized, loading } = sportState;
  const widgets = get(SiteConfigManager.widgets, cmsWidgetPageBlock.HomePageSport) || [];

  if (!initialized && loading) {
    return <Loader />;
  }

  return (
    <div className='home-tab home-tab--sports'>
      <SportTypeButtonList />
      {isEmpty(widgets) && (
        <>
          <Upcoming showTimeFilter />
          <TopSports />
          <TopTournaments />
        </>
      )}
      {widgets.map((w) => (
        <WidgetLoader key={w.id} widget={w} />
      ))}
    </div>
  );
};

export default SportsTab;
