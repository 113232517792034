import React from 'react';
import { clientProfileRoutes } from '@apollo/routing';
import Label from '../../../shared/components/I18n/Label';
import CommunicationForm from './CommunicationForm';
import { cmsStaticBlock } from '../../../core/constants';
import StaticBlock from '../../../shared/components/StaticBlock/StaticBlock';
import ClientProfileLinks from '../ClientProfileLinks';

const { communication } = clientProfileRoutes;

const Communication = () => (
  <div className='main__page__account__block'>
    <div className='main__page__account__block__title'>
      <div className='name'>
        <Label message='communication' />
      </div>

      <ClientProfileLinks tabs={[]} availableRoutes={[]} parentRoute={communication} />
    </div>
    <div className='main__page__account__block__content'>
      <div className='wrapper wrapper--communications'>
        <div className='details-profile details-profile--communication'>
          <StaticBlock options={{ source: cmsStaticBlock.ClientCommunication }} />
          <CommunicationForm />
        </div>
      </div>
    </div>
  </div>
);

export default Communication;
