import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { CmsApi, useSettings } from '@apollo/core';
import { StringService } from '@apollo/core/src/services';
import useTranslate from '../I18n/Interpreter';

const defaultState = {
  content: null,
  error: null,
  resolved: false,
  loading: true,
};

const StaticBlock = ({ options, className, params = {}, children }) => {
  const t = useTranslate();
  const [state, setState] = useState(defaultState);
  const { lang, isCordova } = useSettings();
  const staticBlock = useRef(null);

  const handleStaticDataLoaded = useCallback(
    (res) => {
      setState((state) => ({
        ...state,
        content: res.data,
        loading: false,
        resolved: true,
      }));
    },
    [setState],
  );

  const handleStaticDataError = useCallback(
    (staticError) => {
      const { error } = staticError;

      setState((state) => ({
        ...state,
        error,
        loading: false,
        resolved: true,
      }));
    },
    [setState],
  );

  const handleAnchorClick = useCallback(
    (event) => {
      if (!isCordova) {
        return;
      }
      const anchor = event.currentTarget;
      const target = anchor.getAttribute('target');
      if (target && target.toLocaleLowerCase() == '_blank') {
        event.preventDefault();
        const href = anchor.getAttribute('href');
        window.open(href, '_system');
        return false;
      }
    },
    [isCordova],
  );

  useEffect(() => {
    const ac = new AbortController();

    CmsApi.getStaticBlock({
      signal: ac.signal,
      source: options.source,
      lang,
    })
      .then(handleStaticDataLoaded)
      .catch(handleStaticDataError);

    return () => ac.abort();
  }, [handleStaticDataError, handleStaticDataLoaded, options.source, lang]);

  useEffect(() => {
    const anchors = staticBlock.current.getElementsByTagName('a');
    for (let i = 0; i < anchors.length; i += 1) {
      anchors[i].addEventListener('click', handleAnchorClick.bind(this), false);
    }
  }, [state.content]);

  const renderStaticContent = useCallback(
    (content) => {
      const cleanHtml = {
        __html: DOMPurify.sanitize(
          content, // .replace('target="_blank"', 'target="_system"'),
          { KEEP_CONTENT: true, ADD_ATTR: ['target'] },
        ),
      };
      return <div className={className} dangerouslySetInnerHTML={cleanHtml} />;
    },
    [className],
  );

  const renderView = useCallback(() => {
    const { content, error, loading, resolved } = state;

    if (loading) {
      return <div className='static-loading'>{t('loading...')}</div>;
    }

    if (resolved) {
      if (error) {
        return <div>{error.message}</div>;
      }

      if (content) {
        const parsedContent = StringService.format(content, params);
        return renderStaticContent(parsedContent);
      }
    }

    return children;
  }, [state, params, renderStaticContent]);

  return (
    <div ref={staticBlock} className={`static ${state.content ? '' : 'static--empty'}`}>
      {renderView()}
    </div>
  );
};

StaticBlock.defaultProps = {
  options: {},
  className: '',
};

StaticBlock.propTypes = {
  options: PropTypes.object,
  className: PropTypes.string,
};

export default React.memo(StaticBlock);
