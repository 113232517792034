import React, { useState } from 'react';
import moment from 'moment';
import cx from 'classnames';
import { SingleDatePicker, isInclusivelyBeforeDay } from 'react-dates';

import {
  useSportsbookState,
  useSportsbookStateUpdate,
} from '@apollo/core';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { getDayEnd, getDayStart } from '../../../core/utils';
import Label from '../../../shared/components/I18n/Label';

const ResultsCalendar = ({
  className,
  disabled,
}) => {
  const [sportsState] = useSportsbookState();
  const {
    fromDate,
    toDate,
    dateFilterType,
  } = sportsState;

  const sportsbookUpdate = useSportsbookStateUpdate();

  const [focusedInput, setFocusedInput] = useState(0);

  const onStartDatesChange = (startDate) => {
    const payload = {
      dateFilterType: 'range',
      fromDate: getDayStart(startDate),
    };

    // If start after end
    if (toDate && startDate.isAfter(toDate)) {
      const endDate = moment();
      // Also rewriting to date
      payload.toDate = getDayEnd(endDate);
    }

    sportsbookUpdate(payload);
  };

  const onEndDatesChange = (endDate) => {
    const payload = {
      dateFilterType: 'range',
      toDate: getDayEnd(endDate),
    };

    // If end before start
    if (fromDate && endDate.isBefore(fromDate)) {
      const startDate = endDate.clone();
      // Also rewriting from date
      payload.fromDate = getDayStart(startDate);
    }

    sportsbookUpdate(payload);
  };

  const momentFromDate = React.useMemo(
    () => moment(fromDate),
    [fromDate],
  );

  const momentToDate = React.useMemo(
    () => moment(toDate),
    [toDate],
  );

  const containerClassName = cx(
    className,
    {
      focused: dateFilterType === 'range',
    },
  );

  return (
    <div className={containerClassName}>
      <div className='position-left'>
        <SingleDatePicker
          date={momentFromDate}
          block={false}
          disabled={disabled}
          id='date-range-from'
          readOnly
          noBorder
          hideKeyboardShortcutsPanel
          displayFormat='DD/MM/YYYY'
          renderCalendarInfo={() => {}}
          isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
          onDateChange={onStartDatesChange} // PropTypes.func.isRequired,
          numberOfMonths={1}
          onFocusChange={({ focused }) => setFocusedInput(focused ? 1 : 0)}
          focused={focusedInput === 1}
        />
        <span className='icon AkIcon-calendar-icon' />
      </div>
      <Label message='to' />
      <div className='position-right'>
        <SingleDatePicker
          date={momentToDate}
          block={false}
          disabled={disabled}
          readOnly
          id='date-range-to'
          noBorder
          hideKeyboardShortcutsPanel
          displayFormat='DD/MM/YYYY'
          renderCalendarInfo={() => {}}
          isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
          onDateChange={onEndDatesChange} // PropTypes.func.isRequired,
          numberOfMonths={1}
          onFocusChange={({ focused }) => setFocusedInput(focused ? 2 : 0)}
          focused={focusedInput === 2}
        />
        <span className='icon AkIcon-calendar-icon' />
      </div>
    </div>
  );
};

export default ResultsCalendar;
