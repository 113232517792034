import React from 'react';
import cx from 'classnames';

import {
  BetManager,
  BetTypeProvider,
  centsToDecimal,
  NotificationManager,
  useApplicationState,
  useBetslipState,
} from '@apollo/core';

import { get, isEmpty, isNil } from 'lodash';
import Label from '../../../shared/components/I18n/Label';
import MoneyInput from '../../../shared/components/Form/MoneyInput/MoneyInput';
import Odds from '../../../shared/components/Odds';
import { getCurrencySymbol } from '../../../core/utils';

export default function EditBetControls(props) {
  const { bet, amountIncreasement, setAmountIncreasement, replaceBet, setEditMode } = props;

  const { betItems, betTypes, active, fullfited, loading } = useBetslipState();

  const {
    wallet: { currency },
  } = useApplicationState();

  const disabled = !active;
  const currencySymbol = getCurrencySymbol(currency);
  // Edit bet available Single or multiple only
  const selectedBet = betTypes[0] || betItems[0];
  const [isExpanded, setExpanded] = React.useState(false);

  const betAmountValue = bet.estimatedCashOut + amountIncreasement;

  // Formatting bet amount & posible win
  const finalBetAmount = centsToDecimal(betAmountValue);

  const AmountLabel = React.useMemo(() => {
    const handleShowDropdown = () => setExpanded((expanded) => !expanded);

    return (
      <span onClick={handleShowDropdown}>
        {currencySymbol}
        {finalBetAmount}
      </span>
    );
  }, [currencySymbol, finalBetAmount]);

  const handleAmountInputSubmit = React.useCallback(() => setExpanded(false), []);
  const handleSubmitEditBet = React.useCallback(() => {
    if (disabled || !selectedBet) {
      return;
    }

    // Processing selected bet
    const processedBet = BetManager.betProcessor.processBet(selectedBet);

    // Setting bet amount
    processedBet.betMoney.betAmount = betAmountValue;
    processedBet.betId = bet.betId;

    BetManager.editBet(processedBet, { estimatedCashOut: bet.estimatedCashOut })
      .then((response) => {
        if (response && response.bet) {
          NotificationManager.display({
            type: 'notification',
            status: 'success',
            title: 'edit_bet_success_title',
            message: 'edit_bet_success_message',
            data: {
              bet: response.bet,
              icon: 'success',
            },
          });

          replaceBet(response.bet, bet.betId);
          setEditMode(false);
        }
      })
      .catch((error) => {
        const newCashOutAmount = get(error, 'additionalInfo.newCashOutAmount');

        if (!isNil(newCashOutAmount)) {
          // Updating bet state with provided new cashout amount
          replaceBet(
            {
              ...bet,
              estimatedCashOut: newCashOutAmount,
            },
            bet.betId
          );
        }
      });
  }, [disabled, selectedBet, betAmountValue, bet, replaceBet, setEditMode]);

  // Do not mounting bet type provider with edit bet controlls
  // Untill selected bet exist and betslip outcomes ready
  if (isEmpty(selectedBet) || !fullfited) {
    return null;
  }

  const posibleWin = centsToDecimal(betAmountValue * selectedBet.odds);

  const submitButtonClassName = cx('buttonBet', {
    disabled,
  });

  return (
    <BetTypeProvider betType={selectedBet} key={selectedBet.requestId}>
      <div className='coupon__element__editFooter'>
        <div className='coupon__element__editFooter__states'>
          {selectedBet && selectedBet.odds && (
            <div className='block'>
              <div>
                <Label message='new_odds' />
              </div>
              <div>
                <Odds value={selectedBet.odds} />
              </div>
            </div>
          )}

          <div className='block'>
            <div>
              <Label message='stake' />
            </div>
            <div className='editable'>
              {AmountLabel}
              {isExpanded && (
                <DropdownAmountInput
                  // disabled={!selectedBet.active}
                  currencySymbol={currencySymbol}
                  posibleWin={posibleWin}
                  finalBetAmount={finalBetAmount}
                  estimatedCashOut={bet.estimatedCashOut}
                  amountIncreasement={amountIncreasement}
                  setAmountIncreasement={setAmountIncreasement}
                  onSubmit={handleAmountInputSubmit}
                />
              )}
            </div>
          </div>

          {posibleWin && (
            <div className='block'>
              <div>
                <Label message='possible_win' />
              </div>
              <div>
                {currencySymbol}
                {posibleWin}
              </div>
            </div>
          )}
        </div>

        <div className='coupon__element__editFooter__button'>
          <button
            type='button'
            disabled={disabled || loading}
            className={submitButtonClassName}
            onClick={handleSubmitEditBet}
          >
            <Label message={loading ? 'loading' : 'submit'} />
          </button>
        </div>
      </div>
    </BetTypeProvider>
  );
}

const DropdownAmountInput = (props) => {
  const {
    amountIncreasement,
    setAmountIncreasement,
    disabled,
    onSubmit,
    posibleWin,
    currencySymbol,
    estimatedCashOut,
    finalBetAmount,
  } = props;

  // const newBetAmountValue
  // const posibleWinValue

  return (
    <div className='betslipDropdown'>
      <div className='betslipDropdown__title'>
        <Label message='betslip_edit_title' />
      </div>
      <div className='betslipDropdown__content'>
        <div className='betslipDropdown__content__input'>
          <div className='block'>
            <div className='block__wrap'>
              <label className='row'>
                <div />
                <MoneyInput
                  value={amountIncreasement}
                  disabled={disabled}
                  onChange={setAmountIncreasement}
                />
              </label>
            </div>
          </div>

          <span>
            <Label message='new_bet_amount' /> <span>{`${currencySymbol}${finalBetAmount}`}</span>{' '}
          </span>

          <span>
            <Label message='possible_win' /> <span>{`${currencySymbol}${posibleWin}`}</span>{' '}
          </span>
        </div>
      </div>
      <button type='button' className='betslipDropdown__footer' onClick={onSubmit}>
        <Label message='submit' />
      </button>
    </div>
  );
};
