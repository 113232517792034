import React from 'react';
import { TournamentLink } from '../../../shared/components/Sport/SportLinks';

const TournamentListItem = (props) => {
  const { tournament, active } = props;

  const { name, urlName, sportUrlName, categoryUrlName } = tournament;

  return (
    <TournamentLink
      active={active}
      sportUrlName={sportUrlName}
      categoryUrlName={categoryUrlName}
      tournamentUrlName={urlName}
      className='bottom__block two'
    >
      <div title={name}>
        <span className='name nameNowrap'>{name}</span>
        <span className='subName' />
      </div>
    </TournamentLink>
  );
};

export default TournamentListItem;
