import qs from 'query-string';
import { withRouter } from 'react-router-dom';

const QueryRoute = (props) => {
  const {
    location,
    command, // Command string
    path,
    children,
  } = props;

  const params = qs.parse(location.search);
  const cmdParam = params.cmd;
  const pathParams = params.path || '/';
  let match = true;

  if (command) { match = params.cmd && (cmdParam === command); }

  if (path) { match = match && (pathParams === path); }

  return match
    ? children
    : null;
};

export default withRouter(QueryRoute);
