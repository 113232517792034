import React from 'react';
import { useApplicationState } from '@apollo/core';
import _ from 'lodash';
import { onSignUpSubmit, useActiveForm } from '@apollo/react-forms';
import { Redirect, useLocation } from 'react-router-dom';
import Theme from 'themeSource/custom';
import AuthBlock from '../AuthBlock/AuthBlock';
import SignUpForm from '../../components/SignUp/SignUpForm';
import StaticBlock from '../../components/StaticBlock/StaticBlock';
import { cmsStaticBlock } from '../../../core/constants';
import { useParticleAnimation } from '../../../features/Animations/ParticleAnimation';
import { useTextAnimation } from '../../../features/Animations/TextAnimation';

const isSignupParticle = Theme.animations?.signupParticles;
const isSignupBonusParticle = Theme.animations?.signupBonusParticles;

const SignUp = () => {
  const applicationState = useApplicationState();
  const authenticated = _.get(applicationState, 'authenticated');
  const resolved = _.get(applicationState, 'resolved');
  const activeForm = useActiveForm('CLIENT_REGISTRATION', onSignUpSubmit);
  const { runParticleAnimation, prepareParticleAnimation } = useParticleAnimation();
  const { runTextAnimation } = useTextAnimation();

  const { pathname } = useLocation();

  if (resolved && authenticated) {
    if (isSignupParticle) {
      runParticleAnimation(3000);
    }

    if (isSignupBonusParticle) {
      prepareParticleAnimation();
    }

    runTextAnimation('Welcome!', 3000);

    return <Redirect from='/' to={pathname} />;
  }

  const isLastStep = activeForm.stepsAmount
    && activeForm.activeStep === activeForm.activeType.steps[activeForm.stepsAmount - 1];

  return (
    <AuthBlock>
      <div className='signup signup--modal'>
        <div className='signup__container'>
          <SignUpForm activeForm={activeForm} />
        </div>
      </div>
      {isLastStep && (
        <>
          <StaticBlock
            options={{ source: cmsStaticBlock.SignUpBanner }}
            className='modal-banner modal-banner--footer'
          />
          <StaticBlock
            options={{ source: cmsStaticBlock.SignUpBannerRespGambling }}
            className='modal-banner modal-banner--footer'
          />
        </>
      )}
    </AuthBlock>
  );
};

export default SignUp;
