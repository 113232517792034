import { useRacingState } from '@apollo/core/src/state/racing/racing';

const SpecialOddsName = ({ market }) => {
  const [racingState] = useRacingState();
  const { marketTypes } = racingState;

  const marketTypeInfo = marketTypes.find((mt) => mt.type === market.type);

  return marketTypeInfo?.uiOddsText || market?.oddsText;
};

export default SpecialOddsName;
