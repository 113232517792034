import React from 'react';
import { useLocation, useHistory, matchPath } from 'react-router-dom';
import { Bus, useApplicationState } from '@apollo/core';
import cx from 'classnames';
import {
  startsWith,
} from 'lodash';
import Label from '../../I18n/Label';

const HeaderMenuItem = ({
  link, label, authRequired = false, className,
}) => {
  const {
    authenticated,
    layout,
  } = useApplicationState();
  const { pathname } = useLocation();
  const { push: historyPush } = useHistory();

  const redirectHandler = React.useCallback(() => {
    if (startsWith(link, 'http')) {
      document.location.replace(link);

      return;
    }

    if (authRequired && !authenticated) {
      historyPush(`${pathname}?cmd=signin&path=signIn`);

      return;
    }

    if (layout.mobileDevice === true) {
      Bus.send({
        event: Bus.events.layout.setMainMenu,
        data: false,
      });
    }

    historyPush(link);
  }, [link, authRequired, authenticated, pathname, layout.mobileDevice]);

  const itemClassName = cx(
    'header__menu__item',
    className,
    {
      active: matchPath(pathname, link),
    },
  );

  return (
    <a className={itemClassName} onClick={redirectHandler}>
      <Label message={label} />
    </a>
  );
};

export default React.memo(HeaderMenuItem);
