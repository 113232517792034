import { useTranslation } from '@apollo/core';
import { snakeCase } from 'lodash';

const PARAMS_REPLACE_RX = /\[(\w+)\]/g;

const useTranslate = () => {
  const translation = useTranslation();

  return (message, params = null) => {
    if (!message || !translation) {
      return '';
    }
    const messageKey = snakeCase(message)
      .toLowerCase();
    const messageParams = params || {};
    const translatedMessage = translation[messageKey] || `#${messageKey}`;

    return translatedMessage.replace(PARAMS_REPLACE_RX, (value, match) => {
      if (!messageParams[match]) {
        return `[${match}]`;
      }
      return messageParams[match];
    });
  };
};

export default useTranslate;
