import React, { useCallback } from 'react';
import { map } from 'lodash';
import { useSportsbookState } from '@apollo/core';
import SportListItem from './SportListItem';
import { SidebarActions, useSidebarState } from '../../../state/Sidebar/Sidebar';

const SportList = ({ isOrderByName = false }) => {
  const [sidebarState, sidebarDispatcher] = useSidebarState();
  const [sportsState] = useSportsbookState();
  const { sports, selectedSportId } = sportsState;

  const sortedSports = [...sports].sort((a, b) => a.name.localeCompare(b.name));

  const toggleOpenedSportUrlName = useCallback(
    (sportUrlName) => {
      sidebarDispatcher({
        type: SidebarActions.TOGGLE_OPENED_SPORT,
        payload: {
          openedSportUrlName: sportUrlName,
        },
      });
    },
    [sportsState],
  );

  return map(isOrderByName ? sortedSports : sports, (sport) => {
    const { urlName, id } = sport;
    return (
      <SportListItem
        key={id}
        active={sidebarState.openedSportUrlName === urlName}
        selected={selectedSportId === id}
        onToggleOpenedSportUrlName={toggleOpenedSportUrlName}
        sport={sport}
      />
    );
  });
};

export default SportList;
