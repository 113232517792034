import React from 'react';
import { isEmpty, map } from 'lodash';
import RaceRunner from '../RaceRunner';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import Label from '../../../../shared/components/I18n/Label';
import { formatOdds } from '../../../../core/utils';

const RaceDeductionsTable = ({ activeRace, marketDeductKeyMap }) => {
  const t = useTranslate();
  const runners = activeRace?.runners.filter((runner) => runner.scratched);

  return !isEmpty(runners) ? (
    <>
      <div className='tournament__header'>
        <div className='event event--classic'>
          <div className='event__main-period'>
            <div className='event__info'>
              <div className='event-participants'>
                <div className='event-participants__participant'>
                  <Label message='Scratched Runner' />
                </div>
              </div>
            </div>
            <div className='event__market-row'>
              <div className='event__market-row-market event__market-row--race-dividend'>
                <div>{t('Win*')}</div>
              </div>
              <div className='event__market-row-market event__market-row--race-dividend'>
                <div>{t('Place*')}</div>
              </div>
              <div className='event__market-row-market event__market-row--race-dividend'>
                <div>{t('FavOut*')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='event-group event-group--races'>
        {map(runners, (r) => (
          <div
            key={`${r.runnerId}-${r.finishPosition}`}
            className='event event--classic'
          >
            <div className='event__main-period'>
              <RaceRunner
                runner={r}
                activeRace={activeRace}
                showRunnerImage={false}
              />
              <div className='event__market-row'>
                <div className='event__market-row-market event__market-row--race-dividend'>
                  <div className='bold'>
                    {formatOdds(r?.runnerOdds?.[marketDeductKeyMap.F_WIN], 2)}
                    {r?.runnerOdds?.[marketDeductKeyMap.F_WIN] ? 'c' : ''}
                  </div>
                </div>
                <div className='event__market-row-market event__market-row--race-dividend'>
                  <div className='bold'>
                    {formatOdds(r?.runnerOdds?.[marketDeductKeyMap.F_PLC], 2)}
                    {r?.runnerOdds?.[marketDeductKeyMap.F_PLC] ? 'c' : ''}
                  </div>
                </div>
                <div className='event__market-row-market event__market-row--race-dividend'>
                  <div className='bold'>
                    {formatOdds(r?.runnerOdds?.[marketDeductKeyMap.FAV_OUT], 2)}
                    {r?.runnerOdds?.[marketDeductKeyMap.FAV_OUT] ? 'c' : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  ) : null;
};
export default RaceDeductionsTable;
