import React, { useMemo } from 'react';
import { SportSelector } from '@apollo/core/src/state/sport/sport';
import Upcoming from '../SportPage/Upcoming';

const PrematchFeatured = ({ sportId }) => {
  const tournaments = SportSelector.selectTournamentsBySportId(sportId);
  const tournamentIds = useMemo(
    () => tournaments
      .filter((t) => t.totalCount > 0 && t.isTop === true)
      .map((tournament) => tournament.id),
    [tournaments],
  );
  return <Upcoming sportId={sportId} tournamentIds={tournamentIds} />;
};

export default PrematchFeatured;
