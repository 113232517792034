import React, { useMemo } from 'react';
import { useApplicationState } from '@apollo/core';
import { RaceTypeAll } from '@apollo/core/src/constants';
import { isEmpty, map } from 'lodash';
import Theme from 'themeSource/custom';
import Scrollbars from 'react-custom-scrollbars';
import RaceLink from '../../../../shared/components/Racing/RaceLink';
import RaceCountdown from '../../../../shared/components/Racing/RaceCountdown/RaceCountdown';
import { RacingIcon } from '../../../../shared/components/Racing/RacingIcon';
import Label from '../../../../shared/components/I18n/Label';

const alwaysMobileLayout = Theme.RACE_HOMEPAGE_SAME_AS_MOBILE || false;

const NextToJumpList = ({ nextToJump, nextRacesUpdate, raceType = RaceTypeAll.id }) => {
  const { layout } = useApplicationState();

  const render = useMemo(() => {
    if (isEmpty(nextToJump)) {
      return (
        <Label
          className='next-jump-box__item next-jump-box__item--empty'
          message={`No available ${raceType}`}
        />
      );
    }
    return map(nextToJump, (r) => (
      <RaceLink
        key={r.raceId}
        className='next-jump-box__item'
        raceDate={r.meeting.date}
        raceType={r.raceType.typeName}
        raceCountry={r.venue.country}
        raceVenue={r.venue.venueName}
        raceNumber={r.number}
      >
        <div className='next-jump-box__item-title'>
          {layout.mobileDevice === false && !alwaysMobileLayout ? (
            <RacingIcon id={r?.raceType?.id} name={r.raceType?.typeName} />
          ) : null}
          <span className='race-number'>
            {layout.mobileDevice === true || alwaysMobileLayout ? 'Race ' : 'R'}
            {r.number}
          </span>
          <span className='race-name'>{r.venue.venueName}</span>
          {layout.mobileDevice === true || alwaysMobileLayout ? (
            <div className='event-icon'>
              <RacingIcon id={r?.raceType?.id} name={r.raceType?.typeName} />
            </div>
          ) : null}
        </div>
        <div className='next-jump-box__item-countdown--wrapper'>
          <RaceCountdown
            className='next-jump-box__item-countdown'
            key={`${r?.raceId}-${r?.status}-${r?.result}`}
            date={new Date(r.startTime)}
            completeText={r.result || r.status}
            onComplete={nextRacesUpdate}
          />
        </div>
      </RaceLink>
    ));
  }, [nextToJump, nextRacesUpdate]);

  return (
    <div className='next-jump-box'>
      {layout.mobileDevice === true || alwaysMobileLayout ? (
        <div className='next-jump-box__mobile'>
          <Scrollbars className='scroll__container'>{render}</Scrollbars>
        </div>
      ) : (
        render
      )}
    </div>
  );
};
export default NextToJumpList;
