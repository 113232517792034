import React from 'react';
import { get } from 'lodash';
import { SiteConfigManager } from '@apollo/core';
import LinkList from '../../../features/ClientProfile/LinkList';
import { cmsNavBlock } from '../../../core/constants';

const ResponsibleGamblingPolicyLink = ({ className }) => {
  const helpNav = get(SiteConfigManager.navigation, cmsNavBlock.Footer);

  const responsibleGamblingPolicyLink = (helpNav
      && helpNav[2]?.children?.filter(
        (link) => link?.name === 'Responsible Gambling Policy',
      ))
    || [];

  return responsibleGamblingPolicyLink.length ? (
    <LinkList
      array={responsibleGamblingPolicyLink.map((link) => ({
        link: link.location,
        name: link.name,
      }))}
      itemClassName={className}
      isScroll={false}
    />
  ) : null;
};

export default ResponsibleGamblingPolicyLink;
