import React, { useCallback } from 'react';
import { get, map } from 'lodash';
import { Link } from 'react-router-dom';
import { SiteConfigManager, useSettings } from '@apollo/core';
import moment from 'moment';
import { cmsNavBlock, cmsStaticBlock } from '../../../core/constants';
import { isUrlAbsolute, LocalConfig } from '../../../core/utils';
import Label from '../I18n/Label';
import FooterPayment from './FooterPayment/FooterPayment';
import FooterPartners from './FooterPartners/FooterPartners';
import StaticBlock from '../StaticBlock/StaticBlock';
import AppLinks from './AppLinks/AppLinks';

const Footer = () => {
  const { isCordova } = useSettings();

  const getScrollParent = (node) => {
    const isElement = node instanceof HTMLElement;
    const overflowY = isElement && window.getComputedStyle(node).overflowY;
    const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';

    if (!node) {
      return null;
    }

    if (isScrollable && node.scrollHeight >= node.clientHeight) {
      return node;
    }

    return getScrollParent(node.parentNode) || document.body;
  };

  const scrollToTopHandler = React.useCallback(() => {
    const footerElement = document.querySelector('footer.footer');

    if (footerElement) {
      const scrollableParent = getScrollParent(footerElement);
      scrollableParent.scrollTop = 0;
    }

    document.documentElement.scrollTop = 0;
  }, []);

  const handleAnchorClick = useCallback(
    (event) => {
      if (!isCordova) {
        return;
      }
      event.preventDefault();
      const anchor = event.currentTarget;
      const href = anchor.getAttribute('href');
      window.open(href, '_system');
      return false;
    },
    [isCordova]
  );

  const bottomNav = get(SiteConfigManager.navigation, cmsNavBlock.Footer);
  const bottomNavigation = React.useMemo(() => {
    const childComponents = (children) =>
      map(children, ({ name, location }) => {
        if (isUrlAbsolute(location)) {
          return (
            <a
              key={name}
              className='nav-links__link nav-links__link--external'
              href={location}
              target='_blank'
              rel='noreferrer'
              onClick={handleAnchorClick}
            >
              <Label message={name} />
            </a>
          );
        }
        return (
          <Link key={name} className='nav-links__link nav-links__link--internal' to={`${location}`}>
            <Label message={name} />
          </Link>
        );
      });

    return (
      <nav className='nav-links'>
        {map(bottomNav, ({ name, children }) => (
          <div className='nav-links__group' key={name}>
            <div className='nav-links__title'>
              <Label message={name} />
            </div>
            {childComponents(children)}
          </div>
        ))}
      </nav>
    );
  }, [bottomNav]);

  const copyrightText = React.useMemo(
    () => `© ${LocalConfig.get('THEME_NAME')} ${moment().get('year')}`,
    []
  );

  return (
    <footer className='footer'>
      <div className='footer__container'>
        <div className='footer-block footer-block--navigation'>
          <FooterPayment />

          <FooterPartners />
        </div>

        <AppLinks />

        <div className='footer-block footer-block--navigation'>{bottomNavigation}</div>

        <div className='footer-block footer-block--csm-content'>
          <StaticBlock options={{ source: cmsStaticBlock.Footer }} />
        </div>

        <div className='footer-block footer-block--social'>
          {/* <FooterSocial /> */}
          <div className='footer__up-button' onClick={scrollToTopHandler}>
            <i className='icon AkIcon-long-arrow-up-icon' />
            <Label message='up' />
          </div>
        </div>

        <div className='footer-block footer-block--copyrights'>
          <div className='copyright-text'>
            {copyrightText}
            <span className='app-version-number'>
              &nbsp; build: &nbsp;
              {CORDOVA ? APP_VERSION : VERSION}
            </span>
          </div>
          <div className='age-restriction'>
            <span>{LocalConfig.get('public.legal_age')}</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
