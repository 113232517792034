import { useCallback, useEffect, useReducer } from 'react';
import { AuthApi, betHistoryConstants, NotificationManager } from '@apollo/core';
import { modifyOperation } from '@apollo/core/src/api/payment';
import _ from 'lodash';
import { DEPOSITS_FILTERS, OTHERS_FILTERS, WITHDRAWALS_FILTERS } from './TransactionHistoryFilter';

const { rangeName, last24, last48, getThreeMonthRange, getDayRange, getTwoDayRange } =
  betHistoryConstants;

const SET_STATE = 'SET_STATE';

const ITEMS_PER_PAGE = 10;
const INITIAL_STATE = {
  shouldOperationsLoad: true,
  transactions: [],
  activeTimeFilter: rangeName,
  range: getThreeMonthRange(),
  activeTypeFilters: [...DEPOSITS_FILTERS, ...WITHDRAWALS_FILTERS, ...OTHERS_FILTERS],
  currentPage: 0,
  totalPages: 1,
  isLoading: true,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_STATE: {
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      return state;
    }
  }
};

const useTransactionHistory = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { range, activeTimeFilter, activeTypeFilters, currentPage, shouldOperationsLoad } = state;

  useEffect(() => {
    dispatch({
      type: SET_STATE,
      payload: { shouldOperationsLoad: true },
    });
  }, [activeTimeFilter, activeTypeFilters, currentPage, range]);

  useEffect(() => {
    if (!shouldOperationsLoad) {
      return;
    }

    dispatch({
      type: SET_STATE,
      payload: { isLoading: true },
    });

    let createTime = range;

    if (activeTimeFilter === last24) {
      createTime = getDayRange();
    } else if (activeTimeFilter === last48) {
      createTime = getTwoDayRange();
    }

    AuthApi.getClientTransactionHistory({
      filter: {
        types: (activeTypeFilters || []).filter((f) => f != null),
        createTime,
      },
      paging: {
        itemsPerPage: ITEMS_PER_PAGE,
        currentPage,
      },
      sorting: [
        {
          direction: 'DESC',
          fieldName: 'createTime',
          order: 0,
        },
      ],
    })
      .then((res) => {
        const newOperations = _.get(res, 'transactions');
        const newTotalPages = _.get(res, 'paging.totalPages');
        dispatch({
          type: SET_STATE,
          payload: {
            transactions: newOperations,
            totalPages: newTotalPages,
            isLoading: false,
            shouldOperationsLoad: false,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: SET_STATE,
          payload: {
            isLoading: false,
            shouldOperationsLoad: false,
          },
        });
      });
  }, [shouldOperationsLoad]);

  const cancelWithdrawalHandler = useCallback(async (paymentId) => {
    dispatch({
      type: SET_STATE,
      payload: { isLoading: true },
    });

    try {
      await modifyOperation({
        action: 'CANCEL',
        paymentId,
      });
      dispatch({
        type: SET_STATE,
        payload: {
          shouldOperationsLoad: true,
          currentPage: 0,
        },
      });
    } catch (error) {
      NotificationManager.display({
        type: 'popup',
        title: 'withdrawal_cancellation_error',
        message: 'withdrawal_cancellation_error_msg',
      });

      console.log(error);

      dispatch({
        type: SET_STATE,
        payload: { isLoading: false },
      });
    }
  }, []);

  const setActiveTimeFilter = useCallback((activeTimeFilter) => {
    dispatch({
      type: SET_STATE,
      payload: {
        activeTimeFilter,
        currentPage: 0,
      },
    });
  }, []);

  const setActiveTypeFilters = useCallback((activeTypeFilters) => {
    dispatch({
      type: SET_STATE,
      payload: {
        activeTypeFilters,
        currentPage: 0,
      },
    });
  }, []);

  const setCurrentPage = useCallback((currentPage) => {
    dispatch({
      type: SET_STATE,
      payload: { currentPage },
    });
  }, []);

  const setRange = useCallback(
    (range) => {
      dispatch({
        type: SET_STATE,
        payload: {
          range,
          currentPage: 0,
        },
      });
    },
    [dispatch]
  );

  return {
    ...state,
    setActiveTimeFilter,
    setActiveTypeFilters,
    setCurrentPage,
    cancelWithdrawalHandler,
    setRange,
  };
};

export default useTransactionHistory;
