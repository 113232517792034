import React, { createContext, useContext, useCallback, useMemo, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Theme from 'themeSource/custom';

const TextAnimationContext = createContext();

const TextAnimationProvider = ({ children }) => {
  const isTextsEnabled = Theme?.animations?.texts;

  const [animationData, setAnimationData] = useState({ text: '', time: 0 });

  const runTextAnimation = useCallback(
    (text, time) => {
      if (!isTextsEnabled) return;

      setAnimationData({ text, time });

      setTimeout(() => {
        setAnimationData({ text: '', time: 0 });
      }, time);
    },
    [isTextsEnabled],
  );

  const contextValue = useMemo(() => ({ runTextAnimation }), [runTextAnimation]);

  return (
    <TextAnimationContext.Provider value={contextValue}>
      {children}

      <AnimatePresence>
        {isTextsEnabled && animationData.text && (
          <motion.div
            key='text-animation'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            className='text-animation-wrapper'
          >
            <div className='text-animation'>
              {animationData.text.split('').map((char, index) => (
                <span key={index} className='text-animation-char'>
                  {char}
                </span>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </TextAnimationContext.Provider>
  );
};

const useTextAnimation = () => {
  const context = useContext(TextAnimationContext);

  if (!context) {
    throw new Error('useTextAnimation must be used within a TextAnimationProvider');
  }

  return context;
};

export { TextAnimationProvider, useTextAnimation };
