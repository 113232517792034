import React, { useMemo } from 'react';
import cx from 'classnames';
import Label from '../../../../../shared/components/I18n/Label';

const ProgressBar = ({
  current = 0,
  required = 100,
  valueLabel = '',
  titleProgress = 'bonus_bets_count',
  titleComplete = 'bonus_bets_complete',
  tooltip = '',
}) => {
  const progress = Math.min(
    100,
    Math.round((`${current}`.replace(',', '.') / `${required}`.replace(',', '.')) * 100),
  );

  const title = useMemo(() => {
    if (progress >= 100 && titleComplete) {
      return (
        <div className='progress-bar__title progress-bar__title--complete'>
          <Label message={titleComplete} />
        </div>
      );
    }
    if (progress < 100 && titleProgress) {
      return (
        <div className='progress-bar__title progress-bar__title--progress'>
          <Label message={titleProgress} />
        </div>
      );
    }
    return null;
  }, [progress, titleProgress, titleComplete]);

  const classes = cx(
    'progress-bar',
    `progress-bar--${progress}`,
    progress === 0 ? 'progress-bar--none' : null,
    progress < 10 ? 'progress-bar--started' : null,
    progress >= 90 ? 'progress-bar--uncomplete' : null,
    progress >= 100 ? 'progress-bar--complete' : 'progress-bar--incomplete',
  );

  return (
    <div className={classes} title={tooltip}>
      {title}
      <div className='progress-bar__bar' style={{ width: `${progress}%` }}>
        <span className='progress-bar__current'>
          <span className='progress-bar__value-label'>{valueLabel}</span>
          {current}
        </span>
      </div>
      <span className='progress-bar__required'>
        <span className='progress-bar__value-label'>{valueLabel}</span>
        {required}
      </span>
    </div>
  );
};
export default React.memo(ProgressBar);
