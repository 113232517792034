import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { centsToDecimal } from '@apollo/core';
import BetSourceTooltip from './BetSourceTooltip';
import BetHistoryMultipleTypeSubRowList from './BetHistoryMultipleTypeSubRowList';
import { betTypes } from '../../../../../core/constants';
import Label from '../../../../../shared/components/I18n/Label';
import useTranslate from '../../../../../shared/components/I18n/Interpreter';
import Cashout from '../../../../../shared/components/Cashout/WithPopups/Cashout';
import Odds from '../../../../../shared/components/Odds';
import DateTime from '../../../../../shared/components/DateTime/DateTime';
import { getCurrencySymbol, isTBD } from '../../../../../core/utils';
import BetHistoryFreeBetBonus from '../BetHistoryFreeBetBonus';

const renderOrderCashoutIcon = (t) => <span title={t} className='AkIcon-cashout-icon' />;

const BetHistoryMultipleType = ({ bet, replaceBet, setOrderCashoutInfoToBet }) => {
  const t = useTranslate();

  const [
    createTime,
    betId,
    amount,
    betStatus,
    outcomes,
    bonusInfoList,
    betOdds,
    estimatedCashOut,
    estimatedPartialCashOut,
    currency,
    orderCashoutInfo,
    betType,
    betSize,
    cashOutCoefficient,
    minPartialCashOut,
    originalBets,
    payout,
  ] = _.at(bet, [
    'createTime',
    'betId',
    'amount',
    'betStatus',
    'outcomes',
    'bonusInfoList',
    'betOdds',
    'estimatedCashOut',
    'estimatedPartialCashOut',
    'betMoney.currency',
    'orderCashoutInfo',
    'betType',
    'betSize',
    'cashOutCoefficient',
    'minPartialCashOut',
    'originalBets',
    'betMoney.payout',
  ]);

  const desiredCashOutAmount = _.get(orderCashoutInfo, 'desiredCashOutAmount');

  const currencySymbol = getCurrencySymbol(currency);

  const decimalWinAmount = useMemo(
    () => (payout ? `${currencySymbol}${centsToDecimal(payout)}` : '-'),
    [payout, currencySymbol],
  );

  const renderMultipleAmount = useCallback(
    (str) => {
      const multiBetType = { 2: 'Doubles', 3: 'Trebles' }[outcomes.length] || `${outcomes.length} folds`;
      return <span>{`${str} (${t(multiBetType)})`}</span>;
    },
    [t, outcomes.length],
  );

  const renderSystemAmount = useCallback((str) => <span>{`${str} ${betSize}`}</span>, [betSize]);

  return (
    <div className={`bet-history-multiple ${betStatus}`}>
      <div className={`history-table-row ${betStatus}`}>
        <div className='bet-history-row'>
          <div className='history-table-cell date'>
            {desiredCashOutAmount && (
              <Label message='waiting_for_order_cashout' render={renderOrderCashoutIcon} />
            )}
            <span className='date-id'>
              <DateTime dateTime={createTime} />
            </span>
            <span className='sub-title'>
              #
              {betId}
            </span>
          </div>
          <div className='history-table-cell bet-type-title'>
            {betType === betTypes.MULTIPLE && (
              <Label message='Multibet' render={renderMultipleAmount} />
            )}
            {betType === betTypes.SYSTEM && <Label message='system' render={renderSystemAmount} />}
          </div>
          <div className='history-table-cell'>
            <div className='cashout-container'>
              <div className='cashout-container'>
                <Cashout
                  betId={betId}
                  amount={amount}
                  odds={betOdds}
                  estimatedCashOut={estimatedCashOut}
                  estimatedPartialCashOut={estimatedPartialCashOut}
                  currencySymbol={currencySymbol}
                  replaceBet={replaceBet}
                  cashOutCoefficient={cashOutCoefficient}
                  minPartialCashOut={minPartialCashOut}
                  setOrderCashoutInfoToBet={setOrderCashoutInfoToBet}
                />
              </div>
            </div>
          </div>
          {/* <div className='history-table-cell bet-source' /> */}
          <div className='history-table-cell'>
            <span className='bet'>
              <BetSourceTooltip bonusInfoList={bonusInfoList} />
              <div className={`amount amount--${betStatus}`}>
                {`${currencySymbol}${centsToDecimal(amount)}`}
              </div>
            </span>
          </div>
          <div className='history-table-cell'>
            <span className='odds'>
              {isTBD({ outcomes }) || betOdds <= 1 ? (
                <Label message='tbd' />
              ) : (
                <Odds value={betOdds} decimal={3} minDecimal={1} />
              )}
            </span>
          </div>
          <div className='history-table-cell to-return'>
            <span className='bet'>{decimalWinAmount}</span>
            {desiredCashOutAmount && (
              <span className='cashout-info'>
                <Label message='waiting_for_order_cashout' render={renderOrderCashoutIcon} />
                {currencySymbol}
                {centsToDecimal(desiredCashOutAmount)}
              </span>
            )}
          </div>
          <div className='history-table-cell status-label'>
            <Label message={betStatus} />
            <BetHistoryFreeBetBonus bet={bet} />
          </div>
        </div>
      </div>
      <BetHistoryMultipleTypeSubRowList outcomes={outcomes} originalBets={originalBets} bet={bet} />
    </div>
  );
};

export default BetHistoryMultipleType;
