import React, { useEffect, useMemo } from 'react';
import MyOrdersList from './MyOrdersList';
import useOrders from './useOrders';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import Svg from '../../../../shared/components/svg/Svg';
import Pagination from '../../../../shared/components/Pagination';
import { extractRaceIdsFromBets, prepareBetSlipRunners } from '../../../../core/utils';

const MyOrders = () => {
  const t = useTranslate();
  const { bets, isLoading, currentPage, setCurrentPage, totalPages } = useOrders();

  const raceIds = useMemo(() => JSON.stringify(extractRaceIdsFromBets(bets)), [bets]);
  useEffect(() => {
    prepareBetSlipRunners(JSON.parse(raceIds));
  }, [raceIds, currentPage]);

  if (!isLoading && totalPages === 0 && bets.length === 0) {
    return (
      <div className='empty-state-history'>
        <div className='svg-container'>
          <Svg name='emptyState' />
        </div>
        <span>{t('There is no items yet')}</span>
      </div>
    );
  }

  return (
    <div className='history-table'>
      <div className='history-table-header bet-history'>
        <div className='history-table-row'>
          <div className='bet-history-row'>
            <div className='history-table-cell date-id'>
              <span className='date'>
                {t('date')}
                {' '}
                /
                {t('id')}
              </span>
            </div>
            <div className='history-table-cell'>
              <span className='amount'>{t('bet')}</span>
            </div>
            <div className='history-table-cell'>
              <span className='result'>{t('odds')}</span>
            </div>
            <div className='history-table-cell'>
              <span className='result'>{t('bet')}</span>
            </div>
            <div className='history-table-cell'>
              <span className='result'>{t('payout')}</span>
            </div>
            <div className='history-table-cell'>
              <span className='result'>{t('status')}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='history-table-body bet-history'>
        <MyOrdersList bets={bets} />
      </div>
      {bets.length > 0 && (
        <Pagination
          pages={totalPages}
          currentPage={currentPage}
          changeCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};

export default MyOrders;
