import React from 'react';
import { useApplicationState } from '@apollo/core';
import NextToJumpBar from './NextToJumpBar/NextToJumpBar';
import Header from './Header';

const HeaderContainer = () => {
  const { layout } = useApplicationState();
  return (
    <>
      {layout && layout.mobileDevice === false ? <NextToJumpBar /> : null}
      <Header />
    </>
  );
};
export default HeaderContainer;
