import React, { useCallback, useMemo } from 'react';
import {
  createCashoutContext,
  CashoutContextProvider,
  centsToDecimal,
  DeferredPromise,
  NotificationManager,
} from '@apollo/core';
import { onWithdrawalSubmit } from '@apollo/react-forms';
import OrderCashoutPopup from './OrderCashoutPopup';
import PartialCashoutPopup from './PartialCashoutPopup';
import {
  callCashoutSuccessPopup,
  callCashoutErrorPopup,
  callOrderCashoutSuccessPopup,
  callOrderCashoutErrorPopup,
  callPartialCashoutSuccessPopup,
  callPartialCashoutErrorPopup,
  callOrderPartialCashoutSuccessPopup,
  callOrderPartialCashoutErrorPopup,
  callCashoutNotAllowedErrorCallbackPopup,
} from '../callPopup';
import Label from '../../I18n/Label';
import StaticBlock from '../../StaticBlock/StaticBlock';
import { cmsStaticBlock } from '../../../../core/constants';

const Cashout = ({
  betId,
  amount,
  odds,
  currencySymbol,
  replaceBet,
  cashOutCoefficient,
  minPartialCashOut,
  setOrderCashoutInfoToBet,
  estimatedCashOut: initialEstimatedCashOut,
  estimatedPartialCashOut: initialEstimatedPartialCashOut,
}) => {
  const cashoutContext = createCashoutContext({
    betId,
    amount,
    odds,
    replaceBet,
    currencySymbol,
    setOrderCashoutInfoToBet,
    minPartialCashOut,
    initialEstimatedCashOut,
    initialEstimatedPartialCashOut,
    cashOutCoefficient,
    cashoutSuccessCallback: callCashoutSuccessPopup,
    cashoutErrorCallback: callCashoutErrorPopup,
    orderCashoutSuccessCallback: callOrderCashoutSuccessPopup,
    orderCashoutErrorCallback: callOrderCashoutErrorPopup,
    partialCashoutSuccessCallback: callPartialCashoutSuccessPopup,
    partialCashoutErrorCallback: callPartialCashoutErrorPopup,
    orderPartialCashoutSuccessCallback: callOrderPartialCashoutSuccessPopup,
    orderPartialCashoutErrorCallback: callOrderPartialCashoutErrorPopup,
    cashoutNotAllowedErrorCallback: callCashoutNotAllowedErrorCallbackPopup,
  });

  const { estimatedCashOut, isLoading, onCashout, isCashOutAllowed } = cashoutContext;

  const amountEstimatedCashOut = useMemo(
    () => centsToDecimal(estimatedCashOut),
    [estimatedCashOut],
  );

  const onCashOutHandler = useCallback(async () => {
    NotificationManager.display({
      type: 'confirm',
      title: 'confirm_cashout',
      // $[amount!$]
      message: (
        <Label
          className='client-info__label'
          message='confirm_cashout_amount'
          params={{ amount: estimatedCashOut }}
        />
      ),
      data: {
        children: <StaticBlock options={{ source: cmsStaticBlock.ConfirmCashOut }} />,
        onConfirm: () => {
          onCashout();
        },
        onClose: () => {},
      },
    });
  }, [onCashout, estimatedCashOut]);

  if (!estimatedCashOut || !isCashOutAllowed) return null;

  return (
    <CashoutContextProvider value={cashoutContext}>
      <div className='bet-history-cashout'>
        <OrderCashoutPopup />
        <PartialCashoutPopup />
        <button type='button' className='bet-history-cashout-button' onClick={onCashOutHandler}>
          <Label message={isLoading ? 'loading' : 'cashout'} />
          {!isLoading && <span>{` ${currencySymbol}${amountEstimatedCashOut}`}</span>}
        </button>
      </div>
    </CashoutContextProvider>
  );
};

export default Cashout;
