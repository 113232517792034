import React from 'react';

import {
  useSportState,
} from '@apollo/core';
import Loader from '../../shared/components/Loader';

export default function ResultsSportLoader({ children }) {
  const {
    loading,
  } = useSportState();

  if (loading) {
    return (
      <Loader className='empty-state' />
    );
  }

  return children;
}
