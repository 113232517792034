import { isEmpty } from 'lodash';
import { isMultilegsMarket } from '@apollo/core';
import { RaceMainTabs } from '../../../core/constants';

export const prodWhiteList = [
  'F_WIN',
  'F_PLC',
  'COMBINED',
  'SAME_RACE_MULTI',
  'FAV_V_FIELD',
  'ODDS_V_EVENS',
  'INSIDE_V_OUTSIDE',
  'RAILS_N_WIDE',
  'HEAD_TO_HEAD',
  'ROUGHIES_COMBO',
];

export const defaultMainTab = RaceMainTabs.WP_TAB;

export const MainTabsPreferOrder = Object.keys(RaceMainTabs);

export const FixedExotics = ['F_QUIN', 'F_EXACTA', 'F_TRIFECTA', 'F_FIRST4'];

export const getMarketLegsFromMarket = (market) => market?.legs?.split('-') || [];

export const getMarketLegsFromMarketType = (marketType) =>
  marketType?.split(':')?.[1]?.split('-') || [];

export const getMultiLegTabText = (tabKey) => tabKey?.split(':')?.[0];
export const isMultiLegsByMarketTypeId = (marketTypeId) =>
  isMultilegsMarket(getMultiLegTabText(marketTypeId));

export const getMultiLegDefaultRaceNumber = (tabKey, raceNumber) => {
  const multiLegs = getMarketLegsFromMarketType(tabKey);
  if (!isEmpty(multiLegs) && !multiLegs?.includes(raceNumber?.toString())) {
    return multiLegs[0];
  }
  return raceNumber;
};

export const handleSortRunners = (runners = []) =>
  runners.sort((first, second) => {
    if (first.scratched) {
      return 1;
    }

    if (second.scratched) {
      return -1;
    }

    if (first.scratched && second.scratched) {
      return 0;
    }

    return first.number < second.number ? -1 : 1;
  });
