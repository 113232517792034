import React from 'react';
import { cmsStaticBlock } from '../../../../core/constants';
import StaticBlock from '../../../../shared/components/StaticBlock/StaticBlock';
import DepositPayIdInfo from '../DepositPayIdInfo';

const PayIdPaymentForm = ({
  selectedPayment,
  savedCredentials,
  savedCredentialsLoading,
  loadCredentials,
}) => (
  <div className='row payments-list__payid'>
    <StaticBlock
      className='text--contrast'
      options={{
        source: `${cmsStaticBlock.DepositPrefix}-${selectedPayment?.integrator}`,
      }}
    />
    <DepositPayIdInfo
      savedCredentials={savedCredentials}
      savedCredentialsLoading={savedCredentialsLoading}
      paymentMethodId={selectedPayment.paymentId || selectedPayment.id}
      onSuccess={loadCredentials}
    />
  </div>
);

export default PayIdPaymentForm;
