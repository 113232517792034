import React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { SPORT_SERVICE } from '@apollo/core/src/constants';
import useTranslate from '../../shared/components/I18n/Interpreter';
import { useBroadcast } from '../../state/Broadcast/BroadcastContext';
import useStatscore from './Statscore/useStatscore';

const { LIVE, PREMATCH } = SPORT_SERVICE;

const EventLinks = ({ event }) => {
  const t = useTranslate();
  const { setBroadcast } = useBroadcast();
  //const { updateStatscoreEventData } = useStatscore();
  const [name, sportService, broadcastUrl, externalId] = _.at(event, [
    'name',
    'sportService',
    'broadcastUrl',
    'externalId',
  ]);
  const isLiveSportService = sportService === SPORT_SERVICE.LIVE;
  const areBroadcastIconsAvailable = Boolean(isLiveSportService && broadcastUrl);
  const isStatisticsAvailable = Boolean(externalId);
  const isLiveStatisticsAvailable = Boolean(externalId && isLiveSportService);
  const areIconsPresent =
    areBroadcastIconsAvailable || isStatisticsAvailable || isLiveStatisticsAvailable;

  if (!areIconsPresent) {
    return null;
  }

  const className = cx([
    'event-links',
    areBroadcastIconsAvailable || isLiveStatisticsAvailable ? 'two-columns' : 'one-column',
  ]);

  const renderBroadcastIcons = areBroadcastIconsAvailable ? (
    <div className='event-links-block two-column'>
      <div
        title={t('open_video')}
        className='event-link'
        onClick={() => setBroadcast({ broadcastUrl, name })}
      >
        <i className='AkIcon-translation-icon' />
      </div>
      <div
        title={t('open_video_in_new_tab')}
        className='event-link'
        onClick={() => window.open(broadcastUrl)}
      >
        <i className='AkIcon-video-window-icon' />
      </div>
    </div>
  ) : null;

  const openLiveStatsHandler = () => {
    //updateStatscoreEventData(externalId, LIVE);
  };

  const openStatsHandler = () => {
    //updateStatscoreEventData(externalId, PREMATCH);
  };

  const renderStatisticsIcons = isStatisticsAvailable ? (
    <div
      className={cx('event-links-block', isLiveStatisticsAvailable ? 'two-column' : 'one-column')}
    >
      {isLiveStatisticsAvailable ? (
        <div title={t('live_statistics')} className='event-link' onClick={openLiveStatsHandler}>
          <i className='AkIcon-live-statistic-icon' />
        </div>
      ) : null}
      <div title={t('statistics')} className='event-link' onClick={openStatsHandler}>
        <i className='AkIcon-stats-icon' />
      </div>
    </div>
  ) : null;

  return (
    <div className={className}>
      {renderStatisticsIcons}
      {renderBroadcastIcons}
    </div>
  );
};

export default EventLinks;
