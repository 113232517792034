import React, { useState } from 'react';
import { SiteConfigManager } from '@apollo/core';
import { buildCdnUrl } from '../../../core/utils';

const ParticipantSkeletonLogo = (
  <svg className='emptySvg' viewBox='-7 -7 44 44'>
    <path
      d='M24.3395856,25.5605603 C22.0456462,28.747069 19.1911008,31.2601293 16.2850402,32.6903017 C15.4274644,33.11 14.4274644,33.11 13.5698887,32.6903017 C10.6638281,31.2601293 7.80928261,28.747069 5.51534321,25.5605603 C2.7062523,21.6592672 1.2971614,17.54375 1.64261594,14.2760776 C2.08200988,10.19375 2.57594927,7.25797414 2.94261594,5.40237069 C3.07897958,4.72349138 3.59110079,4.18038793 4.26685837,4.00538793 L10.1607978,2.46961207 C13.2850402,1.6549569 16.5698887,1.6549569 19.6941311,2.46961207 L25.5911008,4.00840517 C26.2638281,4.18340517 26.7759493,4.72349138 26.9123129,5.40538793 C27.2789796,7.25797414 27.772919,10.1907328 28.2092826,14.2760776 C28.5577675,17.54375 27.1486765,21.6592672 24.3395856,25.5605603 M29.7941311,13.8989224 C29.3062523,9.32478448 28.7547372,6.04202586 28.3426159,3.96918103 C28.1911008,3.20883621 27.6153432,2.60237069 26.8638281,2.40625 L20.2638281,0.683405172 C16.7668584,-0.227801724 13.0880705,-0.227801724 9.59110079,0.683405172 L2.99110079,2.40323276 C2.23958564,2.59935345 1.66382806,3.20581897 1.51231291,3.96616379 C1.103222,6.0450431 0.548676547,9.32780172 0.0577674564,13.8989224 C-0.330111331,17.555819 1.24867655,22.1631466 4.39110079,26.5321121 C6.95776746,30.0984914 10.1547372,32.9105603 13.4062523,34.5096983 C14.3668584,34.9834052 15.4880705,34.9834052 16.4486765,34.5096983 C19.7001917,32.9105603 22.8971614,30.0984914 25.4638281,26.5321121 C28.6062523,22.1631466 30.1850402,17.555819 29.7941311,13.8989224'
      id='Fill-1'
    />
    <path
      d='M22.7655767,23.6511828 C20.8735208,26.3109826 18.5184113,28.4079436 16.1212314,29.6009937 C15.4138348,29.9530136 14.5877495,29.9530136 13.8803529,29.6009937 C11.483173,28.4079436 9.12806348,26.3109826 7.23600758,23.6511828 C4.92018242,20.3931735 3.75669759,16.9571893 4.04255378,14.2300172 C4.40363527,10.8221048 4.81208086,8.37228171 5.115211,6.82300092 C5.2263773,6.25510908 5.64986794,5.80315364 6.2054208,5.65661902 L11.0677621,4.37486217 C13.6463186,3.69468326 16.3552657,3.6952447 18.9338222,4.3754236 L23.7969993,5.65886476 C24.3522736,5.80539938 24.7754856,6.25735482 24.8866519,6.82468522 C25.1900607,8.37143956 25.5976704,10.819859 25.9568016,14.2300172 C26.2448867,16.9571893 25.0814019,20.3931735 22.7655767,23.6511828'
      id='Fill-4'
      opacity='0.600000024'
    />
    <path
      d='M19.9418392,20.8020216 C18.7378097,22.5412066 17.2389989,23.9120873 15.7135053,24.692366 C15.2632013,24.9225561 14.7376772,24.9225561 14.2876274,24.692366 C12.7621337,23.9120873 11.2633229,22.5412066 10.0592934,20.8020216 C8.58564065,18.6716451 7.84512949,16.4252527 8.02708075,14.6421343 C8.25706106,12.4138941 8.51677362,10.812034 8.70990624,9.79893441 C8.78055213,9.42773642 9.04992132,9.13230386 9.40340485,9.03654477 L12.4975929,8.1983897 C14.138712,7.75379394 15.8624207,7.75379394 17.5032856,8.19891585 L20.598236,9.03812322 C20.9514654,9.13361923 21.2208346,9.42931487 21.2917346,9.80024979 C21.4846131,10.8115078 21.7440716,12.4123156 21.9725271,14.6421343 C22.155749,16.4252527 21.415492,18.6716451 19.9418392,20.8020216'
      id='Fill-6'
      opacity='0.600000024'
    />
  </svg>
);

const Logo = ({
  participantId,
  logoUri,
  alt = '',
  size = 'md',
  isNewDesign = false,
  isHideEmpty = false,
  className = '',
}) => {
  const isShowingLogo = SiteConfigManager.getConfig('config.config.general.showLogos');
  const isShowingBadge = SiteConfigManager.getConfig('config.config.general.showBadges');

  const [isError, setError] = useState(false);

  if (!isShowingLogo && !isShowingBadge) return null;

  const getSkeletonLogo = () => (isNewDesign ? <i className='AkrIcon-championship' /> : ParticipantSkeletonLogo);

  const participantLogo = logoUri ? buildCdnUrl(logoUri, 'LOGOS_CDN_URL') : null;

  const loadedHandler = () => setError(false);
  const errorHandler = () => setError(true);

  const isEmptyLogo = !participantLogo || isError || !isShowingLogo;

  return (isError || !isShowingLogo) && isHideEmpty ? null : (
    <span
      title={participantId}
      className={`event-participants__participant-logo event-participants__participant-logo--${size} ${isEmptyLogo ? 'event-participants__participant-logo--empty' : ''} ${className}`}
    >
      {isEmptyLogo ? (
        getSkeletonLogo()
      ) : (
        <img
          src={participantLogo}
          alt={`${alt} logo`}
          onError={errorHandler}
          onLoad={loadedHandler}
        />
      )}
    </span>
  );
};

export default React.memo(Logo);
