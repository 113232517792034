import React from 'react';

const MyBetsEmptyIcon = () => (
  <div className='emptyIcon'>
    <svg viewBox='0 0 167 104' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M46 86.25H129C132.866 86.25 136 83.116 136 79.25C136 75.384 132.866 72.25 129 72.25C129 72.25 123 69.116 123 65.25C123 61.384 126.952 58.25 131.826 58.25H142C145.866 58.25 149 55.116 149 51.25C149 47.384 145.866 44.25 142 44.25H120C123.866 44.25 127 41.116 127 37.25C127 33.384 123.866 30.25 120 30.25H160C163.866 30.25 167 27.116 167 23.25C167 19.384 163.866 16.25 160 16.25H62C58.134 16.25 55 19.384 55 23.25C55 27.116 58.134 30.25 62 30.25H22C18.134 30.25 15 33.384 15 37.25C15 41.116 18.134 44.25 22 44.25H47C50.866 44.25 54 47.384 54 51.25C54 55.116 50.866 58.25 47 58.25H7C3.13401 58.25 0 61.384 0 65.25C0 69.116 3.13401 72.25 7 72.25H46C42.134 72.25 39 75.384 39 79.25C39 83.116 42.134 86.25 46 86.25ZM153 51.25C153 55.116 156.134 58.25 160 58.25C163.866 58.25 167 55.116 167 51.25C167 47.384 163.866 44.25 160 44.25C156.134 44.25 153 47.384 153 51.25Z' fill='var(--dt-light-3)' />
      <path fillRule='evenodd' clipRule='evenodd' d='M43.1867 23.6486C42.7756 23.6991 42.4832 24.0733 42.5337 24.4844L51.5495 97.912C51.7346 99.4194 53.1066 100.491 54.6141 100.306L113.174 93.116C114.682 92.9309 115.754 91.5588 115.569 90.0514L114.734 83.2539L105.433 15.4198C105.34 14.7358 105.818 14.1054 106.502 14.0116C107.186 13.9178 107.816 14.3963 107.91 15.0802L117.213 82.9317L118.05 89.7467C118.403 92.6246 116.357 95.244 113.479 95.5974L54.9188 102.788C52.0409 103.141 49.4215 101.095 49.0681 98.2166L40.0523 24.7891C39.8336 23.0076 41.1005 21.386 42.882 21.1672L42.9158 21.1633L47.7742 20.6183C48.4602 20.5413 49.0788 21.0351 49.1557 21.7212C49.2327 22.4072 48.7389 23.0258 48.0529 23.1027L43.1945 23.6477L43.1867 23.6486Z' fill='var(--dt-light-4)' />
      <path fillRule='evenodd' clipRule='evenodd' d='M104.14 19.5192L112.56 81.0029L113.317 87.1711C113.561 89.1582 112.167 90.9646 110.204 91.2057L57.7615 97.6447C55.7979 97.8858 54.0083 96.4704 53.7644 94.4833L45.6143 28.1068C45.4797 27.0104 46.2594 26.0125 47.3557 25.8779L53.8433 25.0814' fill='var(--dt-light-3)' />
      <path fillRule='evenodd' clipRule='evenodd' d='M59.6719 5.25C59.6719 3.04086 61.4627 1.25 63.6719 1.25H109.229C110.289 1.25 111.306 1.67109 112.056 2.4207L125.499 15.8556C126.25 16.6058 126.672 17.6236 126.672 18.6849V81.25C126.672 83.4591 124.881 85.25 122.672 85.25H63.6719C61.4627 85.25 59.6719 83.4591 59.6719 81.25V5.25Z' fill='var(--dt-light-2)' />
      <path fillRule='evenodd' clipRule='evenodd' d='M58.4219 5.25C58.4219 2.3505 60.7724 0 63.6719 0H109.229C110.62 0 111.955 0.552678 112.94 1.53654L126.383 14.9715C127.368 15.9561 127.922 17.292 127.922 18.6849V81.25C127.922 84.1495 125.571 86.5 122.672 86.5H63.6719C60.7724 86.5 58.4219 84.1495 58.4219 81.25V5.25ZM63.6719 2.5C62.1531 2.5 60.9219 3.73122 60.9219 5.25V81.25C60.9219 82.7688 62.1531 84 63.6719 84H122.672C124.191 84 125.422 82.7688 125.422 81.25V18.6849C125.422 17.9553 125.132 17.2556 124.616 16.7398L111.173 3.30485C110.657 2.7895 109.958 2.5 109.229 2.5H63.6719Z' fill='var(--dt-light-4)' />
      <path fillRule='evenodd' clipRule='evenodd' d='M109.672 2.40283C110.362 2.40283 110.922 2.96248 110.922 3.65283V15.25C110.922 16.2165 111.705 17 112.672 17H120.605C121.296 17 121.855 17.5596 121.855 18.25C121.855 18.9404 121.296 19.5 120.605 19.5H112.672C110.325 19.5 108.422 17.5972 108.422 15.25V3.65283C108.422 2.96248 108.982 2.40283 109.672 2.40283Z' fill='var(--dt-light-4)' />
      <path fillRule='evenodd' clipRule='evenodd' d='M70.4219 18.25C70.4219 17.5596 70.9815 17 71.6719 17H97.6719C98.3622 17 98.9219 17.5596 98.9219 18.25C98.9219 18.9404 98.3622 19.5 97.6719 19.5H71.6719C70.9815 19.5 70.4219 18.9404 70.4219 18.25ZM70.4219 30.25C70.4219 29.5596 70.9815 29 71.6719 29H114.672C115.362 29 115.922 29.5596 115.922 30.25C115.922 30.9404 115.362 31.5 114.672 31.5H71.6719C70.9815 31.5 70.4219 30.9404 70.4219 30.25ZM71.6719 42C70.9815 42 70.4219 42.5596 70.4219 43.25C70.4219 43.9404 70.9815 44.5 71.6719 44.5H114.672C115.362 44.5 115.922 43.9404 115.922 43.25C115.922 42.5596 115.362 42 114.672 42H71.6719ZM70.4219 56.25C70.4219 55.5596 70.9815 55 71.6719 55H114.672C115.362 55 115.922 55.5596 115.922 56.25C115.922 56.9404 115.362 57.5 114.672 57.5H71.6719C70.9815 57.5 70.4219 56.9404 70.4219 56.25ZM71.6719 68C70.9815 68 70.4219 68.5596 70.4219 69.25C70.4219 69.9404 70.9815 70.5 71.6719 70.5H97.6719C98.3622 70.5 98.9219 69.9404 98.9219 69.25C98.9219 68.5596 98.3622 68 97.6719 68H71.6719Z' fill='var(--dt-txt-1)' />
    </svg>
  </div>
);

export default MyBetsEmptyIcon;
