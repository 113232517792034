import React, { useLayoutEffect, useMemo, useCallback, useRef } from 'react';

const config = {
  childList: true,
  subtree: true,
};

const useVerifyDocumentsDocumentTypePlaceholder = () => {
  const observedElementRef = useRef(null);
  const callback = useCallback(() => {
    const observedElement = observedElementRef.current;
    const selectValueElement = observedElement.querySelector('.select-value');
    const placeholderElement = observedElement.querySelector('.file-input-placeholder .desc-doc');

    if (!selectValueElement || !placeholderElement) {
      return;
    }

    const selectedValueText = selectValueElement.textContent;
    const placeholderText = placeholderElement.textContent;

    if (selectedValueText !== placeholderText) {
      placeholderElement.textContent = selectedValueText
    }
  }, []);
  const observer = useMemo(() => {
    return new MutationObserver(callback);
  }, []);

  useLayoutEffect(() => {
    observer.observe(observedElementRef.current, config);

    return () => observer.disconnect();
  }, []);

  return observedElementRef;
};

export default useVerifyDocumentsDocumentTypePlaceholder;
