import React, { useMemo } from 'react';
import { map } from 'lodash';
import cx from 'classnames';
import Label from '../I18n/Label';

const Radio = ({
  text, isActive, onClick, withTranslate,
}) => {
  const buttonClassName = cx('radio-button', isActive && 'radio-button-active');

  return (
    <div className='radio' onClick={onClick}>
      <div className={buttonClassName} />
      <div className='radio-label'>
        {withTranslate ? <Label message={text} /> : text}
      </div>
    </div>
  );
};

const RadioSelect = ({
  label,
  value,
  className,
  onChange,
  options,
  withTranslate,
  disabled,
}) => {
  const fieldClassName = cx('field', className, disabled && 'disabled');
  const renderOptions = useMemo(
    () => map(options, (option) => {
      const { value: key, text } = option;

      return (
        <Radio
          key={key}
          text={text}
          isActive={option === value}
          onClick={() => onChange(option)}
          withTranslate
        />
      );
    }),
    [options, onChange, value, withTranslate],
  );

  return (
    <div className={fieldClassName}>
      <div className='radio-select'>
        <span className='radio-select-label'>
          {withTranslate
            ? <Label message={label} />
            : label}
        </span>
        {renderOptions}
      </div>
    </div>
  );
};

export default RadioSelect;
