import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { BONUS_TYPE } from '@apollo/core/src/constants';
import { clientProfileRoutes } from '@apollo/routing';
import Label from '../../../shared/components/I18n/Label';
import BonusBanners from './BonusBanners/BonusBanners';
import BonusesNavigation, { ALL_TAB_NAME } from './BonusesNavigation';
import ClientProfileLinks from '../ClientProfileLinks';

const bonusTabs = [ALL_TAB_NAME, BONUS_TYPE.ODDS_BOOST, BONUS_TYPE.FREEBET, BONUS_TYPE.BET_RETURN];
const { bonuses } = clientProfileRoutes;

const Bonuses = () => {
  const {
    params: { bonusType },
  } = useRouteMatch();

  const activeTab = useMemo(
    () =>
      bonusType && bonusTabs.find((t) => t.toLowerCase() === bonusType.toLowerCase())
        ? bonusType.toLowerCase()
        : ALL_TAB_NAME.toLowerCase(),
    [bonusType]
  );

  const activeBonusType = useMemo(
    () => Object.values(BONUS_TYPE).find((type) => type.toLowerCase() === activeTab?.toLowerCase()),
    [activeTab]
  );

  return (
    <div className='main__page__account__block'>
      <div className='main__page__account__block__title'>
        <div className='name'>
          <Label message='bonuses' />
        </div>

        <ClientProfileLinks tabs={[]} availableRoutes={[]} parentRoute={bonuses} />

        <BonusesNavigation activeTab={activeTab} bonusTabs={bonusTabs} />
      </div>
      <div className='main__page__account__block__content'>
        <BonusBanners bonusType={activeBonusType} />
      </div>
    </div>
  );
};

export default Bonuses;
