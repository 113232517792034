import React, { useEffect, useMemo, useState } from 'react';
import { map } from 'lodash';
import { useRacingState } from '@apollo/core/src/state/racing/racing';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import RaceLink from '../../../shared/components/Racing/RaceLink';
import RaceCountdownMobile from '../../../shared/components/Racing/RaceCountdown/RaceCountdownMobile';
import MainBody from '../../../shared/components/MainBody/MainBody';
import RacingBreadcrumbs from '../RacingBreadcrumbs';

const MeetingRacesMobile = () => {
  const [venue, setVenue] = useState();
  const [racingState] = useRacingState();
  const { venues, races } = racingState;

  const { venueId, raceDate, raceCountry, raceType } = useParams();

  const racesById = useMemo(
    () => races.reduce((acc, race) => {
      acc[race.raceId] = race;
      return acc;
    }, {}),
    [races],
  );

  useEffect(() => {
    setVenue(venues.find((ven) => ven.venueId === Number(venueId)));
  }, [venueId, venues]);

  return (
    <MainBody className='main__body--venues-page'>
      <RacingBreadcrumbs />
      <div className='sport-page__header sport-page__header--racing'>
        <h1 className='sport-name'>Racing</h1>
      </div>
      <div className='venue__content__container--mobile'>
        <div className='venue__content__container__header--mobile'>
          <RaceLink
            className=''
            raceDate={raceDate}
            raceType={raceType}
            raceCountry={raceCountry}
            raceVenue={venue?.venueName}
            raceNumber={raceType}
          >
            {venue?.venueName}

            <span className='race-date'>{moment(raceDate).format('DD MMM')}</span>
          </RaceLink>
        </div>
        <div className='venue_race_table venue_race__mobile'>
          <div className='venue_race_table_data '>
            {venue?.races?.length ? (
              <>
                {map(venue?.races, (race) => {
                  const updatedRace = racesById[race.raceId] || race;
                  return (
                    <RaceLink
                      raceDate={updatedRace?.meeting.date}
                      raceType={updatedRace?.raceType?.typeName}
                      raceCountry={race?.country?.countryId}
                      raceVenue={race?.venue?.venueName}
                      venueId={updatedRace?.venueId}
                      className=''
                      raceNumber={updatedRace?.number}
                      key={updatedRace?.raceId}
                    >
                      <div className='venue_race_table_row' key={updatedRace?.raceId}>
                        <td className='venue_race_table_row_item__data first'>
                          <div className=''>
                            <div className='venue_race_table_row_item__data__link'>
                              <span className='race-number--rounded'>{`R${updatedRace?.number}`}</span>

                              {updatedRace.distance ? (
                                <span className='name'>{`${updatedRace.distance}m`}</span>
                              ) : null}
                              {updatedRace.title ? (
                                <span className='name' title={updatedRace.title}>
                                  {updatedRace.title}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </td>
                        <td className={`venue_race_table_row_item__data ${race?.status}`}>
                          <div className='race-summary__timer__wrapper'>
                            <div className='race-summary__timer'>
                              <RaceCountdownMobile
                                key={`${updatedRace?.raceId}-${updatedRace?.status}-${updatedRace?.result}`}
                                date={new Date(updatedRace?.startTime)}
                                completeText={updatedRace?.result || updatedRace?.status}
                              />
                            </div>
                            {updatedRace?.hasFixedOdds ? (
                              <div className='race-summary__fixed-odds'>FO</div>
                            ) : null}
                          </div>
                        </td>
                      </div>
                    </RaceLink>
                  );
                })}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </MainBody>
  );
};
export default MeetingRacesMobile;
