import React from 'react';
import Label from '../../../../shared/components/I18n/Label';
import { getCurrencySymbol } from '../../../../core/utils';

const RunnerForm = ({ staticInfo }) => (staticInfo ? (
  <div className='runner-form'>
    {staticInfo?.comment}
    <div className='runner-form__info'>
      <div className='runner-form__info--item'>
        <Label message='type' className='item-title' />
        {staticInfo.age ? (
          <>
            {staticInfo.age}
            <Label message='yo' />
            {' '}
          </>
        ) : null}
        {staticInfo.participant?.colour}
      </div>
      <div className='runner-form__info--item'>
        <Label message='sire' className='item-title' />
        {staticInfo.participant?.sire}
      </div>
      <div className='runner-form__info--item'>
        <Label message='Dam' className='item-title' />
        {staticInfo.participant?.dam}
      </div>
      <div className='runner-form__info--item'>
        <Label message='trainer location' className='item-title' />
        {staticInfo.participant?.trainerLocation}
      </div>
      {/*       <div className='runner-form__info--item'>
        <Label message='gear change' className='item-title' />
        -
      </div> */}
    </div>

    <div className='runner-form__statistics'>
      <div className='statistic-col statistic-col--main'>
        <div className='b-row'>
          <Label message='last 5 stars' className='item-title' />
          <div className='item-value'>{staticInfo.last5Starts || '-'}</div>
        </div>
        <div className='b-row'>
          <Label message='last 20 stars' className='item-title' />
          <div className='item-value'>{staticInfo.last20Starts || '-'}</div>
        </div>
        <div className='b-row highlight'>
          <Label message='career' className='item-title' />
          <div className='item-value'>
            {`${
              staticInfo.totalStarts || staticInfo.totalStarts === 0
                ? staticInfo.totalStarts
                : '-'
            } : ${staticInfo.totalWins || staticInfo.totalStarts === 0 ? staticInfo.totalWins : '-'} - ${
              staticInfo.totalPlacings || staticInfo.totalStarts === 0
                ? staticInfo.totalPlacings
                : '-'
            }`}
          </div>
        </div>
        <div className='b-row'>
          <Label message='win %' className='item-title' />
          <div className='item-value'>
            {staticInfo.totalStarts
              ? `${Math.round((staticInfo.totalWins / (staticInfo.totalStarts || 1)) * 100)} %`
              : '-'}
          </div>
        </div>
        <div className='b-row'>
          <Label message='place %' className='item-title' />
          <div className='item-value'>
            {staticInfo.totalWins
              ? `${Math.round(
                ((staticInfo.totalWins + staticInfo.totalPlacings) / staticInfo.totalStarts)
                      * 100,
              )}%`
              : '-'}
          </div>
        </div>
      </div>
      <div className='statistic-col statistic-col--main'>
        <div className='b-row'>
          <Label message='tcdw' className='item-title' />
          <div className='item-value'>{staticInfo.tcdwIndicators}</div>
        </div>
        <div className='b-row'>
          <Label message='blinkers' className='item-title' />
          <div className='item-value'>
            {staticInfo.blinkers ? <Label message='yes' /> : <Label message='no' />}
          </div>
        </div>
        <div className='b-row'>
          <Label message='class change' className='item-title' />
          <div className={`item-value item-value--${staticInfo.classChange}`}>
            {['up', 'dn', 'eq'].includes(staticInfo.classChange) ? (
              <Label message={`class change ${staticInfo.classChange}`} />
            ) : (
              staticInfo.classChange
            )}
          </div>
        </div>
        <div className='b-row'>
          <Label message='prize won' className='item-title' />
          <div className='item-value'>
            {_.isNumber(staticInfo.participant?.prize)
              ? `${getCurrencySymbol()}${staticInfo.participant.prize.toLocaleString(undefined, {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              })}`
              : '-'}
          </div>
        </div>
        <div className='b-row'>
          <Label message='last run' className='item-title' />
          <div className='item-value'>
            {staticInfo.daysSinceLastRun}
            {' '}
            <Label message='days ago' />
          </div>
        </div>
      </div>
      {_.isArray(staticInfo.statistics) ? (
        <div className='statistic-col statistic-col'>
          {staticInfo.statistics
            ?.slice(0, Math.ceil(staticInfo.statistics.length / 2))
            .map((item) => (
              <div className={`b-row ${item.highlight ? 'highlight' : ''}`} key={item.key}>
                <Label message={item.key} className='item-title' />
                <div className='item-value'>{`${item.starts} : ${item.wins} - ${item.placings}`}</div>
              </div>
            ))}
        </div>
      ) : null}
      {_.isArray(staticInfo.statistics) ? (
        <div className='statistic-col'>
          {staticInfo.statistics
            ?.slice(Math.ceil(staticInfo.statistics.length / 2))
            .map((item) => (
              <div className={`b-row ${item.highlight ? 'highlight' : ''}`} key={item.key}>
                <Label message={item.key} className='item-title' />
                <div className='item-value'>{`${item.starts} : ${item.wins} - ${item.placings}`}</div>
              </div>
            ))}
        </div>
      ) : null}
    </div>
  </div>
) : null);

export default RunnerForm;
