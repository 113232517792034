import React from 'react';
import { buildCdnUrl } from '../../../core/utils';

const EventTop = ({ children, sportId }) => {
  const backgroundUrl = buildCdnUrl('static/assets/images/sport-covers/');

  return (
    <div className={`event-details__top event-detail__top-${sportId}`}>
      <span
        className='event-details__top__cover'
        style={{
          backgroundImage: `url(${backgroundUrl}cover-${sportId}.jpg), url(${backgroundUrl}cover-default.jpg)`,
        }}
      />
      {children}
    </div>
  );
};

export default React.memo(EventTop);
