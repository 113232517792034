import React, { useCallback } from 'react';
import { useSettings } from '@apollo/core';
import { LocalConfig } from '../../../../core/utils';

const AppLinks = () => {
  const { isCordova } = useSettings();
  const iosHref = LocalConfig.get('apps.ios', null);
  const androidHref = LocalConfig.get('apps.android', null);

  const handleAnchorClick = useCallback(
    (event) => {
      if (!isCordova) {
        return;
      }
      event.preventDefault();
      const anchor = event.currentTarget;
      const href = anchor.getAttribute('href');
      window.open(href, '_system');
      return false;
    },
    [isCordova]
  );

  if (isCordova || (!iosHref && !androidHref)) {
    return null;
  }

  return (
    <div className='footer_app-link'>
      <div className='footer_app-link__container'>
        {iosHref && (
          <div className='footer_app-link__btn footer_app-link__btn--ios'>
            <a href={iosHref} target='_blank' onClick={handleAnchorClick} rel='noreferrer'>
              <img
                src='./static/assets/images/ico-apple-app-store.png'
                alt='Apple AppStore'
                loading='lazy'
              />
            </a>
          </div>
        )}
        {androidHref && (
          <div className='footer_app-link__btn footer_app-link__btn--android'>
            <a href={androidHref} target='_blank' onClick={handleAnchorClick} rel='noreferrer'>
              <img
                src='./static/assets/images/ico-google-play.png'
                alt='Google Play'
                loading='lazy'
              />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppLinks;
