import React, { useMemo } from 'react';
import { map } from 'lodash';
import { CategoryProvider, useSportState, getSportMainMarketTypesByPeriod } from '@apollo/core';
import SportLoader from '../SportLoader';
import LiveCategory from './LiveCategory';

const LiveCategories = () => {
  const { categories, mainMarketTypes, initialized, loading } = useSportState();

  const mainMarketTypesByPeriod = useMemo(
    () => getSportMainMarketTypesByPeriod(mainMarketTypes),
    [mainMarketTypes]
  );

  if (!initialized && loading) {
    return <SportLoader />;
  }

  return map(categories, (category) => {
    const { id } = category;

    return (
      <CategoryProvider category={category} key={id}>
        <LiveCategory mainMarketTypesByPeriod={mainMarketTypesByPeriod} />
      </CategoryProvider>
    );
  });
};

export default LiveCategories;
