import React from 'react';
import { map } from 'lodash';
import moment from 'moment';
import cx from 'classnames';

import {
  useSportsbookState,
  useSportsbookStateUpdate,
} from '@apollo/core';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import ResultsTimeFilterItem from './ResultsTimeFilterItem';
import { getDayEnd, getDayStart } from '../../../core/utils';

const DAY_FILTER_RANGE = 7;

function getFilterDates(range) {
  return new Array(range)
    .fill('')
    .map((x, index) => {
      const date = moment().subtract(index, 'days');
      const subtitle = date.format('ddd');
      const title = date.date();
      const fromDate = getDayStart(date);
      const toDate = getDayEnd(date);

      return {
        title,
        subtitle,
        fromDate,
        toDate,
      };
    })
    .reverse();
}

const filterDates = getFilterDates(DAY_FILTER_RANGE);

const ResultsTimeFilter = ({ className }) => {
  const [sportsState] = useSportsbookState();
  const {
    fromDate,
    toDate,
    dateFilterType,
  } = sportsState;

  const sportsbookUpdate = useSportsbookStateUpdate();

  const DateFilters = React.useMemo(
    () => map(filterDates, (filterOption) => {
      const {
        title,
        subtitle,
      } = filterOption;

      const handleSelectTimeFilter = () => {
        sportsbookUpdate({
          fromDate: filterOption.fromDate,
          toDate: filterOption.toDate,
          dateFilterType: 'single',
        });
      };

      const active = (filterOption.fromDate >= fromDate) && (
        filterOption.toDate <= toDate
      );

      return (
        <ResultsTimeFilterItem
          key={title}
          title={title}
          subtitle={subtitle}
          onClick={handleSelectTimeFilter}
          active={active}
        />
      );
    }),
    [fromDate, toDate, sportsbookUpdate],
  );

  const containerClassName = cx(
    className,
    {
      focused: dateFilterType === 'single',
    },
  );

  return (
    <div className={containerClassName}>
      {DateFilters}
    </div>
  );
};

export default ResultsTimeFilter;
