import React, { useCallback } from 'react';
import { AccountManager, Bus, NotificationManager } from '@apollo/core';
import PersonalDataForm from './PersonalDataForm';
import StaticBlock from '../../../shared/components/StaticBlock/StaticBlock';
import { cmsStaticBlock } from '../../../core/constants';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import Label from '../../../shared/components/I18n/Label';

const DetailMain = () => {
  const t = useTranslate();

  const closeAccountHandler = useCallback(() => {
    NotificationManager.display({
      type: 'confirm',
      title: 'self_close_account',
      message: 'confirm_self_close_account',
      data: {
        onConfirm: () => {
          AccountManager.closeAccount({ selfClosed: true }).then(() => {
            Bus.emit(Bus.events.auth.logout);
          });
        },
      },
    });
  }, []);

  return (
    <>
      <div className='wrapper'>
        <div className='details-profile  details-profile--info'>
          <h2 className='content-header'>
            <Label message='Account Details' />
          </h2>
          <PersonalDataForm />
        </div>
      </div>

      <div className='wrapper'>
        <div className='details-profile details-profile--deactivate'>
          <h2 className='content-header'>
            <Label message='Deactivate My Account' />
          </h2>
          <StaticBlock options={{ source: cmsStaticBlock.SelfCloseAccount }} />
          <div className='block block__bGroup'>
            <button
              type='button'
              className='editLink editLink--danger'
              onClick={closeAccountHandler}
            >
              <span className='icon AkIcon-close-icon' />
              {t('self_close_account')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailMain;
