import React, { memo, useRef, useState } from 'react';
import { actions, useBetslipDispatch } from '@apollo/core';
import { isEmpty } from 'lodash';
import { RaceMarketsTabs } from '@apollo/core/src/constants';
import Dropdown from '../../../shared/components/Dropdown/Dropdown';
import Label from '../../../shared/components/I18n/Label';
import { AnimatedDropdown } from '../../Animations/AnimatedDropdown';
import { RacingMarketTypeName } from '../../../shared/components/Racing/RacingMarketTypeName';

const MarketsDropdown = (props) => {
  const { requestId, selectedMarket, event, outcomeSelection, disabled } = props;
  const [isExpanded, setExpanded] = useState(false);
  const toggleRef = useRef(null);

  const marketsList = React.useMemo(
    () => (event?.markets || []).filter(
      (m) => m.typeUiPosition === RaceMarketsTabs.WP_TAB
          && !isEmpty(m.outcomes)
          // && m.type !== 'CONCESSION'
          && m.outcomes.find((o) => o.specifier === outcomeSelection && o.odds),
    ),
    [event],
  );

  const dispatch = useBetslipDispatch();
  const handleMarketSelect = (marketId) => {
    const market = marketsList.find((market) => market.marketId === marketId);
    const outcome = (market.outcomes || []).find((o) => o.selection === outcomeSelection);
    if (market) {
      dispatch({
        type: actions.BETSLIP_UPDATE_BET,
        payload: {
          requestId,
          marketTypeId: market.type,
          /*          isNew: true,
          synchronized: false, */
          marketId,
          outcomeId: outcome?.outcomeId,
          odds: outcome?.odds,
        },
      });
    }

    setExpanded(false);
  };

  return marketsList.length > 1 ? (
    <Dropdown
      onExpanded={setExpanded}
      className={`markets-selector ${disabled ? 'disabled' : ''}`}
      toggleRef={toggleRef}
      disabled={disabled}
    >
      <div ref={toggleRef}>
        <RacingMarketTypeName marketType={selectedMarket} />
      </div>
      <AnimatedDropdown isExpanded={isExpanded} className='markets-selector__dropdown'>
        {marketsList.map((market) => (
          <div
            key={market.marketId}
            className={selectedMarket === market.type ? 'active' : ''}
            onClick={() => handleMarketSelect(market.marketId)}
          >
            <RacingMarketTypeName marketType={market.type} />
          </div>
        ))}
      </AnimatedDropdown>
    </Dropdown>
  ) : (
    <span>
      {' '}
      <Label message={selectedMarket} />
    </span>
  );
};

export default memo(MarketsDropdown);
