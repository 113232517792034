import React, { useMemo } from 'react';
import { constants, useBetslipState } from '@apollo/core';
import { marketTypeParser } from '../../../../core/DataParser';
import MarketTypeDefaultView from './MarketTypeDefaultView';
import MarketTypeDropDownView from './MarketTypeDropDownView';
import { getDisplayLayoutById } from '../../../../core/DataParser/mappingObject';

const { DISPLAY_LAYOUTS } = constants;

const DetailsMarketType = (props) => {
  // marketType from processedMarketTypes
  const { event, marketType, activeGroup } = props;
  const {
    settings: { feedStates },
  } = useBetslipState();

  const isMainGroup = useMemo(
    () => Boolean(activeGroup?.value?.group === 'MAIN'),
    [activeGroup?.value?.group],
  );

  const data = useMemo(() => {
    try {
      const data = marketTypeParser({ event, marketType, feedStates });
      if (isMainGroup) {
        const mainMarket = marketType?.filteredMarkets?.find((m) => m.isMain);
        if (mainMarket) {
          // for main group we show market with isMain flag to prevent multiple outcomes
          data.rows = data.rows.filter(([{ marketId }]) => marketId === mainMarket?.id);
        }
      }
      return data;
    } catch (e) {
      console.log('MarketTypeDefaultView e', e, marketType);
      return null;
    }
  }, [event, marketType, feedStates, isMainGroup]);

  const marketView = useMemo(() => {
    if (isMainGroup) {
      return (
        <MarketTypeDefaultView
          {...{ ...props, marketType: { ...marketType, name: data?.title } }}
          data={data}
        />
      );
    }
    const displayLayout = marketType.displayLayout || getDisplayLayoutById(marketType.id);
    if (displayLayout === DISPLAY_LAYOUTS.HANDICAP_DROPDOWN) {
      return <MarketTypeDropDownView {...props} data={data} />;
    }
    return <MarketTypeDefaultView {...props} data={data} />;
  }, [props, data, isMainGroup, marketType]);

  if (!data || !data.rows?.length) {
    console.warn('Market Type has no outcomes.', data?.title);
    return null;
  }

  // we can set adapter here
  return marketView;
};

export default DetailsMarketType;
