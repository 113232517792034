import React from 'react';
import cx from 'classnames';

import {
  useBetslipState,
  BetItemProvider,
  useBetItem,
  BetManager,
} from '@apollo/core';
import MarketSwitcher from './EditBetMarketSwitcher';
import Label from '../../../shared/components/I18n/Label';
import Odds from '../../../shared/components/Odds';

export default function EditBetsList({ editBetMode }) {
  const {
    betItems,
    loading,
  } = useBetslipState();

  const [
    displayedEventIdSwitcher,
    setEventMarketSwitcher,
  ] = React.useState(null);

  const showRemoveButton = editBetMode && (betItems.length > 1);

  const listComponents = React.useMemo(
    () => (
      betItems.map(
        (betItem) => (
          <BetItemProvider key={betItem.outcomeId} betItem={betItem}>
            <BetItemView
              showMarketSwitcher={betItem.eventId === displayedEventIdSwitcher}
              setMarketSwitcher={setEventMarketSwitcher}
              showRemoveButton={showRemoveButton}
            />
          </BetItemProvider>
        ),
      )
    ),
    [betItems, displayedEventIdSwitcher, showRemoveButton],
  );

  return (
    <>
      {
        // FIXME: Should be
        // <div className='loading'>
        //   <Svg name='loader' />
        // </div>

        loading && (
          <Label message='loading' />
        )
      }

      {listComponents}
    </>
  );
}

function BetItemView(props) {
  const {
    setMarketSwitcher,
    showMarketSwitcher,
    showRemoveButton,
  } = props;

  const {
    event,
    eventId,
    marketTypeId,
    marketId,
    outcomeId,
    active,
    marketType,
    outcome,
    loading,
  } = useBetItem();

  const handleToggleDisplaySwitcher = React.useCallback(
    () => (
      showMarketSwitcher
        ? setMarketSwitcher(null)
        : setMarketSwitcher(eventId)
    ),
    [showMarketSwitcher],
  );

  const RemoveBetButton = React.useMemo(
    () => {
      if (!showRemoveButton) {
        return null;
      }

      const handleRemoveBet = () => (
        BetManager.removeBet({
          eventId,
          marketTypeId,
          marketId,
          outcomeId,
        })
      );

      return (
        <span className='close' onClick={handleRemoveBet}>×</span>
      );
    },
    [showRemoveButton, outcomeId, eventId, marketTypeId, marketId],
  );

  const handleSubmitMarketSwitcher = React.useCallback(
    () => (
      setMarketSwitcher(null)
    ),
    [],
  );

  if (loading) {
    return (
      <div className='coupon__element__content'>
        <Label message='loading' />
      </div>
    );
  }

  const containerClassName = cx(
    'coupon__element__content',
    {
      disabled: !active,
    },
  );

  return (
    <div className={containerClassName}>
      { RemoveBetButton }
      <div className='team'>
        <span className={`icon sportIcon-${event.sportId}`} />

        <span className='name'>
          <span>{event.name}</span>
        </span>
      </div>
      <div className='pick editable'>
        <span onClick={handleToggleDisplaySwitcher}>
          {outcome.name}
        </span>
        {
          showMarketSwitcher && (
            <MarketSwitcher
              marketType={marketType}
              event={event}
              outcome={outcome}
              onSubmit={handleSubmitMarketSwitcher}
            />
          )
        }
      </div>
      <div className='odd'>
        <Odds value={outcome.odds} />
      </div>
    </div>
  );
}
