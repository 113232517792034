import React from 'react';
import { DateService } from '@apollo/core';

const DateTime = ({ dateTime }) => {
  const date = DateService.getFormattedDate(dateTime);
  const timeTz = DateService.getFormattedTime(dateTime) || '';
  const [time, timeZone] = timeTz.split(' ');

  return (
    <span className='date-time'>
      <span className='date-time__date'>{date}</span>
      {' '}
      <span className='date-time__time-with-zone'>
        <span className='date-time__time'>{time}</span>
        {' '}
        {timeZone && <span className='date-time__time-zone'>{timeZone}</span>}
      </span>
    </span>
  );
};

export default DateTime;
