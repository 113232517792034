import React from 'react';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import { MAX_TIMESTAMP, MIN_TIMESTAMP } from '../../../../core/constants';
import { getEventDate } from '../../../../core/utils';

const EventDateGroup = ({ startTime, headerRenderer, children }) => {
  const t = useTranslate();
  let title;

  if (startTime === MIN_TIMESTAMP) {
    title = t('Favorites');
  } else if (startTime === MAX_TIMESTAMP) {
    title = '';
  } else {
    title = getEventDate(startTime, t);
  }

  return (
    <div className='event-group event-group--date'>
      {headerRenderer ? (
        headerRenderer(title)
      ) : (
        <div className='event-group__header event-group__header--default'>
          <div className='event-group__title'>{title}</div>
        </div>
      )}
      {children}
    </div>
  );
};

export default EventDateGroup;
