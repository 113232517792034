import React from 'react';
import SVG from 'react-inlinesvg';

const svgMap = {
  loader: './static/assets/svg/loader.svg',
  emptyState: './static/assets/svg/empty-state.svg',
  emptyStateBonus: './static/assets/svg/empty-state-bonus.svg',
  warning: './static/assets/svg/icon-warning.svg',
};

const Svg = ({ name = 'loader', preProcessor }) => (
  <SVG
    src={svgMap[name]}
    cacheRequests={false}
    preProcessor={preProcessor}
  />
);

export default Svg;
