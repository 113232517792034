import React, { useCallback, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { betHistoryConstants, useBetHistory } from '@apollo/core';
import HistoryFilterListItem from '../HitoryFilterListItem';
import Dropdown from '../../../../shared/components/Dropdown/Dropdown';
import Label from '../../../../shared/components/I18n/Label';
import { AnimatedDropdown } from '../../../Animations/AnimatedDropdown';

const { extendedBetStatuses } = betHistoryConstants;

const BetHistoryFilterList = () => {
  const { selectedBetStatus, onSelectBetStatus } = useBetHistory();

  const [isExpanded, setExpanded] = useState(false);
  const toggleRef = useRef(null);

  const shrinkDropdown = useCallback(() => {
    setExpanded(false);
  }, [setExpanded]);

  const historySelectLabelClass = cx('history__current status-label', { active: isExpanded });

  const filtersList = useMemo(
    () =>
      _.map(extendedBetStatuses, (betStatus) => (
        <HistoryFilterListItem
          key={betStatus}
          text={betStatus}
          onClick={() => onSelectBetStatus(betStatus)}
          isChecked={selectedBetStatus === betStatus}
        />
      )),
    [selectedBetStatus, onSelectBetStatus]
  );

  return (
    <Dropdown onExpanded={setExpanded} className='history-select-container' toggleRef={toggleRef}>
      <div className={historySelectLabelClass} ref={toggleRef}>
        <Label message={selectedBetStatus} />
      </div>

      <AnimatedDropdown
        isExpanded={isExpanded}
        className='history__dropdown'
        onClick={shrinkDropdown}
      >
        {filtersList}
      </AnimatedDropdown>
    </Dropdown>
  );
};

export default BetHistoryFilterList;
