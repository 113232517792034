import React from 'react';
import { Route } from 'react-router-dom';

import {
  SiteConfigManager,
} from '@apollo/core';

import {
  COMPONENT_TYPES,
} from '@apollo/routing';

import Competition from './Competition';

const { PROMOTION_TOURNAMENTS } = COMPONENT_TYPES;

const CompetitionRouter = () => {
  const competitionPath = SiteConfigManager.getComponentPath(PROMOTION_TOURNAMENTS);

  return (
    <Route path={`${competitionPath}/:id?`} component={Competition} />
  );
};

export default CompetitionRouter;
