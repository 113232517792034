import React from 'react';
import { isUrlNamesMatch } from '@apollo/routing';
import { TournamentLink } from '../../../../shared/components/Sport/SportLinks';

const TournamentFilterOption = ({ option, value }) => {
  const tournament = option;

  return (
    <TournamentLink
      className='sport-filter-option sport-filter-option--tournament'
      active={isUrlNamesMatch(tournament.urlName, value)}
      sportUrlName={tournament.sportUrlName}
      categoryUrlName={tournament.categoryUrlName}
      tournamentUrlName={tournament.urlName}
    >
      {tournament.name}
    </TournamentLink>
  );
};

export default TournamentFilterOption;
