import React from 'react';
import {
  AccountManager,
  ResponsibleApi,
  NotificationManager,
  useAuthorizationEffect, Bus,
} from '@apollo/core';

import BasePopup from './BasePopup';
import Button from '../components/Button/Button';

export default function RealityCheckPopup(notification) {
  const {
    id,
    message,
    title,
  } = notification;

  const handleLogoutButtonClick = React.useCallback(
    () => {
      NotificationManager.removeNotification(id);
      Bus.emit(Bus.events.auth.logout);
    },
    [id],
  );

  const handleContinueButtonClick = React.useCallback(
    () => {
      NotificationManager.removeNotification(id);
      ResponsibleApi.realityCheckPing();
    },
    [id],
  );

  useAuthorizationEffect(
    (authorization) => {
      if (!authorization) {
        NotificationManager.removeNotification(id);
      }
    },
  );

  return (
    <BasePopup
      onClose={handleContinueButtonClick}
      type='popup info'
      title={title}
      message={message}
      withCloseButton={false}
    >
      <div className='block__content__bGroup'>

        <Button
          onClick={handleContinueButtonClick}
          label='continue'
          className='bBase'
          type='success'
        />

        <Button
          onClick={handleLogoutButtonClick}
          label='Logout'
          className='bBase'
          type='neutral'
        />
      </div>

    </BasePopup>
  );
}
