import React, { useState } from 'react';
import cx from 'classnames';
import Dropdown from '../Dropdown/Dropdown';
import { AnimatedDropdown } from '../../../features/Animations/AnimatedDropdown';

const StatusTooltip = ({ message, position, children, hover, className }) => {
  const [isExpanded, setExpanded] = useState(false);
  const relativeExpandedClass = cx(className || '', 'description-tooltip', {
    active: isExpanded,
  });
  const DropdownClass = cx('description-tooltip__dropdown', position);

  return (
    <Dropdown className={relativeExpandedClass} onExpanded={setExpanded}>
      <div className='tooltip-symbol'>{children}</div>
      <AnimatedDropdown isExpanded={isExpanded || hover} className={DropdownClass}>
        <div className='tooltip-message'>
          <span>{message}</span>
        </div>
      </AnimatedDropdown>
    </Dropdown>
  );
};

export default StatusTooltip;
