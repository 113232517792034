import React, { useEffect, useRef, useState } from 'react';
import { map } from 'lodash';
import cx from 'classnames';

import Theme from 'themeSource/custom';

import Outcome from '../../../../shared/components/DetailOdds/Outcome';
import SgmOutcome from '../Sgm/SgmOutcome';
import Label from '../../../../shared/components/I18n/Label';

const MarketTypeRows = ({ rows, event, marketType, isArchived, isSgm }) => {
  const lastMarketIdInRow = useRef(null);
  const hideAfterIndex = useRef(rows?.length);

  const [isShowAll, setIsShowAll] = useState(false);

  const MAX_MARKET_ROWS_COUNT = Theme.MAX_MARKET_ROWS_COUNT || 5;

  const firstRowRef = useRef();

  const toggleShowMore = () => {
    setIsShowAll(!isShowAll);
  };

  let isHide = false;

  useEffect(() => {
    if (!isShowAll) {
      if (firstRowRef.current) {
        firstRowRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  }, [isShowAll]);

  return (
    <>
      {map(rows, (row, rowIndex) => {
        const isNewMarket = lastMarketIdInRow.current !== row[0]?.marketId;
        lastMarketIdInRow.current = row[0]?.marketId;

        if (
          rowIndex >= MAX_MARKET_ROWS_COUNT
          && !isShowAll
          && (isNewMarket || Object.keys(marketType?.markets || {}).length === 1)
        ) {
          if (!isHide) {
            hideAfterIndex.current = rowIndex;
          }
          isHide = true;
        }

        return isHide ? null : (
          <div
            key={`${marketType.id}-${rowIndex}`}
            className={`MarketTypeDefaultView__row ${isNewMarket ? 'MarketTypeDefaultView__row--new-market' : ''}`}
            ref={rowIndex === 0 ? firstRowRef : null}
          >
            {map(row, (outcome, indexInRow) => {
              if (!outcome) {
                return null;
              }
              const outcomeClasses = cx([
                'market',
                'bet',
                'MarketTypeDefaultView__cell',
                'marketGroup__content',
                isArchived && outcome.settlementStatus && outcome.settlementStatus.toLowerCase(),
                (!outcome?.name || outcome.isFake) && 'center',
                outcome.isFake && outcome.className,
              ]);

              if (isArchived && outcome.settlementStatus) {
                outcome.odds = outcome.settlementStatus.replace('_', ' ');
              }

              return isSgm ? (
                <SgmOutcome
                  key={`${marketType.id}-${outcome.id}`}
                  event={event}
                  className={outcomeClasses}
                  marketType={marketType}
                  outcome={outcome}
                  indexInRow={indexInRow}
                />
              ) : (
                <Outcome
                  key={`${marketType.id}-${outcome.marketId}-${outcome.id}`}
                  event={event}
                  className={outcomeClasses}
                  marketType={marketType}
                  outcome={outcome}
                  indexInRow={indexInRow}
                />
              );
            })}
          </div>
        );
      })}
      {rows?.length > hideAfterIndex.current ? (
        <div className='MarketTypeDefaultView--show-toggler'>
          {isShowAll ? (
            <Label message='show less' onClick={toggleShowMore} />
          ) : (
            <Label message='show all' onClick={toggleShowMore} />
          )}
        </div>
      ) : null}
    </>
  );
};

export default MarketTypeRows;
