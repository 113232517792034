import React from 'react';
import Logo from './Logo';

const ParticipantLogo = ({
  participant = {},
  size = 'md',
  className = '',
  isNewDesign = false,
  isHideEmpty = false,
}) => (
  <Logo
    participantId={participant.id}
    logoUri={participant.logoUri}
    alt={participant.fullName}
    isHideEmpty={isHideEmpty}
    isNewDesign={isNewDesign}
    size={size}
    className={className}
  />
);

export default ParticipantLogo;
