import React from 'react';
import { useHistory } from 'react-router-dom';

const BackButton = ({ onClick }) => {
  const { goBack } = useHistory();

  return (
    <div className='header__back' onClick={onClick || goBack}>
      <span className='arrow AIcon-angle-left' />
    </div>
  );
};

export default React.memo(BackButton);
