import React, { useEffect } from 'react';
import { useApplicationState } from '@apollo/core';
import useTranslate from '../I18n/Interpreter';

const SelectedOddsButtons = ({ clearSelection, addToBet, clearDisabled, addDisabled, betNow }) => {
  const t = useTranslate();
  const { layout } = useApplicationState();

  useEffect(() => {
    if (!layout?.sideBarRight) {
      // remove bet now mode, continue as usual.
      BetManager.removeBetNow();
    }
  }, [layout?.sideBarRight]);

  return (
    <div className={`add_to_bet__btn ${addDisabled ? 'add_to_bet__btn--disabled' : ''}`}>
      <div className='clear_section'>
        <button
          type='button'
          className='btn clear'
          disabled={clearDisabled}
          onClick={clearSelection}
        >
          <span className='icon AkrIcon-trash' />
          {' '}
          <span>{t('Clear')}</span>
        </button>
      </div>

      <button type='button' className='btn add' onClick={(e) => addToBet(e)} disabled={addDisabled}>
        {layout.mobileDevice ? t('add_selections_to_betslip') : t('add_to_betslip')}
      </button>

      {betNow ? (
        <button
          type='button'
          className='btn add bet-now'
          onClick={(e) => betNow(e)}
          disabled={addDisabled}
        >
          {t('bet now')}
        </button>
      ) : null}
    </div>
  );
};
export default SelectedOddsButtons;
