import React, { useMemo } from 'react';
import { isNumber } from 'lodash';
import { isMultilegsMarket } from '@apollo/core';
import { SPORT_SERVICE } from '@apollo/core/src/constants';
import { getRaceNumbersFromOutcomeInfo } from '../../../core/utils';

const BetCouponTeams = ({ outcome }) => {
  const {
    homeScore,
    awayScore,
    // todo: Order Bets do not have this
    outcomeInfo = {},
  } = outcome;

  const {
    eventName,
    localizedEventName,
    sportService = SPORT_SERVICE.PREMATCH,
    marketTypeId,
  } = outcomeInfo;

  /* const time = useMemo(() => {
    if (!eventStartTime) {
      return '';
    }

    const formatterStartDate = DateService.getFormattedDate(eventStartTime);
    const formatterStartTime = DateService.getFormattedTime(eventStartTime);

    return `${formatterStartDate} ${formatterStartTime}`;
  }, [eventStartTime]); */

  const eventNameStr = useMemo(() => {
    if (isMultilegsMarket(marketTypeId)) {
      const raceNumbers = getRaceNumbersFromOutcomeInfo(outcome.outcomeInfo) || [];
      return `${localizedEventName.split(' R')[0]} (${raceNumbers.join(', ')})`;
    }
    return localizedEventName || eventName;
  }, [outcome]);

  return (
    <div className='coupon__teams'>
      {sportService === SPORT_SERVICE.LIVE && <span className='isLive' />}

      {isNumber(homeScore) && isNumber(awayScore) && (
        <span className='score'>
          {' '}
          {homeScore}
          :
          {awayScore}
          {' '}
        </span>
      )}
      {/* {
        (sportService !== SPORT_SERVICE.LIVE) && time && (
          <span className='time'>
            {time}
          </span>
        )
      } */}

      <span className='teams'>{eventNameStr}</span>
    </div>
  );
};

export default BetCouponTeams;
