import React from 'react';
import cx from 'classnames';
import { RACE_STATUS } from '../../../core/constants';

export const RacingStatus = ({ status }) => {
    const className = cx(
        'race-status',
        `race-status--${status}`
    );

    return (
        <div className={className}>
            {status === RACE_STATUS.ABANDONED ? RACE_STATUS.ABANDONED.slice(0, 5) : status}
        </div>
    );
};
