import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

export const AnimatedDropdown = ({
  isExpanded,
  className,
  children,
  onClick,
  duration = 0.3,
  endOverflow = 'initial',
}) => (
  <AnimatePresence>
    {isExpanded && (
      <motion.div
        className={className}
        initial={{ height: 0, opacity: 0, overflow: 'hidden' }}
        animate={{ height: 'auto', opacity: 1, transitionEnd: { overflow: endOverflow } }}
        exit={{ height: 0, opacity: 0, overflow: 'hidden' }}
        transition={{ duration }}
        onClick={onClick}
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);
