import React from 'react';
import { isUrlNamesMatch } from '@apollo/routing';
import useIsFavoritePage from '../useIsFavoritePage';
import { SportLink } from '../../../shared/components/Sport/SportLinks';

const SportFilterOption = ({ option, value }) => {
  const sport = option;
  // const isLivePage = useIsLivePage();
  const isFavoritePage = useIsFavoritePage();

  return (
    <SportLink
      className='sport-filter-option sport-filter-option--default'
      active={!isFavoritePage && isUrlNamesMatch(sport.urlName, value)}
      sportUrlName={sport.urlName}
    >
      {/* <div className='sport-filter-option__container sport-filter-option__container--icon'>
        <i className={`sportIcon-${sport.id} sport-filter-option__icon`} />
        <div className='sport-filter-option__counter'>
          {sport.liveCount + (isLivePage ? 0 : sport.prematchCount)}
        </div>
      </div> */}
      <div className='sport-filter-option__container sport-filter-option__container--name'>
        <div className='sport-filter-option__name'>{sport.name}</div>
      </div>
    </SportLink>
  );
};

export default SportFilterOption;
