import React, { useCallback, useState } from 'react';
import _, { map } from 'lodash';
import { centsToDecimal, DateService } from '@apollo/core';
import MyOrderOutcomeInfo from './MyOrderOutcomeInfo';
import { betTypes } from '../../../../core/constants';
import Label from '../../../../shared/components/I18n/Label';
import Odds from '../../../../shared/components/Odds';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import { getCurrencySymbol } from '../../../../core/utils';

const amountOfOutcomesToHide = 3;

const MyOrdersListItem = ({ bet }) => {
  const { id, createTime, betAmount, outcomes, minDesiredOdds, betCurrency, status } = bet;
  const betType = outcomes.length === 1 ? betTypes.SINGLE : betTypes.MULTIPLE;
  const [isExpanded, setIsExpanded] = useState(outcomes.length < amountOfOutcomesToHide);

  const t = useTranslate();

  const date = DateService.getFormattedDateWithTime(createTime);

  const currencySymbol = getCurrencySymbol(betCurrency);
  const decimalWinAmount = `${currencySymbol}${centsToDecimal(betAmount * minDesiredOdds)}`;

  const renderMultipleAmount = useCallback(
    (t) => <span>{`${t} ${outcomes.length}`}</span>,
    [outcomes.length]
  );

  return (
    <div className='bet-history-multiple UNSETTLED'>
      <div className='history-table-row'>
        <div className='bet-history-row'>
          <div className='history-table-cell date'>
            <span className='date-id'>{date}</span>
            <span className='sub-title'>#{id}</span>
          </div>
          <div className='history-table-cell bet-type-title'>
            {betType === betTypes.MULTIPLE && (
              <Label message='Multiple of' render={renderMultipleAmount} />
            )}
            {betType === betTypes.SINGLE && <Label message={betTypes.SINGLE} />}
          </div>
          <div className='history-table-cell'>
            <span className='odds'>
              <Odds value={minDesiredOdds} />
            </span>
          </div>
          <div className='history-table-cell'>
            <span className='bet'>{`${currencySymbol}${centsToDecimal(betAmount)}`}</span>
          </div>
          <div className='history-table-cell to-return'>
            <span className='bet'>{decimalWinAmount}</span>
          </div>
          <div className='history-table-cell status-label'>
            <Label message={status} />
          </div>
        </div>
      </div>
      <div className='history-table-row header-multiple'>
        <div className='bet-history-row'>
          <div className='history-table-cell'>
            <Label message='event id' />
          </div>
          <div className='history-table-cell'>
            <Label message='event' />
          </div>
          <div className='history-table-cell'>
            <Label message='current odds' />
          </div>
          <div className='history-table-cell'>
            <Label message='selection' />
          </div>
        </div>
      </div>
      <div className='history-table-body-multiple'>
        {map(isExpanded ? outcomes : _.slice(outcomes, 0, amountOfOutcomesToHide), (outcome) => (
          <MyOrderOutcomeInfo outcome={outcome} />
        ))}

        {!isExpanded && outcomes.length > amountOfOutcomesToHide && (
          <div className='history-table-row load-more-events'>
            <span onClick={() => setIsExpanded(true)}>
              {`${outcomes.length - amountOfOutcomesToHide} `}
              {t('more_events')}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyOrdersListItem;
