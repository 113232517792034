import React from 'react';
import Loader from '../Loader';
import Label from '../I18n/Label';
import StaticBlock from '../StaticBlock/StaticBlock';
import { cmsStaticBlock } from '../../../core/constants';

const LayoutErrorFallback = (props) => {
  const { error } = props;
  return (
    <div className='global-loader global-loader--blocked'>
      <Loader iconName='warning' />
      <div className='content'>
        <h1 className='content__title'>Oops, something went wrong</h1>
        <div className='content__body'>
          {error && <h3 className='content__body-title'>{`Error: ${error?.message}`}</h3>}
          <StaticBlock options={{ source: cmsStaticBlock.ExceptionScreen }} />
        </div>
      </div>
    </div>
  );
};

export default LayoutErrorFallback;
