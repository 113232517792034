import React, { useMemo } from 'react';
import { useSettings } from '@apollo/core';
import cx from 'classnames';

const MainBody = ({ className, children }) => {
  const { buildMode, isCordova, platform } = useSettings();
  const cordovaDev = useMemo(
    () => isCordova && buildMode && buildMode.toLowerCase() !== 'production',
    [isCordova, buildMode],
  );

  const classes = useMemo(
    () => cx([
      'main__body',
      className,
      isCordova && 'main__body--cordova',
      cordovaDev && 'main__body--cordova-dev',
      platform && `header--platform-${platform}`,
    ]),
    [className, isCordova, buildMode, platform],
  );

  return (
    <div className={classes}>
      <div className='main__body__content'>
        {children}
      </div>
    </div>
  );
};

export default MainBody;
