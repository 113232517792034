import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { CompetitionApi, DateService, ApiManager } from '@apollo/core';
import HeaderTitle from '../../shared/components/HeaderTitle/HeaderTitle';
import Label from '../../shared/components/I18n/Label';
import CompetitionTable from './CompetitionTable';
import { buildCdnUrl, LocalConfig } from '../../core/utils';

const Competition = ({ competition }) => {
  const [leaderboardList, setLeaderboardList] = useState([]);

  const [
    status,
    name,
    coverImageLink,
    fullDescription,
    shortDescription,
    startTime,
    endTime,
  ] = _.at(competition, [
    'status',
    'description.name',
    'description.coverImageLink',
    'description.fullDescription',
    'description.shortDescription',
    'timeRange.startTime',
    'timeRange.endTime',
  ]);

  useEffect(() => {
    const fetch = async () => {
      const { leaderboard } = await CompetitionApi.fetchCompetition(competition.id);
      setLeaderboardList(leaderboard);
    };

    fetch();
  }, [competition.id]);

  const imgSrc = buildCdnUrl(coverImageLink);

  return (
    <div className='tournaments__detail'>
      <div className='tournaments-box'>
        <HeaderTitle size='xs' title={name} />
        <img className='tournaments-box__banner' src={imgSrc} />
        <div className='tournaments-box__text'>{shortDescription}</div>
      </div>

      <div className='tournaments-box tournaments-box_sticky'>
        <div className='tournaments-box__inner'>
          <div className='tournaments-box-date'>
            <div className='tournaments-box-date__text'>
              <Label message='start' />
              :
            </div>
            <div className='tournaments-box-date__amount'>{DateService.getFormattedDate(startTime)}</div>
          </div>

          <div className='tournaments-box-date'>
            <div className='tournaments-box-date__text'>
              <Label message='the_end' />
              :
            </div>
            <div className='tournaments-box-date__amount'>{DateService.getFormattedDate(endTime)}</div>
          </div>
        </div>
      </div>

      <div className='tournaments-box'>
        <div className='tournaments-box__text'>{fullDescription}</div>
      </div>

      <CompetitionTable data={leaderboardList} />
    </div>
  );
};

export default Competition;
