import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';
import { Bus, ResponsibleApi, SiteConfigManager, useApplicationState } from '@apollo/core';
import RenderMainStateRow from '../RenderMainStateRow';
import LimitsEdit from './LimitsEdit';
import useTranslate from '../../../../../shared/components/I18n/Interpreter';
import {
  onSetDepositLimitsSubmit,
  onSetLostLimitsSubmit,
} from '../../../../../shared/components/Form/formConfig/responsibleGaming';
import { getCurrencySymbol } from '../../../../../core/utils';
import Label from '../../../../../shared/components/I18n/Label';

const Limits = ({ type }) => {
  const t = useTranslate();
  const {
    layout,
    account: { currency },
  } = useApplicationState();
  const [regulationTypeState, setRegulationTypeState] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);

  const limitsConfig = SiteConfigManager.getConfig('config.config.depositLimits');

  const isDeposit = useMemo(() => type === 'DEPOSIT', [type]);

  const currencySymbol = useMemo(() => getCurrencySymbol(currency), [currency]);

  const toggleEditMode = useCallback(() => {
    setIsEditMode(!isEditMode);

    if (isEditMode) {
      Bus.send({
        event: Bus.events.layout.setLayout, // TODO: add new event to hide keyPadMoneyInput only
        data: {
          ...layout,
          keyPadMoneyInput: undefined,
        },
      });
    }
  }, [isEditMode]);

  const limitPeriods = useMemo(() => {
    if (limitsConfig?.periods) {
      return limitsConfig.periods.split(',').map((s) => s.trim());
    }
    return ['DAY', 'WEEK', 'MONTH'];
  }, [limitsConfig]);

  const limitMinAmount = useMemo(() => {
    if (limitsConfig?.min) {
      return limitsConfig.min;
    }
    return 1000; // $10.00
  }, [limitsConfig]);

  const activeRegulationState = useMemo(
    () => (regulationTypeState || []).find((s) => s.value),
    [regulationTypeState],
  );

  const onSubmit = useCallback(
    (formData, actions) => (isDeposit ? onSetDepositLimitsSubmit : onSetLostLimitsSubmit)(formData, actions, type),
    [isDeposit, type],
  );

  const updateLimitsCallback = useCallback(
    (data) => {
      const availableLimits = (data || []).filter((l) => {
        if (l.value) {
          return true;
        }
        if (!isEmpty(limitPeriods) && limitPeriods.includes(l.type)) {
          return true;
        }
        return false;
      });
      setRegulationTypeState(availableLimits);
      return availableLimits;
    },
    [limitPeriods],
  );

  useEffect(() => {
    ResponsibleApi[isDeposit ? 'getClientDepositLimits' : 'getClientLossLimits']({ type }).then(
      (data) => {
        updateLimitsCallback(data);
      },
    );
  }, [isDeposit, type, updateLimitsCallback, isEditMode]);

  if (!regulationTypeState) return null;

  return (
    <div className='profile-limits'>
      {isEditMode ? (
        <div className='profile-limits__edit'>
          <LimitsEdit
            currencySymbol={currencySymbol}
            regulationTypeState={regulationTypeState}
            minDepositAmount={limitMinAmount}
            activeRegulationState={activeRegulationState}
            updateLimitsCallback={updateLimitsCallback}
            toggleEdit={toggleEditMode}
            label={`${type.toLowerCase()}_limits`}
            action={onSubmit}
          />
        </div>
      ) : (
        <div className='profile-limits__readonly'>
          <div className='title'>
            <span className='limit-title'>{t(`${type.toLowerCase()}_limits`)}</span>
            <a className='editLink' onClick={toggleEditMode}>
              <span className='icon AkIcon-pencil-icon' />
              {t('edit_link')}
            </a>
          </div>
          <div className='form'>
            {!activeRegulationState && (
              <h4 className='profile-limits__no-limits'>
                <Label message='no_limits_set' />
              </h4>
            )}

            {/* activeRegulationState
              && (
              <RenderMainStateRow
                type={activeRegulationState.type}
                value={`${currencySymbol}${centsToDecimal(String(activeRegulationState.value || 0))}`}
                future={activeRegulationState.future}
                currencyIcon={currencySymbol}
              />
              ) */}

            {map(regulationTypeState, (limit) => {
              if (!limit.value) {
                return null;
              }

              return (
                <RenderMainStateRow
                  key={limit.type}
                  limit={limit}
                  currencySymbol={currencySymbol}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

Limits.defaultProps = {};

Limits.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Limits;
