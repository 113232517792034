import React from 'react';
import { isUndefined } from 'lodash';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import SelectField from '../../../../shared/components/Form/Fields/SelectField';

const DebitCardPaymentForm = ({
  selectedCredentialsIndex,
  savedCredentials,
  selectSavedCredentialsHandler,
  deleteSavedCredentialsHandler,
}) => {
  const t = useTranslate();

  return (
    <div className='row payments-list__debit-card'>
      <SelectField
        field={{
          name: 'stored_accounts',
          label: 'stored_accounts',
          placeholder: 'stored_accounts',
          value: selectedCredentialsIndex,
          translateOptions: false,
          options: [
            {
              text: t('create_new'),
              value: undefined,
            },
            ...savedCredentials.map((withdrawal, index) => ({
              text: withdrawal.data?.accountName,
              value: index,
            })),
          ],
        }}
        onChange={(e) => selectSavedCredentialsHandler(e)}
      />
      {!isUndefined(selectedCredentialsIndex) ? (
        <div className='block__btn'>
          <button
            className='btn btn-link'
            type='button'
            onClick={() => deleteSavedCredentialsHandler({
              index: selectedCredentialsIndex,
            })}
          >
            <span className='icon AkrIcon-trash' />
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default DebitCardPaymentForm;
