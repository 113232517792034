import React from 'react';
import useDropdown from '../../../../../shared/components/Dropdown/useDropdown';

const useMarketExpandedDropdown = (onClose) => {
  const dropdownRef = React.useRef();
  const [dropdownClassName, setDropdownClassName] = React.useState('market-expanded-dropdown');

  // Handling case when dropdown is higher then tournament header
  React.useLayoutEffect(() => {
    const tournamentEl = dropdownRef.current.closest('.tournament-classic');
    const tournamentHeaderEl = tournamentEl
      ? tournamentEl.querySelector('.tournament-header')
      : null;

    if (!tournamentHeaderEl) {
      return;
    }

    const tournamentHeaderBottom = tournamentHeaderEl.getBoundingClientRect().bottom;
    const dropdownTop = dropdownRef.current.getBoundingClientRect().top;

    if (dropdownTop < tournamentHeaderBottom) {
      setDropdownClassName('market-expanded-dropdown bottom');
    }
  }, []);

  useDropdown(dropdownRef, onClose);

  return {
    dropdownRef,
    dropdownClassName,
  };
};

export default useMarketExpandedDropdown;
