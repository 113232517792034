import React from 'react';
import {
  useCategoryState,
} from '@apollo/core';
import Loader from '../../shared/components/Loader';

export default function ResultsCategoryLoader({ children }) {
  const {
    loading,
  } = useCategoryState();

  if (loading) {
    return (
      <Loader className='empty-state' />
    );
  }

  return children;
}
