import React from 'react';

import { centsToDecimal } from '@apollo/core';
import Label from '../../../shared/components/I18n/Label';

const PossibleWinSection = (props) => {
  const { possibleWin, currencySymbol, isTbd } = props;

  if (!possibleWin) {
    return null;
  }

  // const possibleWin = (betAmount * selectedBet.odds).toFixed(2);

  return (
    <div className='column rightSide possible-win'>
      <Label className='possible-win__title' message='possible_win' />
      <span className='possible-win__amount'>
        {isTbd ? (
          <Label message='tbd' />
        ) : (
          <>
            {currencySymbol}
            {centsToDecimal(possibleWin)}
          </>
        )}
      </span>
    </div>
  );
};

export default PossibleWinSection;
