import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import {
  SiteConfigManager,
  SportManager,
  TournamentProvider,
  useApplicationState,
  usePreferences,
  useSportsbookState,
} from '@apollo/core';

import { COMPONENT_TYPES } from '@apollo/routing';
import { sportService } from '../../../core/constants';
import Tournament from '../Tournament/Tournament';
import Label from '../../../shared/components/I18n/Label';
import { extractSportMarketTypes } from '../../../core/utils';

const { PREMATCH, LIVE } = sportService;
const { SPORT_PREMATCH } = COMPONENT_TYPES;

const FavoriteTournament = ({ tournament }) => {
  const [sportsState] = useSportsbookState();
  const { sports } = sportsState;

  const { sportId } = tournament;
  const mainMarketTypesByPeriod = extractSportMarketTypes(sports, sportId);

  return (
    <TournamentProvider tournament={tournament}>
      <Tournament mainMarketTypesByPeriod={mainMarketTypesByPeriod} isFavoriteList />
    </TournamentProvider>
  );
};

const SportsbookFavorites = () => {
  const prematchPath = SiteConfigManager.getComponentPath(SPORT_PREMATCH);
  const applicationState = useApplicationState();
  const authenticated = _.get(applicationState, 'authenticated');
  const resolved = _.get(applicationState, 'resolved');
  const { favoriteEvents } = usePreferences();
  const [tournaments, setTournaments] = useState({});

  useEffect(() => {
    if (_.isEmpty(favoriteEvents)) return;

    SportManager.findEvents({
      filter: {
        sportServices: [LIVE, PREMATCH],
        eventIds: favoriteEvents,
      },
    }).then(({ data: tournaments }) => {
      const processedTournaments = _.chain(tournaments)
        .map((tournament) => {
          const { events } = tournament;
          const arrEvents = _.sortBy(events, ['position']);
          // Adding missing tournament info
          const id = _.get(arrEvents, '0.tournamentId');
          const sportId = _.get(arrEvents, '0.sportId');
          const categoryId = _.get(arrEvents, '0.categoryId');
          const name = _.get(arrEvents, '0.tournamentName');
          const categoryName = _.get(arrEvents, '0.categoryName');
          const sportName = _.get(arrEvents, '0.sportName');

          return {
            events: arrEvents,
            id,
            categoryId,
            sportId,
            name,
            categoryName,
            sportName,
          };
        })
        .sortBy(['sportId', 'categoryId'])
        .value();

      setTournaments(processedTournaments);
    });
  }, [favoriteEvents]);

  if (!resolved) {
    return null;
  }

  if (resolved && !authenticated) {
    return <Redirect from='/' to={prematchPath} />;
  }

  if (_.isEmpty(tournaments) === true) {
    return (
      <div className='tournament tournament--favourites'>
        <div className='event-group event-group--favourites'>
          <div className='event-group__header'>
            <div className='event-group__title'>
              <Label message='You have no favourite events' />
            </div>
          </div>
          <div className='event-group__subtitle'>
            <Label message='To add a match to your favorites, click on the star icon' />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {_.map(tournaments, (tournament) => (
        <FavoriteTournament key={tournament.id} tournament={tournament} />
      ))}
    </>
  );
};

export default SportsbookFavorites;
