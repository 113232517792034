import React from 'react';
import cx from 'classnames';
import { useOddsObserver } from '@apollo/core';
import { BONUS_TYPE } from '@apollo/core/src/constants';
import Odds from '../../../shared/components/Odds/Odds';
import { SPECIAL_MARKETS } from '../../../core/constants';
import useTranslate from '../../../shared/components/I18n/Interpreter';

const BetOdds = ({ odds, outcomeId, market, oddsToDisplay, assignedBonus }) => {
  const initialOdds = useOddsObserver(outcomeId);

  const isBoosted = React.useMemo(
    () => [BONUS_TYPE.ODDS_BOOST, BONUS_TYPE.PROMO_ODDS].includes(assignedBonus?.bonusType),
    [assignedBonus],
  );

  const oddsClassName = cx('odd', {
    up: initialOdds < odds,
    down: initialOdds > odds,
    boosted: isBoosted,
  });
  const t = useTranslate();

  /*  console.log('BetOdds', {
    initialOdds,
    odds,
    outcomeId,
    market,
    oddsToDisplay,
  }); */

  const betOddsView = React.useMemo(() => {
    if (isBoosted) {
      const { betOdds, boostedBetToteOdds, boostedBetOdds } = assignedBonus?.props || {};

      const boostedOdds = boostedBetToteOdds || boostedBetOdds;
      const percentage = betOdds && boostedOdds ? Math.round(((boostedOdds - betOdds) / betOdds) * 100) : 0;

      return (
        <>
          <div className='odd__default'>
            <i
              className={`icons icon--${BONUS_TYPE.ODDS_BOOST.toLowerCase()}`}
              title={t(BONUS_TYPE.ODDS_BOOST)}
            />
            <span>
              {SPECIAL_MARKETS[market?.type] ? (
                percentage ? (
                  <>
                    &nbsp;
                    {`+${percentage}%`}
                  </>
                ) : (
                  t('TBD')
                )
              ) : (
                <Odds value={oddsToDisplay} decimal={3} minDecimal={2} />
              )}
            </span>
          </div>
          <div className='odd__changed'>
            <span>
              {SPECIAL_MARKETS[market?.type] ? (
                t('TBD')
              ) : initialOdds && odds && initialOdds !== odds ? (
                <Odds value={initialOdds} decimal={3} minDecimal={2} />
              ) : (
                <Odds value={odds} decimal={3} minDecimal={2} />
              )}
            </span>
          </div>
        </>
      );
    }

    if (initialOdds && odds && initialOdds !== odds) {
      return (
        <>
          <div className='odd__changed'>
            <span className='odd__changed__icon' />
            <span>
              {SPECIAL_MARKETS[market?.type] ? (
                t('TBD')
              ) : (
                <Odds value={odds} decimal={3} minDecimal={2} />
              )}
            </span>
          </div>
          <div className='odd__default'>
            <span>
              {SPECIAL_MARKETS[market?.type] ? (
                t('TBD')
              ) : (
                <Odds value={initialOdds} decimal={3} minDecimal={2} />
              )}
            </span>
          </div>
        </>
      );
    }

    return (
      <div className='odd__default'>
        <span>
          {SPECIAL_MARKETS[market?.type] ? (
            t('TBD')
          ) : (
            <Odds value={oddsToDisplay} decimal={3} minDecimal={2} />
          )}
        </span>
      </div>
    );
  }, [isBoosted, initialOdds, odds, market?.type, oddsToDisplay, assignedBonus?.props]);

  // If initial and current odds are not the same - display in diff view
  return <div className={oddsClassName}>{betOddsView}</div>;
};

// BetOdds.propTypes = {
//   odds: PropTypes.number.isRequired,
//   outcomeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
// };

export default BetOdds;
