import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { at, isEmpty, map } from 'lodash';
import { BonusApi, centsToDecimal, NotificationManager, useApplicationState } from '@apollo/core';
import { BONUS_TYPE, NOTIFICATION_TYPE } from '@apollo/core/src/constants';
import { useBetslipState } from '@apollo/core/src/state/bet/betslip.context';
import Theme from 'themeSource/custom';
import Label from '../../components/I18n/Label';
import { assignableBonuses } from '../../../core/constants';
import useTranslate from '../../components/I18n/Interpreter';
import { getCurrencySymbol } from '../../../core/utils';
import HeaderLogoItem from '../../components/Header/HeaderMenu/HeaderLogoItem';
import Loader from '../../components/Loader/Loader';
import { BonusSelector } from '../../../state/Bonus/Bonus';

export const SplitBonus = ({ bonus, currencySymbol, notificationId }) => {
  const [splitOptions, setSplitOptions] = useState([]);
  useEffect(() => {
    BonusApi.splitFreeBetOptions({ freeBetId: bonus.id }).then((response) => {
      // console.log('FreeBet Split Options Response', response);
      setSplitOptions(response?.freeBetOptions || []);
    });
  }, [bonus]);

  const onSplitSubmit = async (options) => {
    // console.log('FreeBet Split submit', options, bonus);

    await BonusApi.splitFreeBet({
      freeBetId: bonus.id,
      splitId: options.id || 0,
    }).then((response) => {
      // console.log('FreeBet Split Response', response);
      NotificationManager.removeNotification(notificationId);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      document.querySelector('.main__page').scrollTo({ top: 0, behavior: 'smooth' });
    });
  };

  return (
    <div className='bonus-split-container'>
      {splitOptions.length > 0 ? (
        splitOptions.map((option, index) => (
          <div key={index} className='bonus-split-option'>
            <div className='bonus-split-option__amount'>
              {map(option.splits, (quantity, amount) => (
                <div key={`${amount}_${quantity}`}>
                  <span className='bonus-quotient'>
                    <span className='bonus-quotient__value'>{quantity}</span>
                    <span className='bonus-quotient__multiplier'>{'x '}</span>
                  </span>
                  {currencySymbol}
                  {centsToDecimal(amount)}
                </div>
              ))}
            </div>
            <div className='bonus-split-option__btns'>
              <button
                className='btn btn-primary-outline'
                type='button'
                onClick={() => onSplitSubmit(option)}
              >
                <Label message='split' />
              </button>
            </div>
          </div>
        ))
      ) : (
        <Loader className='empty-state' />
      )}
    </div>
  );
};

export const splitHandler = (bonus, currencySymbol) => {
  const notificationId = 'splitBonusNotification';
  const isHiddenCancelButton = Theme.HIDE_BONUS_NOTIFICATION_CANCEL_BUTTON || false;

  NotificationManager.display({
    id: notificationId,
    type: NOTIFICATION_TYPE.MESSAGE,
    title: 'confirm_split',
    data: {
      withOkButton: false,
      withCancelButton: !isHiddenCancelButton,
      className: 'bonus-split',
      children: (
        <div className='free-bet-split'>
          <p className='bonus-split-info'>
            <Label message='split-info' />
          </p>
          <div className='bonus-split-option-list'>
            <SplitBonus
              bonus={bonus}
              currencySymbol={currencySymbol}
              notificationId={notificationId}
            />
          </div>
        </div>
      ),
    },
  });
};

const BonusModal = ({
  betBonuses,
  onClose,
  onUse,
  bonusTypes = [BONUS_TYPE.FREEBET, BONUS_TYPE.BET_RETURN, BONUS_TYPE.ODDS_BOOST],
}) => {
  const t = useTranslate();
  const [activeTab, setActiveTab] = useState(BONUS_TYPE.FREEBET);

  const applicationState = useApplicationState();
  const [currency] = at(applicationState, ['wallet.currency']);

  const bonuses = BonusSelector.selectAllBonuses();

  const { bonusAssignments } = useBetslipState();

  const isBonusAssigned = useCallback(
    (bonusId) => (bonusAssignments || []).some((ba) => ba.bonusId === bonusId),
    [bonusAssignments],
  );

  const availableBonuses = useMemo(() => {
    if (betBonuses) {
      return betBonuses
        .filter((b) => !isBonusAssigned(b.id))
        .map((bet) => ({ ...bet, amount: bet.freeBetAmount }));
    }
    return bonuses
      .filter(
        (bonus) => assignableBonuses.includes(bonus.bonusType) && bonus.bonusType === activeTab,
      )
      .sort((b1, b2) => (b1.bonusType > b2.bonusType ? 1 : -1));
  }, [bonuses, betBonuses, isBonusAssigned, bonusAssignments, activeTab]);

  const currencySymbol = getCurrencySymbol(currency);

  const confirmationHandler = useCallback(
    (bonus) => {
      splitHandler(bonus, currencySymbol);
    },
    [currencySymbol],
  );

  const renderBonusType = useCallback(
    (bonus) => {
      switch (bonus.bonusType) {
        case BONUS_TYPE.ODDS_BOOST:
          return (
            <div className='bonus-type--boost free-bet-bonus--amount'>
              <i className='icons icon--close-red' />
              {t(bonus.bonusType)}
            </div>
          );

        case BONUS_TYPE.BET_RETURN:
          return (
            <div className='bonus-type--boost free-bet-bonus--amount'>
              <i className='icons icon--close' />
              {t(bonus.bonusType)}
            </div>
          );

        case BONUS_TYPE.FREEBET:
        default:
          return (
            <div className='bonus-type--freebet free-bet-bonus--amount'>
              <i className='icons icon--freebet' />
              {currencySymbol}
              {' '}
              {centsToDecimal(bonus.amount)}
            </div>
          );
      }
    },
    [currencySymbol],
  );

  const tabs = useMemo(
    () => (isEmpty(bonusTypes)
      ? [BONUS_TYPE.FREEBET, BONUS_TYPE.BET_RETURN, BONUS_TYPE.ODDS_BOOST].filter((bonusType) => bonuses.some((bonus) => bonus.bonusType === bonusType))
      : bonusTypes),
    [bonusTypes],
  );

  return (
    <div className='main__page auth-block-modal'>
      <div className='auth-block-modal__top-block'>
        <HeaderLogoItem location='/' />
      </div>

      <div className='auth-block-modal__container'>
        <a className='icon__close__wrapper' onClick={onClose}>
          <i className='icon__close' title={t('close')} />
        </a>
        <div className='b-row free-bet-modal'>
          <div className='auth-block-modal__form b-col'>
            <header className='auth-block-modal__container__header'>
              <h1 className='auth-block-modal__container__header__title'>
                <Label message='Free bets' />
              </h1>
            </header>

            <div className='block'>
              <div className='tabs-list tabs-list--bonuses'>
                {tabs?.length > 1
                  ? tabs.map((tab) => (
                    <button
                      type='button'
                      className={`tab-list-item ${activeTab === tab ? 'active' : ''}`}
                      onClick={() => setActiveTab(tab)}
                    >
                      {t(tab)}
                    </button>
                  ))
                  : null}
              </div>
              {availableBonuses.map((bonus) => (
                <div className='free-bet-bonus--item' key={bonus.id}>
                  {renderBonusType(bonus)}
                  <div>
                    {betBonuses && onUse ? (
                      <button className='btn btn-sm' type='button' onClick={() => onUse(bonus)}>
                        <Label message='Use' />
                      </button>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BonusModal;
