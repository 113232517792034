import React, { Fragment, useMemo } from 'react';
import { SportSelector } from '@apollo/core/src/state/sport/sport';
import Label from '../../../shared/components/I18n/Label';
import SportList from './SportList';
import TournamentsList from '../SportsSidebar/Tournaments/TournamentsList';

const getSportsByLetters = (sports) => {
  if (sports.length === 0) {
    return [];
  }
  return Object.values(
    sports.reduce((acc, sport) => {
      const firstLetter = sport.name ? sport.name[0].toLocaleUpperCase() : '';
      if (!acc[firstLetter]) {
        acc[firstLetter] = { title: firstLetter, totalCount: sport.totalCount, data: [sport] };
      } else {
        acc[firstLetter].data.push(sport);
      }
      return acc;
    }, {}),
  );
};

const AllSports = () => {
  const topTournaments = SportSelector.selectTopTournaments({ limit: 200 });
  const allSports = SportSelector.selectSports();
  const groupSportsByLetters = SiteConfigManager.getConfig(
    'config.config.sportsbook.groupSportsByLetters',
  );

  const sportsByLetters = useMemo(() => {
    if (!groupSportsByLetters) {
      return [];
    }
    return getSportsByLetters(allSports.filter((s) => s.totalCount > 0)).sort((a, b) => a.title.localeCompare(b.title));
  }, [groupSportsByLetters, allSports]);

  return (
    <div className='sport-page__content'>
      <div className='sport-page__content--featured'>
        <h3>
          <Label message='Featured_sports' />
        </h3>
        <div className='sidebar-tree__section'>
          <TournamentsList tournaments={topTournaments} />
        </div>
      </div>
      <div className='sport-page__content--list'>
        <h3>
          <Label message='az' />
        </h3>
        {groupSportsByLetters ? (
          sportsByLetters.map((sport) => (
            <Fragment key={sport.title}>
              <h3 className='sport-page__content--list--title'>{sport.title?.toUpperCase()}</h3>
              <div className='sidebar-tree__section'>
                <SportList isOrderByName sports={sport.data} />
              </div>
            </Fragment>
          ))
        ) : (
          <div className='sidebar-tree__section'>
            <SportList isOrderByName />
          </div>
        )}
      </div>
    </div>
  );
};

export default AllSports;
