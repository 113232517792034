import React, { createContext, useContext, useState } from 'react';

const BroadcastContext = createContext();

const createBroadcastProvider = () => {
  const [broadcast, setBroadcast] = useState({ });

  return {
    broadcast,
    setBroadcast,
  };
};

export const BroadcastProvider = ({ children }) => {
  const contextValue = createBroadcastProvider();

  return (
    <BroadcastContext.Provider value={contextValue}>
      {children}
    </BroadcastContext.Provider>
  );
};

export const useBroadcast = () => (
  useContext(BroadcastContext)
);
