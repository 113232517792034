import React, { createContext, useContext, useCallback, useMemo, useState } from 'react';
import Particles from 'react-tsparticles';
import { loadSlim } from 'tsparticles-slim';
import { loadConfettiPreset } from 'tsparticles-preset-confetti';
import { loadBubblesPreset } from 'tsparticles-preset-bubbles';
import { loadImageShape } from 'tsparticles-shape-image';
import { motion, AnimatePresence } from 'framer-motion';
import Theme from 'themeSource/custom';

const ParticleAnimationContext = createContext();

const ParticleAnimationProvider = ({ children }) => {
  const isParticlesEnabled = Theme?.animations?.particles;
  if (isParticlesEnabled && !Theme.animations?.PARTICLES_CONFIG) {
    throw new Error('Theme is missing PARTICLES_CONFIG');
  }

  const [isVisible, setIsVisible] = useState(false);
  const [animationPrepared, setAnimationPrepared] = useState(false);

  const particlesInit = useCallback(async (engine) => {
    if (Theme.animations?.PARTICLES_CONFIG?.preset === 'confetti') {
      await loadConfettiPreset(engine);
    } else if (Theme.animations?.PARTICLES_CONFIG?.preset === 'bubbles') {
      await loadBubblesPreset(engine);
    } else {
      await loadSlim(engine);
    }

    await loadImageShape(engine);
  }, []);

  const runParticleAnimation = useCallback(
    (duration) => {
      if (!isParticlesEnabled) return;

      setIsVisible(true);
      setAnimationPrepared(false);

      if (duration > 0) {
        setTimeout(() => {
          setIsVisible(false);
        }, duration);
      }
    },
    [isParticlesEnabled],
  );

  const prepareParticleAnimation = useCallback(() => {
    setAnimationPrepared(true);
  }, []);

  const stopParticleAnimation = useCallback(() => setIsVisible(false), []);

  const contextValue = useMemo(
    () => ({
      animationPrepared,
      prepareParticleAnimation,
      runParticleAnimation,
      stopParticleAnimation,
    }),
    [animationPrepared, prepareParticleAnimation, runParticleAnimation, stopParticleAnimation],
  );

  return (
    <ParticleAnimationContext.Provider value={contextValue}>
      {children}

      <AnimatePresence>
        {isParticlesEnabled && isVisible && (
          <motion.div
            key='particles'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            className='particles-wrapper'
          >
            <Particles
              id='tsparticles'
              init={particlesInit}
              options={Theme.animations?.PARTICLES_CONFIG}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </ParticleAnimationContext.Provider>
  );
};

const useParticleAnimation = () => {
  const context = useContext(ParticleAnimationContext);

  if (!context) {
    throw new Error('useParticleAnimation must be used within a ParticleAnimationProvider');
  }

  return context;
};

export { ParticleAnimationProvider, useParticleAnimation };
