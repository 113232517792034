import _ from 'lodash';
import { useApplicationState } from '@apollo/core';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import { getCurrencySymbol } from '../../../core/utils';

const getAmount = (amount, currencySymbol) =>
  `${currencySymbol}${Number(amount / 100).toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })}`;

const usePaymentLimitsTranslate = (payment = {}, type = 'DEPOSIT') => {
  const applicationState = useApplicationState();
  const currency = _.get(applicationState, 'wallet.currency');
  const t = useTranslate();

  const currencySymbol = getCurrencySymbol(currency);

  const { maxAmount, minAmount } = payment;

  const normaliseType = (type || '').toLowerCase();

  if (maxAmount > 0 && maxAmount > 0) {
    return t(`${normaliseType}_payment_limits`, {
      min: getAmount(minAmount, currencySymbol),
      max: getAmount(maxAmount, currencySymbol),
    });
  }

  if (maxAmount === 0) {
    return t(`${normaliseType}_payment_limits_max`, { max: getAmount(maxAmount, currencySymbol) });
  }

  // if (minAmount > 0) {
  //   return t(
  //     `${normaliseType}_payments_limit_min`,
  //     { min: getAmount(minAmount, currencySymbol) },
  //   );
  // }

  return null;
};

export default usePaymentLimitsTranslate;
