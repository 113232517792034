import React from 'react';
import cx from 'classnames';
import { DateService } from '@apollo/core';
import Label from '../../../../shared/components/I18n/Label';
import StatusTooltip from '../../../../shared/components/StatusTooltip/StatusTooltip';

const DOCUMENT_STATUSES_MESSAGES = {
  VERIFIED: 'verified',
  UPLOADED: 'uploaded',
  DECLINED: 'canceled',
};

export default function UploadDocumentItem({ document }) {
  const {
    verificationComment, verificationStatus, documentUploadTime, documentType,
  } = document;

  const documentUploadDate = DateService.getFormattedDate(documentUploadTime);
  const documentUploadHours = DateService.getFormattedTime(documentUploadTime);

  const verifyClassName = cx(
    'verify-row',
    { error: verificationStatus === 'DECLINED' },
  );

  return (
    <div className={verifyClassName}>
      <div className='verify-image'>
        <span className='icon AkIcon-id-icon' />
      </div>
      <div className='label-date'>
        <span>{documentUploadDate}</span>
        <br />
        <span>{documentUploadHours}</span>
      </div>
      <Label className='label-type-doc' message={documentType} />
      <div className='verify-status'>

        <div className='label-with-icon status-label'>
          <Label message={DOCUMENT_STATUSES_MESSAGES[verificationStatus]} />
        </div>
        {
          verificationComment && (
            <StatusTooltip message={verificationComment}><span className='status-label'>?</span></StatusTooltip>
          )
        }
      </div>
    </div>
  );
}
