import React, { useCallback } from 'react';
import { isInactive, isSuspended } from '@apollo/core';
import cx from 'classnames';
import _ from 'lodash';
import { sportService as sportServices } from '../../../../core/constants';
import useSgm from '../../../../state/Sgm/SgmState';
import OutcomeView from '../../../../shared/components/DetailOdds/OutcomeView';
import { isMarketOddsClickable } from '../../../../core/utils';

const SgmOutcome = ({
  className = '',
  event,
  event: { sportService },
  outcome,
  outcome: { id: outcomeId, marketId, name, isFake },
  marketType,
  disabled,
  suspended,
  indexInRow,
}) => {
  const { selectOutcome, selectedOutcomes } = useSgm();

  const isOutcomeSuspended = suspended || isSuspended(outcome);
  const isOutcomeDisabled = disabled || isInactive(outcome);
  const isArchived = sportService === sportServices.ARCHIVED;
  const isDisabledView = !isArchived && (isOutcomeSuspended || isOutcomeDisabled);
  const marketTypeId = _.get(marketType, 'marketTypeId') || _.get(marketType, 'id');
  const displayLayout = _.get(marketType, 'displayLayout', '');

  const onClickHandle = useCallback(() => {
    // Preventing from adding suspended bets
    if (isOutcomeSuspended || isArchived) {
      return;
    }
    selectOutcome({ outcomeId, marketTypeId, marketId });
  }, [outcomeId, selectOutcome, isOutcomeSuspended, isArchived, marketTypeId, marketId]);

  const outcomeClassName = cx(
    className,
    {
      disabled: isDisabledView,
      selected: selectedOutcomes.find((sel) => sel.outcomeId === outcomeId),
    },
    `market--${displayLayout ? displayLayout?.toLowerCase() : 'default'}`
  );

  if (isFake) {
    return (
      <span
        className={`market bold bet center name isFake ${className} ${
          isDisabledView ? 'disabled' : ''
        }
        ${isMarketOddsClickable(displayLayout) ? 'market--odd-box' : ''}`}
      >
        {name}
      </span>
    );
  }

  if (isOutcomeDisabled && !isOutcomeSuspended && !isArchived) {
    return null;
  }

  return (
    <OutcomeView
      event={event}
      className={outcomeClassName}
      outcome={outcome}
      marketType={marketType}
      onClick={onClickHandle}
      indexInRow={indexInRow}
    />
  );
};

export default SgmOutcome;
