import React from 'react';
import { at, isEmpty } from 'lodash';
import { isHidden, isInactive, isSuspended } from '@apollo/core';
import cx from 'classnames';
import useOutrightMarket from './useOutrightMarket';
import Label from '../../../../shared/components/I18n/Label';
import OutrightMarkets from './OutrightMarkets';
import EventStartTime from '../Event/EventStartTime';
import { EVENT_LIST_TEMPLATE } from '../../../../core/utils';
import SportsbookFilter from '../../SportsbookFilter/SportsbookFilter';
import SportsbookFilterOption from '../../SportsbookFilter/SportsbookFilterOption';

const OutrightEvent = ({
  event,
  order = 0,
  // suspended,
  disabled,
  className,
  template,
}) => {
  // Legacy event state
  // const event = useEventState();

  const isEventSuspended = isSuspended(event);
  const isEventDisabled = disabled || isInactive(event);
  const isEventHidden = isHidden(event);
  const [name] = at(event, ['name', 'sportName', 'startTime']);

  const { markets, activeMarket, setActiveMarketId, marketOptions } = useOutrightMarket(event);

  if (isEventHidden || !activeMarket || isEmpty(markets)) {
    return null;
  }

  return (
    <>
      <SportsbookFilter
        options={marketOptions}
        onChange={setActiveMarketId}
        value={activeMarket.id}
        className='sports-filter--outrights outright__filters'
        ItemComponent={SportsbookFilterOption}
      />
      <div className={cx('outright event--outright', className)} style={{ order }}>
        <div className='outright__header'>
          <div className='outright__info'>
            <div className='outright__name'>
              <span title={name}>{name}</span>
            </div>

            {template === EVENT_LIST_TEMPLATE.OUTRIGHT && <EventStartTime event={event} withDate />}

            <div className='outright__title--wrapper'>
              <div className='outright__title'>
                <Label message='all-in betting' />
              </div>
              <div className='outright__title'>
                <Label message='Outright' />
              </div>
            </div>
          </div>
        </div>
        <OutrightMarkets
          event={event}
          activeMarket={activeMarket}
          suspended={isEventSuspended}
          disabled={isEventDisabled}
        />
      </div>
    </>
  );
};

export default OutrightEvent;
