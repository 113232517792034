import {
  logger,
} from '@apollo/core';

import Fingerprint2 from 'fingerprintjs2';

const FP_OPTIONS = {
  preprocessor: null,
  audio: {
    timeout: 1000,
    // On iOS 11, audio context can only be used in response to user interaction.
    // We require users to explicitly enable audio fingerprinting on iOS 11.
    // See https://stackoverflow.com/questions/46363048/onaudioprocess-not-called-on-ios11#46534088
    excludeIOS11: true,
  },
  fonts: {
    swfContainerId: 'fingerprintjs2',
    swfPath: 'flash/compiled/FontList.swf',
    userDefinedFonts: [],
    extendedJsFonts: false,
  },
  screen: {
    // To ensure consistent fingerprints when users rotate their mobile devices
    detectScreenOrientation: true,
  },
  plugins: {
    sortPluginsFor: [/palemoon/i],
    excludeIE: false,
  },
  extraComponents: [],
  excludes: {
    // Unreliable on Windows, see https://github.com/Valve/fingerprintjs2/issues/375
    enumerateDevices: true,
    // devicePixelRatio depends on browser zoom, and it's impossible to detect browser zoom
    pixelRatio: true,
    // DNT depends on incognito mode for some browsers (Chrome) and it's impossible to detect incognito mode
    doNotTrack: true,
    // uses js fonts already
    fontsFlash: true,
    webgl: true,
    canvas: true,
  },
  NOT_AVAILABLE: 'not available',
  ERROR: 'error',
  EXCLUDED: 'excluded',
};

export function resolveFingerprint() {
  return new Promise((resolve, reject) => {
    function delayedCallback() {
      try {
        Fingerprint2.getPromise(FP_OPTIONS)
          .then((components) => {
            logger.debug('[fingerprint]', 'resolved', components); // an array of components: {key: ..., value: ...}
            resolve(components);
          });
      } catch (e) {
        reject(e);
      }
    }

    if (window.requestIdleCallback) {
      window.requestIdleCallback(delayedCallback);
    } else {
      setTimeout(delayedCallback, 500);
    }
  });
}
