import React from 'react';
import _ from 'lodash';
import { SiteConfigManager } from '@apollo/core';
import Label from '../../I18n/Label';

const ICON_OVERRIDE = {
  visa: '/static/assets/images/partners/visa.png',
  mastercard: '/static/assets/images/partners/mastercard.png',
};

const FooterPayment = () => {
  const methods = SiteConfigManager.getConfig('config.config.payment.method_list', false);
  const filteredMethods = _.filter(methods, { visible: true });

  if (filteredMethods.length === 0) {
    return null;
  }

  return (
    <div className='footer-block footer-block--icons'>
      <Label message='footer_payments' />

      <div className='icons-block'>
        {_.map(filteredMethods, ({ type, icon }) => (
          <div key={type} className='payments__logo' style={{ backgroundImage: `url(${ICON_OVERRIDE[type] || icon})` }} />
        ))}
      </div>
    </div>
  );
};

export default FooterPayment;
