import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { staticRoutes } from '@apollo/routing';
import Theme from 'themeSource/custom';
import Sidebar from './Sidebar';
import ClientProfileSidebar from '../../../features/ClientProfile/ClientProfileSidebar';

const SidebarRouting = React.memo(() => (
  <Switch>
    <Route path={staticRoutes.clientProfile}>
      <ClientProfileSidebar />
    </Route>
    <Route>
      <Sidebar />
    </Route>
  </Switch>
));

const SidebarContent = Theme.SidebarLeftRouting ?? SidebarRouting;

const SidebarLeftContainer = () => (
  <div className='main__sidebar main__sidebar--left sidebar'>
    <div className='main-sidebar__container'>
      <SidebarContent />
    </div>
  </div>
);

export default SidebarLeftContainer;
