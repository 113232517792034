import React, { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

const Scrollbar = ({
  children,
  scrollClassName,
  className,
  scrollable = true,
  draggable = true,
  navigationButtons = false,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const scrollbarsRef = useRef(null);
  const nextRaceListRef = useRef(null);
  const intervalRef = useRef(null);
  const mouseMouseDownXRef = useRef(null);
  const startScrollXRef = useRef(null);

  const getVisibleScrollWidth = () => nextRaceListRef.current.offsetWidth;

  const handleScroll = (delta) => {
    // scroll with navigation buttons or mouse Wheel
    if (scrollbarsRef.current && !intervalRef.current) {
      const visibleScrollWidth = getVisibleScrollWidth();
      const startScrollLeft = scrollbarsRef.current.getScrollLeft();
      let targetScrollLeft = startScrollLeft + delta;

      if (
        (delta < 0 && startScrollLeft === 0)
        || (delta > 0
          && startScrollLeft === scrollbarsRef.current.getScrollWidth() - visibleScrollWidth)
      ) {
        return;
      }

      targetScrollLeft = Math.max(
        0,
        Math.min(targetScrollLeft, scrollbarsRef.current.getScrollWidth() - visibleScrollWidth),
      );

      const step = delta < 0 ? -10 : 10;
      const duration = 10;

      intervalRef.current = setInterval(() => {
        const currentScrollLeft = scrollbarsRef.current.getScrollLeft();
        const newScrollLeft = currentScrollLeft + step;

        scrollbarsRef.current.scrollLeft(newScrollLeft);

        if (
          (delta > 0 && newScrollLeft >= targetScrollLeft)
          || (delta < 0 && newScrollLeft <= targetScrollLeft)
        ) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }

        if (
          (delta < 0 && newScrollLeft <= 0)
          || (delta > 0
            && newScrollLeft >= scrollbarsRef.current.getScrollWidth() - visibleScrollWidth)
        ) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      }, duration);
    }
  };

  const handleDragScroll = (delta) => {
    if (scrollbarsRef.current) {
      const visibleScrollWidth = getVisibleScrollWidth();
      const startScrollLeft = scrollbarsRef.current.getScrollLeft();

      if (
        (delta < 0 && startScrollLeft === 0)
        || (delta > 0
          && startScrollLeft === scrollbarsRef.current.getScrollWidth() - visibleScrollWidth)
      ) {
        return;
      }

      const newScrollLeft = startScrollXRef.current + delta;

      scrollbarsRef.current.scrollLeft(newScrollLeft);
    }
  };

  const handleScrollRight = () => {
    handleScroll(getVisibleScrollWidth());
  };

  const handleScrollLeft = () => {
    handleScroll(-getVisibleScrollWidth());
  };

  const handleMouseMove = (event) => {
    const delta = mouseMouseDownXRef.current ? mouseMouseDownXRef.current - event.clientX : 0;
    // console.log(770, delta);

    setIsDragging(true); // to prevent click on link when mouseUp
    /*     if (Math.abs(delta) > 5) {
      // skip minor movement
      console.log(779, isDragging);
    } */

    handleDragScroll(delta);
  };

  const handleMouseUp = () => {
    mouseMouseDownXRef.current = null;
    startScrollXRef.current = null;
    setIsDragging(false);
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('mousemove', handleMouseMove);
  };

  const handleMouseDown = (event) => {
    if (!draggable) {
      return;
    }

    mouseMouseDownXRef.current = event.clientX;
    startScrollXRef.current = scrollbarsRef.current.getScrollLeft();

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  useEffect(
    () => () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    },
    [],
  );

  return (
    <>
      <div
        className={className}
        ref={nextRaceListRef}
        onWheel={(e) => scrollable && handleScroll(e.deltaY)}
        onMouseDown={draggable && handleMouseDown}
      >
        <Scrollbars
          className={`scroll__container ${scrollClassName} ${isDragging ? 'scroll__container--dragging' : ''}`}
          ref={scrollbarsRef}
        >
          {children}
        </Scrollbars>
      </div>
      {navigationButtons ? (
        <div className='scroll__navigation-buttons next-race-nav'>
          <button type='button' className='next-race-nav__button' onMouseDown={handleScrollRight}>
            <span className='icon AIcon-angle-right' />
          </button>
          <button type='button' className='next-race-nav__button' onClick={handleScrollLeft}>
            <span className='icon AIcon-angle-left' />
          </button>
        </div>
      ) : null}
    </>
  );
};

export default Scrollbar;
