import React from 'react';
import cx from 'classnames';
import Label from '../../../shared/components/I18n/Label';

const ResultsTimeFilterItem = (props) => {
  const {
    onClick,
    active,
    title,
    subtitle,
  } = props;

  const dateListItemClass = cx('dateFilter__element', { active });

  return (
    <div className={dateListItemClass} onClick={onClick}>
      <div className='title'>
        <span>{title}</span>
        &nbsp;
        <Label className='subtitle' message={subtitle} />
      </div>
    </div>
  );
};

export default ResultsTimeFilterItem;
