import React from 'react';
import {
  CLIENT_PROFILE_COMPONENT_TYPES,
  clientProfileRoutes,
  responsibleGamingRoutes,
} from '@apollo/routing';
import SelfRegulation from './SelfRegulation/SelfRegulation';
import SelfExclusion from './SelfExclusion/SelfExclusion';
import RealityCheck from './RealityCheck/RealityCheck';
import ClientProfileSubRouter from '../ClientProfileSubRouter';

const { responsibleGaming } = clientProfileRoutes;
const { selfRegulation, selfExclusion, realityCheck } = responsibleGamingRoutes;

const getAvailableRoutes = (clientProfilePath) => ({
  [CLIENT_PROFILE_COMPONENT_TYPES.SELF_REGULATION]: {
    path: `${clientProfilePath}/${responsibleGaming}/${selfRegulation}`,
    component: SelfRegulation,
  },
  [CLIENT_PROFILE_COMPONENT_TYPES.SELF_EXCLUSION]: {
    path: `${clientProfilePath}/${responsibleGaming}/${selfExclusion}`,
    component: SelfExclusion,
  },
  [CLIENT_PROFILE_COMPONENT_TYPES.REALITY_CHECK]: {
    path: `${clientProfilePath}/${responsibleGaming}/${realityCheck}`,
    component: RealityCheck,
  },
});

const ResponsibleGamingRouter = ({ tabs }) => (
  <ClientProfileSubRouter
    tabs={tabs}
    getAvailableRoutes={getAvailableRoutes}
    parentRoute={responsibleGaming}
  />
);

export default ResponsibleGamingRouter;
