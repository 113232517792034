import React from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { useApplicationState } from '@apollo/core';
import { sportService } from '../../../../core/constants';
import useCurrentParticipantTurn from '../../../../shared/components/GameStat/useCurrentParticipantTurn';
import { EventLink } from '../../../../shared/components/Sport/SportLinks';
import Label from '../../../../shared/components/I18n/Label';
import Logo from '../../../../shared/components/Logo/Logo';

const { LIVE } = sportService;

const ParticipantName = ({ sportName, playerName }) => (
  <span
    className={cx(
      'event-participants__participant-name',
      `event-participants__participant-${sportName?.toLowerCase()}`
    )}
    title={playerName}
  >
    {playerName}
  </span>
);

const EventParticipants = ({ event, withSportIcon }) => {
  const [
    sportService,
    sportName,
    homeScore,
    homeName,
    homeId,
    homeLogoUri,
    awayScore,
    awayName,
    awayId,
    awayLogoUri,
  ] = _.at(event, [
    'sportService',
    'sportName',
    'info.score.homeScore',
    'participants.home.fullName',
    'participants.home.id',
    'participants.home.logoUri',
    'info.score.awayScore',
    'participants.away.fullName',
    'participants.away.id',
    'participants.away.logoUri',
  ]);
  const isLive = sportService === LIVE;
  const currentParticipantTurn = useCurrentParticipantTurn(event);
  const { layout } = useApplicationState();

  const homeClassName = cx(
    'event-participants__participant event-participants__participant--home',
    {
      win: homeScore > awayScore,
      lose: awayScore > homeScore,
      active: currentParticipantTurn === 'home',
      inactive: currentParticipantTurn && currentParticipantTurn !== 'home',
    }
  );

  const awayClassName = cx(
    'event-participants__participant event-participants__participant--away',
    {
      win: awayScore > homeScore,
      lose: homeScore > awayScore,
      active: currentParticipantTurn === 'away',
      inactive: currentParticipantTurn && currentParticipantTurn !== 'away',
    }
  );

  return (
    <EventLink className='event-participants' event={event}>
      {withSportIcon && (
        <div className={`item__logo sportIcon-${event.sportId}`} title={event.sportName} />
      )}
      <div className={homeClassName}>
        {!layout.mobileDevice && (
          <Logo participantId={homeId} logoUri={homeLogoUri} size='lg' alt={homeName} isHideEmpty />
        )}
        <ParticipantName sportName={sportName} playerName={homeName} />
        {isLive && <span className='event-participants__participant-score'>{homeScore}</span>}
      </div>
      <span>
        {' '}
        <Label message='versus' />{' '}
      </span>
      <div className={awayClassName}>
        {!layout.mobileDevice && (
          <Logo participantId={awayId} logoUri={awayLogoUri} size='lg' alt={awayName} isHideEmpty />
        )}
        <ParticipantName sportName={sportName} playerName={awayName} />
        {isLive && <span className='event-participants__participant-score'>{awayScore}</span>}
      </div>
    </EventLink>
  );
};

export default EventParticipants;
