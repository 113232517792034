import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { RaceTypesMap } from '@apollo/core/src/constants';
import { map } from 'lodash';
import FilterRaceType from './FilterRaceType';
import useNextRaces from '../../../../features/Racing/useNextRaces';
import Label from '../../I18n/Label';
import Dropdown from '../../Dropdown/Dropdown';
import RaceLink from '../../Racing/RaceLink';
import { RacingIcon } from '../../Racing/RacingIcon';
import RaceCountdown from '../../Racing/RaceCountdown/RaceCountdown';
import { AnimatedDropdown } from '../../../../features/Animations/AnimatedDropdown';
import Scrollbar from '../../Scrollbar/Scrollbar';

const NextToJumpBar = () => {
  const [selectedRaceTypes, setSelectedRaceTypes] = useState(Object.keys(RaceTypesMap));
  const [isExpanded, setExpanded] = useState(false);
  const toggleRef = useRef(null);

  const [nextToJump, nextRacesUpdate] = useNextRaces({
    raceTypes: selectedRaceTypes,
  });

  const onSelectRaceType = React.useCallback(
    (e) => {
      let updated = [...selectedRaceTypes];
      if (updated?.includes(e)) {
        updated = selectedRaceTypes.filter((item) => item !== e);
      } else {
        updated.push(e);
      }
      if (updated.length) {
        setSelectedRaceTypes(updated);
      }
    },
    [selectedRaceTypes],
  );

  const filtersList = useMemo(
    () => _.map(Object.keys(RaceTypesMap), (raceType) => (
      <div key={RaceTypesMap?.[raceType]?.id}>
        <FilterRaceType
          typeName={RaceTypesMap?.[raceType]?.typeName}
          id={RaceTypesMap?.[raceType]?.id}
          raceType={raceType}
          isChecked={selectedRaceTypes.includes(raceType)}
          onClick={() => onSelectRaceType(raceType)}
        />
      </div>
    )),
    [selectedRaceTypes],
  );

  return (
    <div className={classNames('next-jump-bar')}>
      <div className='next-jump-bar__item next-jump-bar__item--title'>
        <Label message='next_to_jump' className='next-jump-bar__title' />
      </div>
      <div className='next-jump-bar__item next-jump-bar__item--filters'>
        <Dropdown
          onExpanded={setExpanded}
          className='filter-race-type-container'
          toggleRef={toggleRef}
        >
          <div ref={toggleRef}>
            <Label message='Filter' />
          </div>
          <AnimatedDropdown isExpanded={isExpanded} className='history__dropdown'>
            {filtersList}
          </AnimatedDropdown>
        </Dropdown>
      </div>
      <Scrollbar
        className='next-race-list'
        scrollClassName='next-race-list__scroll'
        navigationButtons
      >
        {map(nextToJump, (r) => (
          <RaceLink
            key={r.raceId}
            className='next-jump-bar__item'
            raceDate={r.meeting?.date}
            raceType={r.raceType?.typeName}
            raceCountry={r.venue?.country}
            raceVenue={r.venue?.venueName}
            raceNumber={r.number}
          >
            <RacingIcon id={r.raceType?.id} name={r.raceType?.typeName} />
            <span className='race-name'>{r.venue?.venueName}</span>
            <span className='race-number'>
              R
              {r.number}
            </span>

            <RaceCountdown
              key={`${r?.raceId}-${r?.status}-${r?.result}`}
              date={new Date(r.startTime)}
              completeText={r.result || r.status}
              onComplete={nextRacesUpdate}
            />
          </RaceLink>
        ))}
      </Scrollbar>
    </div>
  );
};

export default NextToJumpBar;
