import React from 'react';
import { orderByOrderPosition } from '../../../core/utils';
import DetailsMarketType from '../../Sports/DetailsPage/DetailsMarketType/DetailsMarketType';

const ResultDetailsMarketGroup = ({ event }) => {
  const { marketTypes } = event;

  const orderedMarkets = orderByOrderPosition(marketTypes);

  return orderedMarkets.map((marketType) => (
    <DetailsMarketType
      key={marketType.id}
      marketType={marketType}
      event={event}
      isArchived
      isBadgeHeaderHidden
    />
  ));
};

export default ResultDetailsMarketGroup;
