import React from 'react';
import Label from '../../../../shared/components/I18n/Label';

const EmptyBonusBanners = () => (
  <div className='no-bonuses-message'>
    <h3>
      <Label message='no_bonuses_available' />
    </h3>
    <Label message='check_bonuses_later' />
  </div>
);

export default EmptyBonusBanners;
