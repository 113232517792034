import React from 'react';
import _ from 'lodash';
import CmdLink from '../../../components/CmdLink/CmdLink';
import Label from '../../../components/I18n/Label';

const Navigation = ({ types }) => {
  if (types.length === 1) {
    return null;
  }

  return (
    <div className='block__content__types'>
      {
        _.map(types, ({ title }) => (
          <CmdLink
            key={title}
            cmd='signin'
            path={_.camelCase(title)}
            className='element'
          >
            <Label message={title} />
          </CmdLink>
        ))
      }
    </div>
  );
};

export default Navigation;
