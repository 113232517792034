import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { Bus, CmsApi, useSettings } from '@apollo/core';
import { number } from 'prop-types';

import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import { map } from 'lodash';
import BannerSliderItem from './BannerSliderItem';

const BannerSlider = React.memo(({ group }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [banners, setBanners] = useState([]);
  const { lang } = useSettings();

  const loadBanners = useCallback(async () => {
    await CmsApi.getBanner({
      group,
      lang,
    }).then((data) => {
      setBanners(data);
    });
  }, [group, lang]);

  useEffect(() => {
    loadBanners().then();
    Bus.on(Bus.events.auth.login, loadBanners);
    Bus.on(Bus.events.auth.logout, loadBanners);
    return () => {
      Bus.off(Bus.events.auth.login, loadBanners);
      Bus.off(Bus.events.auth.logout, loadBanners);
    };
  }, [loadBanners]);

  const autoPlay = useMemo(() => banners?.length > 1, [banners]);

  return (
    <div className='banner-slider'>
      <Carousel
        infinite={autoPlay}
        slidesPerPage={1}
        dots={autoPlay}
        autoPlay={autoPlay ? 8000 : null}
        animationSpeed={1000}
      >
        {map(banners, (bannerData, index) => (
          <BannerSliderItem
            key={index}
            index={index}
            activeIndex={activeIndex}
            bannerData={bannerData}
          />
        ))}
      </Carousel>
    </div>
  );
});

BannerSlider.propTypes = {
  group: number.isRequired,
};

export default BannerSlider;
