import React, { useCallback, useMemo, Fragment } from 'react';
import { prepareRequest, useApplicationState } from '@apollo/core';
import _ from 'lodash';
import useTranslate from '../../../I18n/Interpreter';
import { setEmailVerificationRequested } from '../../../../../core/utils';
import ToastManager from '../../../../../core/ToastManager';
import Label from '../../../I18n/Label';

const VerifyBlock = () => {
  const t = useTranslate();
  const applicationState = useApplicationState();
  const [
    email,
    emailVerified,
    emailVerificationRequested,
  ] = _.at(
    applicationState,
    [
      'account.email',
      'account.emailVerified',
      'account.emailVerificationRequested',
    ],
  );

  const verifyHandler = useCallback(async () => {
    try {
      const request = prepareRequest({
        method: 'POST',
        url: 'client/clients/email/confirmation-request',
      });
      await request();
      setEmailVerificationRequested(true);
      ToastManager.success(() => <Label message='verification_request_successful' />);
    } catch (error) {
      ToastManager.error(() => <Label message='verification_request_unsuccessful' />);
    }
  }, []);

  const renderStatus = useMemo(() => {
    let icon;

    if (emailVerified) {
      icon = (
        <div className='input-action-button-group' title={t('verification success')}>
          <div className='input-action-button'>
            <span className='field-status-icon AkIcon-check-icon' />
          </div>
        </div>
      );
    } else if (emailVerificationRequested) {
      icon = (
        <div className='input-action-button-group' title={t('verification in process')}>
          <div className='input-action-button'>
            <span className='field-status-icon AkIcon-reality-check-icon' />
          </div>
        </div>
      );
    }

    return (
      <div className='field-status-container'>
        <div className='field-status'>
          {icon}
        </div>
      </div>
    );
  }, [emailVerified, emailVerificationRequested]);

  const renderButton = useMemo(
    () => {
      if (!email || emailVerified || emailVerificationRequested) {
        return null;
      }

      return (
        <Label
          className='bBase verify-btn'
          message='verify'
          onClick={verifyHandler}
        />
      );
    },
    [
      email,
      emailVerified,
      verifyHandler,
      emailVerificationRequested,
    ],
  );

  return (
    <>
      {renderStatus}
      {renderButton}
    </>
  );
};

export default VerifyBlock;
