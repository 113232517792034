import _ from 'lodash';

export const getSportsWithTournamentsByPreferences = ({ tournaments, sports, preferences }) => {
  const preferencesSports = _.get(preferences, 'sports');

  return (
    _(tournaments)
      .groupBy('sportId')
      .toPairs()
      .map(([sportId, tournaments]) => {
        const preferencesSport = _.find(preferencesSports, ({ id: _.toNumber(sportId) }));
        if (!preferencesSport) {
          return;
        }

        const sport = _.find(sports, { id: _.toNumber(sportId) });

        const isSportFavorite = _.get(preferencesSport, 'isFavorite');
        const preferencesTournaments = _.get(preferencesSport, 'tournaments');

        let availableTournaments;

        if (isSportFavorite) {
          availableTournaments = _.filter(tournaments, ({ id }) => (
            !_.find(
              preferencesTournaments,
              ({ id: favId, isExcluded, events }) => id === favId && isExcluded && _.isEmpty(events),
            )
          ));
        } else {
          availableTournaments = _.filter(tournaments, ({ id }) => (
            _.find(preferencesTournaments, ({ id: favId }) => id === favId)
          ));
        }

        return ({
          sportId,
          sport,
          tournaments: availableTournaments,
        });
      })
      .compact()
      .sortBy('sport.position')
      .value()
  );
};

export const getEventsByPreferences = ({ events, preferences }) => {
  if (_.isEmpty(events)) return [];

  const sportId = _.get(events, '0.sportId');
  const tournamentId = _.get(events, '0.tournamentId');

  const preferencesSports = _.get(preferences, 'sports');
  const currentSport = _.get(preferencesSports, sportId);
  const isSportFavorite = _.get(currentSport, 'isFavorite');
  const preferencesTournaments = _.get(currentSport, 'tournaments');
  const currentTournament = _.get(preferencesTournaments, tournamentId);
  const isTournamentExcluded = _.get(currentTournament, 'isExcluded');
  const isTournamentFavorite = _.get(currentTournament, 'isFavorite');
  const preferencesEvents = _.get(currentTournament, 'events');
  let availableEvents = [];

  if (isSportFavorite && !isTournamentExcluded && _.isEmpty(preferencesEvents)) {
    return events;
  }

  if (isTournamentFavorite) {
    availableEvents = _.filter(events, ({ id }) => (
      !_.find(preferencesEvents, ({ id: favId, isExcluded }) => id === favId && isExcluded)
    ));
  } else {
    availableEvents = _.filter(events, ({ id }) => (
      _.find(preferencesEvents, ({ id: favId }) => id === favId)
    ));
  }

  return availableEvents;
};
