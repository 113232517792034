import React, { useMemo } from 'react';
import { BONUS_TYPE } from '@apollo/core/src/constants';
import { centsToDecimal } from '@apollo/core';
import { negativeBalanceTypes, transactionTypes } from '../../../../../core/constants';
import { getCurrencySymbol } from '../../../../../core/utils';
import BetSourceTooltip from '../../BetHistory/TableList/BetSourceTooltip';

const { BONUS, WITHDRAWAL_COMPLETE } = transactionTypes;

const TransactionAmount = ({ transaction, bonusInfoList, bonusType }) => {
  const { type, amount, cashBalanceBefore, cashBalanceAfter, currency } = transaction;

  const calcAmount = useMemo(
    () =>
      type !== WITHDRAWAL_COMPLETE && cashBalanceBefore > 0
        ? Math.abs(cashBalanceAfter - cashBalanceBefore)
        : amount,
    [type, amount, cashBalanceAfter, cashBalanceBefore]
  );

  const baseAmount = useMemo(() => centsToDecimal(amount), [amount]);
  const decimalAmount = useMemo(() => centsToDecimal(calcAmount), [calcAmount]);

  const isBonusTransaction = useMemo(
    () => !!bonusInfoList || type === BONUS,
    [bonusInfoList, type]
  );
  const currencySymbol = getCurrencySymbol(currency);

  return (
    <div className='transaction-amount'>
      <BetSourceTooltip bonusInfoList={bonusInfoList} />
      {isBonusTransaction ? (
        <div className='bonus-transaction'>
          <div
            className={`bonus-transaction__value bonus-transaction__value--${negativeBalanceTypes[type] ? 'neg' : 'pos'}`}
          >
            {[BONUS_TYPE.ODDS_BOOST, BONUS_TYPE.PROMO_ODDS, BONUS_TYPE.BET_RETURN].includes(
              bonusType
            )
              ? `${negativeBalanceTypes[type] ? '-' : ''}${currencySymbol}${decimalAmount}`
              : `${negativeBalanceTypes[type] ? '-' : ''}${currencySymbol}${baseAmount}`}
          </div>
        </div>
      ) : (
        <span className='bet amount'>
          {`${negativeBalanceTypes[type] ? '-' : ''}${currencySymbol}${decimalAmount}`}
        </span>
      )}
    </div>
  );
};

export default TransactionAmount;
