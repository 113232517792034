import React from 'react';
import { isMultilegsMarket } from '@apollo/core';
import useTranslate from '../../../../../shared/components/I18n/Interpreter';
import BetItemEventLink from '../../../../../shared/components/BetItemEventLink/BetItemEventLink';
import Accordion from '../../../../../shared/components/Accordion/Accordion';

const TransactionEventDetailsMobile = ({
  outcome,
  eventStartTimeDate,
  raceNumbers,
  eventName,
  marketName,
  subTitle,
}) => {
  const { sameGameMultiLegs } = outcome;
  const selection = outcome.localizedOutcomeName;

  const t = useTranslate();

  const formatSelections = (sel) => {
    function nth(n) {
      return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] ?? 'th';
    }
    const values = sel.split(',').join(', ').split('/');

    if (values.length === 1) {
      return (
        <div className='event-leg'>
          <span>{values}</span>
        </div>
      );
    }

    return (
      <Accordion className='event-legs' collapsed titleOpen={t('hide_legs_more')}>
        {values.map((val, key) => (
          <div key={key} className='event-leg'>
            {values.length > 1 && `${key + 1}${nth(key + 1)}: `}
            <span>{val}</span>
          </div>
        ))}
      </Accordion>
    );
  };

  return (
    <div key={`${outcome.marketId}_${outcome.outcomeName}`} className='event-details'>
      <BetItemEventLink className='event-name' outcomeInfo={outcome}>
        <div className='event'>
          {selection && sameGameMultiLegs?.length ? (
            <>
              {sameGameMultiLegs?.length} {t('legs')} {marketName}
            </>
          ) : null}
          <div>{eventName}</div>
        </div>
      </BetItemEventLink>
      {selection && !sameGameMultiLegs?.length && (
        <div className='event__market-name'>
          {isMultilegsMarket(outcome.marketTypeId) ? (
            `${marketName.split(' (')[0]}`
          ) : (
            <div className='sub-title'>
              {marketName}
              <div className='text-small'>{formatSelections(selection)}</div>
            </div>
          )}
        </div>
      )}
      {sameGameMultiLegs?.length ? (
        <Accordion className='event-legs' collapsed titleOpen={t('hide_legs_more')}>
          {sameGameMultiLegs.map((leg) => (
            <div key={leg.outcomeId} className='event-leg'>
              {leg.localizedOutcomeName}
              <div>{leg.localizedMarketName}</div>
            </div>
          ))}
        </Accordion>
      ) : null}
      {isMultilegsMarket(outcome.marketTypeId) && raceNumbers.length ? (
        <Accordion className='event-legs' collapsed titleOpen={t('hide_legs_more')}>
          {raceNumbers.map((number, i) => (
            <div key={number} className='event-leg'>
              {`R${number}: `}
              <span className=''>{outcome.outcomeName?.split('/')[i]}</span>
            </div>
          ))}
        </Accordion>
      ) : null}
      <div className='start-date'>{eventStartTimeDate}</div>
      <div>{subTitle}</div>
    </div>
  );
};

export default TransactionEventDetailsMobile;
