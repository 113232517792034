import React from 'react';
import { hasMarketTypes, isHidden } from '@apollo/core';
import { BonusSelector } from '../../../state/Bonus/Bonus';
import useTranslate from '../I18n/Interpreter';

const BetReturnBonusIcon = ({ race = null, event = null }) => {
  const t = useTranslate();

  const isShow = race || (event && hasMarketTypes(event) && !isHidden(event));

  return BonusSelector.hasActiveBetReturn(race?.raceId || event?.id) && isShow ? (
    <i className='icons icon--bet-bonus-all' title={t('bet return')} />
  ) : null;
};

export default BetReturnBonusIcon;
