import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Bus, useApplicationState, useSettings } from '@apollo/core';
import { useHistory } from 'react-router-dom';
import { staticRoutes } from '@apollo/routing';
import cx from 'classnames';
import HeaderMenuList from './HeaderMenu/HeaderMenuList';
import HeaderInfo from './HeaderStatusBlock/HeaderInfo';
import HeaderLogoItem from './HeaderMenu/HeaderLogoItem';
import ChangeLang from './HeaderStatusBlock/ChangeLang/ChangeLang';
import BackButton from './HeaderMenu/BackButton';

const routes = Object.values(staticRoutes);

const Header = () => {
  const { layout } = useApplicationState();
  const { buildMode, isCordova, platform } = useSettings();
  const cordovaDev = useMemo(
    () => isCordova && buildMode && buildMode.toLowerCase() !== 'production',
    [isCordova, buildMode],
  );

  const [isShowBack, setIsShowBack] = useState(false);

  const {
    location: { pathname },
    goBack,
  } = useHistory();

  const toggleMobileMenu = React.useCallback(() => {
    Bus.send({
      event: Bus.events.layout.setSideBarLeft,
      data: !layout.sideBarLeft,
    });
  }, [Bus, layout]);

  useEffect(() => {
    if (pathname === '/' || routes.find((route) => route === pathname)) {
      // show logo
      setIsShowBack(false);
    } else {
      // show go back button if it's root route
      setIsShowBack(true);
    }
  }, [pathname]);

  const onClickBack = useCallback(() => {
    goBack();
  }, [goBack]);

  const classes = useMemo(() => cx([
    'header',
    isCordova && 'header--cordova',
    cordovaDev && 'header--cordova-dev',
    platform && `header--platform-${platform}`,
  ]), [isCordova, buildMode, platform]);

  return (
    <header className={classes}>
      <div className='header__container'>
        <button
          className={`navbar-toggler ${
            layout.sideBarLeft !== true ? 'navbar-toggler--collapsed' : ''
          }`}
          type='button'
          aria-controls='navbarSupportedContent'
          aria-label='Toggle navigation'
          onClick={toggleMobileMenu}
        >
          <svg
            width={layout.sideBarLeft ? '45' : '40'}
            height={layout.sideBarLeft ? '45' : '40'}
            viewBox='0 0 100 100'
          >
            <path
              className='line line1'
              d='M 20,40.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058'
            />
            <path
              className='line line2'
              d='M 20,60.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942'
            />
            {layout.sideBarLeft !== true ? (
              <path
                className='line line2'
                d='M 20,80.999954 H 80.000231 C 80.000231,121.999954 94.498839,124.182648 94.532987,0.288669 94.543142,-33.019327 90.966081,18.329754 85.259173,18.331003 79.552261,-45.332249 75.000211,-34.000058 75.000211,-34.000058 L 25.000021,123.999942'
              />
            ) : null}
          </svg>
        </button>

        {
            isShowBack && layout.mobileDevice
              ? <BackButton onClick={onClickBack} />
              : <HeaderLogoItem location='/' />
          }

        <HeaderMenuList />

        <ChangeLang />

        {/* <HeaderClock /> */}

        <HeaderInfo />
      </div>
    </header>
  );
};

export default Header;
